<div class="document-view {{ mode }}" #pdfContainer>
    <!--Loading Indicator-->
    <div class="loading-indicator" *ngIf="shouldDisplayLoadingIndicator">
        <div class="image-and-text">
            <div class="image">
                <img src="/assets/images/loading-indicator.gif" />
            </div>
            <div class="text">Loading page...</div>
        </div>
    </div>

    <!--Navigation-->
    <div class="navigation">
        <div class="inner">
            <!--Zoom-->
            <div class="zoom">
                <!--Button Zoom-->
                <div class="zoom-button full" *ngIf="mode === 'consume'">
                    <button
                        (click)="fitZoomToFullScreen()"
                        class="app-button small no-text light"
                    >
                        <i class="material-icons icon" *ngIf="fullScreenMode"
                            >fullscreen_exit</i
                        >
                        <i class="material-icons icon" *ngIf="!fullScreenMode"
                            >fullscreen</i
                        >
                    </button>
                </div>

                <!--Button Left-->
                <div class="zoom-button out">
                    <button
                        (click)="updateZoom(false)"
                        [disabled]="
                            currentZoomViewerValue * 100 <= zoomSliderMin
                        "
                        class="app-button small no-text light"
                    >
                        <i class="material-icons icon">zoom_out</i>
                    </button>
                </div>

                <!--Pages Drop-Down-->
                <div class="zoom-details">
                    <div class="numbers">{{ currentZoomControlValue }}%</div>
                </div>

                <!--Button Right-->
                <div class="zoom-button in">
                    <button
                        (click)="updateZoom(true)"
                        [disabled]="
                            currentZoomViewerValue * 100 >= zoomSliderMax
                        "
                        class="app-button small no-text light"
                    >
                        <i class="material-icons icon">zoom_in</i>
                    </button>
                </div>
            </div>

            <!--Pagination-->
            <div class="pagination">
                <!--Button Left-->
                <div class="paging-button up">
                    <button
                        (click)="displayPreviousPage()"
                        [disabled]="!shouldDisplayButton('previous')"
                        class="app-button small no-text blue"
                    >
                        <i class="material-icons icon">keyboard_arrow_left</i>
                    </button>
                </div>

                <!--Pages Drop-Down-->
                <div class="paging-details">
                    <div class="number-of-pages">
                        {{ currentPageIndex + 1 }}&nbsp;&nbsp;/&nbsp;&nbsp;{{
                            numberOfPages
                        }}
                    </div>
                </div>

                <!--Button Right-->
                <div class="paging-button down">
                    <button
                        (click)="displayNextPage()"
                        [disabled]="!shouldDisplayButton('next')"
                        class="app-button small no-text blue"
                    >
                        <i class="material-icons icon">keyboard_arrow_right</i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!--Viewer-->
    <div class="viewer" #pdfWrapper>
        <pdf-viewer
            [(page)]="currentPageNumber"
            [src]="documentUrl"
            [zoom]="currentZoomViewerValue"
            [original-size]="false"
            [fit-to-page]="true"
            [render-text]="true"
            [autoresize]="true"
            [show-all]="false"
            [external-link-target]="'blank'"
            (after-load-complete)="afterLoadComplete($event)"
            class="app-pdf-viewer"
        >
        </pdf-viewer>
    </div>
</div>
