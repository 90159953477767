import {
    AfterContentChecked,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { AppSelectOption } from './classes/app.select.option';
import { AppHelpersService } from '../../services/app.helpers.service';

@Component({
    selector: 'app-select',
    templateUrl: './app.select.component.html',
    styleUrls: ['./app.select.component.less'],
})
export class AppSelectComponent implements OnInit, AfterContentChecked {
    isClicked = false;
    selectedIndex: number;
    values = {
        calculated: {
            width: undefined,
            optionsWidth: undefined,
            height: undefined,
            lineHeight: undefined,
            scrollHeight: undefined,
            top: undefined,
            fontSize: undefined,
        },
        display: {
            width: undefined,
            optionsWidth: undefined,
            height: undefined,
            lineHeight: undefined,
            scrollHeight: undefined,
            top: undefined,
            fontSize: undefined,
        },
    };

    private defaultDisplayDirection = 'bottom'; // 'top', 'bottom'; Future: 'left', 'right', 'auto'
    private defaultHeight = 30;
    private defaultScrollHeight = 75;
    private defaultFontSize = 12;
    private defaultWidth = 125;
    private defaultOption: AppSelectOption;

    @Input() options: Array<AppSelectOption>;
    @Input() selected: AppSelectOption;
    @Input() shouldIncludeDefaultValue: boolean;
    @Input() defaultLabel = 'None';
    @Input() width: number;
    @Input() height: number;
    @Input() scrollHeight: number;
    @Input() roundBorders: boolean;
    @Input() displayDirection: string;
    @Input() label: string;
    @Input() style: string;
    @Input() deselectable = false;

    @Output() update: EventEmitter<AppSelectOption> =
        new EventEmitter<AppSelectOption>();

    constructor(private appHelpersService: AppHelpersService) {}

    ngOnInit() {
        // Init should display select property.
        this.defaultOption = new AppSelectOption(this.defaultLabel, '');
        if (this.shouldIncludeDefaultValue === true) {
            this.options.unshift(this.defaultOption);
        }

        if (!this.selected) {
            this.selected = this.defaultOption;
        }

        // Initialize direction.
        if (!this.displayDirection) {
            this.displayDirection = this.defaultDisplayDirection;
        }

        // Calculate values.
        this.values.calculated.width = this.width
            ? this.width
            : this.defaultWidth;
        this.values.calculated.optionsWidth = this.width
            ? this.width + 10
            : this.defaultWidth;
        this.values.calculated.height = this.height
            ? this.height
            : this.defaultHeight;
        this.values.calculated.lineHeight = this.values.calculated.height;
        this.values.calculated.scrollHeight = this.calculateScrollHeight();
        this.values.calculated.top = this.calculateTop();
        this.values.calculated.fontSize = this.height
            ? this.height * 0.5 > this.defaultFontSize
                ? this.defaultFontSize
                : this.height * 0.5
            : this.defaultFontSize;

        // Using values, calculate display values.
        Object.keys(this.values.calculated).forEach((value) => {
            this.values.display[value] = this.values.calculated[value] + 'px';
        });
    }

    ngAfterContentChecked() {
        // Init selected option index.
        if (this.selected) {
            this.selectedIndex = this.appHelpersService.findIndexByProperty(
                this.options,
                'value',
                this.selected.value
            );
        }
    }

    getOptionClass(index: number): string {
        return !isNaN(this.selectedIndex) && this.selectedIndex === index
            ? 'option selected'
            : 'option';
    }

    handleOptionClick(option: AppSelectOption, index: number): void {
        if (this.deselectable && this.isOptionSelected(option)) {
            this.selected = null;
            this.selectedIndex = null;
        } else {
            this.selected = option;
            this.selectedIndex = index;
        }
        this.isClicked = false;
        this.update.emit(this.selected);
    }

    toggleSelect(): void {
        this.isClicked = !this.isClicked;

        if (this.isClicked === true) {
            this.values.calculated.scrollHeight =
                this.calculateScrollHeight(true);
            this.values.calculated.top = this.calculateTop();
            this.values.display.scrollHeight =
                this.values.calculated.scrollHeight + 'px';
            this.values.display.top = this.values.calculated.top + 'px';
        }
    }

    isOptionSelected(option: AppSelectOption): boolean {
        return this.selected === option;
    }

    getLabel(): string {
        if (this.selected) {
            return this.selected.label === 'None'
                ? this.label
                    ? this.label
                    : this.selected.label
                : this.selected.label;
        } else {
            return this.label
                ? this.label
                : this.defaultLabel
                ? this.defaultLabel
                : 'None';
        }
    }

    private calculateScrollHeight(isHeightUpdate = false): number {
        const numberOfOptions = this.options.length;
        let calculatedHeight = this.defaultScrollHeight;

        if (!isHeightUpdate && this.scrollHeight) {
            calculatedHeight = this.scrollHeight;
        } else {
            calculatedHeight = numberOfOptions * (this.values.calculated.height - 2);
        }

        return calculatedHeight;
    }

    private calculateTop(): number {
        let style = 0;

        if (this.displayDirection === 'bottom') {
            style = this.values.calculated.height;
        } else if (this.displayDirection === 'top') {
            style = this.values.calculated.scrollHeight * -1;
        }

        return style;
    }
}
