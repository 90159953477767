<div class="app-dialog dialog-upload-contacts">
    <div class="header">
        Upload
        <a
            href="https://support.vingapp.com/how-to-import-contacts"
            target="_blank"
        >
            <i class="material-icons icon">help</i>
        </a>
    </div>

    <form>
        <div class="body" id="upload-contacts-body">
            <!--Alert-->
            <app-alert></app-alert>

            <!--Drag-drop file space-->
            <ngx-file-drop
                class="file-drag-drop {{
                    contactIsFileOver ? 'file-over' : ''
                }}"
                dropZoneClassName="file-dropper-area"
                dropZoneLabel="Drop files here"
                (onFileDrop)="contactFilesDropped($event)"
                (onFileOver)="contactIsFileOver = true"
                (onFileLeave)="contactIsFileOver = false"
            >
                <ng-template ngx-file-drop-content-tmp>
                    <div *ngIf="!isFileSet()" style="margin: 10px">
                        <div class="text">Drop Import Files Here</div>
                        <div class="or">
                            <fieldset>
                                <legend>or</legend>
                            </fieldset>
                        </div>
                        <div class="browse">
                            <button
                                type="button"
                                class="app-button green w-90"
                                (click)="openFileDialog()"
                            >
                                Browse
                            </button>
                            <!--                            <input-->
                            <!--                                cdkFocusInitial-->
                            <!--                                type="file"-->
                            <!--                                accept=".csv,.tsv,.xlsx"-->
                            <!--                                (change)="selectFile($event)"-->
                            <!--                            />-->
                        </div>
                    </div>

                    <div class="file-drag-drop-list" *ngIf="isFileSet()">
                        <!--File-->
                        <div class="image">
                            <img
                                src="/assets/images/icons/file.png"
                                height="60"
                            />
                        </div>
                        <div class="filename ellipsis-text">
                            {{ contactFile.name }}
                        </div>
                        <div class="close" *ngIf="!contactFile.fileKey">
                            <i
                                class="material-icons icon"
                                title="Remove file upload."
                                (click)="removeFromFilesList(contactFile.id)"
                                >close</i
                            >
                        </div>
                    </div>
                </ng-template>
            </ngx-file-drop>
        </div>

        <div class="footer">
            <button
                mat-dialog-close
                class="app-button light w-90 mr-1"
                (click)="close()"
            >
                {{ success ? 'Done' : 'Cancel' }}
            </button>
            <button
                class="app-button green w-90"
                (click)="save()"
                [disabled]="!isFileSet()"
                *ngIf="!success && !error"
            >
                Upload
            </button>
            <button
                class="app-button green w-90"
                (click)="reset()"
                *ngIf="success || error"
            >
                {{ success ? 'Upload Another' : 'Try Again' }}
            </button>
        </div>
    </form>
</div>
