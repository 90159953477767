import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class AppHeaderService {
    public page = new BehaviorSubject(null);
    public events = new Subject<any>();

    set(page) {
        this.page.next(page);
    }

    destroy() {
        this.page.next(null);
    }
}
