import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialog,
} from '@angular/material/dialog';
import { AppAlertService } from '../../../../shared/services/app.alert.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';

@Component({
    templateUrl: './app.dialog-resolve.component.html',
    styleUrls: ['./app.dialog-resolve.component.less'],
})
export class AppDialogResolveComponent implements OnInit {
    field: string;
    apis = {
        postSubmissions: 'POST:api/resolve/submissions/',
        postResponses: 'POST:api/resolve/responses/',
    };
    messages = {
        success: 'Resolving was successful.',
        error: 'There was an error while resolving.',
    };
    error = false;
    selectedItems = [];
    confirmationText = '';
    results: any;
    isResponses = false;
    constructor(
        private appHttpService: AppHttpService,
        private appMessageService: AppMessageService,
        private appHelpersService: AppHelpersService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        public dialogRef: MatDialogRef<AppDialogResolveComponent>,
        private dialog: MatDialog,
        private appAlertService: AppAlertService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            analyticsFilterUrl: any;
            analyticsFilterCount: number;
            contactName: string;
            page: string;
            id: number;
            selected: any[];
            allSelected: boolean;
            allSelectedIndexes: any[];
            excluded: any[];
            results: any;
            rows: any;
            isOverview: boolean;
        }
    ) {
        this.setResults();
        this.setDataType();
    }

    private setResults() {
        if (this.data.results.data) {
            this.results = this.data.results.data.results;
        } else {
            this.results = this.data.results.results;
        }
    }

    private setDataType() {
        if (this.data.page === 'CHECKLIST-SUBMISSIONS' && !this.data.isOverview) {
            this.isResponses = true;
        }
    }

    setSelectedItems(selected: any[]) {
        return selected.forEach((id) => {
            const found = this.results.find(
                (item) => item.response ? item.response.responseId == id : item.submissionId === id
            );

            if (found !== undefined) {
                this.selectedItems.push(found);
            }
        });
    }

    ngOnInit() {
        // TODO - should simplify this and find out why data comes back differently in some cases
        if (this.data.allSelected) {
            this.setSelectedItems(this.data.allSelectedIndexes);
        } else {
            this.setSelectedItems(this.data.selected);
        }

        this.confirmationText = 'This will resolve ';

        if (!this.isResponses) {
            this.confirmationText += 'all flagged responses for the selected submission';
        } else {
            this.confirmationText += 'the selected flagged response';
        }

        if (this.selectedItems.length > 1) {
            this.confirmationText += 's';
        }

        this.confirmationText += '. Are you sure you want to continue?';
    }

    getResolveUrl() {
        if (!this.isResponses) {
            return this.apis.postSubmissions;
        } else {
            return this.apis.postResponses;
        }
    }

    resolve() {
        let resolveData = {};

        if (this.data.allSelected) {
            resolveData['analyticsFilter'] = {
                requestUrl: this.data.analyticsFilterUrl,
                count: this.data.analyticsFilterCount,
                excludedIds: this.data.excluded,
            };
        } else if (!this.isResponses) {
            resolveData = {
                submission_ids: this.data.selected,
            };
        } else {
            resolveData = {
                response_ids: this.data.selected,
            };
        }

        this.appHttpService.request(
            this.getResolveUrl(),
            resolveData,
            (res) => {
                this.dialogRef.close();
                this.appMessageService.show(
                    'app',
                    'success',
                    this.messages.success,
                    4
                );
                this.appLoadingIndicatorService.hide();
            },
            (err) => {
                this.appLoadingIndicatorService.hide();
                this.appMessageService.show(
                    'modal',
                    'error',
                    this.messages.error,
                    4
                );
            }
        );
    }
}
