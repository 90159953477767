import { Injectable } from '@angular/core';
import { AppInfo } from './app.info';

@Injectable()
export class AppInfoService {
    private appInfo: AppInfo;

    setAppInfo(appInfo: AppInfo): void {
        this.applyAppInfoFix(appInfo);
        this.appInfo = appInfo;
    }

    getAppInfo(): AppInfo {
        return this.appInfo;
    }

    getAppInfoProperty(property: string): object {
        return this.appInfo[property];
    }

    private applyAppInfoFix(appInfo: AppInfo): void {
        // Special manual conversions due to issues with js .replace().

        // componentAnalyticsInfo
        appInfo.analyticsInfo['componentAnalyticsInfo'] =
            appInfo.analyticsInfo['component_analyticsInfo'];
        delete appInfo.analyticsInfo['component_analyticsInfo'];

        // contactAssignmentsAnalyticsInfo
        appInfo.analyticsInfo['contactAssignmentsAnalyticsInfo'] =
            appInfo.analyticsInfo['contact_assignments_analyticsInfo'];
        delete appInfo.analyticsInfo['contact_assignments_analyticsInfo'];

        // contactSubmissionsAnalyticsInfo
        appInfo.analyticsInfo['contactSubmissionsAnalyticsInfo'] =
            appInfo.analyticsInfo['contact_submissions_analyticsInfo'];
        delete appInfo.analyticsInfo['contact_submissions_analyticsInfo'];

        // packetAnalyticsInfo
        appInfo.analyticsInfo['packetAnalyticsInfo'] =
            appInfo.analyticsInfo['packet_analyticsInfo'];
        delete appInfo.analyticsInfo['packet_analyticsInfo'];

        // questionAnalyticsInfo
        appInfo.analyticsInfo['questionAnalyticsInfo'] =
            appInfo.analyticsInfo['question_analyticsInfo'];
        delete appInfo.analyticsInfo['question_analyticsInfo'];

        // checklist_analyticsInfo
        appInfo.analyticsInfo['checklistAnalyticsInfo'] =
            appInfo.analyticsInfo['checklist_analyticsInfo'];
        delete appInfo.analyticsInfo['checklist_analyticsInfo'];
    }
}
