<div class="app-dialog-image">
    <div class="header">
        Image Preview

        <button class="close" (click)="onConfirm()">&times;</button>
    </div>

    <div class="body">
        <img [src]="data.url" alt="Image" />
    </div>
</div>
