import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AppInfoService } from '../../../../shared/services/app-info/app.info.service';
import { AppSelectOption } from '../../../../shared/components/app-select/classes/app.select.option';
import { Utils } from '../../../../shared/app.utils';
import { debounceTime } from 'rxjs/operators';
import {Checklist} from "../../classes/checklist";

@Component({
    selector: 'app-checklists-cards',
    styleUrls: ['./list.component.less'],
    templateUrl: './list.component.html',
})
export class ListComponent implements OnInit, OnChanges {
    private lastSearchText = '';
    private searchSubject: Subject<string> = new Subject();

    searchText: string;

    @Input() searchStr: string;
    @Input() checklists: Array<Checklist>;
    @Input() sorts: Array<AppSelectOption>;
    @Input() selectedSort: AppSelectOption;
    @Input() statuses: Array<AppSelectOption>;
    @Input() selectedStatus: AppSelectOption;
    @Input() owners: Array<AppSelectOption>;
    @Input() selectedOwner: AppSelectOption;
    @Input() selectedChecklistIds: Array<number>;
    @Input() excludedChecklistIds: Array<number>;
    @Input() selectedAllChecklists: boolean;
    @Input() shouldDisplayHorizontal: boolean;
    @Input() selectedChecklist: Checklist;
    @Input() checklistsCount: number;
    @Input() isSchedulerOpen: boolean;

    @Output() handleToggleChecklistId = new EventEmitter<any>();

    @Output() handleSearchChange = new EventEmitter<any>();
    @Output() handleSortOptionChange = new EventEmitter<any>();
    @Output() handleStatusOptionChange = new EventEmitter<any>();
    @Output() handleOwnerOptionChange = new EventEmitter<any>();
    @Output() handleLazyLoadChecklists = new EventEmitter<any>();
    @Output() handleClickChecklist = new EventEmitter<any>();
    @Output() handleEditChecklist = new EventEmitter<any>();
    @Output() handleDeleteChecklist = new EventEmitter<any>();
    @Output() handleResetSearch = new EventEmitter<any>();

    constructor(
        private router: Router,
        private appInfoService: AppInfoService
    ) {}

    ngOnInit() {
        const appInfo = this.appInfoService.getAppInfo();
        const searchDelayMs = appInfo.packetsInfo.searchDelayMs;

        this.searchSubject
            .pipe(debounceTime(searchDelayMs))
            .subscribe((searchText) => {
                this.processSearchTextChange(searchText);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.searchStr === '') {
            // Reset Search
            this.searchText = this.searchStr;
            this.lastSearchText = '';
        }
    }

    getWrapperClass(): string {
        let css = '';
        if (this.isSchedulerOpen) {
            css += 'scheduler-open ';
        }

        if (this.shouldDisplayHorizontal) {
            css += 'wrapper horizontal';
        } else {
            css += 'wrapper';
        }
        return css;
    }

    toggleSelectedChecklistId(id: number): void {
        this.handleToggleChecklistId.emit(id);
    }

    handleClearSearchField(): void {
        this.searchText = '';
        this.processSearchTextChange('');
    }

    handleSearchTextChange(event): void {
        if (event.key !== 'Enter') {
            const searchText = event.target.value;
            this.searchSubject.next(searchText);

            this.handleResetSearch.emit();
        }
    }

    handleClick(checklist: Checklist): void {
        if (checklist.published) {
            this.handleClickChecklist.emit(checklist.id);
        }
    }

    handleEdit(checklist: Checklist): void {
        if (!checklist.published) {
            this.handleEditChecklist.emit(checklist.id);
        }
    }

    handleDelete(checklist: Checklist): void {
        if (!checklist.published) {
            this.handleDeleteChecklist.emit(checklist.id);
        }
    }

    handleSortValueChange(selectedSort: AppSelectOption): void {
        this.handleSortOptionChange.emit(selectedSort);
    }

    handleStatusValueChange(selectedStatus: AppSelectOption): void {
        this.handleStatusOptionChange.emit(selectedStatus);
    }

    handleOwnerValueChange(selectedOwner: AppSelectOption): void {
        this.handleOwnerOptionChange.emit(selectedOwner);
    }

    lazyLoadChecklists(event): void {
        this.handleLazyLoadChecklists.emit(event);
    }

    get2LetterName(name: string) {
        return Utils.get2LetterName(name);
    }

    private processSearchTextChange(searchText: string): void {
        if (this.lastSearchText !== searchText) {
            this.lastSearchText = searchText;
            this.handleSearchChange.emit(this.lastSearchText);
        }
    }
}
