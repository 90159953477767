import { Component, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Chart } from 'chart.js';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import * as fileSaver from 'file-saver';

import { AppSelectOption } from '../../../../shared/components/app-select/classes/app.select.option';
import {
    XTable,
    XtCell,
    XtHeader,
    XtRow,
} from '../../../../shared/components/app-power-table/classes/XTable';
import { XtFilter } from '../../../../shared/components/app-power-table/classes/XTableFilter';
import { columnWidths } from '../../../../shared/components/app-power-table/defaults';
import { ColumnAlignEnum } from '../../../../shared/classes/enums/column.align.enum';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppUserService } from '../../../../shared/services/app.user.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppInfoService } from '../../../../shared/services/app-info/app.info.service';
import { Utils } from '../../../../shared/app.utils';
import { AnalyticsFilters } from '../../../../shared/classes/analytics/analytics.filters';
import { DialogManageRequirementsComponent } from '../../dialogs/dialog-requirements-manager/dialog-requirements-manager.component';

@Component({
    selector: 'app-selected-contact-requirements',
    templateUrl: './requirements.component.html',
    styleUrls: ['./requirements.component.less'],
})
export class RequirementsComponent implements OnInit, OnDestroy {
    orgHasAccess: boolean;
    displayChart: boolean;
    // TODO: not sure what the replacement for this will be
    requirements: any;
    selectors = {
        analytics: '.details-and-requirements .requirements',
        tableBody: '.contacts-requirements-table .power-table-body',
    };
    chartKeyItems = [
        { name: 'completed', label: 'Completed' },
        { name: 'incomplete', label: 'Incomplete' },
    ];
    labels: Array<any> = [];
    updatePaging = new Subject();
    entityName = 'requirement';

    /*XTable*/
    isTableReady = false;
    xTableData: XTable;
    xTableFilters: Array<XtFilter> = [];
    numberOfPages: number;
    numberOfResults: number;
    currentPageIndex: number;
    currentPageSize: number;
    selectedVings: Array<number> = [];
    excludedVings: Array<number> = [];
    selectedAllVings = false;
    results: any;
    analyticsFilterData: {};

    searchDelayMs: number;
    contactSubscription: any;
    name: string;

    private searchText: string;
    private selected = new AnalyticsFilters(null, null, null);
    private contact: any;
    private analyticsInterval = null;

    @Input() selectNewContactRequirements: Observable<any>;
    allReqs: any;

    constructor(
        @Inject('window') private window,
        @Inject('location') private location,
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appInfoService: AppInfoService,
        private appUserService: AppUserService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.orgHasAccess = this.appUserService.hasRequirementsAccess();
        const appInfo = this.appInfoService.getAppInfo();
        this.currentPageSize =
            appInfo.analyticsInfo.contactAssignmentsAnalyticsInfo.pageSize;
        this.searchDelayMs =
            appInfo.analyticsInfo.contactAssignmentsAnalyticsInfo.searchDelayMs;

        this.contactSubscription = this.selectNewContactRequirements.subscribe(
            (newContact: any) => {
                this.currentPageIndex = 0;
                this.isTableReady = false;
                this.requirements = null;
                this.labels = [];
                this.xTableFilters = [];
                this.searchText = '';
                this.selectedVings = [];
                this.excludedVings = [];
                this.selectedAllVings = false;

                this.initializeAnalytics(newContact);
            }
        );

        const pageRefreshMs = appInfo.contactsInfo.pageRefreshMs;
        // this.analyticsInterval = setInterval(() => {
        //     if (this.contact && document.hasFocus()) {
        //         this.appLoadingIndicatorService.show(
        //             this.selectors.tableBody,
        //             '',
        //             () => {
        //                 if (this.contact) {
        //                     this.initializeAnalytics(null, false);
        //                 } else {
        //                     this.appLoadingIndicatorService.hide(350);
        //                 }
        //             }
        //         );
        //     }
        // }, pageRefreshMs);
    }

    ngOnDestroy() {
        this.contactSubscription.unsubscribe();

        // if (this.analyticsInterval) {
        //     clearInterval(this.analyticsInterval);
        // }
    }

    // XTable
    handleSearchTextChange(searchText): void {
        this.appLoadingIndicatorService.show(this.selectors.tableBody);
        this.searchText = searchText;
        // Init analytics table page.
        this.currentPageIndex = 0;
        this.initializeAnalytics();
    }

    handlePageChange(currentPageIndex): void {
        this.appLoadingIndicatorService.show(this.selectors.tableBody);
        this.currentPageIndex = currentPageIndex;
        this.initializeAnalytics(null, false);
    }

    private initializeAnalytics(
        contact?: any,
        shouldInitPagingData = true
    ): void {
        if (contact) {
            this.contact = contact;
        }
        this.appLoadingIndicatorService.show(
            this.selectors.analytics,
            '',
            () => {
                const url =
                    `GET:api/requirements/contact/?contact_id=` +
                    this.contact.id;

                this.appHttpService.request(url, {}, (response) => {
                    this.requirements = response;
                    this.results = response.results;

                    if (contact) {
                        // Initialize labels, table, & filters.
                        this.initTable();
                    } else {
                        // Update table.
                        this.xTableData.rows = this.initRows(response.results);
                        this.initTable();
                    }
                    this.appLoadingIndicatorService.hide(350);
                });

                this.appHttpService.request(
                    `GET:api/requirements/`,
                    {},
                    (response) => {
                        this.allReqs = response.results;
                    }
                );
            }
        );
    }

    private initTable() {
        const data = this.requirements.results,
            rows: Array<XtRow> = this.initRows(data),
            headers: Array<XtHeader> = [
                new XtHeader('Requirement', columnWidths['name']),
                new XtHeader('', columnWidths['smallIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('Certificate', columnWidths['date'], ColumnAlignEnum.CENTER),
                new XtHeader('Completed', columnWidths['date']),
                new XtHeader('Expiration', columnWidths['date']),
                new XtHeader('', columnWidths['smallIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('', columnWidths['smallIcon'], ColumnAlignEnum.CENTER),
            ];

        this.xTableData = new XTable(headers, rows);

        setTimeout(() => {
            this.isTableReady = true;
        }, 100);
    }

    private initRows(data): Array<XtRow> {
        const rows: Array<XtRow> = [];

        data.forEach((item) => {
            const completionDate = this.getDisplayDate(item.completed);
            const expirationDate = this.getDisplayDate(item.expiration);
            const cells = [
                new XtCell(
                    'requirement',
                    item['title'],
                    null,
                    item['summary']
                ),
                new XtCell(null, null, null, null),
                new XtCell(null, null, null, null),
                new XtCell('completed', completionDate, null, null),
                new XtCell('expires', expirationDate, null, null),
                new XtCell('edit', null, null, null),
                new XtCell('delete', null, null, null),
            ];
            // making sure packet has a certificate url before adding the name file which displays the open file icon
            if (item.certificateUrl) {
                cells.splice(2, 1, new XtCell('file', null, null, null));
            }
            if (item.sentiment) {
                cells.splice(
                    1,
                    1,
                    new XtCell('sentiment', null, null, null, item.sentiment.icon, null, null, null, null, item.sentiment.color)
                );
            }
            rows.push(new XtRow(item.id, cells, true));
        });

        return rows;
    }

    private getDisplayDate(date): string {
        return date ? moment(date).format('L') : '--';
    }

    private getDisplayTime(time): string {
        return time ? moment(time).format('LT') : '';
    }

    manageRequirements() {
        const deselectedItems = this.allReqs.filter(
            (req) =>
                !this.results.some(
                    (selectedItem) => req.title === selectedItem.title
                )
        );
        const manageReqDialog = this.dialog.open(
            DialogManageRequirementsComponent,
            {
                width: '1000px',
                minHeight: '800px',
                height: 'auto',
                disableClose: false,
                autoFocus: false,
                data: {
                    contactId: this.contact.id,
                    selectedItems: this.results,
                    deSelectedItems: deselectedItems,
                },
            }
        );
        manageReqDialog.afterClosed().subscribe(() => {
            this.initializeAnalytics();
        });
    }
}
