<div id="dashboard">
    <div class="views charts" id="view-chart">
        <div class="activity contacts" *ngIf="engagement">
            <div class="dashboard-chart-header">
                <h2>{{ engagement.title }}</h2>
                <i
                    (click)="displayInfoDialog('engagement')"
                    class="material-icons icon dashboard-info-modal-trigger"
                    >info_outline</i
                >
                <div class="last-updated">Updated at {{ lastUpdated }}</div>
            </div>
            <div class="chart">
                <div class="chart-inner">
                    <div class="chart-area" id="contacts-chart"></div>

                    <!--If chart has no data to display-->
                    <div
                        class="no-chart-data"
                        *ngIf="displayContactChart === false"
                    ></div>

                    <div class="chart-center-info">
                        <div class="center-info-label">
                            {{ engagement.total }}
                        </div>
                        <div class="center-info-value">
                            {{
                                engagement.totalLabel
                                    | pluralize: engagement.total
                            }}
                        </div>
                    </div>
                </div>

                <div class="chart-key">
                    <div
                        *ngFor="let item of engagement.sentiments"
                        class="chart-key-item"
                    >
                        <div class="item-color">
                            <div
                                class="color-box"
                                [ngStyle]="{ background: item.sentiment }"
                            ></div>
                        </div>
                        <div class="item-label">
                            <a href="/contacts/?status={{ item.value }}">{{ item.label }}</a>
                        </div>
                        <div class="item-value">
                            {{ item.count }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="activity vings" *ngIf="activity">
            <div class="dashboard-chart-header">
                <h2>{{ activity.title }}</h2>
                <i
                    (click)="displayInfoDialog('activity')"
                    class="material-icons icon dashboard-info-modal-trigger"
                    >info_outline</i
                >
                <div class="last-updated">Updated at {{ lastUpdated }}</div>
            </div>
            <div class="chart">
                <div class="chart-inner">
                    <div class="chart-area" id="vings-chart"></div>

                    <!--If chart has no data to display-->
                    <div
                        class="no-chart-data"
                        *ngIf="displayAssignmentChart === false"
                    ></div>

                    <div class="chart-center-info">
                        <div class="center-info-label">
                            {{ activity.total }}
                        </div>
                        <div class="center-info-value">
                            {{
                                activity.totalLabel
                                    | pluralize
                                        : activity.viewed +
                                              activity.completed +
                                              activity.unviewed
                            }}
                        </div>
                    </div>
                </div>

                <div class="chart-key">
                    <div
                        *ngFor="let item of chartKeyItems"
                        [class]="'chart-key-item ' + item.name"
                    >
                        <div class="item-color">
                            <div class="color-box" style="background-color: {{ item.color }};"></div>
                        </div>
                        <div class="item-label">
                            {{ item.label }}
                        </div>
                        <div class="item-value">
                            {{ activity[item.name] }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="activity submissions" *ngIf="submissions">
            <div class="dashboard-chart-header">
                <h2>{{ submissions.title }}</h2>
                <i
                    (click)="displayInfoDialog('submissions')"
                    class="material-icons icon dashboard-info-modal-trigger"
                    >info_outline</i
                >
                <div class="last-updated">Updated at {{ lastUpdated }}</div>
            </div>
            <div class="chart">
                <div class="chart-inner">
                    <div class="chart-area" id="submissions-chart"></div>

                    <!--If chart has no data to display-->
                    <div
                        class="no-chart-data"
                        *ngIf="displaySubmissionsChart === false"
                    ></div>

                    <div class="chart-center-info">
                        <div class="center-info-label">
                            {{ submissions.total }}
                        </div>
                        <div class="center-info-value">
                            {{
                                submissions.totalLabel
                                    | pluralize
                                        : submissions.total
                            }}
                        </div>
                    </div>
                </div>

                <div class="chart-key">
                    <div
                        *ngFor="let item of submissions.items"
                        [class]="'chart-key-item ' + item.value"
                    >
                        <div class="item-color">
                            <div style="background-color: {{item.color}};" class="color-box"></div>
                        </div>
                        <div class="item-label">
                            <a href="/checklists/?status={{ item.value }}">{{ item.label }}</a>
                        </div>
                        <div class="item-value">
                            {{ item.count }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="views items">
        <!--Contacts-->
        <div class="activity contacts">
            <div class="activity-title">
                <span>{{ dashboardInfo.contactsTitle }}</span>
                <a routerLink="/contacts">View All</a>
            </div>

            <div class="activity-list">
                <app-contact-card
                    *ngFor=" let contact of contacts"
                    [origin]="'dashboard'"
                    [contact]="contact"
                    (handleSelectContact)="selectContact(contact.id)"
                ></app-contact-card>
            </div>
        </div>
        <!--Packets-->
        <div class="activity vings">
            <div class="activity-title">
                <span>{{ dashboardInfo.packetsTitle }}</span>
                <a routerLink="/vings">View All</a>
            </div>

            <div class="activity-list">

                <app-ving-card *ngFor="let packet of packets"
                [origin]="'dashboard'"
                [packet]="packet"
                (handleClickObject)="selectPacket(packet.id)"
                >
                </app-ving-card>
            </div>
        </div>

        <!--Packets-->
        <div class="activity checklists">
            <div class="activity-title">
                <span>{{ dashboardInfo.checklistsTitle }}</span>
                <a routerLink="/checklists">View All</a>
            </div>

            <div class="activity-list">

                <app-ving-card *ngFor="let checklist of checklists"
                [origin]="'dashboard'"
                [packet]="checklist"
                (handleClickObject)="selectChecklist(checklist.id)"
                >
                </app-ving-card>
            </div>
        </div>
    </div>
</div>
