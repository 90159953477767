import { HttpParameterCodec } from '@angular/common/http';
import { Utils } from './app.utils';

export class AppQueryEncoderHelper implements HttpParameterCodec {
    private key: string;

    encodeKey(key: string): string {
        this.key = key;
        return Utils.encURIComponent(null, key);
    }

    encodeValue(value: string): string {
        return Utils.encURIComponent(this.key, value);
    }

    decodeKey(key: string): string {
        this.key = key;
        return Utils.decURIComponent(null, key);
    }

    decodeValue(value: string): string {
        return Utils.decURIComponent(this.key, value);
    }
}
