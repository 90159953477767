import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ElementRef,
    Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { QuestionType } from '../../../../shared/classes/questions/question-type';
import { AppJobService } from '../../../../shared/services/job/app.job.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppPermissionService } from '../../../../shared/services/app.permission.service';
import { AppUserService } from '../../../../shared/services/app.user.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { Question } from '../../../../shared/classes/questions/question';
import * as $ from 'jquery';
import { Choice } from '../../../../shared/classes/questions/choice';
import { DialogViewStatusComponent } from '../../dialogs/dialog-view-status/dialog.view.status.component';
import { ConsumeChecklist } from '../../classes/consume.checklist';
import {View} from "../../classes/view";

@Component({
    selector: 'app-view-checklist',
    templateUrl: './view-checklist.component.html',
    styleUrls: ['./view-checklist.component.less'],
})
export class ViewChecklistComponent implements OnInit, OnDestroy {
    @ViewChild('video') video: ElementRef;

    isMobile = null;
    isPreview = false;
    previewComponentId;
    viewIsInitialized = false;
    showList = false;
    displayFeedback = false;
    ongoingCall: boolean;

    private radioButtonTypes = [
        QuestionType.trueFalse,
        QuestionType.yesNo,
        QuestionType.multipleChoice,
        QuestionType.likert,
    ];
    private lastQuestionsJson: string;
    private questionsJobEnabled = true;
    private intervals = {
        questions: 500,
        document: 3000,
        media: 500,
    };
    private jobs = {
        questionsValidate: 'app-questions-validate-job',
        mediaProgress: 'app-media-progress-job',
        document: 'app-document-job',
    };
    private currentPersistRequest: any;
    private urlParams: object;

    @Input() view: View;

    constructor(
        private appJobService: AppJobService,
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appUserService: AppUserService,
        private appPermissionService: AppPermissionService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private deviceService: DeviceDetectorService
    ) {
        this.isMobile = this.deviceService.isMobile();
    }

    ngOnInit() {
        this.appLoadingIndicatorService.show('view');

        // Retrieve and set the url params.
        this.urlParams = this.appHelpersService.getUrlParams();

        // Check to see if preview param was passed in.
        if (
            this.urlParams &&
            'preview' in this.urlParams &&
            this.urlParams['preview']
        ) {
            this.isPreview = true;

            if ('componentId' in this.urlParams) {
                const componentId = parseInt(this.urlParams['componentId'], 10);

                if (Number.isInteger(componentId)) {
                    this.previewComponentId = componentId;
                }
            }
        }

        this.initView(this.view);
    }

    ngOnDestroy() {
        // Adjust view style.
        $('#view').css({ 'overflow-y': 'auto' });

        this.appJobService.kill(this.jobs.questionsValidate);
    }
    shouldDisplayButton(): boolean {
        let shouldDisplay = false;

        const isRequired = [];
        const isRequiredAndAnswered = [];
        this.view.checklist.questions.map((item) => {
            if (item.required) {
                isRequired.push(item);

                if (item.answer) {
                    isRequiredAndAnswered.push(item);
                }
            }
        });

        if (isRequiredAndAnswered.length == isRequired.length) {
            shouldDisplay = true;
        }

        return shouldDisplay;
    }

    submitChecklist(): void {
        if (!this.isPreview) {
            this.view.submittedDate = 'now';
            const sendView = this.view;

            let url = `PUT:api/view/shares/${this.view.stub}/`;

            if (JSON.stringify(this.urlParams) !== '{}') {
                url += `?${$.param(this.urlParams)}`;
            }

            this.appLoadingIndicatorService.show('#view');
            this.appHttpService.request(url, sendView, (view) => {
                this.appLoadingIndicatorService.hide();
                this.redirectOutside();
            });
        } else {
            window.close();
        }
    }

    shouldDisplayRadio(question: Question): boolean {
        return this.radioButtonTypes.indexOf(question.type) >= 0;
    }

    shouldDisplayCheckbox(question: Question): boolean {
        return question.type === QuestionType.multipleResponse;
    }

    updateAnswer(newValue, question): void {
        this.view.checklist.questions.find((q) => q.id === question.id).answer =
            newValue;
        this.persistView();
    }

    updateAnswerByType(newValue, question): void {
        if (this.shouldDisplayRadio(question)) {
            this.view.checklist.questions.find(
                (q) => q.id === question.id
            ).answer = newValue;
        } else if (this.shouldDisplayCheckbox(question)) {
            const answer = this.view.checklist.questions.find(
                (q) => q.id === question.id
            ).answer;
            if (answer === null) {
                this.view.checklist.questions.find(
                    (q) => q.id === question.id
                ).answer = [];
                this.view.checklist.questions
                    .find((q) => q.id === question.id)
                    .answer.push(newValue);
            } else if (answer.indexOf(newValue) === -1) {
                this.view.checklist.questions
                    .find((q) => q.id === question.id)
                    .answer.push(newValue);
            } else {
                answer.splice(answer.indexOf(newValue), 1);
            }
        }
    }
    objHasImage(item): boolean {
        const itemHasOwnProperty = Object.prototype.hasOwnProperty.call(
            item,
            'imageUrl'
        );
        return itemHasOwnProperty && item.imageUrl && item.imageUrl.length > 0;
    }

    isShortAnswer(question: Question): boolean {
        return question.type === QuestionType.shortAnswer;
    }

    choiceHasImage(choice: Choice): boolean {
        const choiceHasOwnProperty = Object.prototype.hasOwnProperty.call(
            choice,
            'imageUrl'
        );
        return (
            choiceHasOwnProperty &&
            choice.imageUrl &&
            choice.imageUrl.length > 0
        );
    }

    getViewClass(): string {
        let cls = 'checklist-view-container';
        cls += this.isPreview ? ' preview' : '';
        return cls;
    }
    private initView(view: object) {
        this.viewIsInitialized = true;
        this.appLoadingIndicatorService.hide(500);
        const pkt = view['checklist'],
            checklist = new ConsumeChecklist(
                pkt.title,
                pkt.summary,
                this.getLogoUrl(view['owner']),
                pkt.visitsCount ? pkt.visitsCount : 0,
                pkt.questions
            );

        this.view = new View(
            'checklist',
            view['stub'],
            checklist,
            view['nonUserDoneRedirectUrl']
        );

        // Initialize checklist.
        this.updateChecklist(view['checklist']);
        this.startQuestionsJob();
        this.persistView();
    }

    private persistView(callback?: Function): void {
        if (!this.isPreview) {
            this.ongoingCall = true;
            if (
                this.currentPersistRequest &&
                this.currentPersistRequest.closed === false
            ) {
                this.currentPersistRequest.unsubscribe();
            }

            let url = `PUT:api/view/shares/${this.view.stub}/`;

            if (JSON.stringify(this.urlParams) !== '{}') {
                url += `?${$.param(this.urlParams)}`;
            }
            this.currentPersistRequest = this.appHttpService.request(
                url,
                this.view,
                (view) => {
                    //this.updateChecklist(view.checklist);
                    this.currentPersistRequest = undefined;
                    this.ongoingCall = false;

                    if (callback) {
                        callback();
                    }
                }
            );
        }
    }

    private isValueOneOrMore(value: number): boolean {
        return value >= 1;
    }

    private getLogoUrl(item) {
        if (item.organization != null && item.organization.logoUrl != null) {
            return item.organization.logoUrl;
        }

        return undefined;
    }

    private updateChecklist(checklist) {
        const questionKeys = [
            'id',
            'text',
            'image',
            'imageUrl',
            'answerIsCorrect',
            'choices',
        ];
        const choiceKeys = ['id', 'text', 'image', 'imageUrl', 'correct'];
        const questions = [];
        checklist.questions.forEach((question) => {
            const updateQuestion = checklist.questions.find(
                (q) => q.id === question.id
            );

            if (updateQuestion) {
                questionKeys.forEach((qKey) => {
                    if (typeof question[qKey] !== 'undefined') {
                        if (qKey === 'choices') {
                            const choices = [];
                            question[qKey].forEach((choice) => {
                                const updateChoice = updateQuestion[qKey].find(
                                    (c) => c.id === choice.id
                                );
                                if (updateChoice) {
                                    choiceKeys.forEach((cKey) => {
                                        if (
                                            typeof choice[cKey] !== 'undefined'
                                        ) {
                                            updateChoice[cKey] = choice[cKey];
                                        }
                                    });
                                    choices.push(updateChoice);
                                } else {
                                    const newChoice = <Choice>{};
                                    Object.keys(choice).forEach((ckey) => {
                                        newChoice[ckey] = choice[ckey];
                                    });
                                    choices.push(newChoice);
                                }
                            });

                            // Remove old choices from revise and Sort
                            if (
                                this.appHelpersService.isEqual(
                                    choices,
                                    updateQuestion[qKey]
                                )
                            ) {
                                const tempChoices =
                                    this.appHelpersService.getClone(choices);
                                tempChoices.sort((a, b) => {
                                    return (
                                        question[qKey].findIndex(
                                            (c) => c.id === a.id
                                        ) -
                                        question[qKey].findIndex(
                                            (c) => c.id === b.id
                                        )
                                    );
                                });
                                updateQuestion[qKey] =
                                    this.appHelpersService.getClone(
                                        tempChoices
                                    );
                            }
                        } else {
                            updateQuestion[qKey] = question[qKey];
                        }
                    }
                });
                questions.push(updateQuestion);
            } else {
                const newQuestion = <Question>{};
                Object.keys(question).forEach((qkey) => {
                    newQuestion[qkey] = question[qkey];
                });
                questions.push(newQuestion);
            }

            // Remove old components from revise and Sort
            if (
                !this.appHelpersService.isEqual(
                    questions,
                    this.view.checklist.questions
                )
            ) {
                const tempQuestions =
                    this.appHelpersService.getClone(questions);
                tempQuestions.sort((a, b) => {
                    return (
                        checklist.questions.findIndex((c) => c.id === a.id) -
                        checklist.questions.findIndex((c) => c.id === b.id)
                    );
                });
                this.view.checklist.questions =
                    this.appHelpersService.getClone(tempQuestions);
            }
            return;
        });
    }

    private startQuestionsJob(): void {
        this.appJobService.create(
            this.jobs.questionsValidate,
            () => {
                if (this.view.checklist) {
                    if (this.questionsJobEnabled && !this.displayFeedback) {
                        // Disable job while processing.
                        this.questionsJobEnabled = false;

                        const currentJson = this.buildAnswersJson();

                        const jsonHasChanged =
                            currentJson !== this.lastQuestionsJson;

                        // If there is a change in the completed state, persist the change.
                        if (jsonHasChanged) {
                            if (jsonHasChanged) {
                                this.lastQuestionsJson = currentJson;
                            }
                            this.persistView(() => {
                                this.questionsJobEnabled = true;
                            });
                        } else {
                            this.questionsJobEnabled = true;
                        }
                    }
                } else {
                    this.appJobService.kill(this.jobs.questionsValidate);
                }
            },
            this.intervals.questions
        );
    }

    private buildAnswersJson(): string {
        return JSON.stringify(
            this.view.checklist.questions.map((q) => q.answer)
        );
    }
    private redirectOutside() {
        const statusDialog = this.dialog.open(DialogViewStatusComponent, {
            width: '400px',
            height: '200px',
            disableClose: true,
            data: {
                checklist: this.view.checklist,
            },
        });

        statusDialog.afterClosed().subscribe((result) => {
            if (this.appUserService.isLoggedIn()) {
                // user is logged in - go to assignments
                this.router.navigateByUrl('/user/checklists');
            } else {
                // user is not logged in - go to redirect URL
                window.location.assign(this.view.nonUserDoneRedirectUrl);
            }
        });

        return statusDialog;
    }
}
