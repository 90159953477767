export class AppPrototypes {
    static formatPhone(phone: string) {
        const phoneLength: number = phone.length,
            phonePrefix = phone.substr(0, 2);

        // Verify that the phone is a US number. Ex: +13305551234.
        // If so, format the number differently.
        if (phoneLength === 12 && phonePrefix === '+1') {
            const areaCode = phone.substr(2, 3),
                exchange = phone.substr(5, 3),
                number = phone.substr(8, 4);

            phone = '+1 (' + areaCode + ') ' + exchange + '-' + number;
        }

        return phone;
    }

    static cleanPhone(phone: string) {
        // Remove all alphas.
        phone = phone.replace(/\D/g, '');

        return phone.trim();
    }
}
