import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppHelpersService } from '../../../../shared/services/app.helpers.service';

@Component({
    templateUrl: './dialog-list-messages.component.html',
    styleUrls: ['./dialog-list-messages.component.less'],
})
export class DialogListMessagesComponent {
    constructor(
        private appHelpersService: AppHelpersService,
        public dialogRef: MatDialogRef<DialogListMessagesComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            messages: Array<any>;
        }
    ) {}

    onConfirm() {
        setTimeout(() => {
            this.dialogRef.close();
        }, 100);
    }

    getDisplayDate(date: string): string {
        return this.appHelpersService.getDisplayDate(date);
    }
}
