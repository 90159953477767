import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import * as _ from 'lodash';
import { AppDialogConfirmComponent } from '../../dialogs/dialog-confirm/app.dialog-confirm.component';
import { AppMessageService } from '../../services/app.message.service';
import { AppLoadingIndicatorService } from '../../services/app.loading-indicator.service';
import { ContactService } from '../../../modules/contacts/services/contact.service';
import { MatDialog } from '@angular/material/dialog';
import { AppDialogEditCategoryComponent } from '../../dialogs/dialog-edit-category/app.dialog-edit-category.component';
import { AppDialogCreateCategoryComponent } from '../../dialogs/dialog-create-category/app.dialog-create-category.component';
import { AppLabel } from '../../classes/app-label';
import { AppInfoListItem } from '../../services/app-info/app.info.list.item';
import { DragRef } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-filter-navigation',
    templateUrl: './app.filter.navigation.html',
    styleUrls: ['./app.filter.navigation.less'],
})
export class AppFilterNavigationComponent implements OnChanges {
    panelOpenState = false;
    // @Input() items: any[];
    @Input() page: string;
    @Input() entityLists: Array<any>;

    @Input() searchText: string;
    @Input() labels: Array<AppLabel>;
    @Input() filters: Array<AppInfoListItem>;
    @Input() selected: Array<string>;
    @Input() selectedLabels: Array<string>;
    @Input() selectedFilters: Array<string>;

    @Output() onEditCategory = new EventEmitter<any>();
    @Output() onRemoveLabelFromList = new EventEmitter<any>();
    @Output() onCategoryCreate = new EventEmitter<any>();
    @Output() onClearFilters = new EventEmitter<any>();
    @Output() onToggleLabel = new EventEmitter<any>();
    @Output() onToggleFilter = new EventEmitter<any>();
    @Output() onToggleSelectedItem = new EventEmitter<any>();
    @Output() onDeleteLabel = new EventEmitter<any>();
    @Output() onDeleteCategory = new EventEmitter<any>();
    @Output() onDisplayManageListModal = new EventEmitter<any>();
    @Output() onDeleteList = new EventEmitter<any>();
    @Output() onResetSearch = new EventEmitter<any>();
    @Output() onEditCustomFilter = new EventEmitter<number>();
    @Output() onDeleteCustomFilter = new EventEmitter<number>();

    public name: string = null;
    public currentTimeout: ReturnType<typeof setTimeout>;
    // @ContentChild(TemplateRef, { static: false }) templateRef;
    private isDraggingLabel: boolean;

    constructor(
        private cdRef: ChangeDetectorRef,
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private contactService: ContactService,
        private dialog: MatDialog
    ) {}
    ngOnChanges(selected) {
        if (selected.selectedLabels) {
            this.selectedLabels = selected.selectedLabels.currentValue;
            this.getCount(this.selectedLabels);
        }
    }

    stopPropagation(event): void {
        event.stopPropagation();
    }

    getStyleFilter(value: string): string {
        return this.selectedFilters.indexOf(value) >= 0 ? 'block' : 'none';
    }

    toggleLabel(name: any): void {
        this.onToggleLabel.emit(name);
    }

    editCategory(event, category: any) {
        event.stopPropagation();
        this.onEditCategory.emit(category);
    }

    toggleFilter(value: string): void {
        this.onToggleFilter.emit(value);
    }
    toggleSelectedItem(type: string, item: any): void {
        const value = {
            type: type,
            item: item,
        };
        this.onToggleSelectedItem.emit(value);
    }

    editCustomFilter(event, filter) {
        event.stopPropagation();
        this.onEditCustomFilter.emit(filter);
    }

    deleteCustomFilter(event, filter) {
        event.stopPropagation();
        this.onDeleteCustomFilter.emit(filter);
    }

    deleteCategory(event, category: string): void {
        event.stopPropagation();
        this.onDeleteCategory.emit(category);
    }

    displayManageListModal(event, id: string) {
        event.stopPropagation();
        this.onDisplayManageListModal.emit(id);
    }
    deleteList(event, id: string) {
        event.stopPropagation();
        this.onDeleteList.emit(id);
    }
    categoryCreate($event: any[]) {
        this.onCategoryCreate.emit();
    }
    clearFilters($event: any[]) {
        this.onClearFilters.emit();
    }

    removeLabelFromList($event: object) {
        this.onRemoveLabelFromList.emit($event);
    }

    getIsDragging($event: boolean) {
        this.isDraggingLabel = $event;
    }

    openCategory($event: MouseEvent, category: any) {
        if (this.isDraggingLabel) {
            if (this.currentTimeout) {
                clearTimeout(this.currentTimeout);
            }
            this.currentTimeout = setTimeout(() => {
                category.open = true;
            }, 500);
        }
    }

    private getCount(selectedLabels: Array<string>) {
        this.entityLists['categories'].forEach((category) => {
            category.count = category.labels.filter((label) =>
                selectedLabels.includes(label.name)
            ).length;
        });
    }
}
