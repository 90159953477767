import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './dialog.readonly.component.html',
    styleUrls: ['./dialog.readonly.component.less'],
})
export class DialogReadonlyComponent implements OnInit {
    title: string;
    text: string;
    text2: string;
    link: string;
    link_title: string;

    constructor(
        public dialogRef: MatDialogRef<DialogReadonlyComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            text: string;
            text2: string;
            link: string;
            link_title: string;
        }
    ) {}

    ngOnInit() {
        this.title = this.data.title;
        this.text = this.data.text;
        this.text2 = this.data.text2;
        this.link = this.data.link;
        this.link_title = this.data.link_title;
    }

    close() {
        this.dialogRef.close();
    }
}
