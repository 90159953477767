import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppUserService } from "../../../../shared/services/app.user.service";
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { Utils } from "../../../../shared/app.utils";
import {AppPrototypes} from "../../../../shared/app.prototypes";

@Component({
    templateUrl: './dialog-merge-users.component.html',
    styleUrls: ['./dialog-merge-users.component.less'],
})
export class DialogMergeUsersComponent implements OnInit {
    messages = {
        success: 'Your accounts were merged successfully.',
        ignored: 'This will not be suggested again.',
        error: 'An error occurred. Please refresh and try again.',
    };
    title = '';
    contactMethods = [];
    verifyMethod = '';
    verifyType = '';
    verifyMessage = '';
    verificationSent = false;
    verificationCode: string;
    validation: Object;
    @Output() handleToggleContactId = new EventEmitter<any>();
    @Output() handleSelectContact = new EventEmitter<any>();

    constructor(
        private router: Router,
        private appHttpService: AppHttpService,
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appHelpersService: AppHelpersService,
        private appUserService: AppUserService,
        public dialogRef: MatDialogRef<DialogMergeUsersComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            contacts: Array<object>;
            mergeSuggestion: any;
            callback: Function;
        }
    ) {
    }

    ngOnInit() {
        if (this.data.title) {
            this.title = this.data.title;
        }

        const contactMethods = this.data.mergeSuggestion.verifyMethods;

        for (let i = 0; i < contactMethods.length; i++) {
            const method = contactMethods[i],
                type = Utils.validateEmail(method) ? 'email' : 'sms',
                formattedMethod = [type, method];

                if (formattedMethod[0] === 'sms') {
                    formattedMethod[1] = AppPrototypes.formatPhone(formattedMethod[1]);
                    formattedMethod[2] = this.obfuscatePhone(formattedMethod[1]);
                } else {
                    formattedMethod[2] = this.obfuscateEmail(formattedMethod[1]);
                }

            this.contactMethods.push(formattedMethod);
        }

        this.verifyMessage = "You may have another Ving account."

        if (this.contactMethods.length > 1) {
            this.verifyMessage += " Select a contact method"
        } else {
            let type;

            if (this.contactMethods[0][0] == 'sms') {
                type = 'phone'
            } else {
                type = 'email'
            }

            this.verifyMessage += ` Select the ${ type }`
        }

        this.verifyMessage += " below to verify ownership and merge accounts:"

        this.appLoadingIndicatorService.hide(350);
    }

    obfuscateEmail(email) {
        const split_email = email.split('@'),
            username = split_email[0],
            domain = split_email[1],
            split_domain = domain.split('.'),
            ext = split_domain.slice(-1)[0],
            noExtDomain = domain.replace(`.${ext}`, '');

        return username + '@' + this.obfuscateString(noExtDomain) + '.' + ext;
    }

    obfuscatePhone(number) {
        const split_a = number.split('('),
            country = split_a[0],
            temp = split_a[1],
            temp_split = temp.split(')'),
            area = temp_split[0],
            remainder = temp_split[1];

            return country + '(' + this.obfuscateString(area) + ')' + remainder;
    }

    obfuscateString(string) {
        const len = string.length,
            replaceWith = '*',
            replaceNum = Math.ceil(len / 5),
            idx = Math.floor(Math.random() * (len-3));

        let replacement = '';

        for (let i = 0; i < replaceNum; i += 1) {
            replacement += replaceWith;
        }

        return string.slice(0, idx) + replacement + string.slice(idx+replaceNum, len);
    }

    ignore() {
        this.appHttpService.request(
            `POST:api/merge/users/ignore/${this.data.mergeSuggestion.id}/`,
            {},
            () => {
                this.dialogRef.close();
                this.data.callback(this.dialogRef, this.messages.ignored);
                this.appLoadingIndicatorService.hide(200);
            },
            (err) => {
                const error =
                    err.data &&
                    Array.isArray(err.data) &&
                    this.appHelpersService.isValidString(err.data[0])
                        ? err.data[0]
                        : this.messages.error;
                this.appMessageService.show(
                    'modal',
                    'error',
                    this.messages.error,
                    5
                );
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    sendVerification(formattedMethod) {
        this.appLoadingIndicatorService.show('app');

        this.verifyType = formattedMethod[0]
        const post_data = {verification_method: formattedMethod[1]};

        this.appHttpService.request(
            `POST:api/merge/users/verify/${this.data.mergeSuggestion.id}/`,
            post_data,
            () => {
                this.verificationSent = true;
                this.appLoadingIndicatorService.hide(200);
            },
            (err) => {
                const message =
                    err.status === 400 && typeof err.data === 'string'
                        ? err.data
                        : this.messages.error;
                this.appMessageService.show('app', 'error', message, 5.0);
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    executeMerge() {
        this.appLoadingIndicatorService.show('app');

        this.appHttpService.request(
            `POST:api/merge/users/execute/${this.data.mergeSuggestion.id}/`,
            {verification_code: this.verificationCode},
            (mergedUser) => {
                this.appUserService.setUser(mergedUser);
                this.dialogRef.close();
                this.appMessageService.show(
                    'app',
                    'success',
                    this.messages.success,
                    4
                );
                this.appLoadingIndicatorService.hide();
            },
            (err) => {
                const message =
                    err.status === 400 && typeof err.data === 'string'
                        ? err.data
                        : this.messages.error;
                this.appMessageService.show('app', 'error', message, 5.0);
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }
}
