import {
    Component,
    OnInit,
    AfterViewInit,
    ChangeDetectorRef,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppHttpService } from '../../shared/services/app.http.service';
import { AppUserService } from '../../shared/services/app.user.service';
import { AppLoadingIndicatorService } from '../../shared/services/app.loading-indicator.service';
import { AppMessageService } from '../../shared/services/app.message.service';
import { AppUser } from '../../shared/classes/user/app-user';
import { AppService } from '../../app.service';
import { AppMessage } from '../../shared/app.messages';
import { AppDialogNewItemComponent } from '../../shared/dialogs/dialog-new-item/app.dialog-new-item.component';
import { AppDialogResetPasswordComponent } from '../accounts/dialogs/dialog-reset-password/app.dialog-reset-password.component';
import { AppDialogVerificationComponent } from '../accounts/dialogs/dialog-verification/app.dialog-verification.component';
import { AppDialogConfirmComponent } from '../../shared/dialogs/dialog-confirm/app.dialog-confirm.component';
import { Utils } from '../../shared/app.utils';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.less'],
})
export class ProfileComponent implements OnInit, AfterViewInit {
    private messages = {
        success: {
            info: 'General information successfully updated.',
            password: 'Password successfully updated.',
            avatar: {
                upload: 'Avatar successfully uploaded.',
                remove: 'Avatar successfully removed.',
            },
            organization: {
                upload: 'Logo successfully uploaded.',
                remove: 'Logo successfully removed.',
            },
            contact: {
                add: 'Contact method successfully added.',
                remove: 'Contact method successfully removed.',
            },
            history: 'Successfully copied the history to the clipboard.',
        },
        error: {
            info: 'There was an error uploading the general information. Please try again.',
            password:
                'There was an error updating the password. Please try again.',
            avatar: {
                upload: 'There was an error uploading the avatar. Please try again.',
                remove: 'There was an error removing the avatar. Please try again.',
            },
            organization: {
                upload: 'There was an error uploading the logo. Please try again.',
                remove: 'There was an error removing the logo. Please try again.',
            },
            contact: {
                add: 'There was an error adding the contact method. Please try again.',
                remove: 'There was an error removing the contact method. Please try again.',
                verify: 'There was an error verifying the contact method. Please try again.',
            },
            history: 'There was an error copying the history.',
        },
        confirm: {
            contact: 'Are you sure you want to delete this contact method?',
        },
    };

    user: AppUser;
    userLevel: string;

    // Profile
    firstName: string;
    lastName: string;
    sendFrom: string;
    bccEmail: string;
    emails: Array<string>;
    phones: Array<string>;

    // Password Form
    newPassword = '';
    confirmPassword = '';

    // Avatar
    avatar = {
        key: '',
        crop: {
            startX: undefined,
            startY: undefined,
            width: undefined,
            height: undefined,
        },
    };
    // Logo
    logo = {
        key: '',
        crop: {
            startX: undefined,
            startY: undefined,
            width: undefined,
            height: undefined,
        },
    };

    selectedTab = 'profile';
    tabs = ['profile', 'password', 'customize', 'history'];
    tabsForCreator = ['history']; // Don't include 'profile', it is default tab.

    constructor(
        private appService: AppService,
        private appHttpService: AppHttpService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appMessageService: AppMessageService,
        private appUserService: AppUserService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.appLoadingIndicatorService.show('view');
        this.appUserService.whenUserSet(() => {
            this.user = this.appUserService.getUser();
            this.userLevel = this.appUserService.getUserLevel();
            this.firstName = this.user.firstName;
            this.lastName = this.user.lastName;
            this.sendFrom = this.user.sendFrom;
            this.bccEmail = this.user.crmBccEmail;
            this.emails = this.user.emails;
            this.phones = this.user.phones;

            this.appLoadingIndicatorService.hide();
        });
        setTimeout(() => (this.appService.selected.navOption = 'profile'), 0);
    }

    ngAfterViewInit() {
        this.appUserService.whenUserSet(() => {
            this.route.fragment.subscribe((fragment: string) => {
                setTimeout(() => {
                    this.handleClickTab(fragment);
                }, 0);
            });
        });
    }

    isTabSelected(tab) {
        return tab === this.selectedTab;
    }

    handleClickTab(tab) {
        this.appLoadingIndicatorService.show('.content');

        if (
            this.tabs.includes(tab) &&
            (this.isCreatorLevel() ||
                (!this.isCreatorLevel() && !this.tabsForCreator.includes(tab)))
        ) {
            this.selectedTab = tab;
            this.router.navigateByUrl('/user/profile#' + tab);
        } else {
            this.selectedTab = 'profile';
            this.router.navigateByUrl('/user/profile');
        }

        // refresh the history data for history tab
        if (tab === 'history') {
            this.getUser(null);
        } else {
            this.appLoadingIndicatorService.hide(200);
        }
    }

    handleInfos(infoForm: NgForm): void {
        if (infoForm.invalid) {
            return;
        }

        this.appLoadingIndicatorService.show('view');

        const data = {
            first_name: infoForm.value.firstName,
            last_name: infoForm.value.lastName,
            send_from: infoForm.value.sendFrom,
            crm_bcc_email: infoForm.value.bccEmail,
        };
        this.appHttpService.request(
            'PUT:api/auth/update/',
            data,
            (user) => {
                this.user = user;
                this.appUserService.setUser(user);
                this.appMessageService.show(
                    'app',
                    'success',
                    this.messages.success.info,
                    3
                );
                this.appLoadingIndicatorService.hide(200);
            },
            () => {
                this.appMessageService.show(
                    'view',
                    'error',
                    this.messages.error.info,
                    4.0
                );
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    resetPassword() {
        this.dialog.open(AppDialogResetPasswordComponent, {
            height: '500px',
            width: '425px',
            disableClose: true,
            data: {
                title: 'Reset Password',
            },
        });
    }

    addNewEmail() {
        this.dialog.open(AppDialogNewItemComponent, {
            height: '215px',
            width: '425px',
            disableClose: true,
            data: {
                title: 'Add New Email',
                type: 'email',
                label: 'New email',
                placeholder: 'Enter new email',
                callback: (item) => {
                    this.apiAddContactMethod(item);
                },
            },
        });
    }

    addNewPhone() {
        this.dialog.open(AppDialogNewItemComponent, {
            height: '215px',
            width: '425px',
            disableClose: true,
            data: {
                title: 'Add New Phone',
                type: 'phone',
                label: 'New phone',
                placeholder: 'Enter new phone',
                callback: (item) => {
                    this.apiAddContactMethod(item);
                },
            },
        });
    }

    removeContactMethod(contact: string) {
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            disableClose: true,
            data: {
                text: this.messages.confirm.contact,
                callback: () => {
                    this.apiRemoveContactMethod(contact);
                },
            },
        });
    }

    handlePasswords(passwordForm: NgForm): void {
        if (passwordForm.invalid) {
            return;
        }

        this.appLoadingIndicatorService.show('view');

        const data = {
            new_password: passwordForm.value.newPassword,
            re_new_password: passwordForm.value.confirmPassword,
        };
        this.appHttpService.request(
            'POST:api/auth/password/',
            data,
            () => {
                passwordForm.resetForm();
                this.appMessageService.show(
                    'app',
                    'success',
                    this.messages.success.password,
                    3
                );
                this.appLoadingIndicatorService.hide(200);
            },
            () => {
                this.appMessageService.show(
                    'view',
                    'error',
                    this.messages.error.password,
                    4.0
                );
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    isAvatarExists() {
        return this.user.avatar && 'url' in this.user.avatar;
    }

    isLogoExists() {
        return (
            this.user.organization &&
            'logo' in this.user.organization &&
            this.user.organization.logo &&
            'url' in this.user.organization.logo
        );
    }

    // Avatar
    updateAvatar(data) {
        this.appLoadingIndicatorService.show('view');

        // Check to see if the avatar was removed.
        const imageRemoved =
            'imageRemoved' in data && data.imageRemoved === true;
        if (imageRemoved) {
            this.apiUploadImage({}, 'avatar');
        } else {
            // Set image data.
            ['startX', 'startY', 'width', 'height'].forEach((key) => {
                this.avatar.crop[key] = data.image.crop[key];
            });

            // Check to see if the avatar was edited
            const imageEdited =
                'imageEdited' in data && data.imageEdited === true;
            if (imageEdited) {
                this.avatar.key = undefined;
                this.apiUploadImage(this.avatar, 'avatar');
            } else {
                this.appHttpService.apiAwsUploadFile(
                    data.selectedFile,
                    (key) => {
                        this.avatar.key = key;
                        this.apiUploadImage(this.avatar, 'avatar');
                    },
                    () => {
                        this.appMessageService.show(
                            'app',
                            'error',
                            AppMessage.get('awsUploadError'),
                            5
                        );
                        this.appLoadingIndicatorService.hide(350);
                    }
                );
            }
        }
    }

    // Logo
    updateLogo(data) {
        this.appLoadingIndicatorService.show('view');

        // Check to see if the logo was removed.
        const imageRemoved =
            'imageRemoved' in data && data.imageRemoved === true;
        if (imageRemoved) {
            const org = {
                logo: {},
            };
            this.apiUploadImage(org, 'organization');
        } else {
            // Set image data.
            ['startX', 'startY', 'width', 'height'].forEach((key) => {
                this.logo.crop[key] = data.image.crop[key];
            });

            // Check to see if the logo was edited
            const imageEdited =
                'imageEdited' in data && data.imageEdited === true;
            if (imageEdited) {
                this.logo.key = undefined;
                const org = {
                    logo: this.logo,
                };
                this.apiUploadImage(org, 'organization');
            } else {
                this.appHttpService.apiAwsUploadFile(
                    data.selectedFile,
                    (key) => {
                        this.logo.key = key;
                        const org = {
                            logo: this.logo,
                        };
                        this.apiUploadImage(org, 'organization');
                    },
                    () => {
                        this.appMessageService.show(
                            'app',
                            'error',
                            AppMessage.get('awsUploadError'),
                            5
                        );
                        this.appLoadingIndicatorService.hide(350);
                    }
                );
            }
        }
    }

    // History
    getHistory(): string {
        return this.user.history ? this.user.history.join('\n') : '';
    }

    handleCbSuccess(): void {
        this.appMessageService.show(
            'app',
            'success',
            this.messages.success.history,
            3
        );
    }

    handleCbError(): void {
        this.appMessageService.show(
            'app',
            'error',
            this.messages.error.history,
            3
        );
    }

    isCreatorLevel(): boolean {
        return this.userLevel === 'admin' || this.userLevel === 'manager';
    }

    shouldEnableListsField(): boolean {
        let length = 0;
        if (this.emails && this.emails.length) {
            length += this.emails.length;
        }
        if (this.phones && this.phones.length) {
            length += this.phones.length;
        }
        return length > 1 ? true : false;
    }

    private apiAddContactMethod(contact: string): void {
        const data = {};
        const type = Utils.validateEmail(contact) ? 'email' : 'phone';
        data[type] = contact;

        this.appLoadingIndicatorService.show('view');

        this.appHttpService.request(
            'POST:api/auth/contact-method/',
            data,
            () => {
                this.getVerifyCode(contact);
                this.appLoadingIndicatorService.hide(200);
            },
            (err: any) => {
                const message =
                    err.status === 400 && typeof err.data === 'string'
                        ? err.data
                        : this.messages.error.contact['add'];
                this.appMessageService.show('app', 'error', message, 5.0);
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    private getVerifyCode(contact: string): void {
        const type = Utils.validateEmail(contact) ? 'email' : 'phone';
        this.dialog.open(AppDialogVerificationComponent, {
            height: '235px',
            width: '425px',
            disableClose: true,
            data: {
                text:
                    type === 'email'
                        ? 'Check out your email'
                        : 'Check out your phone',
                callback: (code) => {
                    this.apiVerifyContactMethod(contact, code);
                },
            },
        });
    }

    private apiVerifyContactMethod(contact: string, code: string): void {
        const data = {
            verification_code: code,
        };
        const type = Utils.validateEmail(contact) ? 'email' : 'phone';
        data[type] = contact;

        this.appLoadingIndicatorService.show('view');

        this.appHttpService.request(
            'POST:api/auth/verify/',
            data,
            () => {
                this.getUser('add');
            },
            (err) => {
                const message =
                    err.status === 400 && typeof err.data === 'string'
                        ? err.data
                        : this.messages.error.contact['verify'];
                this.appMessageService.show('app', 'error', message, 5.0);
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    private apiRemoveContactMethod(contact: string): void {
        const data = {};
        const type = Utils.validateEmail(contact) ? 'email' : 'phone';
        data[type] = contact.replace(/\+/g, encodeURIComponent('+'));

        this.appLoadingIndicatorService.show('view');

        this.appHttpService.request(
            'DELETE:api/auth/contact-method/',
            data,
            () => {
                this.getUser('remove');
            },
            (err) => {
                const message =
                    err.status === 400 && typeof err.data === 'string'
                        ? err.data
                        : this.messages.error.contact['remove'];
                this.appMessageService.show('app', 'error', message, 5.0);
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    private apiUploadImage(imageData: any, type: string): void {
        const url = 'PUT:api/auth/update/';
        const data = {};
        const img = type === 'organization' ? imageData.logo : imageData;
        data[type] = imageData;

        this.appHttpService.request(
            url,
            data,
            (user) => {
                const message = Object.keys(img).length
                    ? this.messages.success[type]['upload']
                    : this.messages.success[type]['remove'];
                this.appMessageService.show('app', 'success', message, 3);
                this.user = user;
                this.appUserService.setUser(user);
                this.cd.detectChanges();
                this.appLoadingIndicatorService.hide(200);
            },
            () => {
                const message = Object.keys(img).length
                    ? this.messages.error[type]['upload']
                    : this.messages.error[type]['remove'];
                this.appMessageService.show('app', 'error', message, 5.0);
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    private getUser(type: string): void {
        this.appHttpService.apiAuthMe(
            this.appUserService.getToken(),
            (user) => {
                this.appUserService.whenUserSet(() => {
                    this.user = this.appUserService.getUser();
                    this.emails = this.user.emails;
                    this.phones = this.user.phones;
                    if (type) {
                        this.appMessageService.show(
                            'app',
                            'success',
                            this.messages.success.contact[type],
                            5.0
                        );
                    }
                    this.appLoadingIndicatorService.hide(200);
                });
            }
        );
    }
}
