<div class="page">
    <!--Header-->
    <div class="header">
        <div class="container">
            <div class="title">Checklists</div>
            <div class="searchbox">
                <div class="searchbox-control">
                    <input
                        type="text"
                        name="search"
                        placeholder="Search"
                        [(ngModel)]="searchText"
                        (keyup)="handleSearchChange($event)"
                    />
                    <i class="material-icons icon" *ngIf="!searchText"
                        >search</i
                    >
                    <i
                        class="material-icons icon clear"
                        *ngIf="searchText"
                        (click)="clearSearch()"
                        >clear</i
                    >
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <!--Checklists-->
        <div class="content">
            <button
                [ngClass]="{ 'active-assignments-nav-button': !isSubmittedPage }"
                class="assignments-nav-button"
                (click)="tabChecklists('invited')"
            >
                <div class="button-content">
                    <i class="material-icons icon tab-icon">mail</i>
                    <span>Invited</span>
                </div>
            </button>
            <button
                [ngClass]="{ 'active-assignments-nav-button': isSubmittedPage }"
                class="assignments-nav-button"
                (click)="tabChecklists('submitted')"
            >
                <div class="button-content">
                    <i class="material-icons icon tab-icon"
                        >assignment_turned_in</i
                    >
                    <span>Submitted</span>
                </div>
            </button>
            <div class="assignments-table">
                <!--Header-->
                <div class="assignments-table-header">
                    <table>
                        <thead>
                            <tr>
                                <th class="col-title">Title</th>
                                <th class="col-date">Last Invited</th>
                                <th class="col-date">Last Submitted</th>
                            </tr>
                        </thead>
                    </table>
                </div>

                <!--Body-->
                <div class="assignments-table-body">
                    <perfect-scrollbar>
                        <table>
                            <tbody>
                                <tr
                                    *ngFor="let checklist of checklists; let i = index"
                                    [class]="wasSubmitted(checklist) ? 'submitted' : ''"
                                >
                                    <!--Title-->
                                    <td
                                        class="col-title"
                                        [routerLink]="'/view/' + getStub(checklist)"
                                    >
                                        <div class="text-wrapper">
                                            <div class="title">
                                                {{ checklist.title }}
                                            </div>
                                        </div>
                                    </td>

                                    <!--Last Invited-->
                                    <td class="col-date">
                                        {{ appHelpersService.getDisplayDate(checklist.lastInvitedDate) }}
                                    </td>

                                    <!--Last Submitted-->
                                    <td class="col-date">
                                        {{ appHelpersService.getDisplayDate(checklist.lastSubmittedDate) }}
                                    </td>
                                </tr>
                            </tbody>
                            <div class="assignments-no-results-message">
                                <div *ngIf="!isSubmittedPage && checklists?.length === 0">
                                    When you have an invitation to a checklist, it will appear here.
                                </div>
                                <div *ngIf="isSubmittedPage && checklists?.length === 0">
                                    After you've submitted responses to a checklist, it will appear here.
                                </div>
                            </div>
                        </table>
                    </perfect-scrollbar>
                </div>

                <!--Footer-->
                <div class="assignments-table-footer">
                    <!--Number of users-->
                    <div class="footer-key-value" *ngIf="!isMobile">
                        {{ getTableFooterText() }}
                    </div>

                    <!--Paging-->
                    <div
                        class="footer-paging"
                        *ngIf="numberOfPages > 1"
                        [style.width]="getFooterPagingWidth()"
                    >
                        <!--Button Left-->
                        <div class="footer-paging-button left">
                            <button
                                (click)="handlePagination('previous')"
                                [disabled]="currentPageIndex < 1"
                                class="app-button small no-text blue"
                            >
                                <i class="material-icons icon"
                                    >keyboard_arrow_left</i
                                >
                            </button>
                        </div>

                        <!--Pages Drop-Down-->
                        <div class="footer-paging-details">
                            <div class="number-of-pages">
                                {{
                                    currentPageIndex + 1
                                }}&nbsp;&nbsp;/&nbsp;&nbsp;{{ numberOfPages }}
                            </div>
                        </div>

                        <!--Button Right-->
                        <div class="footer-paging-button right">
                            <button
                                (click)="handlePagination('next')"
                                [disabled]="
                                    currentPageIndex >= numberOfPages - 1
                                "
                                class="app-button small no-text blue"
                            >
                                <i class="material-icons icon"
                                    >keyboard_arrow_right</i
                                >
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
