<div class="app-dialog dialog-send-invitation">
    <div class="header">
        {{ data.header }}
        <div class="email-toggle">
            <mat-button-toggle-group
                class="contact-method-toggle"
                aria-label="Contact Method"
            >
                <mat-button-toggle
                    [ngClass]="contactMethod == true ? 'checked' : 'unchecked'"
                    (click)="updateContactMethod(true)"
                    value="{{ contactMethod }}"
                    ><i
                        class="
                            material-icons
                            contact-method-email-toggle-icon
                            icon
                        "
                        >email</i
                    ></mat-button-toggle
                >
                <mat-button-toggle
                    [ngClass]="contactMethod == false ? 'checked' : 'unchecked'"
                    (click)="updateContactMethod(false)"
                    value="{{ contactMethod }}"
                    ><i class="material-icons contact-method-toggle-icon icon"
                        >sms</i
                    ></mat-button-toggle
                >
            </mat-button-toggle-group>
        </div>
    </div>

    <div class="body">
        <!--Search Contacts-->
        <div class="form-group">
            <app-select2
                [prePopulate]="prePopulate"
            (handleResults)="handleResultsUpdate($event)"
        >
        </app-select2>
        </div>

        <!--Message-->
        <div class="form-group">
            <textarea
                rows="6"
                maxlength="280"
                placeholder="Add a message (Optional)"
                [(ngModel)]="message"
            ></textarea>
        </div>

        <!--Error-->
        <div class="error" *ngIf="error">
            <p>{{ messages.countError }}</p>
        </div>

        <div
            class="schedule-invite"
            *ngIf="showSchedulerInForm && !sendMultipleInvites"
        >
            <p><strong>Send Date:</strong></p>
            <div class="schedule-invite-fields-wrapper">
                <mat-form-field appearance="fill">
                    <mat-label>Choose a date</mat-label>
                    <input
                        matInput
                        [matDatepicker]="picker"
                        [formControl]="scheduledDate"
                    />
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                        style="margin-top: -25px"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="fill" style="margin-left: 10px">
                    <mat-label>Choose a time</mat-label>
                    <mat-select [formControl]="singleTime">
                        <mat-option *ngFor="let time of times" [value]="time">
                            {{ time.display }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <i
                    *ngIf="!data.prePopulate"
                    (click)="cancelScheduling()"
                    style="margin-left: 10px; cursor: pointer; font-size: 14px"
                    class="material-icons icon"
                    >close</i
                >
            </div>
        </div>
        <div class="quick-schedule" *ngIf="sendMultipleInvites">
            <p class="quick-schedule-title">Schedule:</p>
            Send
            <mat-form-field class="quick-schedule-interval">
                <mat-select [formControl]="quickScheduleInterval">
                    <mat-option [value]="'daily'"> daily </mat-option>
                    <mat-option [value]="'weekly'"> weekly </mat-option>
                    <mat-option [value]="'monthly'"> monthy </mat-option>
                </mat-select>
            </mat-form-field>
            starting
            <mat-form-field class="quick-schedule-start-date">
                <input
                    matInput
                    [matDatepicker]="quickSetPicker"
                    [formControl]="quickScheduleDate"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="quickSetPicker"
                    style="margin-top: -25px"
                ></mat-datepicker-toggle>
                <mat-datepicker #quickSetPicker></mat-datepicker>
            </mat-form-field>
            at
            <mat-form-field class="quick-schedule-start-time">
                <mat-select [formControl]="quickScheduleTime">
                    <mat-option
                        *ngFor="let startTime of times"
                        [value]="startTime.display"
                    >
                        {{ startTime.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <button
                class="app-button green quick-schedule-button"
                (click)="setQuickScheduleStartDate()"
            >
                Apply
            </button>
        </div>
        <div class="multi-invites" *ngIf="sendMultipleInvites">
            <form [formGroup]="userForm">
                <div formGroupName="type">
                    <div
                        formArrayName="options"
                        cdkDropList
                        [cdkDropListData]="
                            userForm.get('type.options').controls
                        "
                        (cdkDropListDropped)="drop($event)"
                        #autoscroll
                    >
                        <div
                            *ngFor="
                                let child of userForm.get('type.options')
                                    .controls;
                                let i = index
                            "
                        >
                            <div
                                class="multi-invite-item"
                                [formGroupName]="i"
                                cdkDrag
                                [cdkDragData]="child"
                            >
                                <div class="multi-invite-item-title">
                                    {{ child.get('label').value }}
                                </div>

                                <div class="schedule-invite-fields-wrapper">
                                    <mat-form-field
                                        appearance="fill"
                                        style="width: 120px; padding-top: 12px"
                                    >
                                        <mat-label>Choose a date</mat-label>
                                        <input
                                            matInput
                                            [matDatepicker]="picker"
                                            formControlName="date"
                                        />
                                        <mat-datepicker-toggle
                                            matSuffix
                                            [for]="picker"
                                        ></mat-datepicker-toggle>
                                        <mat-datepicker
                                            #picker
                                        ></mat-datepicker>
                                    </mat-form-field>

                                    <mat-form-field
                                        appearance="fill"
                                        style="
                                            margin-left: 10px;
                                            width: 140px;
                                            padding-top: 12px;
                                            padding-right: 12px;
                                        "
                                    >
                                        <mat-label>Choose a time</mat-label>
                                        <mat-select formControlName="time">
                                            <mat-option
                                                *ngFor="let time of times"
                                                [value]="time.display"
                                            >
                                                {{ time.display }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <div
                                        class="remove-schedule-invite-item"
                                        (click)="removeFromSelected(i)"
                                    >
                                        <i
                                            class="
                                                material-icons
                                                icon
                                                select-icon
                                            "
                                            style="
                                                font-size: 18px;
                                                margin-top: 3px;
                                                margin-right: 5px;
                                            "
                                            >close</i
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div style="padding-bottom: 20px; height: 80px" class="footer d-flex jc-c">
        <button mat-dialog-close class="app-button light w-90 mr-1">
            Cancel
        </button>
        <button
            *ngIf="
                !showSchedulerInForm &&
                !sendMultipleInvites &&
                !invitationId
            "
            class="app-button green schedule-invite-button-dropdown"
            [disabled]="!contactsSelected()"
        >
            <div class="schedule-invite-button-dropdown-inner">
                <div
                    class="send-invite-button-text-dropdown"
                    (click)="sendInvitations()"
                >
                    <p>Send Now</p>
                </div>
                <div
                    class="schedule-invite-button-dropdown-trigger"
                    style="z-index: 1"
                    (click)="isMenuOpen = !isMenuOpen"
                    *ngIf="!showSchedulerInForm"
                >
                    <div>
                        <i class="material-icons icon-menu" *ngIf="!isMenuOpen"
                            >arrow_drop_down</i
                        >
                        <i class="material-icons icon-menu" *ngIf="isMenuOpen"
                            >arrow_drop_up</i
                        >
                    </div>
                </div>
            </div>
        </button>
        <button
            *ngIf="
                !showSchedulerInForm && !sendMultipleInvites && invitationId
            "
            class="app-button green schedule-invite-button-dropdown"
            [disabled]="!contactsSelected()"
            (click)="updateInvitations()"
        >
            <div class="schedule-invite-button-dropdown-inner">
                <div class="send-invite-button-text-dropdown">
                    <p>Update</p>
                </div>
            </div>
        </button>
        <button
            *ngIf="
                (showSchedulerInForm || sendMultipleInvites) &&
                !invitationId
            "
            class="d-flex app-button green schedule-invite-button"
            [disabled]="!contactsSelected()"
            (click)="sendInvitations()"
        >
            <div class="send-invite-button-text">Schedule</div>
        </button>
        <button
            *ngIf="
                (showSchedulerInForm || sendMultipleInvites) && invitationId
            "
            class="d-flex app-button green schedule-invite-button"
            [disabled]="!contactsSelected()"
            (click)="updateInvitations()"
        >
            <div class="send-invite-button-text">Update</div>
        </button>
    </div>
    <div class="schedule-menu" *ngIf="isMenuOpen">
        <div class="menu-option" (click)="showScheduler()">
            <div class="menu-option-label">Schedule Send</div>
        </div>
    </div>
</div>
