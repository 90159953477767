import {
    OnInit,
    OnDestroy,
    AfterViewInit,
    Component,
    Inject,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { SignInUser } from './classes/sign-in.user';
import { GenerateLinkData } from './classes/generate-link-data';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppAlertService } from '../../../../shared/services/app.alert.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppUserService } from '../../../../shared/services/app.user.service';
import { AppPermissionService } from '../../../../shared/services/app.permission.service';
import { Utils } from '../../../../shared/app.utils';

@Component({
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.less'],
})
export class SignInComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('email') inputEmail: ElementRef;
    @ViewChild('password') inputPassword: ElementRef;
    messages = {
        error: 'Sign in failed. Please try again.',
        error2: 'Email or Phone is wrong.<br/>Please enter valid one.',
        success: 'Check your messages.<br/>Your magic link is on the way.',
    };
    signInUser = new SignInUser('', '');
    generateLinkData = new GenerateLinkData('', '', false);
    step = 1;
    auth_as: string = null;
    private isMobile = null;

    constructor(
        @Inject('storage') private storage: Storage,
        @Inject('location') private location: Location,
        private router: Router,
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appAlertService: AppAlertService,
        private appUserService: AppUserService,
        private appPermissionService: AppPermissionService,
        private deviceService: DeviceDetectorService
    ) {
        this.isMobile = this.deviceService.isMobile();
    }

    ngOnInit() {
        this.appUserService.setLoggedInUIState('false');

        const urlParams = this.appHelpersService.getUrlParams();
        if (urlParams && 'auth_as' in urlParams && urlParams['auth_as']) {
            this.signInUser.username = urlParams['auth_as'];
            this.auth_as = urlParams['auth_as'];
        }
        if (urlParams && 'redirect' in urlParams && urlParams['redirect']) {
            this.appUserService.setRedirectPath(urlParams['redirect']);
        }
    }

    ngAfterViewInit() {
        this.appLoadingIndicatorService.hide(350);
        setTimeout(() => this.inputEmail.nativeElement.focus());
    }

    ngOnDestroy() {
        this.appAlertService.destroy();
    }

    typePassword() {
        this.appAlertService.destroy();
        this.step = 2;
        setTimeout(() => this.inputPassword.nativeElement.focus());
    }

    back() {
        this.step = 1;
        setTimeout(() => this.inputEmail.nativeElement.focus());
    }

    processSignIn() {
        this.appLoadingIndicatorService.show('view');
        this.appHttpService.request(
            'POST:api/auth/login/',
            this.signInUser,
            (response) => {
                this.appHttpService.apiAuthMe(response['authToken'], () => {
                    const redirectPath = this.appUserService.getRedirectPath();
                    if (redirectPath) {
                        this.appUserService.clearRedirectPath();
                        this.router.navigateByUrl(redirectPath);
                    } else {
                        this.router.navigateByUrl(
                            this.appPermissionService.getBaseRedirectPage(
                                this.isMobile
                            )
                        );
                    }
                });
            },
            () => {
                this.step = 1;
                this.signInUser.password = '';
                this.appAlertService.set('view', 'error', this.messages.error);
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    magicLink() {
        this.appLoadingIndicatorService.show('view');
        this.appAlertService.destroy();
        if (Utils.validateEmail(this.signInUser.username)) {
            this.generateLinkData.email = this.signInUser.username;
        } else {
            this.generateLinkData.phone = this.signInUser.username;
        }

        this.appHttpService.request(
            'POST:api/auth/generate-link/',
            this.generateLinkData,
            () => {
                this.generateLinkData = new GenerateLinkData('', '', false);
                this.appAlertService.set(
                    'view',
                    'success',
                    this.messages.success
                );
                this.appLoadingIndicatorService.hide(200);
            },
            () => {
                this.generateLinkData = new GenerateLinkData('', '', false);
                this.appAlertService.set('view', 'error', this.messages.error2);
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    navigateToGoogleSso() {
        const baseUrl = this.appHelpersService.getBaseUrl();
        location.href = baseUrl + 'api/auth/social/login/google-oauth2/';
    }

    navigateToAdpSso() {
        const baseUrl = this.appHelpersService.getBaseUrl();
        location.href = baseUrl + 'api/auth/social/login/adp-oauth2/';
    }

    forgotPassword() {
        this.router.navigate(['/reset-password']);
    }
}
