export class AppDataTransform {
    public static getConvertedData(convertTo, dataToConvert) {
        // convertTo: 'camel', 'snake'
        // If object is empty, return as is.
        if (Object.keys(dataToConvert).length === 0) {
            return dataToConvert;
        }

        let json = JSON.stringify(dataToConvert);
        const functionName = AppDataTransform.getConvertFunctionName(convertTo),
            data = {
                strArray: json.match(/"([^"]+?)"\s*:/g),
                str: undefined,
                keys: [],
            };

        data.str = data.strArray.join(',');
        data.str = data.str.substr(1, data.str.length - 3);

        data.keys = data.str.split('":,"');
        delete data.str; // clear un-needed memory.

        const uniqueKeys = data.keys.filter((f, i, a) => a.indexOf(f) === i),
            uniqueKeysLength = uniqueKeys.length;
        delete data.keys; // clear un-needed memory.

        for (let i = 0; i < uniqueKeysLength; i++) {
            json = AppDataTransform.replaceAll(
                json,
                uniqueKeys[i],
                AppDataTransform[functionName](uniqueKeys[i])
            );
        }

        return JSON.parse(json);
    }

    static getConvertFunctionName(convertTo) {
        return (
            'convertTo' +
            convertTo[0].toUpperCase() +
            convertTo.slice(1, convertTo.length)
        );
    }

    static convertToCamel(str) {
        return str.replace(/_\w/g, (m) => m[1].toUpperCase());
    }

    static convertToSnake(str) {
        return str.replace(/([A-Z])/g, function ($str) {
            return '_' + $str.toLowerCase();
        });
    }

    static replaceAll = function (json, originalKey, replacementKey) {
        return json.replace(new RegExp(originalKey, 'g'), replacementKey);
    };
}
