import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard.routing.module';
import { AppControlsModule } from '../../shared/modules/app.controls.module';
import { AppPipesModule } from '../../shared/modules/app.pipes.module';
import { VingsModule } from '../vings/vings.module';
import { ContactsModule } from '../contacts/contacts.module';
import { ChecklistsModule } from '../checklists/checklists.module';

@NgModule({
    imports: [
        AppControlsModule,
        AppPipesModule,
        DashboardRoutingModule,
        VingsModule,
        ContactsModule,
        ChecklistsModule,
    ],
    declarations: [DashboardComponent],
    providers: [],
})
export class DashboardModule {}
