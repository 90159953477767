<div class="page">
    <!--Header-->
    <div class="header">
        <div class="container">
            <div class="title">Assignments</div>
            <div class="searchbox">
                <div class="searchbox-control">
                    <input
                        type="text"
                        name="search"
                        placeholder="Search"
                        [(ngModel)]="searchText"
                        (keyup)="handleSearchChange($event)"
                    />
                    <i class="material-icons icon" *ngIf="!searchText"
                        >search</i
                    >
                    <i
                        class="material-icons icon clear"
                        *ngIf="searchText"
                        (click)="clearSearch()"
                        >clear</i
                    >
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <!--Assignments-->
        <div class="content">
            <button
                [ngClass]="{ 'active-assignments-nav-button': !isDone }"
                class="assignments-nav-button"
                (click)="tabAssignments('todo')"
            >
                <div class="button-content">
                    <i class="material-icons icon tab-icon">assignment</i>
                    <span> To Do</span>
                </div>
            </button>
            <button
                [ngClass]="{ 'active-assignments-nav-button': isDone }"
                class="assignments-nav-button"
                (click)="tabAssignments('done')"
            >
                <div class="button-content">
                    <i class="material-icons icon tab-icon"
                        >assignment_turned_in</i
                    >
                    <span>Done</span>
                </div>
            </button>
            <div class="assignments-table">
                <!--Header-->
                <div class="assignments-table-header">
                    <table>
                        <thead>
                            <tr>
                                <th class="col-notification">&nbsp;</th>
                                <th
                                    class="col-time"
                                    title="Expected view schedule"
                                    *ngIf="!isDone"
                                >
                                    <i class="material-icons icon">timer</i>
                                </th>
                                <th class="col-title">Title</th>
                                <th class="col-due" *ngIf="!isDone">Due</th>
                                <th class="col-remaining" *ngIf="!isDone">Remaining</th>
                                <th class="col-complete">Progress</th>
                                <th class="col-score">Score</th>
                            </tr>
                        </thead>
                    </table>
                </div>

                <!--Body-->
                <div class="assignments-table-body">
                    <perfect-scrollbar>
                        <table>
                            <tbody>
                                <tr
                                    *ngFor="let assignment of assignments; let i = index"
                                    [class]="assignment.isComplete ? 'completed' : ''"
                                >
                                    <!--Notification-->
                                    <td class="col-notification">
                                        <i
                                            class="material-icons icon"
                                            *ngIf="assignment.messages.length"
                                            (click)="
                                                onShowMessages(assignment.messages)
                                            "
                                            >message</i
                                        >
                                    </td>
                                    <!--Time-->
                                    <td class="col-time" *ngIf="!isDone">
                                        <span
                                            *ngIf="
                                                assignment.consumeInterval > 1 &&
                                                assignment.consumeMinutes
                                            "
                                            >{{ assignment.consumeMinutes }} min
                                            every
                                            {{
                                                assignment.consumeInterval
                                            }}
                                            days</span
                                        >
                                        <span
                                            *ngIf="
                                                assignment.consumeInterval == 1 &&
                                                assignment.consumeMinutes
                                            "
                                            >{{ assignment.consumeMinutes }} min
                                            daily</span
                                        >
                                        <span *ngIf="!assignment.consumeInterval"
                                            >Not limited</span
                                        >
                                    </td>

                                    <!--Title-->
                                    <td
                                        class="col-title"
                                        [routerLink]="'/view/' + assignment.stub"
                                    >
                                        <div class="text-wrapper">
                                            <div class="title">
                                                {{ assignment.title }}
                                            </div>
                                        </div>
                                    </td>

                                    <!--Due-->
                                    <td
                                        class="col-due"
                                        *ngIf="!isDone"
                                        [routerLink]="'/view/' + assignment.stub"
                                    >
                                        <div class="text-wrapper">
                                            <div class="text">
                                                {{ getDisplayDate(assignment.dateDue) }}
                                            </div>
                                        </div>
                                    </td>

                                    <!--Remaining-->
                                    <td
                                        class="col-remaining"
                                        *ngIf="!isDone"
                                        [routerLink]="'/view/' + assignment.stub"
                                    >
                                        <div class="text-wrapper">
                                            <div class="text">
                                                {{ getRemaining(assignment.duration, assignment.durationComplete) }}
                                            </div>
                                        </div>
                                    </td>


                                    <!--Complete-->
                                    <td
                                        class="col-complete"
                                        [routerLink]="'/view/' + assignment.stub"
                                        [ngClass]="{ done: isDone }"
                                    >
                                        <!--ProgressBar-->
                                        <div
                                            class="progress"
                                            *ngIf="!isDone && assignment.progress < 1"
                                            [title]="getProgressValue(assignment.progress) + '%'"
                                        >
                                            <div class="text">
                                                {{
                                                    getProgressValue(assignment.progress)
                                                        | number: '1.0-0'
                                                }}%
                                            </div>
                                            <div class="bar">
                                                <div
                                                    class="fill"
                                                    [style.width]="
                                                        getProgressValue(assignment.progress) +
                                                        '%'
                                                    "
                                                ></div>
                                            </div>
                                        </div>
                                        <div
                                            class="progress"
                                            *ngIf="isDone || assignment.progress == 1"
                                        >
                                            <i
                                                class="
                                                    progress-check
                                                    material-icons
                                                    icon
                                                "
                                                >checkmark</i
                                            >
                                        </div>
                                    </td>
                                    <td
                                        class="col-score"
                                        [routerLink]="'/view/' + assignment.stub"
                                    >
                                        <div
                                            *ngIf="assignment.score"
                                            [ngClass]="{
                                                green: assignment.score >= 0.8,
                                                yellow:
                                                    assignment.score < 0.8 &&
                                                    assignment.score >= 0.6,
                                                red: assignment.score <= 0.6
                                            }"
                                        >
                                            {{
                                                assignment.score * 100
                                                    | number: '1.0-0'
                                            }}%
                                        </div>
                                        <div *ngIf="!assignment.score">- -</div>
                                    </td>
                                </tr>
                            </tbody>
                            <div class="assignments-no-results-message">
                                <div *ngIf="!isDone && assignments?.length === 0">
                                    When you have an assignment to complete, it will
                                    appear here.
                                </div>
                                <div *ngIf="isDone && assignments?.length === 0">
                                    Your completed assignments will appear here.
                                </div>
                            </div>
                        </table>
                    </perfect-scrollbar>
                </div>

                <!--Footer-->
                <div class="assignments-table-footer">
                    <!--Number of users-->
                    <div class="footer-key-value" *ngIf="!isMobile">
                        {{ getTableFooterText() }}
                    </div>

                    <!--Paging-->
                    <div
                        class="footer-paging"
                        *ngIf="numberOfPages > 1"
                        [style.width]="getFooterPagingWidth()"
                    >
                        <!--Button Left-->
                        <div class="footer-paging-button left">
                            <button
                                (click)="handlePagination('previous')"
                                [disabled]="currentPageIndex < 1"
                                class="app-button small no-text blue"
                            >
                                <i class="material-icons icon"
                                    >keyboard_arrow_left</i
                                >
                            </button>
                        </div>

                        <!--Pages Drop-Down-->
                        <div class="footer-paging-details">
                            <div class="number-of-pages">
                                {{
                                    currentPageIndex + 1
                                }}&nbsp;&nbsp;/&nbsp;&nbsp;{{ numberOfPages }}
                            </div>
                        </div>

                        <!--Button Right-->
                        <div class="footer-paging-button right">
                            <button
                                (click)="handlePagination('next')"
                                [disabled]="
                                    currentPageIndex >= numberOfPages - 1
                                "
                                class="app-button small no-text blue"
                            >
                                <i class="material-icons icon"
                                    >keyboard_arrow_right</i
                                >
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
