<div class="app-dialog dialog-merge-contacts">
    <div class="header">
        {{ title }}
    </div>

    <div class="body">
        <div *ngIf="data.mergeSuggestion">These contacts may be the same person. Do you want to merge them?</div>
        <div *ngIf="!data.mergeSuggestion">Do you want to merge these contacts?</div>
        <br>
        <app-contact-card
            *ngFor=" let contact of data.contacts"
            [origin]="'mergeDialog'"
            [contact]="contact"
            (handleSelectContact)="this.selectContact($event)"
        ></app-contact-card>
    </div>

    <div class="footer">
        <button *ngIf="data.mergeSuggestion" mat-dialog-close class="app-button light w-90 mr-1" (click)="ignore()">
            Don't Suggest Again
        </button>
        <button class="app-button green w-90" (click)="save()">Merge</button>
    </div>
</div>
