<div id="vings">
    <div id="content" [class]="selectedPacket ? 'active' : ''">
        <!--Panel One - Filtering-->
        <div id="nav" class="step1">
            <app-filter-navigation
                [page]="'packets'"
                [searchText]="searchText"
                [entityLists]="entityLists"
                [selectedLabels]="selectedLabels"
                [filters]="filters"
                [selectedFilters]="selectedFilters"
                (onToggleLabel)="onToggleLabel($event)"
                (onToggleFilter)="onToggleFilter($event)"
                (onDeleteLabel)="onDeleteLabel($event)"
                (onEditCategory)="onEditCategory($event)"
                (onDeleteCategory)="onDeleteCategory($event)"
                (onCategoryCreate)="loadLabels(true)"
                (onClearFilters)="onClearFilters()"
                (onRemoveLabelFromList)="removeLabel($event)"
            >
            </app-filter-navigation>
            <!--Reset Button-->
            <div class="reset-button">
                <button
                    *ngIf="
                        selectedLabels.length ||
                        selectedFilters.length ||
                        !!searchText
                    "
                    class="app-button light block"
                    (click)="onResetSearch()"
                >
                    Reset Search
                </button>
            </div>
        </div>

        <!--Panel Two - Objects -->
        <div class="list-wrapper">
            <!--Panel Two-->
            <div id="panel-two" class="step1">
                <div class="container">
                    <app-vings-cards
                        [searchStr]="searchText"
                        [packets]="packets"
                        [packetsCount]="packetsCount"
                        [statuses]="statuses"
                        [selectedStatus]="selectedStatus"
                        [sorts]="sorts"
                        [selectedSort]="selectedSort"
                        [owners]="owners"
                        [selectedOwner]="selectedOwner"
                        [selectedPacket]="selectedPacket"
                        [shouldDisplayHorizontal]="shouldDisplayHorizontal"
                        [selectedPacketIds]="selectedPacketIds"
                        [excludedPacketIds]="excludedPacketIds"
                        [selectedAllPackets]="selectedAllPackets"
                        [isSchedulerOpen]="scheduledIsOpen"
                        (handleLazyLoadPackets)="lazyLoadPackets($event)"
                        (handleSearchChange)="onSearchTextChange($event)"
                        (handleSortOptionChange)="onSortOptionChange($event)"
                        (handleStatusOptionChange)="
                            onStatusOptionChange($event)
                        "
                        (handleOwnerOptionChange)="onOwnerOptionChange($event)"
                        (handleClickPacket)="onClickPacket($event)"
                        (handleEditPacket)="onEditPacket($event)"
                        (handleDeletePacket)="onDeletePacket($event)"
                        (handleTogglePacketId)="onTogglePacketId($event)"
                        (handleResetSearch)="onDeselectPackets($event)"
                    >
                    </app-vings-cards>
                    <div class="app-scroll-loading-indicator">Loading...</div>
                </div>
            </div>

            <!--Panel Four-->
            <div id="panel-four-trigger" (click)="toggleScheduled()" *ngIf="!isDetail">
                <i *ngIf="!scheduledIsOpen" class="material-icons icon">arrow_back_ios</i>
                <i *ngIf="scheduledIsOpen" class="material-icons icon">arrow_forward_ios</i>
            </div>

            <div id="panel-four" class="step1" *ngIf="scheduledIsOpen && !isDetail">
                <div class="view">
                    <app-vings-scheduled
                        [selectedPacketIds]="selectedPacketIds"
                        [excludedPacketIds]="excludedPacketIds"
                        [selectedAllPackets]="selectedAllPackets"
                        [packets]="packets"
                        (handleSelectPacket)="onClickPacket($event)"
                        [scheduledInvitations]="scheduledInvitations"
                    ></app-vings-scheduled>
                </div>
            </div>
        </div>

        <!--Panel Three-->
        <div id="panel-three" class="step2">
            <div class="view">
                <app-vings-selected-ving
                    [selectNewPacket]="selectNewPacket.asObservable()"
                    (handleSelectPacket)="onClickPacket($event)"
                    (handlePackets)="onUpdateSelectedPacket($event)"
                >
                </app-vings-selected-ving>
            </div>
        </div>
    </div>

    <!--Header-->
    <div id="header">
        <div class="left">
            <div class="arrow" (click)="resetPacket()" *ngIf="selectedPacket">
                <i class="material-icons icon">arrow_back</i>
            </div>
            <div class="vings-title">
                <div class="title-icon">
                    <i class="material-icons icon">perm_media</i>
                </div>
                <div class="title-text">Vings</div>
            </div>
        </div>

        <div class="right">
            <div class="inner">
                <app-vings-header
                    [packets]="packets"
                    [selectedPacketIds]="selectedPacketIds"
                    [excludedPacketIds]="excludedPacketIds"
                    [selectedAllPackets]="selectedAllPackets"
                    [packetsCount]="packetsCount"
                    [selectedLabels]="selectedLabels"
                    [selectedFilters]="selectedFilters"
                    [selectedPacket]="selectedPacket"
                    [selectedStatus]="selectedStatus"
                    [isDetail]="isDetail"
                    (onClickHeaderButton)="onClickHeaderButton($event)"
                    (onToggleAllPacketIds)="onToggleAllPacketIds()"
                >
                </app-vings-header>
            </div>
        </div>
    </div>
</div>
