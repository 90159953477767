import {
    NG_VALIDATORS,
    FormControl,
    ValidatorFn,
    Validator,
} from '@angular/forms';
import { Directive } from '@angular/core';

@Directive({
    selector: '[password][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: PasswordValidator,
            multi: true,
        },
    ],
})
export class PasswordValidator implements Validator {
    validator: ValidatorFn;

    // Min 6 characters, must at least contain upper and lower case letters and a number.
    private regex = new RegExp(
        /^(?=.*[a-z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,50}$/
    );

    constructor() {
        this.validator = this.passwordValidator();
    }

    validate(c: FormControl) {
        return this.validator(c);
    }

    passwordValidator(): ValidatorFn {
        return (c: FormControl) => {
            let valid = false;

            if (c.value && c.value.match(this.regex)) {
                valid = true;
            }

            if (valid) {
                // Check for sequential numerical characters
                for (const i in c.value) {
                    if (
                        +c.value[+i + 1] === +c.value[i] + 1 &&
                        +c.value[+i + 2] === +c.value[i] + 2 &&
                        +c.value[+i + 3] === +c.value[i] + 3
                    ) {
                        valid = false;
                    }
                }
            }

            if (valid) {
                // Check for sequential alphabetical characters
                for (const j in c.value) {
                    if (
                        String.fromCharCode(c.value.charCodeAt(j) + 1) ===
                        c.value[+j + 1] &&
                        String.fromCharCode(c.value.charCodeAt(j) + 2) ===
                        c.value[+j + 2] &&
                        String.fromCharCode(c.value.charCodeAt(j) + 3) ===
                        c.value[+j + 3]
                    ) {
                        valid = false;
                    }
                }
            }

            if (valid) {
                return null;
            } else {
                return {
                    invalid: true,
                    password: {
                        valid: false,
                    },
                };
            }
        };
    }
}
