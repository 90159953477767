import {
    Component,
    Inject,
    OnInit,
    ViewChild,
    ElementRef,
} from '@angular/core';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { AppDialogConfirmComponent } from '../../../../../../shared/dialogs/dialog-confirm/app.dialog-confirm.component';
import { ComponentFile } from '../../classes/component-file';
import { ComponentFileStatus } from '../../classes/component-file-status';

@Component({
    templateUrl: './dialog.create-components.component.html',
    styleUrls: ['./dialog.create-components.component.less'],
})
export class DialogCreateComponentsComponent implements OnInit {
    @ViewChild('selectFileField') selectFileField: ElementRef;
    icons: Array<any>;
    componentFiles: Array<ComponentFile>;
    componentsIsFileOver = false;

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<DialogCreateComponentsComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            icons: Array<any>;
            componentFiles: Array<ComponentFile>;
            dropFiles: Function;
            selectFiles: Function;
            createComponents: Function;
            retryNewComponent: Function;
            removeComponent: Function;
            cancelCreateComponents: Function;
        }
    ) {}

    ngOnInit() {
        this.icons = this.data.icons;
        this.componentFiles = this.data.componentFiles;
    }

    shouldEnableCreateComponents(): boolean {
        const atLeastOneComponentFile = this.componentFiles.length > 0,
            atLeastOneComponentReady =
                this.componentFiles.filter(
                    (cf) => cf.status === ComponentFileStatus.ready
                ).length > 0,
            allComponentsReadyOrError = this.componentFiles.every(
                (cf) =>
                    cf.status === ComponentFileStatus.ready ||
                    cf.status === ComponentFileStatus.error ||
                    !cf.valid
            );

        return (
            atLeastOneComponentFile &&
            atLeastOneComponentReady &&
            allComponentsReadyOrError
        );
    }

    componentFilesDropped(event: any) {
        this.componentsIsFileOver = false;

        this.data.dropFiles(event);
    }

    selectFiles(event: any): void {
        this.data.selectFiles(event);

        // Clear input file
        this.selectFileField.nativeElement.value = '';
    }

    create(): void {
        this.data.createComponents(this.dialogRef);
    }

    retry(id: string): void {
        this.data.retryNewComponent(id);
    }

    remove(id: string): void {
        this.data.removeComponent(id, this.dialogRef);
    }

    cancel(): void {
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            disableClose: true,
            data: {
                text: 'Are you sure you want to cancel?',
                callback: () => {
                    this.dialogRef.close();
                    this.data.cancelCreateComponents();
                },
            },
        });
    }
}
