<div class="app-dialog dialog-edit-component-description">
    <div class="header">Edit Summary</div>

    <div class="body">
        <textarea
            [(ngModel)]="text"
            name="text"
            [minlength]="validations.component.summary.min"
            [maxlength]="validations.component.summary.max"
        ></textarea>
    </div>

    <div class="footer">
        <button mat-dialog-close class="app-button light w-90 mr-1">
            Cancel
        </button>
        <button type="submit" class="app-button green w-90" (click)="save()">
            Save
        </button>
    </div>
</div>
