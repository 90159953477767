import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PluralizePipe } from '../pipes/app.pluralize.pipe';

@NgModule({
    imports: [CommonModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [PluralizePipe],
    exports: [PluralizePipe],
})
export class AppPipesModule {}
