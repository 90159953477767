import { Injectable } from '@angular/core';
import { AppHttpService } from '../../../shared/services/app.http.service';
import { AppJobService } from '../../../shared/services/job/app.job.service';

@Injectable()
export class ContactService {
    private jobs = {
        emailsAndPhones: 'validate-emails-and-phones',
        labelsAndLists: 'validate-labels-and-lists',
    };

    private validation = {
        emails: { min: 0, max: 254 },
        phones: { min: 0, max: 32 },
        labels: { min: 2, max: 40 },
        lists: { min: 3, max: 40 },
    };

    private lastValidatedContactData = {
        emails: '',
        phones: '',
    };

    constructor(
        private appHttpService: AppHttpService,
        private appJobService: AppJobService
    ) {}

    initEmailsAndPhonesValidationJob(originalData, contact, isValid) {
        // If there is at least one Email or Phone and there has been a change within the Emails
        // or Phones, make the validation call. Continue to attempt validation if these conditions
        // arise again.
        this.lastValidatedContactData.emails = JSON.stringify(
            JSON.parse(originalData).emails
        );
        this.lastValidatedContactData.phones = JSON.stringify(
            JSON.parse(originalData).phones
        );

        this.appJobService.create(
            this.jobs.emailsAndPhones,
            () => {
                const updated = {
                        emails: this.getArrayPropertyAsString(
                            'emails',
                            contact
                        ),
                        phones: this.getArrayPropertyAsString(
                            'phones',
                            contact
                        ),
                    },
                    emailsOrPhonesHaveChanged =
                        this.lastValidatedContactData.emails !==
                            updated.emails ||
                        this.lastValidatedContactData.phones !== updated.phones,
                    data = {
                        emails: JSON.parse(updated.emails),
                        phones: JSON.parse(updated.phones),
                    },
                    oneEmailOrPhoneSet =
                        data.emails.length > 0 || data.phones.length > 0;

                // Check if emails/phones have changed and if any are still set, if so make validation call...
                if (emailsOrPhonesHaveChanged && oneEmailOrPhoneSet) {
                    this.lastValidatedContactData.emails = updated.emails;
                    this.lastValidatedContactData.phones = updated.phones;

                    this.appHttpService.request(
                        'POST:api/contact-methods/validate/',
                        data,
                        (response) => {
                            // Update all isValid properties.
                            isValid.emails =
                                response.invalid.emails.length === 0;
                            isValid.phones =
                                response.invalid.phones.length === 0;
                            isValid.oneOrMoreValidEmails =
                                response.valid.emails.length >= 1;
                            isValid.oneOrMoreValidPhones =
                                response.valid.phones.length >= 1;
                        },
                        () => {
                            this.lastValidatedContactData.emails = '';
                            this.lastValidatedContactData.phones = '';
                        }
                    );
                }
            },
            500
        );
    }

    killValidationJobs() {
        this.appJobService.kill(this.jobs.emailsAndPhones);
        this.appJobService.kill(this.jobs.labelsAndLists);
    }

    setContactData(contact, contactPrime) {
        // Set the name, blacklist, job title, start date, separation date and internal id.
        contactPrime.name = contact.name.trim();
        contactPrime.blacklist = contact.blacklist ? contact.blacklist : false;
        contactPrime.title = contact.title ? contact.title.trim() : null;
        contactPrime.hireDate = contact.hireDate ? contact.hireDate : null;
        contactPrime.separationDate = contact.separationDate
            ? contact.separationDate
            : null;
        contactPrime.internalId = contact.internalId
            ? contact.internalId.trim()
            : null;

        // Add emails, phones.
        ['emails', 'phones'].forEach((propertyName) => {
            contactPrime[propertyName] = this.buildContactProperty(
                contact[propertyName]
            );
        });
    }

    isContactValid(contact: any, isValid) {
        let isContactValid = false;
        const contactHasName = this.contactHasName(contact);
        if (isValid.method === 'create') {
            // Baseline validation for 'name'.
            isContactValid = contactHasName;
        } else if (isValid.method === 'edit') {
            // Let the User save with no fields filled in
            isContactValid = true;
        }

        if (contactHasName) {
            // If the name is set, ensure that its the correct length.
            const nameLength = contact.name.length;
            isContactValid =
                isContactValid && nameLength >= 0 && nameLength <= 75;
        }

        // Ensure all 'emails', 'phones' are valid.
        isContactValid = isContactValid && isValid.emails && isValid.phones;

        // Validate all 'emails', 'phones' lengths individually.
        ['emails', 'phones'].forEach((propertyName) => {
            const property = contact[propertyName];
            const propertyMin = this.validation[propertyName].min,
                propertyMax = this.validation[propertyName].max;

            isContactValid =
                isContactValid &&
                this.isValidLength(property, propertyMin, propertyMax);
        });

        return isContactValid;
    }

    checkAllFieldsValid(isValid) {
        const propertyNames = ['emails', 'phones'];
        let allFieldsValid = true;

        for (let i = 0; i < propertyNames.length; i++) {
            if (isValid[propertyNames[i]] === false) {
                allFieldsValid = false;
                break;
            }
        }

        return allFieldsValid;
    }

    getValidation() {
        return this.validation;
    }

    apiGetContacts(params: Object, callback?: Function) {
        this.appHttpService.request('GET:api/contacts/', params, (response) => {
            this.postProcessContacts(response.results);
            if (callback) {
                callback(response);
            }
        });
    }

    apiGetContact(contactId: string, callback: Function) {
        const url = 'GET:api/contacts/' + contactId + '/';
        this.appHttpService.request(url, {}, (contact) => {
            this.postProcessContact(contact);
            callback(contact);
        });
    }

    apiCreateContact(
        data: Object,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        this.appHttpService.request(
            'POST:api/contacts/',
            data,
            (contact) => {
                this.postProcessContact(contact);
                if (successCallback) {
                    successCallback(contact);
                }
            },
            (contact) => {
                if (errorCallback) {
                    errorCallback(contact);
                }
            }
        );
    }

    apiUpdateContact(
        contactId: string,
        data: Object,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        const url = 'PUT:api/contacts/' + contactId + '/';
        this.appHttpService.request(
            url,
            data,
            (contact) => {
                this.postProcessContact(contact);
                if (successCallback) {
                    successCallback(contact);
                }
            },
            (contact) => {
                if (errorCallback) {
                    errorCallback(contact);
                }
            }
        );
    }

    apiUpdateContacts(params: Object, queries?: Object, callback?: Function) {
        const url = 'PUT:api/contacts/?';
        const queryString = queries
            ? Object.keys(queries)
                  .map((key) => key + '=' + queries[key])
                  .join('&')
            : '';

        this.appHttpService.request(url + queryString, params, (contacts) => {
            this.postProcessContacts(contacts);
            if (callback) {
                callback(contacts);
            }
        });
    }

    apiUploadContacts(
        params: Object,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        this.appHttpService.request(
            'POST:api/import/contacts/',
            params,
            (res) => {
                if (successCallback) {
                    successCallback(res);
                }
            },
            (err) => {
                if (errorCallback) {
                    errorCallback(err);
                }
            }
        );
    }

    apiCreateCustomFilter(
        params: Object,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        this.appHttpService.request(
            'POST:api/filters/contact/',
            params,
            (contact) => {
                if (successCallback) {
                    successCallback(contact);
                }
            },
            (error) => {
                if (errorCallback) {
                    errorCallback(error);
                }
            }
        );
    }

    apiUpdateCustomFilter(
        filterId: string,
        data: Object,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        this.appHttpService.request(
            'PUT:api/filters/contact/' + filterId + '/',
            data,
            (contact) => {
                if (successCallback) {
                    successCallback(contact);
                }
            },
            (error) => {
                if (errorCallback) {
                    errorCallback(error);
                }
            }
        );
    }

    apiDeleteCustomFilter(
        filterId: string,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        this.appHttpService.request(
            'DELETE:api/filters/contact/' + filterId + '/',
            {},
            (contact) => {
                if (successCallback) {
                    successCallback(contact);
                }
            },
            (error) => {
                if (errorCallback) {
                    errorCallback(error);
                }
            }
        );
    }

    apiDeleteFilter(
        type: string,
        idx: any,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        let uri = '';
        if (type === 'category') {
            uri = 'labels/categories/contact/';
        } else if (type === 'label') {
            uri = 'labels/contact/';
        } else if (type === 'list') {
            uri = 'lists/';
        }
        this.appHttpService.request(
            'DELETE:api/' + uri + idx + '/',
            {},
            (contact) => {
                if (successCallback) {
                    successCallback(contact);
                }
            },
            (error) => {
                if (errorCallback) {
                    errorCallback(error);
                }
            }
        );
    }

    apiCreateCategory(
        category: string,
        labels: any,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        this.appHttpService.request(
            'POST:api/labels/categories/contact/' + category + '/',
            labels,
            (contact) => {
                if (successCallback) {
                    successCallback(contact);
                }
            },
            (contact) => {
                if (errorCallback) {
                    errorCallback(contact);
                }
            }
        );
    }

    apiRetryContactMethod(
        data: Object,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        this.appHttpService.request(
            'POST:api/contact-methods/retry/',
            data,
            (response) => {
                if (successCallback) {
                    successCallback(response);
                }
            },
            (error) => {
                if (errorCallback) {
                    errorCallback(error);
                }
            }
        );
    }
    apiDeactivateContactMethod(
        data: Object,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        this.appHttpService.request(
            'POST:api/contact-methods/deactivate/',
            data,
            (response) => {
                if (successCallback) {
                    successCallback(response);
                }
            },
            (error) => {
                if (errorCallback) {
                    errorCallback(error);
                }
            }
        );
    }
    apiActivateContactMethod(
        data: Object,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        this.appHttpService.request(
            'POST:api/contact-methods/activate/',
            data,
            (response) => {
                if (successCallback) {
                    successCallback(response);
                }
            },
            (error) => {
                if (errorCallback) {
                    errorCallback(error);
                }
            }
        );
    }

    private postProcessContacts(contacts) {
        contacts.forEach((contact) => {
            this.postProcessContact(contact);
        });
    }

    private postProcessContact(contact) {
        const pluralName = { email: 'emails', phone: 'phones' };

        // Add default email,phone property if present.
        ['email', 'phone'].forEach((contactMethod) => {
            if (
                contact[pluralName[contactMethod]] &&
                contact[pluralName[contactMethod]].length > 0
            ) {
                contact[contactMethod] = contact[pluralName[contactMethod]][0];
            }
        });
    }

    private buildContactProperty(contactProperty) {
        let property;

        if (contactProperty.length > 0) {
            property = contactProperty.split('\n');
            property = property
                .map((prop) => prop.trim())
                .filter((prop) => !!prop);
        } else {
            property = [];
        }

        return property;
    }

    private isValidLength(property, min: number, max: number) {
        const propertyLength = property.length;
        let isValid = true;

        if (propertyLength > 0) {
            for (let i = 0; i < propertyLength; i++) {
                const itemLength = property[i].length;

                if (itemLength < min || itemLength > max) {
                    isValid = false;
                    break;
                }
            }
        }

        return isValid;
    }

    private contactHasName(contact) {
        return contact.name.length > 0;
    }

    private getArrayPropertyAsString(propertyName: string, contact) {
        const newArray = [],
            propertyArray = contact[propertyName].split('\n');

        propertyArray.forEach((propertyValue) => {
            if (propertyValue.trim() !== '') {
                newArray.push(propertyValue.trim());
            }
        });

        return JSON.stringify(newArray);
    }
}
