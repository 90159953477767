<div class="app-dialog-new-item">
    <form (ngSubmit)="handleVerification()" #newItemForm="ngForm" novalidate>
        <div class="header">
            {{ data.title }}
        </div>

        <div class="body">
            <app-form-field
                type="text"
                [name]="data.type"
                [label]="data.label"
                [placeholder]="data.placeholder"
                [required]="true"
                [(ngModel)]="field"
            >
            </app-form-field>
        </div>

        <div class="footer">
            <button mat-dialog-close class="app-button light mr-1">
                Cancel
            </button>
            <button
                type="submit"
                class="app-button green"
                [disabled]="!newItemForm.form.valid || !validateField()"
            >
                Verify
            </button>
        </div>
    </form>
</div>
