import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { GroupOption } from '../../classes/group-option';

@Component({
    selector: 'app-group-toggle',
    templateUrl: './app.group-toggle.component.html',
    styleUrls: ['./app.group-toggle.component.less'],
})
export class AppGroupToggleComponent implements OnInit {
    @Input() options: Array<GroupOption>;
    @Input() value: any;
    @Input() size?: string;
    @Input() gap = false;

    @Output() valueChange = new EventEmitter<any>();

    update(newValue: any) {
        this.value = newValue;
        this.valueChange.emit(this.value);
    }

    getOptionClass(value: any) {
        let optionClass = 'app-group-toggle-option size-' + this.size;

        if (this.gap) {
            optionClass += ' gap';
        }

        if (this.value === value) {
            optionClass += ' selected';
        }

        return optionClass;
    }

    ngOnInit() {
        // Init size property.
        if (!this.size) {
            this.size = 'standard';
        }
    }
}
