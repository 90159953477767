<div class="app-dialog dialog-view-status">
    <div class="header">Status</div>

    <div class="body">
        {{ message }}
    </div>

    <div class="footer">
        <button (click)="close('continue')" class="app-button green w-90 ms-12">
            {{ next }}
        </button>
        <button
            *ngIf="done === 'Done'"
            (click)="close('done')"
            class="app-button green w-90 ms-12"
        >
            {{ done }}
        </button>
    </div>
</div>
