import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AppAvatarCropperComponent } from '../components/app-avatar-cropper/app.avatar.cropper.component';

@NgModule({
    imports: [CommonModule, ImageCropperModule, NgxFileDropModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [AppAvatarCropperComponent],
    exports: [AppAvatarCropperComponent],
})
export class AppImageCropperModule {}
