import {
    Component,
    Inject,
    OnInit,
    ViewChild,
    ElementRef,
    HostListener,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';

import { AppMessageService } from '../../../../shared/services/app.message.service';

@Component({
    templateUrl: './dialog.get.link.component.html',
    styleUrls: ['./dialog.get.link.component.less'],
})
export class DialogGetLinkComponent implements OnInit {
    @ViewChild('inputLink') inputLink: ElementRef;
    messages = {
        success: 'Successfully copied the link to the clipboard.',
        error: 'There was an error copying the link.',
    };
    link = '';
    lastLink = '';
    isDisabled = true;

    constructor(
        private clipboardService: ClipboardService,
        private appMessageService: AppMessageService,
        public dialogRef: MatDialogRef<DialogGetLinkComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            link: string;
        }
    ) {}

    ngOnInit() {
        this.link = this.data.link;
    }

    @HostListener('window:keydown', ['$event'])
    onKeyPress($event: KeyboardEvent) {
        if (($event.ctrlKey || $event.metaKey) && $event.keyCode === 67) {
            this.appMessageService.show(
                'app',
                'success',
                this.messages.success,
                3
            );
            this.clipboardService.copyFromContent(this.link);
        }
    }

    handleCbSuccess(): void {
        this.appMessageService.show('app', 'success', this.messages.success, 3);
    }

    handleCbError(): void {
        this.appMessageService.show('app', 'error', this.messages.error, 3);
    }

    updateTag(): void {
        if (this.isDisabled) {
            if (!this.link.endsWith('/')) {
                this.link += '/';
            }
            this.setFocus();
        } else {
            this.link = this.data.link;
        }
        this.isDisabled = !this.isDisabled;
    }

    onTypeStartTag($event): void {
        this.lastLink = $event.target.value;
        // Tag can be only letters, numbers, dashes, up to 25 characters
        const length = this.link.length - this.data.link.length;
        if (!$event.key.match(/^[0-9a-zA-Z\/]+$/) || length >= 25) {
            $event.preventDefault();
        }
    }

    onTypeEndTag($event): void {
        const link = $event.target.value;
        if (!link.startsWith(this.data.link)) {
            // Always keep share link, user cant change it.
            this.link = this.lastLink;
            this.setFocus();
        }
    }

    private setFocus(): void {
        setTimeout(() => {
            this.inputLink.nativeElement.blur();
            this.inputLink.nativeElement.setSelectionRange(
                this.link.length,
                this.link.length
            );
            this.inputLink.nativeElement.focus();
        });
    }
}
