<div class="app-dialog dialog-qr-code">
    <div class="header">Get QR Code</div>

    <div class="body">
        <img [src]="data.img" alt="QR Code" />
    </div>

    <div class="footer">
        <button mat-dialog-close class="app-button light w-90 mr-1">
            Cancel
        </button>
        <button
            type="submit"
            class="app-button green w-90"
            (click)="onConfirm()"
        >
            Download
        </button>
    </div>
</div>
