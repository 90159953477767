import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AppInfoService } from '../../../../shared/services/app-info/app.info.service';
import { AppSelectOption } from '../../../../shared/components/app-select/classes/app.select.option';
import { Utils } from '../../../../shared/app.utils';
import { debounceTime } from 'rxjs/operators';
import {Contact} from "../../classes/contact";

@Component({
    selector: 'app-contacts-cards',
    styleUrls: ['./list.component.less'],
    templateUrl: './list.component.html',
})
export class ListComponent implements OnInit, OnChanges {
    private lastSearchText = '';
    private searchSubject: Subject<string> = new Subject();

    searchText: string;

    @Input() searchStr: string;
    @Input() contacts: Array<Contact>;
    @Input() sorts: Array<AppSelectOption>;
    @Input() selectedSort: AppSelectOption;
    @Input() statuses: Array<AppSelectOption>;
    @Input() selectedStatus: AppSelectOption;
    @Input() owners: Array<AppSelectOption>;
    @Input() selectedOwner: AppSelectOption;
    @Input() selectedContactIds: Array<number>;
    @Input() excludedContactIds: Array<number>;
    @Input() selectedAllContacts: boolean;
    @Input() shouldDisplayHorizontal: boolean;
    @Input() selectedContact: Contact;
    @Input() contactsCount: number;

    @Output() handleToggleContactId = new EventEmitter<any>();
    @Output() handleSelectContact = new EventEmitter<any>();
    @Output() handleSearchChange = new EventEmitter<any>();
    @Output() handleSortUpdate = new EventEmitter<any>();
    @Output() handleStatusUpdate = new EventEmitter<any>();
    @Output() handleOwnerUpdate = new EventEmitter<any>();
    @Output() handleLazyLoadContacts = new EventEmitter<any>();
    @Output() handleResetSearch = new EventEmitter<any>();

    constructor(
        private router: Router,
        private appInfoService: AppInfoService
    ) {}

    ngOnInit() {
        const appInfo = this.appInfoService.getAppInfo();
        const searchDelayMs = appInfo.packetsInfo.searchDelayMs;

        this.searchSubject
            .pipe(debounceTime(searchDelayMs))
            .subscribe((searchText) => {
                this.processSearchTextChange(searchText);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.searchStr === '') {
            // Reset Search
            this.searchText = this.searchStr;
            this.lastSearchText = '';
        }
    }

    getContactClass(id: number): string {
        const isContactSelected =
            this.selectedContact && this.selectedContact.id === id;
        return isContactSelected ? 'contact selected' : 'contact';
    }

    getSelectedContactClass(contactId) {
        let contactIsSelected = false;
        if (this.selectedAllContacts) {
            contactIsSelected =
                this.excludedContactIds.indexOf(contactId) === -1;
        } else {
            contactIsSelected = this.selectedContactIds.indexOf(contactId) >= 0;
        }
        return contactIsSelected ? 'selected-icon selected' : 'selected-icon';
    }

    toggleSelectedContactId(id: number): void {
        this.handleToggleContactId.emit(id);
    }

    selectContact(contact) {
        this.handleSelectContact.emit(contact);
    }

    handleClearSearchField(): void {
        this.searchText = '';
        this.processSearchTextChange('');
    }

    handleSearchTextChange(event): void {
        if (event.key !== 'Enter') {
            const searchText = event.target.value;
            this.searchSubject.next(searchText);

            this.handleResetSearch.emit();
        }
    }

    updateSelectedStatus(selectedStatus: AppSelectOption): void {
        this.handleStatusUpdate.emit(selectedStatus);
    }

    updateSelectedSort(selectedSort: AppSelectOption): void {
        this.handleSortUpdate.emit(selectedSort);
    }

    updateSelectedOwner(selectedOwner: AppSelectOption): void {
        this.handleOwnerUpdate.emit(selectedOwner);
    }

    lazyLoadContacts(event): void {
        this.handleLazyLoadContacts.emit(event);
    }

    get2LetterName(name: string) {
        return Utils.get2LetterName(name);
    }

    private processSearchTextChange(searchText: string): void {
        if (this.lastSearchText !== searchText) {
            this.lastSearchText = searchText;
            this.handleSearchChange.emit(this.lastSearchText);
        }
    }
}
