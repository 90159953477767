import {
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AppInfoService } from '../../../shared/services/app-info/app.info.service';
import { Utils } from '../../../shared/app.utils';
import {
    catchError,
    distinctUntilChanged,
    map,
    switchMap,
    tap,
} from 'rxjs/operators';
import { AppHttpService } from '../../../shared/services/app.http.service';
import { concat, Observable, of } from 'rxjs';
import { AppHelpersService } from '../../../shared/services/app.helpers.service';
import { AppLoadingIndicatorService } from '../../../shared/services/app.loading-indicator.service';
import { MatDialog } from '@angular/material/dialog';
import { AppMessageService } from '../../../shared/services/app.message.service';

interface Result {
    id: string;
    name: string;
    email: string;
    phone: string;
    method: string;
    disabled?: boolean;
}
@Component({
    selector: 'app-select2',
    styleUrls: ['./app.select2.component.less'],
    templateUrl: './app.select2.component.html',
})
export class AppSelect2Component implements OnInit, OnChanges {
    @Input() prePopulate: any;
    @Output() handleResults = new EventEmitter<any>();
    searchInput$ = new Subject<string>();
    peopleLoading = false;
    results$: Observable<Result[]>;
    selectedResults: Array<any> = [];
    filterBySelected: boolean;
    private types = {
        contact: 1,
        filter: 2,
        label: 3,
        status: 4,
        table: 5,
        email: 6,
        phone: 7,
    };
    private filterData: {
        emails: any;
        contactLabels: any;
        packetIds: any;
        phones: any;
        contactFilters: any;
        contactIds: any;
    };
    constructor(
        private router: Router,
        private appInfoService: AppInfoService,
        private appHttpService: AppHttpService,
        private appHelpersService: AppHelpersService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appMessageService: AppMessageService,
        private dialog: MatDialog
    ) {}

    hasArrayOfValues(obj, property) {
        return obj.hasOwnProperty(property) && Array.isArray(obj[property]) && obj[property].length
    }

    processPrepopulateData() {
        if (this.prePopulate) {
            if (Array.isArray(this.prePopulate) && this.prePopulate.length) {
                this.selectedResults = this.prePopulate;
            } else {
                const preSelected = []

                if (this.hasArrayOfValues(this.prePopulate, 'contactLabels')) {
                    this.prePopulate.contactLabels.forEach((item) => {
                        const preSelectedLabel = {
                            name: item,
                            group: 'Labels',
                            type: this.types.label,
                        };
                        preSelected.push(preSelectedLabel);
                    });
                }
                if (this.hasArrayOfValues(this.prePopulate, 'contactStatuses')) {
                    this.prePopulate.contactStatuses.forEach((item) => {
                        const preSelectedStatus = {
                            name: item,
                            group: 'Statuses',
                            type: this.types.status,
                        };
                        preSelected.push(preSelectedStatus);
                    });
                }
                if (this.hasArrayOfValues(this.prePopulate, 'contactFilters')) {
                    this.prePopulate.contactFilters.forEach((item) => {
                        item.group = 'Filters';
                        item.type = this.types.filter;
                        preSelected.push(item);
                    });
                }
                if (this.hasArrayOfValues(this.prePopulate, 'contacts')) {
                    this.prePopulate.contacts.forEach((item) => {
                        item.group = 'Contacts';
                        item.name = item.displayName;
                        item.type = this.types.contact;
                        preSelected.push(item);
                    });
                }
                if (preSelected.length) {
                    this.selectedResults = preSelected;
                }
            }

            if (this.selectedResults.length) {
                this.handleResults.emit(this.selectedResults);
            }
        }
    }

    ngOnInit() {
        this.processPrepopulateData();

        this.results$ = concat(
            of([]), // default items
            this.searchInput$.pipe(
                distinctUntilChanged(),
                tap(() => (this.peopleLoading = true)),
                switchMap((term) =>
                    this.getSearchResults(term).pipe(
                        catchError(() => of([])), // empty list on error
                        tap(() => (this.peopleLoading = false))
                    )
                )
            )
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.onChange();
    }
    trackByFn(item: any) {
        return item.id;
    }
    getSearchResults(term: string = null): Observable<Result[]> {
        if (term) {
            return this.appHttpService
                .requestObserver('GET:api/recipient-search/', { query: term })
                .pipe(map((data) => this.cleanSearchResults(data)));
        } else {
            return of([]);
        }
    }
    cleanSearchResults(data) {
        const contacts = [];
        data.contacts.map((item, index) => {
            item.key = item.id;
            item.type = this.types.contact;
            if (item.contactMethods.active.length) {
                item.contactMethods.active.map((el, idx) => {
                    const itm = this.appHelpersService.getClone(item);
                    itm.id = 'contacts_' + item.key + '_' + idx;
                    itm.contactMethod = el;
                    if (itm.name && !/^ *$/.test(itm.name)) {
                        contacts.push(itm);
                    } else {
                        itm.name = 'No name set';
                        contacts.push(itm);
                    }
                    itm.class = 'contact_' + idx;
                    itm.group = 'Contacts';
                    itm.method = ' | ' + itm.contactMethod;
                });
            } else {
                const itm = this.appHelpersService.getClone(item);
                itm.id = 'contacts_' + item.key + '_' + 0;
                itm.contactMethod = false;
                if (itm.name && !/^ *$/.test(itm.name)) {
                    contacts.push(itm);
                } else {
                    itm.name = 'No name set';
                    contacts.push(itm);
                }
                itm.class = 'contact_0';
                itm.group = 'Contacts';
            }
        });
        const filters = data.filters.map((item, index) => {
            item.key = item.id;
            item.id = 'filters_' + item.id;
            item.type = this.types.filter;
            item.group = 'Filters';
            item.count = item.contactCount;
            return item;
        });
        const labels = data.labels.map((item, index) => {
            item.id = 'labels_' + item.name;
            item.type = this.types.label;
            item.group = 'Labels';
            item.count = item.objectCount;
            return item;
        });
        const statuses = data.statuses.map((item, index) => {
            item.id = 'statuses_' + item.value;
            item.type = this.types.status;
            item.group = 'Status';
            item.count = item.contactCount;
            return item;
        });
        const newContacts = [];
        data.newEmails.map((email, index) => {
            const item = {
                id: email,
                type: this.types.email,
                email: email,
                group: 'New Contacts',
            };
            newContacts.push(item);
        });
        data.newPhones.map((phone, index) => {
            const item = {
                id: phone,
                type: this.types.phone,
                phone: phone,
                group: 'New Contacts',
            };
            newContacts.push(item);
        });
        const res = [...newContacts, ...filters, ...labels, ...statuses, ...contacts];
        return res;
    }

    onChange() {
        const emails = [],
            phones = [],
            filters = [],
            labels = [],
            statuses = [],
            contactIds = [];

        this.selectedResults.map((item, index) => {
            if (item.type === this.types.contact && item.contactMethod) {
                if (Utils.validateEmail(item.contactMethod)) {
                    emails.push(item.contactMethod);
                } else {
                    phones.push(item.contactMethod);
                }
            } else if (item.type === this.types.filter) {
                filters.push(item.key);
            } else if (item.type === this.types.label) {
                labels.push(item.name);
            } else if (item.type === this.types.status) {
                statuses.push(item.value);
            } else if (item.type === this.types.email) {
                emails.push(item.email);
            } else if (item.type === this.types.phone) {
                phones.push(item.phone);
            }
            return item;
        });

        this.handleResults.emit(this.selectedResults);
        // this.appHttpService.request(
        //     'GET:api/invitations/unsent/',
        //     this.filterData,
        //     (response) => {
        //         this.scheduledInvitations = response.results;
        //         this.formatScheduledInvitations();
        //         this.appLoadingIndicatorService.hide(350);
        //     }
        // );
    }
}
