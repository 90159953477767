import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { ContactService } from '../../services/contact.service';
import { AppUserService } from '../../../../shared/services/app.user.service';

@Component({
    templateUrl: './dialog-merge-contacts.component.html',
})
export class DialogMergeContactsComponent implements OnInit {
    messages = {
        success: 'Contacts were merged successfully.',
        ignored: 'This merge will not be suggested again.',
        error: 'An error occurred while merging contacts. Please refresh and try again.',
        selectMoreContacts: 'Please select more than one contact to merge.',
    };
    title = '';
    contacts = [];
    validation: Object;
    @Output() handleToggleContactId = new EventEmitter<any>();
    @Output() handleSelectContact = new EventEmitter<any>();

    constructor(
        private router: Router,
        private appHttpService: AppHttpService,
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appHelpersService: AppHelpersService,
        private contactService: ContactService,
        private appUserService: AppUserService,
        public dialogRef: MatDialogRef<DialogMergeContactsComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            contacts: Array<object>;
            mergeSuggestion: any;
            callback: Function;
        }
    ) {
    }

    toggleSelectedContactId(id: number): void {
        this.handleToggleContactId.emit(id);
    }

    selectContact(contact) {
        this.handleSelectContact.emit(contact);
    }

    getContactIds(contacts) {
        return contacts.map(x => x.id)
    }

    ngOnInit() {
        if (this.data.title) {
            this.title = this.data.title;
        }
        this.appLoadingIndicatorService.hide(350);
    }

    ignore() {
        this.appHttpService.request(
            `POST:api/merge/contacts/ignore/${this.data.mergeSuggestion.id}/`,
            {},
            () => {
                this.data.callback(this.dialogRef, this.messages.ignored);
                this.router.navigateByUrl(`${this.appUserService.getContactDetailsBaseUrl()}/${this.data.mergeSuggestion.primary.id}`);
                this.appLoadingIndicatorService.hide(200);
            },
            (err) => {
                const error =
                    err.data &&
                    Array.isArray(err.data) &&
                    this.appHelpersService.isValidString(err.data[0])
                        ? err.data[0]
                        : this.messages.error;
                this.appMessageService.show(
                    'modal',
                    'error',
                    this.messages.error,
                    5
                );
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    save() {
        this.appLoadingIndicatorService.show('app');
        if (this.data.contacts.length < 2 && !this.data.mergeSuggestion) {
            this.dialogRef.close();
            this.appMessageService.show(
                'app',
                'general',
                this.messages.selectMoreContacts,
                3
            );
            this.appLoadingIndicatorService.hide(200);
        } else if (this.data.mergeSuggestion) {
            this.appHttpService.request(
                `POST:api/merge/contacts/execute/${this.data.mergeSuggestion.id}/`,
                {},
                (contact) => {
                    this.data.callback(this.dialogRef, this.messages.success);

                    this.handleSelectContact.emit(contact)
                },
                (err) => {
                    const error =
                        err.data &&
                        Array.isArray(err.data) &&
                        this.appHelpersService.isValidString(err.data[0])
                            ? err.data[0]
                            : this.messages.error;
                    this.appMessageService.show(
                        'modal',
                        'error',
                        this.messages.error,
                        5
                    );
                    this.appLoadingIndicatorService.hide(200);
                }
            );
        } else {
            const contactIds = this.getContactIds(this.data.contacts),
                mergeData = { merge_ids: contactIds.slice(1) },
                firstContactId = contactIds[0];

            this.appHttpService.request(
                `POST:api/contacts/${firstContactId}/`,
                mergeData,
                () => {
                    this.data.callback(this.dialogRef, this.messages.success);
                    this.router.navigateByUrl(`${this.appUserService.getContactDetailsBaseUrl()}/${firstContactId}`);
                    this.appLoadingIndicatorService.hide(200);
                },
                (err) => {
                    const error =
                        err.data &&
                        Array.isArray(err.data) &&
                        this.appHelpersService.isValidString(err.data[0])
                            ? err.data[0]
                            : this.messages.error;
                    this.appMessageService.show(
                        'modal',
                        'error',
                        this.messages.error,
                        5
                    );
                    this.appLoadingIndicatorService.hide(200);
                }
            );
        }
    }
}
