
export const questionTypeIcons = {
    true_false: '/assets/images/true-false.svg',
    yes_no: '/assets/images/yes-no.svg',
    likert: '/assets/images/likert.svg',
    multiple_choice: '/assets/images/multiple-choice.svg',
    multiple_response: '/assets/images/multiple-response.svg',
    short_answer: '/assets/images/short-answer.svg',
};

export enum QuestionType {
    trueFalse = 'true_false',
    yesNo = 'yes_no',
    likert = 'likert',
    multipleChoice = 'multiple_choice',
    multipleResponse = 'multiple_response',
    shortAnswer = 'short_answer',
}
