import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VingsComponent } from './vings.component';
import { AuthGuard } from '../../shared/guards/auth.guard';
import { EditComponent } from "./components/edit/edit.component";

const vingsRoutes: Routes = [
    {
        path: 'vings',
        redirectTo: 'vings/',
        canActivate: [AuthGuard],
        pathMatch: 'full',
    },
    {
        path: 'vings/:id',
        component: VingsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'vings/:id/edit',
        component: EditComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(vingsRoutes)],
    exports: [RouterModule],
})
export class VingsRoutingModule {}
