<mat-chip-list
    class="drop-list"
    cdkDropList
    [class.item-dragging]="dragging"
    (cdkDropListDropped)="droppedIntoList($event, category, page)"
>
    <div
        class="create-category-drop-area"
        *ngIf="category === 'Create New Folder'"
    >
        Drag label here to create a new folder
    </div>
    <div *ngFor="let item of items; let index = index">
        <mat-chip
            id="{{ index }}"
            class="item"
            [class.selected]="isSelected(index)"
            [class.label-selected]="isSelectedFilter(item.name)"
            [cdkDragData]="item"
            cdkDrag
            (cdkDragStarted)="dragStarted($event, index)"
            (cdkDragEnded)="dragEnded()"
            (cdkDragDropped)="dropped($event)"
            (click)="select($event, index, item)"
        >
            <div *ngIf="!dragging || !isSelected(index)">
                <ng-container
                    *ngTemplateOutlet="
                        templateRef;
                        context: { $implicit: item, item: item, index: index }
                    "
                ></ng-container>
                <div *cdkDragPreview>
                    <div class="select-item-drag-preview">
                        {{ selections.length || 1 }}
                    </div>
                    <mat-chip>
                        <ng-container
                            *ngTemplateOutlet="
                                templateRef;
                                context: {
                                    $implicit: item,
                                    item: item,
                                    index: index
                                }
                            "
                        ></ng-container>
                    </mat-chip>
                </div>
            </div>
            <div
                class="title-icon"
                (click)="deleteLabel($event, item, category)"
            >
                <i class="material-icons icon delete-trigger">clear</i>
            </div>
        </mat-chip>
    </div>
</mat-chip-list>
