import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-toggle',
    templateUrl: './app.toggle.component.html',
})
export class AppToggleComponent implements OnInit {
    @Input() value: any;
    @Input() size?: string;
    @Input() disabled?: boolean;

    @Output() valueChange = new EventEmitter<any>();

    update(newValue: any) {
        this.value = newValue;
        this.valueChange.emit(this.value);
    }

    ngOnInit() {
        // Init size property.
        if (!this.size) {
            this.size = 'medium';
        }

        if (!this.disabled) {
            this.disabled = false;
        }
    }
}
