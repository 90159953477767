<div class="app-textarea">
    <textarea
        [placeholder]="placeholder"
        [(ngModel)]="text"
        (keyup)="onTextChange(text)"
        [maxlength]="maxLength"
        [minlength]="minLength"
    >
    </textarea>
</div>
