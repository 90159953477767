<div class="selected-checklist" *ngIf="checklist">
    <!--Components-->
    <div class="analytics-panel-1-container">
        <!--Title-->
        <div class="header">
            <!--Owner-->
            <ng-container *ngIf="checklist.share.owner">
            <div class="owner">
                <div
                    class="icon"
                    [style.background]="checklist.share.owner.hexColor"
                >
                    <span [title]="checklist.share.owner.name">{{
                        get2LetterName(checklist.share.owner.name)
                    }}</span>
                </div>
            </div>
            </ng-container>
            <!--Title-->
            <div
                [class]="checklist.title ? 'title' : 'title title-not-set'"
                title="{{ checklist.title }}"
            >
                {{ checklist.title ? checklist.title : 'No title set' }}
            </div>
        </div>

        <!--Summary-->
        <div class="summary" *ngIf="checklist.summary">
            <p>{{ checklist.summary }}</p>
        </div>

        <!--Overview-->
        <div class="overview">
            <!--Preview button-->
            <button
                class="app-button dark small"
                (click)="displayQuestionOverview()"
            >
                Overview
            </button>
        </div>

        <!--List-->
        <div class="list">
            <ng-container *ngFor="let question of checklist['questions']">
                <div
                    [class]="getSelectedComponentClass(question.id)"
                    (click)="handleClickQuestion(question)"
                >
                    <!--Type-->
                    <div class="type-icon">
                        <img src="{{ questionTypeIcons[question.type] }}">
                    </div>
                    <!--Type-->
                    <div class="type">{{
                        question.friendlyType
                    }}</div>
                    <!--Text-->
                    <div
                        [class]="
                            question.text ? 'title' : 'title no-title-set'
                        "
                        [title]="question.text"
                    >
                        {{ question.text ? question.text : 'No title set' }}
                    </div>
                    <!--Flag-->
                    <div class="flag-icon" *ngIf="question.hasUnresolvedFlags">
                        <i class="material-icons icon" title="Unresolved"
                            >flag_circle</i
                        >
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <!--Actions-->
    <div class="actions">
            <!--Share button-->
            <div class="hover-menu">
                <button class="app-button small blue" [disabled]="!checklist.share.active || !orgHasAccess">
                    <i class="material-icons icon">share</i> Share
                    <i class="material-icons icon arrow">arrow_drop_down</i>
                </button>
                <div class="hmenu" *ngIf="checklist.share.active && orgHasAccess">
                    <a
                        href="javascript:"
                        class="hmenu-link"
                        (click)="displayInviteModal()"
                    >
                        <i class="material-icons icon">person_add</i> Invite
                    </a>
                    <a
                        href="javascript:"
                        class="hmenu-link"
                        (click)="displayGetLinkModal()"
                    >
                        <i class="material-icons icon">insert_link</i> Link
                    </a>
                    <a
                        href="javascript:"
                        class="hmenu-link"
                        (click)="displayQRCodeModal()"
                        *ngIf="checklist.share.qrCode?.imageUrl"
                    >
                        <img
                            src="/assets/images/icons/qr-code.png"
                            alt="QR"
                            width="18"
                        />
                        QR Code
                    </a>
                </div>
            </div>

            <!--Recreate button-->
            <div class="hover-menu">
                <button class="app-button small large blue">
                    <i class="material-icons icon">cached</i> Recreate
                    <i class="material-icons icon arrow">arrow_drop_down</i>
                </button>
                <div class="hmenu">
                    <button class="hmenu-link" (click)="recreateChecklist('COPY')">
                        <i class="material-icons icon">file_copy</i> Copy
                    </button>
                    <button class="hmenu-link" (click)="recreateChecklist('REVISE')">
                        <i class="material-icons icon">edit</i> Revise
                    </button>
                </div>
            </div>
            <!--Preview button-->
            <div class="hover-menu">
                <button
                    class="app-button small blue"
                    (click)="displayChecklistPreview()"
                >
                    Preview
                </button>
            </div>
            <!--Revision button-->
            <div class="hover-menu history" *ngIf="isRevisionsExists()">
                <a href="javascript:" class="icon-button">
                    <i class="material-icons icon">history</i>
                </a>
                <div class="hmenu revisions large">
                    <div class="hmenu-title">
                        Revisions
                        <span class="badge">{{
                            checklist.share.revisions.length
                        }}</span>
                    </div>
                    <div class="hmenu-content">
                        <ng-container
                            *ngFor="let revision of checklist.share.revisions"
                        >
                            <div
                                class="hmenu-item"
                                (click)="
                                    handleRevisionPacket(revision.checklistId)
                                "
                            >
                                <small *ngIf="revision.published">{{
                                    displayDate(revision.published)
                                }}</small>
                                <p class="ellipsis">{{ revision.title }}</p>
                                <span class="close" *ngIf="!revision.active">
                                    <i class="material-icons icon">close</i>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

    <!--Body-->
    <div class="body">
        <!--No Access Notice-->
        <div class="notice" *ngIf="!orgHasAccess">
            Your organization has limited access to this feature. You can preview the material, but you'll need to upgrade to share and collect analytics. To learn more, contact:  <a href="mailto:sales@vingapp.com">sales@vingapp.com</a>
        </div>
        <!--Overview-->
        <div
            class="selected-view {{
                selectedQuestionId ? 'component' : 'overview'
            }}"
            *ngIf="isOverviewSelected || selectedQuestionId"
        >
            <div class="selected-component-header" *ngIf="selectedQuestionId">
                <!--Title-->
                <div
                    class="title"
                    *ngIf="!selectedQuestionId && selectedQuestion"
                >
                    {{ selectedQuestionText }}
                </div>
            </div>

            <!--Chart-->
            <div id="selected-view-chart" *ngIf="analytics">
                <!--If chart has data to display-->
                <div
                    id="standard-doughnut-chart"
                    class="selected-view-chart-inner margin-right"
                ></div>

                <!--If chart has no data to display-->
                <div class="no-chart-data" *ngIf="displayChart === false"></div>

                <div class="chart-center-info">
                    <div class="center-info-label" *ngIf="!analytics.stats.overview">
                        {{ analytics.stats.total }}
                    </div>
                    <div class="center-info-label" *ngIf="analytics.stats.overview">
                        {{ analytics.stats.overview.total }}
                    </div>
                    <div class="center-info-value">
                        {{ entityName | pluralize: analytics.stats.total }}
                    </div>
                </div>
                <div class="chart-key">
                    <div
                        *ngFor="let item of chartKeyItems"
                        [class]="'chart-key-item ' + item.name"
                    >
                        <div class="item-color">
                            <div class="color-box" style="background-color: {{ item.color }};"></div>
                        </div>
                        <div class="item-label">
                            {{ item.label }}
                        </div>
                        <div class="item-value" *ngIf="analytics.stats.overview">
                            {{ analytics.stats.overview[item.name] }}
                        </div>
                        <div class="item-value" *ngIf="!analytics.stats.overview">
                            {{ analytics.stats[item.name] }}
                        </div>
                    </div>
                </div>

                <!--Share Settings-->
                <div
                    class="share-settings"
                    *ngIf="isOverviewSelected && !selectedQuestionId"
                >
                    <div class="active-setting">
                        <div class="item">
                            <label>Active</label>
                            <app-toggle
                                size="small"
                                [(value)]="share.active"
                                [disabled]="!orgHasAccess"
                            ></app-toggle>
                        </div>
                        <div class="item">
                            <label>Notification</label>
                            <app-toggle
                                size="small"
                                [(value)]="share.notify"
                                [disabled]="!share.active"
                            ></app-toggle>
                        </div>
                    </div>
                    <div class="privacy-setting">
                        <app-radiobox
                            name="privacy"
                            [(value)]="share.privacy"
                            default="public"
                            label="Anyone"
                        ></app-radiobox>
                        <app-radiobox
                            name="privacy"
                            [(value)]="share.privacy"
                            default="verified"
                            label="Verified"
                        ></app-radiobox>

                        <button
                            class="app-button small green"
                            *ngIf="enableSaveShares()"
                            (click)="saveShareSettings()"
                        >
                            Save
                        </button>
                    </div>
                </div>

                <!--Choice Details-->
                <div
                    class="choice-details"
                    *ngIf="
                        !isOverviewSelected &&
                        selectedQuestionId &&
                        selectedQuestion &&
                        selectedQuestion.type !== 'short_answer'
                    "
                >
                    <div class="header">
                        <span>Answer Details</span>
                        <button
                            class="app-button small blue"
                            *ngIf="selectedQuestion.imageUrl"
                            (click)="
                                viewQuestionImage(selectedQuestion.imageUrl)
                            "
                        >
                            <i class="material-icons icon">visibility</i> View
                            Image
                        </button>
                    </div>
                    <div class="details">
                        <div
                            [class]="
                                'item ' + (item.imageUrl ? 'has-image' : '')
                            "
                            *ngFor="let item of analytics.stats.choices"
                        >
                            <div
                                [class]="item.flag ? 'item-percentage flagged' : 'item-percentage not-flagged'"
                            >
                                {{ getPercentage(item.answerPercentage) }}%
                            </div>
                            <div class="item-text">
                                {{ item.text }}
                            </div>
                            <div class="item-action">
                                <button
                                    class="app-button x-small blue no-text"
                                    *ngIf="item.imageUrl"
                                    (click)="viewQuestionImage(item.imageUrl)"
                                >
                                    <i class="material-icons icon"
                                        >visibility</i
                                    >
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Users Table-->
            <div class="selected-view-table">
                <app-x-table
                    [page]="'CHECKLIST-SUBMISSIONS'"
                    [id]="checklist.id"
                    [question_id]="selectedQuestionId"
                    [title]="checklist.title"
                    [microburst]="microburst"
                    [searchText]="searchText"
                    [xTableData]="xTableData"
                    [xTableFilters]="xTableFilters"
                    [canSelectRows]="true"
                    [showSentiment]="true"
                    [analyticsData]="analyticsFilterData"
                    [numberOfPages]="numberOfPages"
                    [numberOfResults]="numberOfResults"
                    [results]="results"
                    [numbersPerPage]="numbersPerPage"
                    [currentPageIndex]="currentPageIndex"
                    [entityName]="entityName"
                    [noDataText]="noDataText"
                    [infoLink]="infoLink"
                    [searchDelayMs]="getSearchDelayMs()"
                    [updatePaging]="updatePaging.asObservable()"
                    [selectedRows]="selectedRows"
                    [excludedRows]="excludedRows"
                    [selectedAll]="selectedAllContacts"
                    [isOverviewSelected]="isOverviewSelected"
                    (handleSearchTextChange)="handleSearchTextChange($event)"
                    (handleFiltersChange)="handleFiltersChange($event)"
                    (handlePageChange)="handlePageChange($event)"
                    (handleSelectedRows)="handleSelectedRows($event)"
                    (handleExcludedRows)="handleExcludedRows($event)"
                    (handleRefreshChecklistData)="refreshChecklistData()"
                    (handleUpdateUserTables)="updateSubmissionsTableRows(true)"
                    (handleSelectedAllRows)="handleSelectedAllRows($event)"
                    (export)="export()"
                >
                </app-x-table>
            </div>
        </div>
    </div>
</div>

<!--No checklist selected-->
<div class="no-checklist-selected" *ngIf="!checklist">
    <div class="no-checklist-selected-text">Select checklist at left.</div>
</div>
