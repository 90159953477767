import { NgModule } from '@angular/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
    MatOptionModule,
    MatRippleModule,
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
    MatMomentDateModule,
    MomentDateAdapter,
} from '@angular/material-moment-adapter';

export const CUSTOM_DATE_FORMAT = {
    parse: {
        dateInput: 'MM/DD/YYYY',
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'MM/DD/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
@NgModule({
    imports: [
        MatMenuModule,
        MatSliderModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        NoopAnimationsModule,
        MatButtonModule,
        MatRadioModule,
        MatButtonToggleModule,
        MatOptionModule,
        MatRippleModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
    ],
    exports: [
        MatMenuModule,
        MatSliderModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        NoopAnimationsModule,
        MatButtonModule,
        MatRadioModule,
        MatButtonToggleModule,
        MatOptionModule,
        MatRippleModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
    ],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    ],
})
export class AppMaterialModule {}
