<div [id]="wrapperId" class="app-list-field">
    <div class="app-list-field-content">
        <!--Field Label-->
        <div class="app-list-field-label">
            {{ entityName | pluralize: 2 }}
        </div>

        <!--Items-->
        <div class="app-list-field-items" *ngIf="items.length">
            <div class="selected-item" *ngFor="let item of items">
                <div class="text">
                    {{ item }}
                </div>
                <div
                    class="remove-button"
                    *ngIf="!disabled"
                    (click)="removeItem(item)"
                >
                    &times;
                </div>
            </div>
        </div>

        <!--Items-->
        <div
            class="app-list-field-placeholder"
            *ngIf="!items.length && emptyPlaceholder"
        >
            {{ emptyPlaceholder }}
        </div>
    </div>

    <a href="javascript:" class="app-list-field-item-add" (click)="addItem()"
        >+ Add New {{ entityName }}</a
    >
</div>
