import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import * as pluralize from 'pluralize';

import { AppHttpService } from '../../shared/services/app.http.service';
import { AppHelpersService } from '../../shared/services/app.helpers.service';
import { AppLoadingIndicatorService } from '../../shared/services/app.loading-indicator.service';
import { AppSelectOption } from '../../shared/components/app-select/classes/app.select.option';
import { AppService } from '../../app.service';
import { Utils } from '../../shared/app.utils';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from 'moment';

@Component({
    selector: 'app-certificate',
    templateUrl: './certificate.component.html',
    styleUrls: ['./certificate.component.less'],
})
export class CertificateComponent implements OnInit, AfterViewInit {
    isMobile = null;
    certs = [];
    numberOfPages = 0;
    numbersPerPage = 10;
    currentPageIndex = 0;
    entityName = 'certificates';
    pages: Array<AppSelectOption> = [];
    selectedPage: AppSelectOption;
    searchText: string = null;
    isDone = false;
    noCertificatesMessage = 'There are no certificates.';

    private lastSearchText: string = null;
    private searchSubject: Subject<string> = new Subject();

    constructor(
        private dialog: MatDialog,
        private appService: AppService,
        private appHttpService: AppHttpService,
        private appHelpersService: AppHelpersService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private deviceService: DeviceDetectorService
    ) {
        this.isMobile = this.deviceService.isMobile();
    }

    ngOnInit() {
        this.appLoadingIndicatorService.show('view');
        setTimeout(
            () => (this.appService.selected.navOption = 'certificates'),
            0
        );

        this.searchSubject.pipe(debounceTime(250)).subscribe((searchText) => {
            this.processSearchTextChange(searchText);
        });
    }

    ngAfterViewInit() {
        // Init certificates
        this.initializeCertificates();
    }

    handleSearchChange(event): void {
        if (event.key !== 'Enter') {
            const searchText = event.target.value;

            if (this.lastSearchText !== searchText) {
                this.searchSubject.next(searchText);
            }
        }
    }

    clearSearch(): void {
        this.searchText = null;
        const searchText = null;
        this.searchSubject.next(searchText);
    }

    processSearchTextChange(searchText: string) {
        this.lastSearchText = Utils.trim(searchText);
        this.currentPageIndex = 0;

        this.appLoadingIndicatorService.show('.assignments-table .assignments-table-body');
        this.initializeCertificates();
    }

    getTableFooterText(): string {
        const numberOfEntities = this.certs.length;
        let text = '';

        // If there are entities within the table to display, generate footer text.
        if (numberOfEntities > 0) {
            const currentPage = this.currentPageIndex + 1,
                startValue =
                    currentPage * this.numbersPerPage - this.numbersPerPage + 1;
            let endValue = startValue + this.numbersPerPage - 1;

            if (endValue > numberOfEntities) {
                endValue = numberOfEntities;
            }

            // If there are multiple pages, ...
            text =
                'Displaying ' +
                startValue +
                ' to ' +
                endValue +
                ' of ' +
                numberOfEntities +
                ' ' +
                pluralize(this.entityName, numberOfEntities);
        } else if (numberOfEntities === 0) {
            text = 'Displaying 0 ' + pluralize(this.entityName);
        }

        return text;
    }

    private initializeCertificates(): void {
        let query = '';
        let getAssignmentsUrl = `GET:api/certificates/`;
        // Query search text.
        if (this.lastSearchText && this.lastSearchText.length > 0) {
            query = this.lastSearchText;
        }
        if (query.length > 0) {
            getAssignmentsUrl = `GET:api/certificates/?query=${query}`;
        }
        this.appLoadingIndicatorService.show('.assignments-table .assignments-table-body');
        this.appHttpService.request(getAssignmentsUrl, {}, (response) => {
            this.certs = response.results;

            this.certs.map(
                (cert) =>
                    (cert.expiration = this.getDisplayDate(cert.expiration))
            );

            this.appLoadingIndicatorService.hide();
        });
    }

    private getDisplayDate(date): string {
        return date ? moment(date).format('L') : '--';
    }

    openInNewTab(id: number) {
        const selectedReq = this.certs.find((item) => item.id === id);
        const certificateUrl = selectedReq.certificateUrl;
        window.open(certificateUrl, '_blank');
    }
}
