import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { ContactService } from '../../services/contact.service';
import { ContactFilter } from '../../classes/contact-filter';

@Component({
    templateUrl: './dialog-custom-filter.component.html',
    styleUrls: ['./dialog-custom-filter.component.less'],
})
export class DialogCustomFilterComponent implements OnInit {
    messages = {
        success: {
            createFilter: 'Custom Filter successfully created.',
            updateFilter: 'Custom Filter successfully updated.',
        },
        error: {
            submitFilter:
                'There was an error submitting the custom filter. Please try again.',
            updateFilter: 'A filter name already in use. Please use another.',
        },
        filterHasNotChanged: 'No changes to the custom filter have been made.',
    };
    customFilter = new ContactFilter('', '', [], []);
    originalCustomFilter: any;
    origin: string;

    constructor(
        private appHttpService: AppHttpService,
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appHelpersService: AppHelpersService,
        private contactService: ContactService,
        public dialogRef: MatDialogRef<DialogCustomFilterComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            contactsComponent: any;
            customFilter: any;
            origin: string;
            originalCustomFilter: any;
        }
    ) {}

    ngOnInit() {
        this.customFilter = this.data.customFilter;
        this.origin = this.data.origin;
        this.originalCustomFilter = this.data.originalCustomFilter;
    }

    saveCustomFilter() {
        if (!this.customFilter.id && this.customFilter.name) {
            this.apiCreateCustomFilter();
        } else if (this.customFilter.id && this.customFilter.name) {
            if (!this.hasFilterChanged()) {
                this.dialogRef.close();
                this.appMessageService.show(
                    'app',
                    'general',
                    this.messages.filterHasNotChanged,
                    3
                );
            } else {
                this.apiUpdateCustomFilter();
            }
        }
    }

    private apiCreateCustomFilter() {
        this.appLoadingIndicatorService.show('.dialog-custom-filter');
        const data = this.appHelpersService.getClone(this.customFilter);
        delete data.id;

        this.contactService.apiCreateCustomFilter(
            data,
            () => {
                this.data.contactsComponent.loadCustomFilters(true, () => {
                    this.appLoadingIndicatorService.hide(350);
                    this.dialogRef.close();
                    this.appMessageService.show(
                        'app',
                        'success',
                        this.messages.success.createFilter,
                        3
                    );
                });
            },
            () => {
                this.appLoadingIndicatorService.hide(350);
                this.appMessageService.show(
                    'app',
                    'error',
                    this.messages.error.submitFilter,
                    5.0
                );
            }
        );
    }

    private apiUpdateCustomFilter() {
        this.appLoadingIndicatorService.show('.dialog-custom-filter');
        const data = this.appHelpersService.getClone(this.customFilter);
        const filterId = data.id;
        delete data.id;

        this.contactService.apiUpdateCustomFilter(
            filterId,
            data,
            () => {
                this.data.contactsComponent.loadCustomFilters(true, () => {
                    this.appLoadingIndicatorService.hide(350);
                    this.dialogRef.close();
                    this.appMessageService.show(
                        'app',
                        'success',
                        this.messages.success.updateFilter,
                        3
                    );
                });
            },
            () => {
                this.appLoadingIndicatorService.hide(350);
                this.appMessageService.show(
                    'app',
                    'error',
                    this.messages.error.updateFilter,
                    5.0
                );
            }
        );
    }

    private hasFilterChanged() {
        return (
            this.customFilter.name &&
            this.originalCustomFilter.name !== this.customFilter.name
        );
    }
}
