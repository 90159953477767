import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from '../../app.utils';

@Component({
    templateUrl: './app.dialog-new-item.component.html',
    styleUrls: ['./app.dialog-new-item.component.less'],
})
export class AppDialogNewItemComponent {
    field: string;

    constructor(
        public dialogRef: MatDialogRef<AppDialogNewItemComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            type: string;
            label: string;
            placeholder: string;
            callback: Function;
        }
    ) {}

    handleVerification() {
        this.data.callback(this.field);

        setTimeout(() => {
            this.dialogRef.close();
        }, 200);
    }

    validateField() {
        if (this.field) {
            let valid = true;
            switch (this.data.type) {
                case 'email':
                    valid = Utils.validateEmail(this.field);
                    break;
                case 'phone':
                    valid = !Utils.validateEmail(this.field);
                    break;
            }
            return valid;
        } else {
            return false;
        }
    }
}
