<div class="app-toggle">
    <ui-switch
        [size]="size"
        [checked]="value"
        (change)="update($event)"
        [disabled]="disabled"
        checkedLabel="Y"
        uncheckedLabel="N"
    ></ui-switch>
</div>
