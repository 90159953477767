import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppLoadingIndicatorService } from '../../services/app.loading-indicator.service';
import { AppMessageService } from '../../services/app.message.service';
import {Subject} from "rxjs";
import {AppHelpersService} from "../../services/app.helpers.service";
import {AppHttpService} from "../../services/app.http.service";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {Utils} from "../../app.utils";
import {FormControl} from "@angular/forms";

@Component({
    templateUrl: './app.dialog-export-data.component.html',
    styleUrls: ['./app.dialog-export-data.component.less'],
})
export class AppDialogExportDataComponent implements OnInit {
    messages = {
        success: 'Your export will be delivered via email. Large datasets can take a while to process.',
        error: 'There was an error requesting the export. Please try again.',
    };
    exportTypeAssignments = 'Assignment';
    exportTypeSubmissions = 'Submission';
    exportTypeRequirements = 'Requirement';
    exportType = this.exportTypeAssignments;
    title = '';
    summary = '';
    startDate = new FormControl();
    endDate = new FormControl();
    assignmentExportUrl = 'POST:api/data/exports/assignments/';
    submissionExportUrl = 'POST:api/data/exports/submissions/';
    requirementsExportUrl = 'POST:api/data/exports/contactrequirements/';

    constructor(
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        public dialogRef: MatDialogRef<AppDialogExportDataComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            page: string;
            filterKey: string;
            baseUrl: string;
            queries: any;
            selectedIds: Array<number>;
            selectedAll: boolean;
            excludedIds: Array<number>;
            callback: any;
        }
    ) {}

    ngOnInit() {
        this.initExportType();
        this.appLoadingIndicatorService.hide(350);
    }

    updateTitle() {
        this.title = 'Export ' + this.exportType + ' Data';
    }

    getSummary() {
        let summary = `Export all ${this.exportType.toLowerCase()}s
        for your selected ${this.data.page}, `

        if (this.exportType === this.exportTypeAssignments) {
            summary += `that were assigned, viewed, due or completed `;
        } else if (this.exportType === this.exportTypeRequirements) {
            summary += `that were active `
        }

        summary += `between the following dates:`

        return summary
    }

    updateSummary() {
        this.summary = this.getSummary();
    }

    initExportType() {
        if (this.data.page === 'checklists') {
            this.exportType = this.exportTypeSubmissions;
        } else if (this.data.page === 'requirements') {
            this.exportType = this.exportTypeRequirements;
        }
        this.updateTitle();
        this.updateSummary();
    }

    updateExportType(exportType: string) {
        this.exportType = exportType;
        this.updateTitle();
        this.updateSummary();
    }

    getExportUrl() {
        let exportUrl = '';
        const startDate = new Date(this.startDate.value).toISOString().split('T')[0],
            endDate = new Date(this.endDate.value).toISOString().split('T')[0];

        if (this.exportType === this.exportTypeAssignments) {
            exportUrl = this.assignmentExportUrl;
        } else if (this.exportType === this.exportTypeSubmissions) {
            exportUrl = this.submissionExportUrl;
        } else if (this.exportType === this.exportTypeRequirements) {
            exportUrl = this.requirementsExportUrl;
        }

        exportUrl += `?start_date=${startDate}&end_date=${endDate}`

        return exportUrl
    }

    getRequestUrl() {
        const queryString = Object.keys(this.data.queries)
            .map((key) => {
                let str = '';
                if (Array.isArray(this.data.queries[key])) {
                    this.data.queries[key].forEach(function (query) {
                        str += str === '' ? '' : '&';
                        str += key + '=' + query;
                    });
                } else {
                    str += key + '=' + this.data.queries[key];
                }
                return str;
            })
            .join('&');

        let requestUrl = this.data.baseUrl;

        if (queryString !== '') {
            requestUrl += '?' + queryString;
        }

        return requestUrl
    }

    getFilterData() {
        if (this.data.selectedAll) {
            return {
                requestUrl: this.getRequestUrl(),
                excludedIds: this.data.excludedIds,
            };
        } else {
            return {
                requestUrl: this.getRequestUrl(),
                selectedIds: this.data.selectedIds,
            }
        }
    }

    getPostData() {
        const postData = {};
        postData[this.data.filterKey] = this.getFilterData();

        return postData
    }

    export(): void {
        this.appLoadingIndicatorService.show('modal');
        const exportUrl = this.getExportUrl();

        this.appHttpService.request(
            exportUrl,
            this.getPostData(),
            () => {
                this.data.callback(
                    this.dialogRef,
                    this.messages.success
                );
            },
            (err) => {
                const error =
                    err.data &&
                    Array.isArray(err.data) &&
                    this.appHelpersService.isValidString(
                        err.data[0]
                    )
                        ? err.data[0]
                        : this.messages.error;
                this.appMessageService.show(
                    'modal',
                    'error',
                    error,
                    5
                );
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }
}
