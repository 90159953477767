import {
    AfterViewInit,
    Component,
    ChangeDetectorRef,
    Inject,
    OnInit,
    OnDestroy,
    ElementRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { DragRef } from '@angular/cdk/drag-drop';

import { AppService } from '../../app.service';
import { AppLoadingIndicatorService } from '../../shared/services/app.loading-indicator.service';
import { AppHelpersService } from '../../shared/services/app.helpers.service';
import { AppMessageService } from '../../shared/services/app.message.service';
import { AppHttpService } from '../../shared/services/app.http.service';
import { AppHeaderService } from '../../shared/services/app.header.service';
import { AppJobService } from '../../shared/services/job/app.job.service';
import { AppInfoService } from '../../shared/services/app-info/app.info.service';
import { AppUserService } from '../../shared/services/app.user.service';
import { AppFilterService } from '../../shared/services/app.filter.service';
import { AppDataTransform } from '../../shared/app.data-transform';
import { AppPrototypes } from '../../shared/app.prototypes';
import { AppDialogConfirmComponent } from '../../shared/dialogs/dialog-confirm/app.dialog-confirm.component';
import { AppAlertService } from '../../shared/services/app.alert.service';
import { AppDialogChangeOwnerComponent } from '../../shared/dialogs/dialog-change-owner/app.dialog-change-owner.component';
import { DialogCreateContactComponent } from './dialogs/dialog-create-contact/dialog-create-contact.component';
import { DialogUploadContactsComponent } from './dialogs/dialog-upload-contacts/dialog-upload-contacts.component';
import { DialogMergeContactsComponent } from './dialogs/dialog-merge-contacts/dialog-merge-contacts.component';
import { DialogEditContactComponent } from './dialogs/dialog-edit-contact/dialog-edit-contact.component';
import { DialogCustomFilterComponent } from './dialogs/dialog-custom-filter/dialog-custom-filter.component';
import { ContactService } from './services/contact.service';
import { Utils } from '../../shared/app.utils';
import { AppSelectOption } from '../../shared/components/app-select/classes/app.select.option';
import { ContactFilter } from './classes/contact-filter';
import * as $ from 'jquery';
import { AppDialogEditCategoryComponent } from '../../shared/dialogs/dialog-edit-category/app.dialog-edit-category.component';
import { DialogManageLabelsComponent } from '../../shared/dialogs/dialog-manage-labels/dialog.manage-labels.component';
import {
    AppDialogExportDataComponent
} from "../../shared/dialogs/dialog-export-data/app.dialog-export-data.component";

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.less'],
})
export class ContactsComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private appService: AppService,
        private appHttpService: AppHttpService,
        private appInfoService: AppInfoService,
        private appJobService: AppJobService,
        private appHelpersService: AppHelpersService,
        private appMessageService: AppMessageService,
        private appHeaderService: AppHeaderService,
        private appAlertService: AppAlertService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appUserService: AppUserService,
        private appFilterService: AppFilterService,
        private contactService: ContactService,
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private cd: ChangeDetectorRef,
        private eRef: ElementRef,
        private cdRef: ChangeDetectorRef,
        @Inject('window') private window: Window
    ) {}
    messages = {
        success: {
            deleteFilter: 'Custom Filter successfully removed.',
            deleteLabel: 'Label successfully removed.',
            deleteCategory: 'Folder successfully removed.',
            moveLabel: 'Label successfully moved.',
            reAddContactMethod:
                'Contact method has been re-added successfully.',
            reactivatedContactMethod:
                'Contact method has been reactivated successfully.',
            deactivatedContactMethod:
                'Contact method has been deactivated successfully.',
            changeOwner: 'The owner has been changed successfully.',
            createContact: 'Successfully created.',
            contactUpdated: 'Successfully updated.',
            contactMerge: 'These were successfully merged.',
        },
        error: {
            submitFilter:
                'There was an error deleting the custom filter. Please try again.',
            submitLabel:
                'There was an error deleting the label. Please try again.',
            submitCategory:
                'There was an error deleting the folder. Please try again.',
            moveLabel: 'There was an error moving the label. Please try again.',
            reAddContactMethod:
                'There was an error re-adding the contact method. Please try again.',
            reactivatedContactMethod:
                'There was an error reactivating the contact method. Please try again.',
            deactivatedContactMethod:
                'There was an error deactivating the contact method. Please try again.',
            contactMergeError:
                'There was an error merging. Please try again.',
        },
    };
    sorts: Array<AppSelectOption> = [];
    selectedSort: AppSelectOption;
    statuses: Array<AppSelectOption> = [];
    selectedStatus: AppSelectOption;
    owners: Array<AppSelectOption> = [];
    selectedOwner: AppSelectOption;
    entities = [
        {
            id: 'custom',
            name: 'custom',
            icon: 'filter_list',
            header: 'Custom Filters',
        },
        {
            id: 'labels',
            name: 'label',
            icon: 'local_offer',
            header: 'Labels',
        },
    ];
    entityLists = {
        custom: [],
        labels: [],
        categories: [],
    };
    page = 'list';
    contacts = [];
    activeContactMethods = [];
    inactiveContactMethods = [];
    badContactMethods = [];
    selectedContact = null;
    selectedContactIds: Array<number> = [];
    excludedContactIds: Array<number> = [];
    selectedAllContacts = false;
    hovered = false;
    selected = {
        custom: [],
        labels: [],
    };

    contactCardProperties = ['name', 'active'];
    jobs = {
        panelOneWatcher: 'panel-one-watcher',
    };
    selectNewContactAssignments = new Subject();
    selectNewContactSubmissions = new Subject();
    selectNewContactRequirements = new Subject();

    // Filter sort & text
    lastFilterText = '';
    filterText = '';

    // Lazy loading contacts
    isLoading = false;
    loadIndex = 0;
    lastScrollTop = 0;
    contactsCount = 0;
    subs = new Subscription();
    CATEGORIES = 'CATEGORIES';
    sentimentAssignments = '';
    sentimentSubmissions = '';
    sentimentRequirements = '';
    contactMethodFlag = false;

    private contactId: any = '';
    private headerEventRef: Subscription = null;
    private defaultPageSize: number;
    private pagingScrollPercent: number;
    private keepFilters = false;
    public selections: number[] = [];

    ngOnInit() {
        this.appLoadingIndicatorService.show('view');
        setTimeout(() => (this.appService.selected.navOption = 'contacts'), 0);
        $('#view').addClass('contacts');

        // Set header
        this.appHeaderService.set('CONTACTS');
        this.headerEventRef = this.appHeaderService.events.subscribe((ev) => {
            if (ev === 'CREATE_CONTACT') {
                this.displayCreateContactDialog();
            } else if (ev === 'UPLOAD_CONTACTS') {
                this.displayUploadContactsDialog();
            }
        });

        this.route.params.subscribe((params) => {
            if (params.page && params.id) {
                this.page = params.page;
                this.contactId = params.id;
            }
        });
    }

    ngAfterViewInit() {
        // Check contactId
        const contactId = this.route.snapshot.paramMap.get('id');

        this.appLoadingIndicatorService.show('#contacts');

        const appInfo = this.appInfoService.getAppInfo();

        this.defaultPageSize = appInfo.contactsInfo.pageSize;
        this.pagingScrollPercent = appInfo.contactsInfo.pagingScrollPercent;

        this.initializeOthers();

        // Check to see if contactId is present within the URL. If so, query it and display within panel 3
        if (contactId) {
            this.displaySelectedContact(contactId);
        } else {
            // Get custom filters
            this.loadCustomFilters();

            // Get 'labels, Execute default search w/ sort: 'last-activity'
            this.getContactsData(false, true);
        }

        this.cd.detectChanges();
    }

    ngOnDestroy() {
        // Destroy header
        this.appHeaderService.destroy();
        if (this.headerEventRef) {
            this.headerEventRef.unsubscribe();
        }

        $('#view').removeClass('contacts');
        this.appJobService.kill(this.jobs.panelOneWatcher);
    }

    reset() {
        this.appLoadingIndicatorService.show('#contacts');

        this.contactId = null;
        this.selectedContact = null;

        const resetContacts =
            this.contacts && this.contacts.length
                ? (this.selected['custom'].length ||
                      this.selected['labels'].length ||
                      !!this.selectedSort ||
                      !!this.selectedStatus ||
                      !!this.selectedOwner ||
                      !!this.filterText) &&
                  !this.keepFilters
                : true;

        if (this.keepFilters) {
            this.keepFilters = false;
        } else {
            this.selected = {
                custom: [],
                labels: [],
            };
            this.selectedContactIds = [];
            this.excludedContactIds = [];
            this.selectedAllContacts = false;
            this.filterText = '';
            this.sorts = [];
            this.statuses = [];
            this.owners = [];
            this.selectedSort = undefined;
            this.selectedStatus = undefined;
            this.selectedOwner = undefined;

            this.initializeOthers();
        }

        this.loadCustomFilters();
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.getContactsData(false, resetContacts, () => {});
    }

    resetContact() {
        this.keepFilters = true;
        this.router.navigateByUrl('/contacts/');
    }

    // Contacts List :: Lazy Loading

    lazyLoadContacts($event) {
        // Check to see if the lazy-loader is not already loading and
        // that the mouse movement was a scroll down, not scroll up
        if (this.canLazyLoadMore($event)) {
            this.lastScrollTop = $event.target.scrollTop;

            if (
                $event.target.scrollTop /
                    ($event.target.scrollHeight - $event.target.clientHeight) >=
                this.pagingScrollPercent
            ) {
                $('.app-scroll-loading-indicator').fadeIn(200);
                this.isLoading = true;
                this.loadIndex++;

                this.contactService.apiGetContacts(
                    this.buildSearchContactsFilters(
                        this.loadIndex * this.defaultPageSize
                    ),
                    (response) => {
                        this.contactsCount = response.count;
                        response.results.forEach((contact) => {
                            this.contacts.push(contact);
                        });

                        this.isLoading = false;
                        $('.app-scroll-loading-indicator').fadeOut(350);
                    }
                );
            }
        }
    }

    handleHeaderButtonClick(name) {
        switch (name) {
            case 'manage-contacts-labels':
                this.manageContactLabels();
                break;
            case 'change-owner':
                this.displayChangeOwnerModal();
                break;
            case 'archive':
                this.archiveContacts(true);
                break;
            case 'set-user':
                this.setUser();
                break;
            case 'unarchive':
                this.archiveContacts(false);
                break;
            case 'save-custom-filters':
                this.displayCustomFilterDialog();
                break;
            case 'merge-contacts':
                this.displayMergeContactModal();
                break;
            case 'export-data':
                this.displayExportDataModal();
                break;
        }
    }

    resetSearch() {
        this.selected = {
            custom: [],
            labels: [],
        };
        this.filterText = '';
        this.lastFilterText = '';

        this.selectedContactIds = [];
        this.excludedContactIds = [];
        this.selectedAllContacts = false;

        const elems = document.querySelectorAll('.mat-chip.label-selected');

        [].forEach.call(elems, function (el) {
            el.classList.remove('label-selected');
        });

        // Update the contacts in view
        this.queryContacts(true);
    }

    toggleSelectAllContacts() {
        this.selectedAllContacts = !this.selectedAllContacts;

        this.selectedContactIds.length = 0;
        this.excludedContactIds.length = 0;
    }

    editCustomFilter(customId: number): void {
        const custom = this.entityLists.custom.find((ct) => ct.id === customId);
        if (custom) {
            const customFilter = new ContactFilter(
                custom.id,
                custom.name,
                custom.labels,
                custom.queries
            );

            this.dialog.open(DialogCustomFilterComponent, {
                width: '450px',
                height: '500px',
                disableClose: true,
                data: {
                    contactsComponent: this,
                    customFilter: customFilter,
                    origin: 'filter_nav',
                    originalCustomFilter:
                        this.appHelpersService.getClone(customFilter),
                },
            });
        }
    }

    deleteCustomFilter(customId: number): void {
        // Display confirmation dialog.
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            data: {
                text: 'Are you sure you want to delete this custom filter?',
                callback: () => {
                    this.apiDeleteCustomFilter(customId);
                },
            },
        });
    }
    editCategory(category) {
        this.dialog.open(AppDialogEditCategoryComponent, {
            height: '200px',
            width: '425px',
            data: {
                name: category.name,
                type: 'contact',
                callback: () => {
                    this.loadLabels(true);
                },
            },
        });
    }
    deleteLabel(label: string): void {
        // Display confirmation dialog.
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            data: {
                text: 'Are you sure you want to remove this label from all contacts?',
                callback: () => {
                    this.apiDeleteFilter('label', label);
                },
            },
        });
    }
    deleteCategory(category: any): void {
        // Display confirmation dialog.
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            data: {
                text: 'Are you sure you want to delete this folder?',
                callback: () => {
                    this.selected['labels'] = [];
                    this.apiDeleteFilter('category', category.name);
                },
            },
        });
    }

    stopPropagation(event): void {
        event.stopPropagation();
    }

    // Panel 1 (nav)

    toggleSelectedItem(value) {
        const type = value.type;
        const item = value.item;
        const itemIndex = this.selected[type].indexOf(item.id);

        if (itemIndex >= 0) {
            this.selected[type].splice(itemIndex, 1);

            if (type === 'custom') {
                const custom = this.entityLists.custom.find(
                    (ct) => ct.id === item.id
                );
                if (custom) {
                    this.selected.labels = this.selected.labels.filter(
                        (label) => custom.labels.indexOf(label) === -1
                    );

                    if (custom.queries.length) {
                        this.filterText = '';
                    }
                }
            }
        } else {
            this.selected[type].push(item.id);

            if (type === 'custom') {
                const custom = this.entityLists.custom.find(
                    (ct) => ct.id === item.id
                );
                if (custom) {
                    const labels = this.selected.labels.concat(custom.labels);
                    this.selected.labels = Array.from(new Set([...labels]));

                    if (custom.queries.length) {
                        this.filterText = custom.queries[0];
                    }
                }
            }
        }

        // Deselect selected Contacts
        this.selectedAllContacts = false;
        this.selectedContactIds = [];
        this.excludedContactIds = [];

        // Update the contacts in view.
        this.queryContacts(true);
    }

    onDeselectContacts($event): void {
        this.selectedAllContacts = false;
        this.selectedContactIds.length = 0;
        this.excludedContactIds.length = 0;
    }
    selectLabel(item) {
        const labels = [],
            index = this.selected['labels'].indexOf(item.name);

        // Copy existing selected ving ids.
        for (let i = 0; i < this.selected['labels'].length; i++) {
            labels.push(this.selected['labels'][i]);
        }

        if (index >= 0) {
            labels.splice(index, 1);
        } else {
            labels.push(item.name);
        }

        // Deselect selected Vings
        if (this.selected['labels'] !== labels) {
            this.selectedAllContacts = false;
            this.selectedContactIds = [];
            this.excludedContactIds = [];
        }

        // Taking this step to trigger the child component onChanges event.
        this.selected['labels'] = labels;

        // Update the contacts in view.
        this.queryContacts(true);
    }

    // Panel 2 (search, contact cards)

    toggleSelectedContact(id: number) {
        // Stop the click event of the select-icon from triggering the click
        // event of the contact card itself.
        event.stopPropagation();

        if (this.selectedAllContacts) {
            const index = this.excludedContactIds.indexOf(id);

            if (index >= 0) {
                this.excludedContactIds.splice(index, 1);
            } else {
                this.excludedContactIds.push(id);
            }
        } else {
            const index = this.selectedContactIds.indexOf(id);

            if (index >= 0) {
                this.selectedContactIds.splice(index, 1);
            } else {
                this.selectedContactIds.push(id);
            }
        }
    }

    updateSelectedStatus(selectedStatus: AppSelectOption): void {
        this.clearLazyLoadingData();
        this.selectedStatus = selectedStatus;
        this.queryContacts(true);
    }

    updateSelectedSort(selectedSort: AppSelectOption): void {
        this.clearLazyLoadingData();
        this.selectedSort = selectedSort;
        this.queryContacts(true);
    }

    updateSelectedOwner(selectedOwner: AppSelectOption): void {
        this.clearLazyLoadingData();
        this.selectedOwner = selectedOwner;
        this.queryContacts(true);
    }

    handleSearchTextChange(event): void {
        if (event.key !== 'Enter') {
            this.filterText = event;
            this.queryContacts(true);
            this.selectedContactIds = [];
            this.selectedAllContacts = false;
            this.excludedContactIds = [];
        }
    }

    queryContacts(showLoadingIndicator = false, callback?: Function): void {
        if (showLoadingIndicator) {
            this.appLoadingIndicatorService.show('#panel-two .cards-list');
        }

        const runCallback = () => {
            this.appLoadingIndicatorService.hide(500);
            if (callback) {
                callback();
            }
        };

        if (this.contactId) {
            this.contactsCount = 0;
            this.contacts = [];

            runCallback();
        } else {
            this.clearLazyLoadingData();
            this.contactService.apiGetContacts(
                this.buildSearchContactsFilters(),
                (response) => {
                    this.contactsCount = response.count;
                    this.contacts = response.results;

                    runCallback();
                }
            );
        }
    }

    setSentimentAlerts() {
        if (this.selectedContact) {
            if (this.selectedContact.sentimentFlags.assignments != null) {
                this.sentimentAssignments =
                    this.selectedContact.sentimentFlags.assignments;
            } else {
                this.sentimentAssignments = '';
            }
            if (this.selectedContact.sentimentFlags.submissions != null) {
                this.sentimentSubmissions =
                    this.selectedContact.sentimentFlags.submissions;
            } else {
                this.sentimentSubmissions = '';
            }
            if (this.selectedContact.contactMethodFlag) {
                this.contactMethodFlag = this.selectedContact.contactMethodFlag;
            }
            if (this.selectedContact.sentimentFlags.requirements) {
                this.sentimentRequirements =
                    this.selectedContact.sentimentFlags.requirements;
            } else {
                this.sentimentRequirements = '';
            }
        }
    }

    selectContact(contact) {
        // Set contact as selected & display message that this contact is already opened.
        this.selectedContact = contact;
        this.setSentimentAlerts();
        this.router.navigateByUrl(`${this.appUserService.getContactDetailsBaseUrl()}/${contact.id}`);
    }

    // Panel 3 (contact details, analytics)
    selectPage(target): void {
        this.router.navigateByUrl(`/contacts/${target}/${this.contactId}`);
    }

    displaySelectedContact(id): void {
        this.appLoadingIndicatorService.show('#panel-three', '');
        this.contactService.apiGetContact(id, (contact) => {
            this.selectedContact = contact;
            this.setSentimentAlerts();
            this.activeContactMethods = contact.contactMethods['active'];
            this.badContactMethods = contact.contactMethods['bad'];
            this.inactiveContactMethods = contact.contactMethods['inactive'];

            if (contact.mergeSuggestions.length > 0) {
                const mergeDialog = this.dialog.open(DialogMergeContactsComponent, {
                    width: '450px',
                    height: '380px',
                    disableClose: false,
                    data: {
                        title: 'Suggested Contact Merge',
                        contacts: [contact, contact.mergeSuggestions[0].contact, ],
                        mergeSuggestion: contact.mergeSuggestions[0],
                        callback: (dialog, message) => {
                            dialog.close();
                            this.appMessageService.show(
                                'app',
                                'success',
                                message,
                                3
                            );
                        },
                    }
                });
                const sub = mergeDialog.componentInstance.handleSelectContact.subscribe((contact) => {
                    mergeDialog.close();
                    window.history.replaceState(null, null, `${this.appUserService.getContactDetailsBaseUrl()}/${contact.id}`);
                    this.displaySelectedContact(contact.id);
                });
            }

            // Call to initialize the analytics view.
            if (this.page === 'assignments') {
                setTimeout(
                    () =>
                        this.selectNewContactAssignments.next(
                            this.selectedContact
                        ),
                    0
                );
            } else if (this.page === 'submissions') {
                setTimeout(
                    () =>
                        this.selectNewContactSubmissions.next(
                            this.selectedContact
                        ),
                    0
                );
            } else if (this.page === 'requirements') {
                setTimeout(
                    () =>
                        this.selectNewContactRequirements.next(
                            this.selectedContact
                        ),
                    0
                );
            } else {
                this.reset()
            }
        });
    }

    selectedContactHasLabels(showHasLabels) {
        let hasLabels = false;

        if (showHasLabels) {
            hasLabels =
                this.selectedContact.labels &&
                this.selectedContact.labels.length > 0;
        } else {
            hasLabels =
                !this.selectedContact.labels ||
                this.selectedContact.labels.length === 0;
        }

        return hasLabels;
    }

    displayEditContactDialog() {
        this.appLoadingIndicatorService.show('view', '', () => {
            this.contactService.apiGetContact(
                this.selectedContact.id,
                (contact) => {
                    this.selectedContact = contact;
                    this.dialog.open(DialogEditContactComponent, {
                        width: '650px',
                        height: '570px',
                        disableClose: true,
                        data: {
                            contact: this.appHelpersService.getClone(
                                this.selectedContact
                            ),
                            callback: (dialog, newContact) => {
                                // Update local data - including selected contact and last selected list (tabs).
                                this.updateContact(newContact);

                                // Reset contacts list
                                this.contactsCount = 0;
                                this.contacts = [];

                                this.displaySelectedContact(newContact.id);

                                dialog.close();
                                this.contactService.killValidationJobs();
                                this.appMessageService.show(
                                    'app',
                                    'success',
                                    this.messages.success.contactUpdated,
                                    3
                                );
                            },
                        },
                    });
                }
            );
        });
    }

    // Utility

    updateContact(updatedContact) {
        // Update selected contact.
        this.selectedContact = updatedContact;
    }

    clearInvalidSelectedLabels() {
        if (!this.selected.custom.length) {
            const selectedLabelsCount = this.selected.labels.length;
            // If the label is not found in the entityLists.labels, remove it from the selected.labels list.
            for (let i = 0; i < selectedLabelsCount; i++) {
                if (
                    this.entityLists.labels.indexOf(this.selected.labels[i]) ===
                    -1
                ) {
                    this.selected.labels.splice(i, 1);
                }
            }
        }
    }

    updateSelectedContactData() {
        // Check to see if there are any open tabs.
        if (this.selectedContact) {
            // Make get contacts request using the 'ids' param; each contact id has its own param.
            this.contactService.apiGetContact(
                this.selectedContact.id,
                (contact) => {
                    this.activeContactMethods =
                        contact.contactMethods['active'];
                    this.inactiveContactMethods =
                        contact.contactMethods['inactive'];
                    this.badContactMethods = contact.contactMethods['bad'];
                    this.selectedContact = contact;
                }
            );
        }
    }

    retryContactMethod(contactMethod, id) {
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            data: {
                text: 'Are you sure you want to re-add this contact method?',
                callback: () => {
                    this.appLoadingIndicatorService.show('view', '', () => {
                        const data = {
                            contact_method: contactMethod,
                            contact_id: id,
                        };

                        // HTTP call to re-add the contact method.
                        this.contactService.apiRetryContactMethod(
                            data,
                            (response) => {
                                // Reset contacts list
                                this.contactsCount = 0;
                                this.contacts = [];

                                this.updateSelectedContactData();
                                this.appMessageService.show(
                                    'app',
                                    'success',
                                    this.messages.success.reAddContactMethod,
                                    3
                                );
                                this.appLoadingIndicatorService.hide(200);
                            },
                            () => {
                                this.appLoadingIndicatorService.hide(200);
                                this.appMessageService.show(
                                    'app',
                                    'error',
                                    this.messages.error.reAddContactMethod,
                                    3
                                );
                            }
                        );
                    });
                },
            },
        });
    }
    deactivateContactMethod(contactMethod, id) {
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            data: {
                text: 'Are you sure you want to deactivate this contact method?',
                callback: () => {
                    this.appLoadingIndicatorService.show('view', '', () => {
                        const data = {
                            contact_method: contactMethod,
                            contact_id: id,
                        };

                        // HTTP call to deactivate the contact method.
                        this.contactService.apiDeactivateContactMethod(
                            data,
                            (response) => {
                                // Reset contacts list
                                this.contactsCount = 0;
                                this.contacts = [];

                                this.updateSelectedContactData();
                                this.appMessageService.show(
                                    'app',
                                    'success',
                                    this.messages.success
                                        .deactivatedContactMethod,
                                    3
                                );
                                this.appLoadingIndicatorService.hide(200);
                            },
                            () => {
                                this.appLoadingIndicatorService.hide(200);
                                this.appMessageService.show(
                                    'app',
                                    'error',
                                    this.messages.error
                                        .deactivatedContactMethod,
                                    3
                                );
                            }
                        );
                    });
                },
            },
        });
    }
    activateContactMethod(contactMethod, id) {
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            data: {
                text: 'Are you sure you want to reactivate this contact method?',
                callback: () => {
                    this.appLoadingIndicatorService.show('view', '', () => {
                        const data = {
                            contact_method: contactMethod,
                            contact_id: id,
                        };

                        // HTTP call to reactivate the contact method.
                        this.contactService.apiActivateContactMethod(
                            data,
                            (response) => {
                                // Reset contacts list
                                this.contactsCount = 0;
                                this.contacts = [];

                                this.updateSelectedContactData();
                                this.appMessageService.show(
                                    'app',
                                    'success',
                                    this.messages.success
                                        .reactivatedContactMethod,
                                    3
                                );
                                this.appLoadingIndicatorService.hide(200);
                            },
                            () => {
                                this.appLoadingIndicatorService.hide(200);
                                this.appMessageService.show(
                                    'app',
                                    'error',
                                    this.messages.error
                                        .reactivatedContactMethod,
                                    3
                                );
                            }
                        );
                    });
                },
            },
        });
    }

    get2LetterName(name: string) {
        return Utils.get2LetterName(name);
    }

    private canLazyLoadMore(event): boolean {
        const isNotCurrentlyLoading = this.isLoading === false,
            isScrollingDown =
                isNotCurrentlyLoading &&
                event.target.scrollTop > this.lastScrollTop,
            hasMoreToLoad =
                isScrollingDown && this.contacts.length < this.contactsCount;

        return isNotCurrentlyLoading && isScrollingDown && hasMoreToLoad;
    }

    private initializeOthers(): void {
        const contactsInfo =
            this.appInfoService.getAppInfoProperty('contactsInfo');

        // Statuses
        contactsInfo['statuses'].forEach((status) => {
            this.statuses.push(new AppSelectOption(status.name, status.value));
        });

        let selectedStatus = contactsInfo['statuses'].find(
            (status) => status.selected === true
        );

        const urlParams = this.appHelpersService.getUrlParams();
        if (urlParams) {
            if ('status' in urlParams) {
                selectedStatus = contactsInfo['statuses'].find(
                    (status) => status.value.toString() === urlParams.status.toString()
                );
                const url = window.location.href;
                window.history.replaceState(null, null, url.split('?')[0]);
            }
        }

        this.selectedStatus = new AppSelectOption(
            selectedStatus.name,
            selectedStatus.value
        );

        // Init sorts.
        contactsInfo['sorts'].forEach((sort) => {
            this.sorts.push(new AppSelectOption(sort.name, sort.value));
        });
        const selectedSort = contactsInfo['sorts'].find(
            (sort) => sort.selected === true
        );
        this.selectedSort = new AppSelectOption(
            selectedSort.name,
            selectedSort.value
        );

        // Init owners
        this.owners.unshift(new AppSelectOption('Anyone', ''));
        this.appUserService.whenUserSet(() => {
            const user = this.appUserService.getUser();

            if (user.assignableOwners) {
                user.assignableOwners.forEach((owner) => {
                    this.owners.push(new AppSelectOption(owner.name, owner.id));
                });
            }
        });
    }

    // Header Buttons
    private displayCreateContactDialog() {
        this.dialog.open(DialogCreateContactComponent, {
            width: '650px',
            height: '570px',
            disableClose: true,
            data: {
                callback: (dialog, id) => {
                    this.queryContacts(false, () => {
                        dialog.close();

                        this.contactService.killValidationJobs();
                        this.router.navigateByUrl(`${this.appUserService.getContactDetailsBaseUrl()}/${id}`);
                        this.appMessageService.show(
                            'app',
                            'success',
                            this.messages.success.createContact,
                            3
                        );
                    });
                },
            },
        });
    }

    private displayUploadContactsDialog() {
        this.dialog.open(DialogUploadContactsComponent, {
            width: '750px',
            minHeight: '472px',
            height: 'auto',
            disableClose: true,
            data: {
                callback: (cb) => {
                    this.queryContacts(false, () => {
                        this.contactService.killValidationJobs();
                        if (cb) {
                            cb();
                        }
                    });
                },
            },
        });
    }

    private displayCustomFilterDialog() {
        // Show custom filter modal for proceeding.
        const customFilter = new ContactFilter(
            '',
            '',
            this.selected.labels,
            this.filterText ? [this.filterText] : []
        );

        this.dialog.open(DialogCustomFilterComponent, {
            width: '450px',
            height: '500px',
            disableClose: true,
            data: {
                contactsComponent: this,
                customFilter: customFilter,
            },
        });
    }

    private manageContactLabels() {
        const selectedContact = this.selectedContact ? true : false;
        this.manageContactLabeling(
            {
                url: {
                    get: 'GET:api/labels/contact/',
                    post: 'POST:api/labels/contact/',
                },
                type: {
                    id: 'labels',
                    name: 'Labels',
                },
                countPropertyName: 'objectCount',
            },
            selectedContact
        );
    }

    private manageContactLabeling(params, selectedContact = false) {
        this.appLoadingIndicatorService.show('view', '', () => {
            const queries = this.buildManageQueries(selectedContact);
            const updatedQueries = AppDataTransform.getConvertedData(
                'snake',
                queries
            );
            this.appHttpService.request(params.url.get, queries, (items) => {
                this.dialog.open(DialogManageLabelsComponent, {
                    width: '450px',
                    height: '500px',
                    disableClose: true,
                    data: {
                        page: 'contacts',
                        typeId: params.type.id,
                        typeName: params.type.name,
                        postUrl: params.url.post,
                        countPropertyName: params.countPropertyName,
                        items: items.results,
                        selectedItemIds: selectedContact
                            ? [this.selectedContact.id]
                            : this.selectedContactIds,
                        selectedAllItems: selectedContact
                            ? false
                            : this.selectedAllContacts,
                        excludedItemIds: selectedContact
                            ? []
                            : this.excludedContactIds,
                        itemCount: selectedContact ? 1 : this.contactsCount,
                        queries: updatedQueries,
                        callback: (dialog, message) => {
                            // Refresh contacts, lists, and labels data, then execute callback.
                            const resetLabel =
                                params.type.id === 'labels'
                                    ? this.selectedContact
                                        ? 'none'
                                        : true
                                    : 'none';
                            this.getContactsData(resetLabel, false, () => {
                                // Close modal & display app-level success message.
                                this.selectedContactIds.length = 0;
                                this.selectedAllContacts = false;
                                this.excludedContactIds.length = 0;
                                this.updateSelectedContactData();

                                if (this.selectedContact) {
                                    // Reset labels
                                    if (params.type.id === 'labels') {
                                        this.appFilterService.resetContactFilters(
                                            'labels'
                                        );
                                    }
                                }

                                dialog.close();
                                this.appMessageService.show(
                                    'app',
                                    'success',
                                    message,
                                    3
                                );
                            });
                        },
                    },
                });
            });
        });
    }

    private manageContactOwner(selectedContact = false) {
        this.appLoadingIndicatorService.show('view', '', () => {
            this.appUserService.whenUserSet(() => {
                const user = this.appUserService.getUser();

                if (user.assignableOwners) {
                    this.dialog.open(AppDialogChangeOwnerComponent, {
                        width: '450px',
                        height: '500px',
                        disableClose: true,
                        data: {
                            title: 'Change Owner',
                            items: user.assignableOwners,
                            owner:
                                this.selectedContact &&
                                this.selectedContact.owner
                                    ? this.selectedContact.owner
                                    : null,
                            callback: (dialog, ownerId) => {
                                this.appLoadingIndicatorService.show(
                                    'modal',
                                    '',
                                    () => {
                                        const queries =
                                            this.buildManageQueries(
                                                selectedContact
                                            );
                                        const updatedQueries =
                                            AppDataTransform.getConvertedData(
                                                'snake',
                                                queries
                                            );
                                        const params = {
                                            owner_id: ownerId,
                                        };

                                        // HTTP call to change the contact owner
                                        this.contactService.apiUpdateContacts(
                                            params,
                                            updatedQueries,
                                            () => {
                                                if (this.selectedContact) {
                                                    // Reset contact list
                                                    this.contactsCount = 0;
                                                    this.contacts = [];

                                                    // Update selected contact
                                                    this.contactService.apiGetContact(
                                                        this.selectedContact.id,
                                                        (contact) => {
                                                            this.selectedContact =
                                                                contact;
                                                            this.selectNewContactAssignments.next(
                                                                this.selectedContact
                                                            );

                                                            dialog.close();
                                                            this.appMessageService.show(
                                                                'app',
                                                                'success',
                                                                this.messages
                                                                    .success
                                                                    .changeOwner,
                                                                3
                                                            );
                                                        }
                                                    );
                                                } else {
                                                    this.selectedContactIds.length = 0;
                                                    this.selectedAllContacts =
                                                        false;
                                                    this.excludedContactIds.length = 0;

                                                    // Update Contacts list
                                                    this.queryContacts(
                                                        false,
                                                        () => {
                                                            dialog.close();
                                                            this.appMessageService.show(
                                                                'app',
                                                                'success',
                                                                this.messages
                                                                    .success
                                                                    .changeOwner,
                                                                3
                                                            );
                                                        }
                                                    );
                                                }
                                            }
                                        );
                                    }
                                );
                            },
                        },
                    });
                }
            });
        });
    }

    private manageMergeContacts(selectedContact = false) {
        let result;
        const selectedContacts = [];

        this.selectedContactIds.forEach((id) => {
            result = this.contacts.find(
                (item) => item.id === id
            );
            selectedContacts.push(result);
        });

        this.appLoadingIndicatorService.show('view', '', () => {
            this.appUserService.whenUserSet(() => {
                this.dialog.open(DialogMergeContactsComponent, {
                    width: '450px',
                    height: '500px',
                    disableClose: true,
                    data: {
                        title: 'Merge Contacts',
                        contacts: selectedContacts,
                        callback: (dialog, contactId) => {
                            dialog.close();
                            this.appMessageService.show(
                                'app',
                                'success',
                                this.messages.success.contactMerge,
                                3
                            );
                        },
                    },
                });
            });
        });
    }

    private archiveContacts(archived = true) {
        const selectedContact = this.selectedContact ? true : false;
        const text =
            'Are you sure you want to ' +
            (archived ? 'archive ' : 'unarchive ') +
            (selectedContact ? 'this?' : 'these?');
        const message =
            (selectedContact ? 'This has ' : 'These have ') +
            'been ' +
            (archived ? 'archived ' : 'unarchived ') +
            'successfully';
        // Prompt user with confirmation modal before proceeding.
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            data: {
                text: text,
                callback: () => {
                    this.appLoadingIndicatorService.show(
                        '#panel-two',
                        '',
                        () => {
                            const queries =
                                this.buildManageQueries(selectedContact);
                            const updatedQueries =
                                AppDataTransform.getConvertedData(
                                    'snake',
                                    queries
                                );
                            const params = {
                                archived: archived,
                            };

                            // HTTP call to archive/unarchive the contact
                            this.contactService.apiUpdateContacts(
                                params,
                                updatedQueries,
                                () => {
                                    // Check to see if one of the archived/unarchived contacts is selected
                                    if (this.selectedContact) {
                                        this.selectedContact.archived =
                                            archived;
                                    } else {
                                        // Clear the selected contact ids local data & update the header button states
                                        this.selectedContactIds = [];
                                        this.selectedAllContacts = false;
                                        this.excludedContactIds = [];
                                    }

                                    this.queryContacts(true, () => {
                                        this.appMessageService.show(
                                            'app',
                                            'success',
                                            message,
                                            3
                                        );
                                    });
                                }
                            );
                        }
                    );
                },
            },
        });
    }

    private setUser(is_user = true) {
        this.dialog.open(AppDialogConfirmComponent, {
            height: '200px',
            width: '425px',
            data: {
                text: "You are sending a login link to everyone selected. An account will be created for them if they don't already have one.",
                secondaryText: 'Are you sure that you want to continue?',
                callback: () => {
                    const selectedContact = this.selectedContact ? true : false;
                    const message = 'These accounts have been updated.';
                    this.appLoadingIndicatorService.show(
                        '#panel-two',
                        '',
                        () => {
                            const queries =
                                this.buildManageQueries(selectedContact);
                            const updatedQueries =
                                AppDataTransform.getConvertedData(
                                    'snake',
                                    queries
                                );
                            const params = {
                                set_user: is_user,
                            };
                            // HTTP call to set the contact
                            this.contactService.apiUpdateContacts(
                                params,
                                updatedQueries,
                                () => {
                                    // Update Contacts list
                                    this.queryContacts(true, () => {
                                        this.appMessageService.show(
                                            'app',
                                            'success',
                                            message,
                                            3
                                        );
                                    });
                                }
                            );
                        }
                    );
                },
            },
        });
    }

    private displayChangeOwnerModal() {
        const selectedContact = this.selectedContact ? true : false;
        this.manageContactOwner(selectedContact);
    }

    private displayMergeContactModal() {
        const selectedContact = this.selectedContact ? true : false;
        this.manageMergeContacts(selectedContact);
    }

    private displayExportDataModal() {
        const selectedContact = this.selectedContact ? true : false;
        this.initExportDataModal(
            {
                page: 'contacts',
                filterKey: 'contactFilter',
                baseUrl: 'api/contacts/',
            },
            selectedContact
        );
    }

    private initExportDataModal(params, selectedContact = false) {
        this.appLoadingIndicatorService.show('view', '', () => {
            const queries = this.buildManageQueries(selectedContact);
            const updatedQueries = AppDataTransform.getConvertedData(
                'snake',
                queries
            );

            this.dialog.open(AppDialogExportDataComponent, {
                width: '450px',
                height: '375px',
                disableClose: true,
                data: {
                    page: params.page,
                    filterKey: params.filterKey,
                    baseUrl: params.baseUrl,
                    queries: updatedQueries,
                    selectedIds: selectedContact
                        ? [this.selectedContact.id]
                        : this.selectedContactIds,
                    selectedAll: selectedContact
                        ? false
                        : this.selectedAllContacts,
                    excludedIds: selectedContact
                        ? []
                        : this.excludedContactIds,
                    callback: (dialog, message) => {
                        dialog.close();
                        this.appMessageService.show(
                            'app',
                            'success',
                            message,
                            3
                        );
                    }
                }
            });
        });
    }

    private isContactSelected(id: number): boolean {
        let selected = false;
        if (this.selectedAllContacts) {
            if (this.excludedContactIds.indexOf(id) === -1) {
                selected = true;
            }
        } else {
            if (this.selectedContactIds.indexOf(id) >= 0) {
                selected = true;
            }
        }

        return selected;
    }

    private clearLazyLoadingData(): void {
        $('#panel-two .cards-list').scrollTop(0);
        this.loadIndex = 0;
        this.lastScrollTop = 0;
    }

    private loadCustomFilters(reset = false, callback?: Function) {
        const runCallback = (res) => {
            this.entityLists.custom = res;

            if (callback) {
                callback();
            }
        };

        this.appFilterService
            .getContactFilters('filters', reset)
            .subscribe((filters) => {
                runCallback(filters);
            });
    }

    public loadLabels(reset = false, callback?: Function) {
        const runCallback = (res) => {
            this.entityLists.categories = Object.keys(res).map(
                (categoryKey) => {
                    const categoryLabels = res[categoryKey];

                    return {
                        name: categoryKey,
                        labels: categoryLabels,
                    };
                }
            );
            let createNew = {};
            createNew = {
                name: 'Create New Folder',
                labels: [],
            };
            this.entityLists.categories.unshift(createNew);
            const index = this.entityLists.categories
                .map((e) => e.name)
                .indexOf('uncategorized');
            if (index !== -1) {
                this.entityLists.categories.splice(
                    1,
                    0,
                    this.entityLists.categories.splice(index, 1)[0]
                );
            }

            const allLabels = [];

            Object.keys(res).forEach((category) => {
                allLabels.push(res[category].map((label) => label.name));
            });

            const flattenedLabels = allLabels.flat();

            const labels = [];
            this.selected.labels.forEach((label) => {
                const isExisted = flattenedLabels.find((lbl) => lbl === label);
                if (isExisted) {
                    labels.push(label);
                }
            });
            this.selected.labels = labels;
            this.entityLists.labels = flattenedLabels;

            if (callback) {
                callback();
            }
        };

        this.appFilterService
            .getContactFilters('labels', reset)
            .subscribe((labels) => {
                runCallback(labels);
            });
    }

    private getContactsData(
        resetLabel,
        resetContacts = false,
        callback?: Function
    ) {
        const promiseLabel = new Promise((resolve) => {
            if (resetLabel !== 'none') {
                this.loadLabels(resetLabel, () => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });

        Promise.all([promiseLabel]).then(() => {
            // Labels & Lists must be updated and invalid ones cleared before
            // building the contact filters and proceeding with the search http call.
            const runCallback = () => {
                this.appLoadingIndicatorService.hide(200);
                if (callback) {
                    callback();
                }
            };

            if (resetContacts) {
                this.queryContacts(false, callback);
            } else {
                runCallback();
            }
        });
    }

    private buildSearchContactsFilters(offset?: number) {
        // Before constructing the filters, clear out any invalid ones
        this.clearInvalidSelectedLabels();

        // Build filters
        const filterText = Utils.trim(this.filterText),
            filters = {
                limit: this.defaultPageSize,
                sort: this.selectedSort.value,
                status: this.selectedStatus.value,
            };

        // Select owner
        if (this.selectedOwner && this.selectedOwner.value) {
            filters['owner_id'] = this.selectedOwner.value;
        }

        // Conditionally apply offset
        if (offset !== undefined) {
            filters['offset'] = offset;
        }

        // Conditionally add filter text if set
        if (filterText && filterText.length > 0) {
            filters['query'] = filterText;
        }

        // Conditionally add labels if one or more selected
        ['labels'].forEach((entity) => {
            if (this.selected[entity] && this.selected[entity].length > 0) {
                filters[entity] = this.selected[entity];
            }
        });

        return filters;
    }

    private buildManageQueries(selectedContact = false) {
        const queries = {};
        if (this.selectedContact) {
            queries['selectedIds'] = this.selectedContact.id.toString();
        } else {
            queries['status'] = this.selectedStatus.value;
            if (this.selectedOwner && this.selectedOwner.value) {
                queries['owner_id'] = this.selectedOwner.value;
            }
            queries['selectedIds'] = this.selectedContactIds.join(',');
            ['labels'].forEach((entity) => {
                if (this.selected[entity] && this.selected[entity].length > 0) {
                    queries[entity] = this.selected[entity];
                }
            });
            if (this.selectedAllContacts) {
                queries['selectedAll'] = true;
                queries['selectedCount'] =
                    this.contactsCount - this.excludedContactIds.length;
                queries['excludedIds'] = this.excludedContactIds.join(',');
                if (this.filterText) {
                    queries['query'] = this.filterText;
                }
            }
        }

        return queries;
    }

    private apiDeleteCustomFilter(id) {
        this.appLoadingIndicatorService.show('view');
        this.contactService.apiDeleteCustomFilter(
            id,
            () => {
                this.selected['labels'] = [];
                this.selected['custom'] = [];
                this.queryContacts(true);
                this.loadCustomFilters(true, () => {
                    this.appLoadingIndicatorService.hide(350);
                    this.appMessageService.show(
                        'app',
                        'success',
                        this.messages.success.deleteFilter,
                        3
                    );
                });
            },
            () => {
                this.appLoadingIndicatorService.hide(350);
                this.appMessageService.show(
                    'app',
                    'error',
                    this.messages.error.submitFilter,
                    5.0
                );
            }
        );
    }

    private apiDeleteFilter(type, index) {
        this.appLoadingIndicatorService.show('view');
        this.contactService.apiDeleteFilter(
            type,
            index,
            () => {
                const resetLabel = type === 'label' ? true : 'none';
                this.getContactsData(resetLabel, false, () => {
                    let message = '';
                    if (type === 'category') {
                        message = this.messages.success.deleteCategory;
                    } else if (type === 'label') {
                        message = this.messages.success.deleteLabel;
                    }
                    this.appMessageService.show('app', 'success', message, 3);
                });
                this.loadLabels(true);
                this.selected['labels'] = [];
                this.selected['custom'] = [];
                this.queryContacts(true);
            },
            () => {
                this.appLoadingIndicatorService.hide(350);
                let message = '';
                if (type === 'category') {
                    message = this.messages.error.submitCategory;
                } else if (type === 'label') {
                    message = this.messages.error.submitLabel;
                }
                this.appMessageService.show('app', 'error', message, 5.0);
            }
        );
    }

    removeLabel($event: any) {
        const indexOfCategory = this.entityLists.categories
            .map((e) => e.name)
            .indexOf($event.category);
        const indexOfLabel = this.entityLists.categories[indexOfCategory].labels
            .map((e) => e)
            .indexOf($event.label);
        this.entityLists.categories[indexOfCategory].labels.splice(
            indexOfLabel,
            1
        );
    }
}
