<div [class]="getViewClass()">
    <!--Header-->
    <div class="view-header" *ngIf="viewIsInitialized">
        <span class="title">
            {{ view.checklist.title }}
        </span>
        <div class="header-message-area">
            <i
                *ngIf="isPreview"
                class="header-message-icon material-icons icon yellow"
                [title]="
                    'Preview mode: activity and scores will not be tracked'
                "
                >preview</i
            >
            <p *ngIf="isPreview" class="yellow preview-message header-message">
                Preview mode: activity and scores will not be tracked
            </p>
        </div>
    </div>

    <!--Body-->
    <div
        [class]="showList ? 'view-body active' : 'view-body'"
        *ngIf="viewIsInitialized"
    >
        <div
            class="selected-component questions {{
                view.checklist.logoUrl ? 'has-footer' : ''
            }}"
        >
            <div class="view questions">
                <!--Summary-->
                <div class="display summary text" *ngIf="view.checklist.summary">
                    {{ view.checklist.summary }}
                </div>
                <!--Questions-->
                <div class="display questions">
                    <div class="questions">
                        <div
                            *ngFor="
                                let question of view.checklist.questions;
                                let i = index
                            "
                            class="question {{
                                isShortAnswer(question) ? 'short-answer' : ''
                            }}"
                        >
                            <!--Text & Image-->
                            <div class="question-text-and-image">
                                <!--Text-->
                                <div class="question-text">
                                    <span class="number">#{{ i + 1 }}</span>
                                    {{ question.text }} <span class="optional" *ngIf="!question.required"><em>(Optional)</em></span>
                                </div>

                                <!--Text-->
                                <div class="question-help-text">
                                    {{ question.helpText }}
                                </div>

                                <!--Image-->
                                <div
                                    class="question-image"
                                    *ngIf="objHasImage(question)"
                                >
                                    <img
                                        [src]="question.imageUrl"
                                        [alt]="question.text"
                                    />
                                </div>
                            </div>

                            <!--Choices-->
                            <div class="choices">
                                <div
                                    *ngFor="let choice of question.choices"
                                    class="choice {{
                                        objHasImage(choice) ? 'has-image' : ''
                                    }}"
                                >
                                    <!--Control-->
                                    <div class="choice-input">
                                        <!--Radio-->
                                        <app-radio
                                            *ngIf="shouldDisplayRadio(question)"
                                            [choiceId]="choice.id"
                                            [answer]="question.answer"
                                            (updateAnswer)="
                                                updateAnswer($event, question)
                                            "
                                        >
                                        </app-radio>

                                        <!--Checkbox-->
                                        <app-checkbox
                                            *ngIf="
                                                shouldDisplayCheckbox(question)
                                            "
                                            [choiceId]="choice.id"
                                            [answer]="question.answer"
                                            (updateAnswer)="
                                                updateAnswer($event, question)
                                            "
                                        >
                                        </app-checkbox>
                                    </div>

                                    <!--Image-->
                                    <div
                                        class="choice-image"
                                        *ngIf="choiceHasImage(choice)"
                                        (click)="
                                            updateAnswerByType(
                                                choice.id,
                                                question
                                            )
                                        "
                                    >
                                        <img
                                            [src]="choice.imageUrl"
                                        />
                                    </div>

                                    <!--Text-->
                                    <div
                                        class="choice-text"
                                        (click)="
                                            updateAnswerByType(
                                                choice.id,
                                                question
                                            )
                                        "
                                    >
                                        <span>{{ choice.text }}</span>
                                    </div>
                                </div>

                                <!--Short Answer-->
                                <app-textarea
                                    *ngIf="isShortAnswer(question)"
                                    [maxLength]="300"
                                    [answer]="question.answer"
                                    [placeholder]="'Answer question here...'"
                                    (updateAnswer)="
                                        updateAnswer($event, question)
                                    "
                                >
                                </app-textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="submit-button">
                    <button
                        class="app-button block green large no-text"
                        (click)="submitChecklist()"
                        [disabled]="!shouldDisplayButton()"
                    >
                        <div>Submit</div>
                    </button>
                </div>
            </div>
            <!--Footer-->
            <div
                class="footer"
                *ngIf="
                    view.checklist.logoUrl
                "
            >
                <img src="{{ view.checklist.logoUrl }}" alt="Logo" />
            </div>
        </div>
    </div>
</div>
