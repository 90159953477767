import {
    Directive,
    ElementRef,
    HostListener,
    Output,
    EventEmitter,
} from '@angular/core';

@Directive({
    selector: '[alphanumeric]',
})
export class AlphanumericDirective {
    @Output() ngModelChange = new EventEmitter();

    constructor(private _el: ElementRef) {}

    @HostListener('input', ['$event']) onInputChange(event) {
        const initalValue = this._el.nativeElement.value;

        this._el.nativeElement.value = initalValue.replace(
            /[^A-Za-z0-9 -]*/g,
            ''
        );
        if (initalValue !== this._el.nativeElement.value) {
            event.stopPropagation();
        }
        this.ngModelChange.emit(this._el.nativeElement.value);
    }
}
