<div class="page">
    <!--Header-->
    <div class="header">
        <div class="container">
            <div class="title">Certificates</div>
            <div class="searchbox">
                <div class="searchbox-control">
                    <input
                        type="text"
                        name="search"
                        placeholder="Search"
                        [(ngModel)]="searchText"
                        (keyup)="handleSearchChange($event)"
                    />
                    <i class="material-icons icon" *ngIf="!searchText"
                        >search</i
                    >
                    <i
                        class="material-icons icon clear"
                        *ngIf="searchText"
                        (click)="clearSearch()"
                        >clear</i
                    >
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <!--Assignments-->
        <div class="content">
            <div class="assignments-table">
                <!--Header-->
                <div class="assignments-table-header">
                    <table>
                        <thead>
                            <tr>
                                <th class="col-title">Requirement</th>
                                <th class="col-expiration">Expiration</th>
                                <th class="col-file">Certificate</th>
                            </tr>
                        </thead>
                    </table>
                </div>

                <!--Body-->
                <div class="assignments-table-body">
                    <perfect-scrollbar>
                        <table>
                            <tbody>
                                <tr *ngFor="let cert of certs; let i = index">
                                    <!--Title-->
                                    <td class="col-title">
                                        <div class="text-wrapper">
                                            <div class="title">
                                                <strong>{{
                                                    cert.title
                                                }}</strong>
                                            </div>
                                            <div class="summary">
                                                {{ cert.summary }}
                                            </div>
                                        </div>
                                    </td>
                                    <th class="col-expiration">
                                        {{ cert.expiration }}
                                    </th>
                                    <td class="col-file">
                                        <i
                                            (click)="openInNewTab(cert.id)"
                                            class="material-icons icon"
                                            >workspace_premium</i
                                        >
                                    </td>
                                </tr>
                            </tbody>
                            <div class="assignments-no-results-message">
                                <div *ngIf="certs?.length === 0">
                                    {{ noCertificatesMessage }}
                                </div>
                            </div>
                        </table>
                    </perfect-scrollbar>
                </div>

                <!--Footer-->
                <div class="assignments-table-footer">
                    <!--Number of users-->
                    <div class="footer-key-value" *ngIf="!isMobile">
                        {{ getTableFooterText() }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
