import { of as observableOf, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';
import { AppDataTransform } from '../app.data-transform';

@Injectable()
export class AppResponseInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map(
                (response) => {
                    if (
                        response instanceof HttpResponse &&
                        'body' in response &&
                        response.body
                    ) {
                        return response.clone({
                            body: AppDataTransform.getConvertedData(
                                'camel',
                                response.body
                            ),
                        });
                    }
                    return response;
                },
                (err) => {
                    if (err && err instanceof HttpResponse) {
                        console.log('err.status: ' + err.status);
                        console.log('err.body');
                        console.log(err.body);
                    }

                    return observableOf(err);
                }
            )
        );
    }
}
