<div id="header" class="app-header" [class]="isMobile ? 'is-mobile' : 'is-desktop'" [hidden]="!availableHeader">
    <div class="nav">
        <div class="left">
            <!--Logo-->
            <img
                (click)="redirectToDashboard()"
                src="/assets/images/app-ving-logo.png"
                title="{{ version }}"
            />
            <!--Options-->
            <div class="options" *ngIf="!isMobile">
                <ng-container *ngFor="let option of navOptions">
                    <div
                        *ngIf="canAccessUrl('/' + option.name)"
                        [class]="getOptionClass(option.name)"
                        [routerLink]="'/' + option.name"
                        (click)="setSelectedOption(option.name)"
                        [title]="option.title"
                    >
                        <i class="material-icons">{{ option.icon }}</i>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="right">
            <!--Logged in-->
            <div class="profile">
                <div class="user-info">
                    <div
                        class="user-wrapper"
                        tabindex="0"
                        (blur)="handleAccountBlur()"
                        (click)="isMenuOpen = !isMenuOpen"
                    >
                        <!--Navigation Menu-->
                        <div class="account-button">
                            <i
                                class="material-icons icon-menu"
                                *ngIf="!isMenuOpen"
                                >arrow_drop_down</i
                            >
                            <i
                                class="material-icons icon-menu"
                                *ngIf="isMenuOpen"
                                >arrow_drop_up</i
                            >
                        </div>

                        <!--Menu-->
                        <div class="account-menu" *ngIf="isMenuOpen">
                            <div
                                class="menu-option"
                                (click)="handleMenuOptionClick('profile')"
                            >
                                <div class="menu-option-icon">
                                    <i class="material-icons icon">person</i>
                                </div>
                                <div class="menu-option-label">Profile</div>
                            </div>
                            <div
                                class="menu-option"
                                (click)="handleMenuOptionClick('assignments')"
                            >
                                <div class="menu-option-icon">
                                    <i class="material-icons icon">perm_media</i>
                                </div>
                                <div class="menu-option-label">Assignments</div>
                            </div>
                            <div
                                class="menu-option"
                                (click)="handleMenuOptionClick('checklists')"
                            >
                                <div class="menu-option-icon">
                                    <i class="material-icons icon">checklist</i>
                                </div>
                                <div class="menu-option-label">Checklists</div>
                            </div>
                            <div
                                class="menu-option"
                                (click)="handleMenuOptionClick('certificates')"
                            >
                                <div class="menu-option-icon">
                                    <i class="material-icons icon"
                                        >workspace_premium</i
                                    >
                                </div>
                                <div class="menu-option-label">
                                    Certificates
                                </div>
                            </div>
                            <div class="separator"></div>
                            <div
                                class="menu-option"
                                (click)="handleMenuOptionClick('logout')"
                            >
                                <div class="menu-option-icon">
                                    <i class="material-icons icon"
                                        >power_settings_new</i
                                    >
                                </div>
                                <div class="menu-option-label">Sign Out</div>
                            </div>
                        </div>

                        <!--Display Name-->
                        <div class="user-display-name">
                            {{ getUserDisplayName() }}
                        </div>

                        <!--Avatar-->
                        <div class="user-avatar">
                            <img src="{{ getUserAvatar() }}" alt="Avatar" />
                        </div>
                    </div>
                </div>

                <!--Help-->
                <a
                    class="help-link"
                    target="_blank"
                    href="https://support.vingapp.com/"
                >
                    <img
                        src="/assets/images/icons/help-icon.png"
                        alt="Help"
                        height="26"
                    />
                </a>
            </div>
        </div>
    </div>
</div>

<div id="view" class="app-body {{ isMobile ? 'is-mobile-body' : null }} {{ availableHeader ? null : 'no-header' }}">
    <div class="page {{ isMobile ? null : 'desktop-min-width' }}">
        <router-outlet></router-outlet>
    </div>
</div>
