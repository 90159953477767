<label class="app-radiobox {{ value === default ? 'checked' : '' }}">
    {{ label }}
    <input
        [name]="name"
        type="radio"
        [value]="value"
        [checked]="value === default"
        (change)="update(default)"
    />
    <span class="checkmark"></span>
</label>
