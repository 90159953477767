<div class="checklist-buttons">
    <ng-container *ngIf="!selectedChecklist">
    <div class="create-checklist-button">
        <button
            title="Create"
            class="app-button small"
            (click)="createChecklist('CREATE_CHECKLIST')"
        >
            Create
        </button>
    </div>
    </ng-container>
    <div class="checklist-action-buttons" [class]="isDetail ? 'selected-checklist active' : ''">
        <ng-container *ngFor="let button of buttons">
            <button
                [title]="button.tooltip"
                [class]="getClass(button.name)"
                (click)="handleClick(button.name)"
                *ngIf="
                    !button.hidden &&
                    (!selectedChecklist || (selectedChecklist && button.selectedChecklist))
                "
            >
                <i class="material-icons icon">
                    {{ button.icon }}
                </i>
            </button>
        </ng-container>
    </div>
    <!--Selected info-->
    <ng-container *ngIf="!selectedChecklist">
        <div class="app-header-selected-info">
            <!--Selected vings count-->
            <div class="selected-count">{{ getSelectedChecklistsCount() }} selected</div>

            <!--Select all vings-->
            <div class="select-all-items" *ngIf="checklists.length > 0">
                <a (click)="toggleSelectAllChecklists()">
                    <span *ngIf="!selectedAllChecklists"> Select all </span>
                    <span *ngIf="selectedAllChecklists"> Deselect all </span>
                </a>
            </div>
        </div>
    </ng-container>
</div>
