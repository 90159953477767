import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './app.dialog-acknowledge.component.html',
    styleUrls: ['./app.dialog-acknowledge.component.less'],
})
export class AppDialogAcknowledgeComponent implements OnInit {
    title: string;
    label: string;

    constructor(
        public dialogRef: MatDialogRef<AppDialogAcknowledgeComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            text: string;
            title?: string;
            label?: string;
            callback?: Function;
        }
    ) {}

    ngOnInit() {
        this.title =
            'title' in this.data && this.data.title
                ? this.data.title
                : 'Acknowledge';
        this.label =
            'label' in this.data && this.data.label ? this.data.label : 'OK';
    }

    onConfirm() {
        if (this.data.callback) {
            this.data.callback();

            setTimeout(() => {
                this.dialogRef.close();
            }, 200);
        } else {
            this.dialogRef.close();
        }
    }
}
