<div class="app-dialog dialog-manage-requirements">
    <div class="header">Add Requirement</div>
    <div class="body">
        <div class="container">
            <div class="create-new-reqs">
                <form (ngSubmit)="save()" #createRequirementForm="ngForm">
                    <mat-form-field
                        class="requirement-title-field"
                        appearance="fill"
                    >
                        <mat-label>Title</mat-label>
                        <input
                            type="text"
                            placeholder="Title..."
                            aria-label="title"
                            matInput
                            [required]="true"
                            [formControl]="myControl"
                            [matAutocomplete]="auto"
                        />
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option
                                *ngFor="let requirement of deSelectedItems"
                                [value]="requirement.title"
                                (click)="updateRequirement(requirement)"
                            >
                                <strong>{{ requirement.title }}</strong>
                                <span *ngIf="requirement.summary"
                                    >: {{ requirement.summary }}</span
                                >
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <app-form-field
                        type="textarea"
                        label="Summary"
                        name="summary"
                        placeholder="Summary..."
                        maxlength="300"
                        [required]="false"
                        [(ngModel)]="createRequirement.summary"
                    >
                    </app-form-field>
                    <div class="d-flex jc-sb">
                        <div>
                            <p><strong>Date Completed:</strong></p>
                            <mat-form-field
                                class="requirement-date"
                                appearance="fill"
                            >
                                <mat-label>Choose a date</mat-label>
                                <input
                                    matInput
                                    autoComplete="off"
                                    [required]="false"
                                    [name]="'completed'"
                                    [(ngModel)]="createRequirement.completed"
                                    [matDatepicker]="picker"
                                />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div>
                            <p><strong>Expiration Date:</strong></p>
                            <mat-form-field
                                class="requirement-date"
                                appearance="fill"
                            >
                                <mat-label>Choose a date</mat-label>
                                <input
                                    matInput
                                    autoComplete="off"
                                    [required]="false"
                                    [name]="'expiration'"
                                    [(ngModel)]="createRequirement.expiration"
                                    [matDatepicker]="picker2"
                                />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker2"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <p><strong>Select Certificate:</strong></p>
                    <div class="req-file-drag-drop">
                        <ngx-file-drop
                            class="file-drag-drop {{
                                requirementFileDropped ? 'file-over' : ''
                            }}"
                            dropZoneLabel="Drop files here"
                            dropZoneClassName="file-drag-drop-area h-100"
                            (onFileDrop)="requirementFileDropped($event)"
                            (onFileOver)="requirementIsFileOver = true"
                            (onFileLeave)="requirementIsFileOver = false"
                        >
                            <ng-template ngx-file-drop-content-tmp>
                                <div class="d-flex jc-sb">
                                    <div class="flex-column tac mt-1 mr-1">
                                        <div class="text">
                                            <b>Drop File Here</b>
                                        </div>
                                        <div class="text mt-1">or</div>
                                        <div class="flex-column browse mt-1">
                                            <button
                                                type="button"
                                                class="app-button green"
                                                (click)="openFileDialog()"
                                            >
                                                Browse
                                            </button>
                                        </div>
                                    </div>
                                    <div class="upload-name">
                                        <div
                                            *ngIf="
                                                requirementFiles.length <= 1 &&
                                                isPDF
                                            "
                                        >
                                            <div
                                                *ngFor="
                                                    let item of requirementFiles;
                                                    let i = index
                                                "
                                            >
                                                <div
                                                    style="
                                                        position: relative;
                                                        text-align: center;
                                                    "
                                                >
                                                    <i
                                                        class="
                                                            material-icons
                                                            icon
                                                        "
                                                        style="
                                                            font-size: 50px;
                                                            color: grey;
                                                        "
                                                        >insert_drive_file</i
                                                    >
                                                    <i
                                                        (click)="
                                                            clearRequirementFiles()
                                                        "
                                                        class="
                                                            material-icons
                                                            icon
                                                        "
                                                        style="
                                                            position: absolute;
                                                            top: 0;
                                                            right: 20px;
                                                        "
                                                        >cancel</i
                                                    >
                                                    <p
                                                        class="
                                                            pdf-file-indicator
                                                        "
                                                    >
                                                        PDF
                                                    </p>
                                                </div>
                                                <p class="pdf-item-name">
                                                    {{ item.name }}
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="
                                                requirementFiles.length <= 1 &&
                                                !isPDF
                                            "
                                        >
                                            <div
                                                *ngFor="
                                                    let item of requirementFiles;
                                                    let i = index
                                                "
                                                style="position: relative"
                                            >
                                                <i
                                                    class="material-icons icon"
                                                    style="
                                                        position: absolute;
                                                        top: 9px;
                                                        right: -6px;
                                                        color: white;
                                                    "
                                                    >circle</i
                                                >
                                                <i
                                                    (click)="
                                                        clearRequirementFiles()
                                                    "
                                                    class="material-icons icon"
                                                    style="
                                                        position: absolute;
                                                        top: 9px;
                                                        right: -6px;
                                                    "
                                                    >cancel</i
                                                >
                                                <img
                                                    class="mt-1"
                                                    style="height: 100px"
                                                    [src]="imageUrl"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            class="red"
                                            *ngIf="multipleSelected"
                                        >
                                            Please only select one file.
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-file-drop>
                    </div>
                    <div class="footer">
                        <button
                            mat-dialog-close
                            class="app-button light w-90 mr-1"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            class="app-button green w-90"
                            [disabled]="!createRequirementForm.form.valid"
                        >
                            Assign
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
