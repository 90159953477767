<div class="app-dialog-verification">
    <form
        (ngSubmit)="handleVerification()"
        #verificationForm="ngForm"
        novalidate
    >
        <div class="header">Verification</div>

        <div class="body">
            <p>{{ data.text }}</p>
            <app-form-field
                type="text"
                name="code"
                label="Verification Code"
                placeholder="Enter Verification Code"
                [required]="true"
                [(ngModel)]="field"
            >
            </app-form-field>
        </div>

        <div class="footer">
            <button mat-dialog-close class="app-button light small mr-1">
                Cancel
            </button>
            <button
                type="submit"
                class="app-button green small"
                [disabled]="!verificationForm.form.valid"
            >
                Verify
            </button>
        </div>
    </form>
</div>
