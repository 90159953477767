import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppLoadingIndicatorService } from '../../services/app.loading-indicator.service';
import { AppMessageService } from '../../services/app.message.service';
import { NgForm } from '@angular/forms';
import { AppHttpService } from '../../services/app.http.service';

@Component({
    templateUrl: './app.dialog-edit-category.component.html',
})
export class AppDialogEditCategoryComponent implements OnInit {
    @Input() labels: any[];
    @Input() type: string;
    private messages = {
        success: {
            category: 'Category name successfully updated.',
        },
        error: {
            category:
                'There was an error updating the category name. Please try again.',
        },
    };
    title = 'Rename Folder';
    categoryName = '';
    confirmPassword = '';
    name: any;
    oldCategoryName: string;

    constructor(
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appHttpService: AppHttpService,
        public dialogRef: MatDialogRef<AppDialogEditCategoryComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            name: string;
            type: string;
            callback: Function;
        }
    ) {}

    ngOnInit() {
        // Initialize the items data into something that can be used within the UI.

        this.appLoadingIndicatorService.hide(350);
    }
    editCategory(categoryForm: NgForm, event): void {
        if (categoryForm.invalid) {
            return;
        }

        this.appLoadingIndicatorService.show('view');

        // const labelNames = this.data.labels.map((label: any) => label.name);
        const data = {
            name: this.data.name,
            type: this.data.type,
        };
        this.oldCategoryName = this.data.name;
        const newName = {
            new_name: categoryForm.value.categoryName,
        };
        this.appHttpService.request(
            'POST:api/labels/categories/' + data.type + '/' + data.name + '/',
            newName,
            () => {
                categoryForm.resetForm();
                this.appMessageService.show(
                    'app',
                    'success',
                    this.messages.success.category,
                    3
                );
                this.appLoadingIndicatorService.hide(200);
                this.dialogRef.close();
                this.data.callback();
            },
            () => {
                this.appMessageService.show(
                    'view',
                    'error',
                    this.messages.error.category,
                    4.0
                );
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }
}
