<div id="checklist-creation">
    <!--Errors-->
    <!--Components-->
    <div class="components-error" *ngIf="checklistHasNoComponentsError">
        {{ messages.error.checklist.componentsNone }}
        &nbsp;
        <i
            class="material-icons icon"
            (click)="checklistHasNoComponentsError = false"
            >highlight_off</i
        >
    </div>
    <!--Components-->
    <div class="components-error" *ngIf="allComponentsValidError">
        {{ messages.error.checklist.componentsHasInvalid }}
        &nbsp;
        <i class="material-icons icon" (click)="allComponentsValidError = false"
            >highlight_off</i
        >
    </div>

    <!--Header (green bar)-->
    <div id="header">
        <div class="left">
            <div class="page-title">
                <div class="title-text">Create</div>
            </div>
        </div>

        <div class="right">
            <div class="inner">
                <!--Title-->
                <div class="title">
                    <span title="{{ title }}">{{ title }}</span>
                    <button
                        class="app-button {{ getEditDetailsClass() }} w-90"
                        (click)="displayEditDetailsDialog()"
                    >
                        <i class="material-icons icon">edit</i> Edit Details
                    </button>
                </div>
                <!--Publish Checklist-->
                <div class="save-and-status">
                    <!--Status Updates-->
                    <div
                        class="status-updates"
                        *ngIf="
                            show.draftSave === 'saving' ||
                            show.draftSave === 'saved'
                        "
                    >
                        <span
                            class="checklist-draft-saving"
                            [style.display]="
                                show.draftSave === 'saving' ? 'block' : 'none'
                            "
                        >
                            <img
                                src="/assets/images/loading-indicator-white.gif"
                            />
                            Draft saving
                        </span>
                        <span
                            class="checklist-draft-saved"
                            [style.display]="
                                show.draftSave === 'saved' ? 'block' : 'none'
                            "
                        >
                            Draft saved
                        </span>
                    </div>
                    <!--Actions-->
                    <div class="save-draft-button">
                        <button
                            class="app-button red no-text"
                            (click)="delete()"
                        >
                            <i class="material-icons icon">delete</i>
                        </button>
                        <button class="app-button blue" (click)="publish()">
                            Publish
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--Panels-->
    <div id="panels">
        <!--Panel One-->
        <div id="panel-one">
            <!--No components text-->
            <div class="app-message-text" *ngIf="!questions.length">
                Start adding questions
            </div>

            <!--Components-->
            <div
                class="components"
                cdkDropList
                [cdkDropListData]="questions"
                (cdkDropListDropped)="drop($event)"
                #autoscroll
            >
                <ng-container *ngFor="let question of questions">
                    <div
                        *ngIf="question"
                        cdkDrag
                        [cdkDragData]="question"
                        [class]="getComponentClass(question)"
                        (click)="
                            selectComponent(
                                question.id,
                                true,
                                false
                            )
                        "
                    >
                        <!--Selected indicator-->
                        <div class="selected-indicator"></div>

                        <!--Type-Icon :: Top-Left-->
                        <div class="type-icon">
                            <img src="{{ questionTypeIcons[question.type] }}">
                        </div>

                        <!--Re-order :: grabber-->
                        <div
                            class="grabber grabable"
                            (click)="$event.stopPropagation()"
                        >
                            <i class="material-icons icon grabable"
                                >unfold_more</i
                            >
                        </div>

                        <!--Controls :: Top-Right-->
                        <div
                            class="controls"
                        >
                            <span
                                class="control-button delete-button"
                                title="Delete"
                                (click)="
                                    displayConfirmDeleteModal(
                                        question.id,
                                        false
                                    )
                                "
                            >
                                <i class="material-icons icon">delete</i>
                            </span>
                        </div>

                        <!--Title-->
                        <div
                            class="title {{
                                question.text ? '' : 'no-title-set'
                            }}"
                        >
                            {{
                                question.text
                                    ? question.text
                                    : 'No title set'
                            }}
                        </div>

                        <!--Text-->
                        <div
                            class="text"
                            style="text-transform: uppercase;"
                            ellipsis
                        >
                            {{ getPrettyType(question.type) }}
                        </div>
                    </div>
                </ng-container>
            </div>

            <!--Add Component Buttons-->
            <div class="add-buttons">
                <!--Add Component Button-->
                <button
                    class="app-button green standard"
                    (click)="displayAddComponentView()"
                    title="Add components"
                >
                    <i class="material-icons icon">add</i> Add Question
                </button>
            </div>
        </div>

        <!--Panel Three-->
        <div id="panel-two">
            <!--Add Component View-->
            <div class="add-component-view" *ngIf="isAddingNewComponents">
                <!--Buttons-->

                <app-component-button *ngFor="let button of addQuestionButtons"
                [componentTitle]="button.text"
                [componentType]="button.questionType"
                [componentTypeImage]="button.componentTypeImage"
                (click)="addQuestion(button.questionType)"
                title="{{
                            !button.isSupported
                                ? 'This feature is not supported in your current browser'
                                : ''
                        }}">
                </app-component-button>
            </div>

                <!--Component :: Content-->
                <div class="content">
                    <!--Questions-->
                    <div
                        class="content-questions"
                        *ngIf="selectedQuestion"
                    >
                        <app-checklist-questions
                            [questions]="questions"
                            [questionType]="questionType"
                            (saveQuestionsChanges)="
                                saveQuestionsChanges($event)
                            "
                            [questionErrors]="questionErrors"
                            [isJustAdded]="isJustAdded"
                            [allValidations]="validations"
                            [clearSelectedQuestion]="
                                clearSelectedQuestion.asObservable()
                            "
                            [selectedQuestion]="selectedQuestion"
                            (blurQuestion)="onBlurTextarea()"
                            (updateQuestion)="saveQuestionsChanges($event)"
                        >
                        </app-checklist-questions>
                    </div>
                </div>
            </div>
        </div>
</div>
