export class QuestionErrors {
    noQuestions: boolean;
    questionIndexes: Array<number>;
    answerIndexes: object;

    constructor() {
        this.noQuestions = false;
        this.questionIndexes = [];
        this.answerIndexes = {};
    }

    hasErrors(): boolean {
        return (
            this.noQuestions === true ||
            this.questionIndexes.length > 0 ||
            Object.keys(this.answerIndexes).length > 0
        );
    }

    clear(): void {
        this.noQuestions = false;
        this.questionIndexes.length = 0;
        this.answerIndexes = {};
    }
}
