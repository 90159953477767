<!--Cropper-->
<div class="avatar-cropper-container" [id]="name">
    <!--File Drag & Drop-->
    <div
        class="drop-area"
        *ngIf="
            (type === 'create' && !isFileSelected()) ||
            (isAvatarExists() && !isFileSelected() && isConfirmedAvatarUpdate)
        "
    >
        <ngx-file-drop
            dropZoneClassName="file-drag-drop h-100 flex-column"
            (onFileDrop)="componentFilesDropped($event)"
            (onFileOver)="componentsIsFileOver = true"
            (onFileLeave)="componentsIsFileOver = false"
        >
            <ng-template ngx-file-drop-content-tmp>
                <div class="flex-column tac mt-200">
                    <div class="text" *ngIf="type === 'create'">
                        <b>Drop Image Here</b>
                    </div>
                    <div class="text" *ngIf="isAvatarExists()">
                        Update by dropping Image Here
                    </div>
                    <div class="text mt-2">or</div>
                    <div class="flex-column browse mt-2">
                        <button
                            type="button"
                            class="app-button light"
                            (click)="cancelUpdateAvatar()"
                            *ngIf="isAvatarExists()"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            class="app-button green"
                            (click)="openFileDialog()"
                        >
                            Browse
                        </button>
                    </div>
                </div>
            </ng-template>
        </ngx-file-drop>
    </div>

    <!--Image View-->
    <div class="review" *ngIf="isAvatarExists() && !isConfirmedAvatarUpdate">
        <div class="review-area">
            <div
                class="image"
                [style.width.px]="handleImageSize('width')"
                [style.height.px]="handleImageSize('height')"
            >
                <img [src]="originalAvatarUrl" />
            </div>
            <div class="actions">
                <button
                    type="button"
                    class="app-button green"
                    (click)="updateCurrentAvatar()"
                >
                    {{ !replaceable ? 'Edit' : 'Update' }}
                </button>
                <button
                    type="button"
                    class="app-button dark"
                    (click)="handleRemoveAvatar()"
                >
                    <i class="material-icons icon">delete</i>
                </button>
            </div>
        </div>
    </div>

    <!--Image Cropper-->
    <div
        class="editor"
        *ngIf="
            (type === 'create' ||
                (type === 'edit' && isConfirmedAvatarUpdate)) &&
            originalFileData &&
            isFileSelected()
        "
    >
        <div class="avatar-cropper">
            <image-cropper
                *ngIf="cropper"
                [maintainAspectRatio]="maintainAspectRatio"
                [aspectRatio]="aspectRatio"
                [format]="fileExt"
                [resizeToWidth]="resizeToWidth"
                [imageBase64]="originalFileData"
                [cropper]="cropper"
                (imageCropped)="imageCropped($event)"
                (loadImageFailed)="loadImageFailed($event)"
            >
            </image-cropper>

            <div class="actions" *ngIf="showActions">
                <button
                    type="button"
                    class="app-button light"
                    (click)="cancelUpdateAvatar()"
                >
                    {{ cancelBtnLbl }}
                </button>
                <button
                    type="button"
                    class="app-button green"
                    (click)="haneldUpdateAvatar()"
                >
                    {{ saveBtnLbl }}
                </button>
            </div>
        </div>
    </div>
</div>
