import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-textarea',
    templateUrl: './app-textarea.component.html',
    styleUrls: ['./app-textarea.component.less'],
})
export class AppTextareaComponent implements OnInit, OnChanges {
    text: string;
    textChanged: Subject<string> = new Subject<string>();

    @Input() answer: any;
    @Input() maxLength = 1000;
    @Input() minLength: number;
    @Input() placeholder: string;

    @Output() updateAnswer = new EventEmitter<any>();

    constructor() {
        this.textChange();
    }

    ngOnInit() {
        this.text = this.answer;
    }

    textChange() {
        this.textChanged
            .pipe(debounceTime(500)) // wait 500ms after the last keyup
            .pipe(distinctUntilChanged()) // only emit if value is different from previous value
            .subscribe((text) => {
                this.updateAnswer.emit(this.text);
            });
    }

    onTextChange(text: string) {
        this.textChanged.next(text);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.text = this.answer;
    }
}
