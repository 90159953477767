import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppLoadingIndicatorService } from '../../services/app.loading-indicator.service';
import { AppMessageService } from '../../services/app.message.service';

@Component({
    templateUrl: './app.dialog-change-owner.component.html',
    styleUrls: ['./app.dialog-change-owner.component.less'],
})
export class AppDialogChangeOwnerComponent implements OnInit {
    messages = {
        itemsHaveNotChanged: 'No changes to the owner have been made.',
    };
    title = 'Change Owner';
    items = [];
    itemsCount: number;
    filterText: string;
    ownerId: number;

    constructor(
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        public dialogRef: MatDialogRef<AppDialogChangeOwnerComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            items: Array<object>;
            owner: any;
            callback: Function;
        }
    ) {}

    ngOnInit() {
        // Initialize the items data into something that can be used within the UI.
        this.items = this.initializeItems(this.data.items);

        if (this.data.title) {
            this.title = this.data.title;
        }

        // Initialize the items count :: for use when filtering.
        this.itemsCount = this.items.length;

        // Initialize owner
        if (this.data.owner) {
            this.ownerId = this.data.owner.id;
        }

        this.appLoadingIndicatorService.hide(350);
    }

    filterItems() {
        const filterText = this.filterText ? this.filterText.toLowerCase() : '';

        for (let i = 0; i < this.itemsCount; i++) {
            if (filterText === '') {
                this.items[i].visible = true;
            } else {
                this.items[i].visible =
                    this.items[i].name.toLowerCase().indexOf(filterText) !== -1;
            }
        }
    }

    clearFilter() {
        this.filterText = '';
        this.filterItems();
    }

    toggleItem(item) {
        this.ownerId = item.id;
    }

    save() {
        if (this.ownerId) {
            this.data.callback(this.dialogRef, this.ownerId);
        } else {
            this.dialogRef.close();
            this.appMessageService.show(
                'app',
                'general',
                this.messages.itemsHaveNotChanged,
                3
            );
        }
    }

    private initializeItems(list) {
        const items = [];

        list.forEach((item) => {
            const itemObj = {
                id: item.id,
                name: item.name,
                visible: true,
            };

            items.push(itemObj);
        });

        return items;
    }
}
