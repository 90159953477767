import { Image } from '../images/image';

export class Choice {
    id: number;
    clientId: number;
    text: string;
    correct?: boolean;
    flag?: boolean;
    image?: Image;
    imageUrl?: string;
    isValid?: boolean;

    constructor(
        text: string,
        correct?: boolean,
        flag?: boolean,
        image?: Image,
        imageUrl?: string
    ) {
        this.clientId = Math.floor(Math.random() * 100000 + 1);
        this.text = text;
        this.correct = correct ? correct : false;
        this.flag = flag ? flag : false;
    }
}
