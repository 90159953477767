import { Injectable } from '@angular/core';
import { QuestionErrors } from '../../../../../shared/classes/questions/question-errors';
import { QuestionValidations } from '../../../../../shared/classes/questions/question-validations';
import { QuestionType } from '../../../../../shared/classes/questions/question-type';
import { Question } from '../../../../../shared/classes/questions/question';

@Injectable()
export class QuestionsValidationService {
    private validations = new QuestionValidations(5, 255, 1, 255);

    getValidations(): QuestionValidations {
        return this.validations;
    }

    setValidations(
        qMin: number,
        qMax: number,
        aMin: number,
        aMax: number
    ): void {
        this.validations = new QuestionValidations(
            this.getMinimum(qMin),
            this.getMinimum(qMax),
            this.getMinimum(aMin),
            this.getMinimum(aMax)
        );
    }

    validateQuestions(questions: Array<Question>): QuestionErrors {
        console.log("Validating questions");
        const questionErrors = new QuestionErrors(),
            questionsLength = questions.length,
            answerTextMin = this.validations.answerText.min,
            answerTextMax = this.validations.answerText.max;

        // Ensure that at least one question is present within the list.
        if (questions.length === 0) {
            questionErrors.noQuestions = true;
        }

        // If a questions are present, check to ensure the following validations.
        if (!questionErrors.noQuestions) {
            // If more than one question exists, ensure that all
            // question text valid, once trimmed.
            for (let i = 0; i < questionsLength; i++) {
                if (
                    !this.isValidText(
                        questions[i].text,
                        this.validations.questionText.min,
                        this.validations.questionText.max
                    )
                ) {
                    questionErrors.questionIndexes.push(i);
                }
            }

            // If more than one question exists, ensure that all
            // question text unique within the list of questions.
            if (questionsLength > 1) {
                const questionTexts = questions.map(function (q) {
                    if (q.text) {
                        return q.text.toLowerCase();
                    }
                });

                for (let i = 0; i < questionsLength; i++) {
                    if (questionErrors.questionIndexes.indexOf(i) < 0) {
                        const questionTextLower =
                            questions[i].text.toLowerCase();
                        if (questionTexts.indexOf(questionTextLower) != i) {
                            questionErrors.questionIndexes.push(i);
                        }
                    }
                }
            }

            // Ensure that all answer choices text is unique per question.
            for (let i = 0; i < questionsLength; i++) {
                if (
                    [
                        QuestionType.multipleChoice,
                        QuestionType.multipleResponse,
                    ].indexOf(questions[i].type) >= 0
                ) {
                    const choiceErrorIndexes = [],
                        choices = questions[i].choices;

                    if (choices) {
                        const choicesLength = choices.length;

                        // Add any choices that have invalid text and don't have an image.
                        for (let j = 0; j < choicesLength; j++) {
                            const hasValidText = this.isValidText(
                                    choices[j].text,
                                    answerTextMin,
                                    answerTextMax
                                ),
                                hasAnImage = this.hasImage(choices[j]);

                            if (!hasValidText && !hasAnImage) {
                                choiceErrorIndexes.push(j);
                                choices[j].isValid = false;
                            } else {
                                choices[j].isValid = true;
                            }
                        }

                        // If the choice text is present for multiple choices,
                        // add it to the errors list.
                        for (let j = 0; j < choicesLength; j++) {
                            if (choiceErrorIndexes.indexOf(j) < 0) {
                                const choiceTextLower = choices[j].text
                                    .trim()
                                    .toLowerCase();

                                if (
                                    choices.filter(
                                        (c) =>
                                            c.text.trim().toLowerCase() ===
                                            choiceTextLower
                                    ).length > 1
                                ) {
                                    choiceErrorIndexes.push(j);
                                }
                            }
                        }
                    }

                    if (choiceErrorIndexes.length) {
                        questionErrors.answerIndexes[i] = choiceErrorIndexes;
                    }
                }
            }
        }

        console.log(questionErrors);
        return questionErrors;
    }

    private isValidText(text: string, min: number, max: number): boolean {
        if (text) {
            let isValid = false;

            if (typeof text === "string") {
                const textLength = text.trim().length;
                if (textLength <= max && textLength >= min) {
                    isValid = true;
                }
            }

            return isValid;
        }
    }

    private hasImage(item): boolean {
        let hasImg = false;

        if ('image' in item && item.image !== null) {
            hasImg = true;
        } else if ('imageUrl' in item && item.imageUrl !== null) {
            hasImg = true;
        }

        return hasImg;
    }

    private getMinimum(val): number {
        return val ? val : 1;
    }
}
