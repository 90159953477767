import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-radiobox',
    templateUrl: './app.radiobox.component.html',
    styleUrls: ['./app.radiobox.component.less'],
})
export class AppRadioboxComponent implements OnInit {
    @Input() name: string;
    @Input() default: any;
    @Input() value: any;
    @Input() label?: any;

    @Output() valueChange = new EventEmitter<any>();

    update(newValue: any) {
        this.value = newValue;
        this.valueChange.emit(this.value);
    }

    ngOnInit() {
        // Init label property.
        if (!this.label) {
            this.label = '';
        }
    }
}
