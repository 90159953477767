import { Question } from '../../../shared/classes/questions/question';

export class ConsumeChecklist {
    title: string;
    summary: string;
    visitsCount: number;
    questions: Array<Question>;
    logoUrl: string;

    constructor(
        title: string,
        summary: string,
        logoUrl: string,
        visitsCount: number,
        questions: Array<Question>
    ) {
        this.title = title;
        this.summary = summary;
        this.logoUrl = logoUrl;
        this.visitsCount = visitsCount;
        this.questions = questions;
    }
}
