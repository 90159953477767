<div class="app-dialog-error">
    <div class="header">
        <h2>{{ title }}</h2>
    </div>

    <div class="body">
        <div class="media">
            <img width="150" *ngIf="avatar" [src]="avatar" alt="Avatar" />
            <div class="media-body">
                <span>{{ message }}</span>
            </div>
        </div>
        <div class="actions">
            <button
                class="app-button small blue"
                *ngIf="redirect_to == 'signup'"
                routerLink="/login/signUp"
                [queryParams]="{ auth_as: auth_as, redirect: redirect }"
                mat-dialog-close
            >
                Sign Up to Continue
            </button>
            <button
                class="app-button small green"
                *ngIf="redirect_to == 'login'"
                routerLink="/login/signIn"
                [queryParams]="{ auth_as: auth_as, redirect: redirect }"
                mat-dialog-close
            >
                Log In to Continue
            </button>
            <button
                class="app-button small blue"
                *ngIf="redirect_to == 'logout'"
                routerLink="/logout"
                mat-dialog-close
            >
                Log Out to Continue
            </button>
            <button
                class="app-button small green"
                *ngIf="redirect_to == 'reload'"
                mat-dialog-close
                (click)="reload()"
            >
                Reload
            </button>
            <button
                class="app-button small green"
                *ngIf="redirect_to == 'restore'"
                mat-dialog-close
                (click)="restore()"
            >
                Restore
            </button>
        </div>
    </div>

    <div class="footer">
        <p>
            <strong>Questions</strong>? Contact
            <a class="link" href="mailto:support@vingapp.com"
                >support@vingapp.com</a
            >
        </p>
    </div>
</div>
