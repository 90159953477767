<div class="page">
    <!--Header-->
    <div class="header">
        <div class="container">
            <div class="title">
                {{ selectedTab }}
            </div>
        </div>
    </div>

    <div class="container">
        <!--Tabs-->
        <div class="tabs">
            <div class="app-vertical-tabs">
                <div
                    (click)="handleClickTab('profile')"
                    class="vertical-tab {{
                        isTabSelected('profile') ? 'selected' : null
                    }}"
                >
                    <div class="tab-icon">
                        <i class="material-icons icon">person</i>
                    </div>
                    <div class="text">Profile</div>
                </div>
                <div
                    (click)="handleClickTab('customize')"
                    class="vertical-tab {{
                        isTabSelected('customize') ? 'selected' : null
                    }}"
                >
                    <div class="tab-icon">
                        <i class="material-icons icon">settings</i>
                    </div>
                    <div class="text">Customize</div>
                </div>
                <div
                    (click)="handleClickTab('history')"
                    *ngIf="isCreatorLevel()"
                    class="vertical-tab {{
                        isTabSelected('history') ? 'selected' : null
                    }}"
                >
                    <div class="tab-icon">
                        <i class="material-icons icon">history</i>
                    </div>
                    <div class="text">History</div>
                </div>
            </div>
        </div>

        <!--Content-->
        <div class="content" *ngIf="user">
            <div class="view profile" *ngIf="isTabSelected('profile')">
                <div class="form">
                    <form
                        (ngSubmit)="handleInfos(infoForm)"
                        #infoForm="ngForm"
                        novalidate
                    >
                        <h4>General Information</h4>
                        <div class="row">
                            <div class="column">
                                <!--First Name-->
                                <app-form-field
                                    type="text"
                                    name="firstName"
                                    placeholder="First Name"
                                    label="First Name"
                                    [required]="true"
                                    [(ngModel)]="firstName"
                                >
                                </app-form-field>
                            </div>
                            <div class="column">
                                <!--Last Name-->
                                <app-form-field
                                    type="text"
                                    name="lastName"
                                    placeholder="Last Name"
                                    label="Last Name"
                                    [required]="true"
                                    [(ngModel)]="lastName"
                                >
                                </app-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="column">
                                <!--Send From Email-->
                                <app-form-field
                                    type="select"
                                    name="sendFrom"
                                    placeholder="Send From Email"
                                    label="Send From Email"
                                    [options]="emails"
                                    [(ngModel)]="sendFrom"
                                >
                                </app-form-field>
                            </div>
                            <div class="column">
                                <!--BCC Email-->
                                <app-form-field
                                    type="text"
                                    name="bccEmail"
                                    placeholder="BCC Email"
                                    label="BCC Email"
                                    [(ngModel)]="bccEmail"
                                >
                                </app-form-field>
                            </div>
                        </div>

                        <div class="form-button">
                            <button
                                type="submit"
                                class="app-button green"
                                [disabled]="!infoForm.form.valid"
                            >
                                Save
                            </button>
                        </div>

                        <h4>Verified Contact Methods</h4>
                        <div class="row">
                            <div class="column">
                                <app-list-field
                                    entityName="Email"
                                    emptyPlaceholder="Currently no emails"
                                    [items]="emails"
                                    [disabled]="!shouldEnableListsField()"
                                    (addField)="addNewEmail($event)"
                                    (removeField)="
                                        removeContactMethod($event, item)
                                    "
                                >
                                </app-list-field>
                            </div>
                            <div class="column">
                                <app-list-field
                                    entityName="Phone"
                                    emptyPlaceholder="Currently no phones"
                                    [items]="phones"
                                    [disabled]="!shouldEnableListsField()"
                                    (addField)="addNewPhone($event)"
                                    (removeField)="
                                        removeContactMethod($event, item)
                                    "
                                >
                                </app-list-field>
                            </div>
                        </div>
                    </form>
                    <h4>Password</h4>
                    <div class="row">
                        <div class="column">
                            <a (click)="resetPassword()" class="module-trigger">
                                Reset Password
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="view customize" *ngIf="isTabSelected('customize')">
                <h5>Avatar</h5>
                <div class="customize-form">
                    <app-avatar-cropper
                        [type]="isAvatarExists() ? 'edit' : 'create'"
                        dimensions="450x350"
                        name="avatarFile"
                        [replaceable]="false"
                        [originalAvatar]="user.avatar"
                        [originalAvatarUrl]="user.avatarUrl"
                        (updateImage)="updateAvatar($event)"
                    >
                    </app-avatar-cropper>
                </div>

                <ng-container *ngIf="user.canEditLogo">
                    <h5>Logo</h5>
                    <div class="customize-form">
                        <app-avatar-cropper
                            [type]="isLogoExists() ? 'edit' : 'create'"
                            dimensions="450x150"
                            name="logoFile"
                            [replaceable]="false"
                            [originalAvatar]="
                                isLogoExists()
                                    ? user.organization.logo
                                    : undefined
                            "
                            [originalAvatarUrl]="
                                isLogoExists()
                                    ? user.organization.logoUrl
                                    : undefined
                            "
                            [aspectRatio]="3"
                            (updateImage)="updateLogo($event)"
                        >
                        </app-avatar-cropper>
                    </div>
                </ng-container>
            </div>

            <div
                class="view history"
                *ngIf="isTabSelected('history') && isCreatorLevel()"
            >
                <div class="history-form">
                    <p *ngFor="let history of user.history">
                        {{ history }}
                    </p>
                    <p
                        class="app-message-text"
                        *ngIf="
                            !user.history ||
                            (user.history && !user.history.length)
                        "
                    >
                        No history
                    </p>
                </div>
                <div class="form">
                    <button
                        type="button"
                        class="app-button green"
                        ngxClipboard
                        [cbContent]="getHistory()"
                        (cbOnSuccess)="handleCbSuccess()"
                        (cbOnError)="handleCbError()"
                        [disabled]="
                            !user.history ||
                            (user.history && !user.history.length)
                        "
                    >
                        Copy
                    </button>
                </div>
            </div>
        </div>

        <div class="identifier" *ngIf="user && user.organization">
            Account Identifier: {{ user.organization.identifier }}
        </div>
    </div>
</div>
