import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from '../../../../shared/app.utils';
import {Packet} from "../../classes/packet";

@Component({
    selector: 'app-ving-card',
    styleUrls: ['./ving.card.component.less'],
    templateUrl: './ving.card.component.html',
})
export class VingCardComponent implements OnInit {
    searchText: string;
    cardPreviewUrl: any;
    @Input() origin: string;
    @Input() packet: any;
    @Input() selectedPacketIds: Array<number>;
    @Input() excludedPacketIds: Array<number>;
    @Input() selectedAllPackets: boolean;
    @Input() selectedPacket: Packet;

    @Output() handleToggleObjectId = new EventEmitter<any>();
    @Output() handleDeleteObject = new EventEmitter<any>();
    @Output() handleClickObject = new EventEmitter<any>();
    @Output() handleEditObject = new EventEmitter<any>();

    constructor(private router: Router) {}

    ngOnInit() {
        this.cardPreviewUrl = this.packet.cardPreviewUrl;
    }

    getSelectedIconClass(id: number): string {
        let packetIsSelected = false;
        if (this.selectedAllPackets) {
            packetIsSelected = this.excludedPacketIds.indexOf(id) === -1;
        } else {
            packetIsSelected = this.selectedPacketIds.indexOf(id) >= 0;
        }
        return packetIsSelected ? 'selected-icon selected' : 'selected-icon';
    }

    getPacketClass(packet: any): string {
        let cls = 'ving-card';
        const isPacketSelected =
            this.selectedPacket && this.selectedPacket.id === packet.id;
        if (!packet.published) {
            cls += ' draft';
        }
        if (isPacketSelected) {
            cls += ' selected';
        }
        return cls;
    }

    getPacketViewAccess(privacy: string): any {
        let icon = '';
        let title = '';

        switch (privacy) {
            case 'public':
                icon = 'public';
                title = 'Anyone';
                break;

            case 'verified':
                icon = 'verified_user';
                title = 'Verified';
                break;

            case 'invited':
                icon = 'lock';
                title = 'Invited';
                break;
        }

        return {
            icon: icon,
            title: title,
        };
    }

    toggleSelectedId(id: number): void {
        this.handleToggleObjectId.emit(id);
    }

    handleClick(obj: any): void {
        if (obj.published) {
            this.handleClickObject.emit(obj);
        }
    }

    handleEdit(obj: any): void {
        if (!obj.published) {
            this.handleEditObject.emit(obj);
        }
    }

    handleDelete(obj: any): void {
        if (!obj.published) {
            this.handleDeleteObject.emit(obj);
        }
    }
    get2LetterName(name: string) {
        return Utils.get2LetterName(name);
    }

    getDurationMinutesText(duration: number) {
        const value = Utils.roundTo5Minutes(duration);

        return `${ value } min`

    }
}
