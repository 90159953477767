import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-component-button',
    templateUrl: './app.component.button.component.html',
    styleUrls: ['./app.component.button.component.less'],
})
export class AppComponentButtonComponent implements OnInit {
    @Input() componentType: string;
    @Input() componentTitle: string;
    @Input() componentTypeImage: string;

    ngOnInit() {
        // Init label property.
        if (!this.componentType) {
            this.componentType = '';
        }
    }
}
