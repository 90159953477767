import {
    OnInit,
    OnDestroy,
    AfterViewInit,
    Component,
    Inject,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppAlertService } from '../../../../shared/services/app.alert.service';
import { AppUserService } from '../../../../shared/services/app.user.service';
import { AppPermissionService } from '../../../../shared/services/app.permission.service';
import { SignInUser } from '../sign-in/classes/sign-in.user';
import { GenerateLinkData } from '../sign-in/classes/generate-link-data';
import { Utils } from '../../../../shared/app.utils';

@Component({
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.less'],
})
export class ForgotPasswordComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    @ViewChild('email') inputEmail: ElementRef;
    @ViewChild('password') inputPassword: ElementRef;
    messages = {
        error: 'Reset password failed. Please try again.',
        success:
            'Check your messages.<br/>Your reset password link is on the way.',
    };
    step = 1;
    auth_as: string = null;
    private isMobile = null;
    signInUser = new SignInUser('', '');
    generateLinkData = new GenerateLinkData('', '', true);

    constructor(
        @Inject('storage') private storage: Storage,
        @Inject('location') private location: Location,
        private router: Router,
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appAlertService: AppAlertService,
        private appUserService: AppUserService,
        private appPermissionService: AppPermissionService,
        private deviceService: DeviceDetectorService
    ) {
        this.isMobile = this.deviceService.isMobile();
    }

    ngOnInit() {
        this.appUserService.setLoggedInUIState('false');

        const urlParams = this.appHelpersService.getUrlParams();
        if (urlParams && 'auth_as' in urlParams && urlParams['auth_as']) {
            this.signInUser.username = urlParams['auth_as'];
            this.auth_as = urlParams['auth_as'];
        }
        if (urlParams && 'redirect' in urlParams && urlParams['redirect']) {
            this.appUserService.setRedirectPath(urlParams['redirect']);
        }
    }

    ngAfterViewInit() {
        this.appLoadingIndicatorService.hide(350);
        setTimeout(() => this.inputEmail.nativeElement.focus());
    }

    ngOnDestroy() {
        this.appAlertService.destroy();
    }

    magicLink() {
        this.appLoadingIndicatorService.show('view');
        this.appAlertService.destroy();
        if (Utils.validateEmail(this.signInUser.username)) {
            this.generateLinkData.email = this.signInUser.username;
        } else {
            this.generateLinkData.phone = this.signInUser.username;
        }

        this.appHttpService.request(
            'POST:api/auth/generate-link/',
            this.generateLinkData,
            () => {
                this.generateLinkData = new GenerateLinkData('', '', true);
                this.appAlertService.set(
                    'view',
                    'success',
                    this.messages.success
                );
                this.appLoadingIndicatorService.hide(200);
            },
            () => {
                this.generateLinkData = new GenerateLinkData('', '', true);
                this.appAlertService.set('view', 'error', this.messages.error);
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    navigateToGoogleSso() {
        const baseUrl = this.appHelpersService.getBaseUrl();
        location.href = baseUrl + 'api/auth/social/login/google-oauth2/';
    }

    navigateToAdpSso() {
        const baseUrl = this.appHelpersService.getBaseUrl();
        location.href = baseUrl + 'api/auth/social/login/adp-oauth2/';
    }
}
