import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AppUserService } from '../services/app.user.service';
import { AppHttpService } from '../services/app.http.service';
import { AppHelpersService } from '../services/app.helpers.service';
import { AppPermissionService } from '../services/app.permission.service';
import { AppLoadingIndicatorService } from '../services/app.loading-indicator.service';
import { AppDialogAcknowledgeComponent } from '../dialogs/dialog-acknowledge/app.dialog-acknowledge.component';

@Injectable()
export class AuthGuard implements CanActivate {
    private paths = {
        view: {
            name: '/view',
            length: '/view'.length,
        },
        login: {
            name: '/login',
            length: '/login'.length,
        },
    };
    private isMobile = null;

    constructor(
        private appUserService: AppUserService,
        private appHttpService: AppHttpService,
        private appHelpersService: AppHelpersService,
        private appPermissionService: AppPermissionService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private router: Router,
        private dialog: MatDialog,
        private deviceService: DeviceDetectorService
    ) {
        this.isMobile = this.deviceService.isMobile();
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const url: string = state.url;

        // Initial check to see if the user is trying to consume a ving.
        //  - If there is a token present, attempt to log the user in.
        //  - Otherwise, attempt to navigate them to the /view area for consumption.

        // Second, check if token is present and user not logged in.
        //  - Attempt to log that user in with the given token.
        //    > If successful, wait for the user obj to be set, and navigate the user
        //      to either the dashboard (if coming from login) or their requested url.

        // Third, check if user is using Mobile device, If so, access is limited.

        // Fourth, check if user is already logged in. If so, access is granted.

        // Lastly, for all other cases, route the user to the /login area.

        if (this.appUserService.isLoggedIn()) {
            // Handle cases based on user role.
            if (
                url.startsWith(this.paths.login.name) ||
                !this.appPermissionService.canAccess(url)
            ) {
                this.continueNavigation(
                    this.appPermissionService.getBaseRedirectPage()
                );
                return false;
            } else if (
                this.isMobile &&
                !this.appPermissionService.canAccess(url, true)
            ) {
                this.continueNavigation('/user/assignments');
                return false;
            } else {
                return true;
            }
        } else if (this.appUserService.isTokenPresentAndNotLoggedIn()) {
            this.authenticateAndRedirect(url);
            return false;
        } else {
            // If not authentication token present, route to appropriate landing page.
            if (this.urlIsLoginChildPage(url)) {
                return true;
            } else {
                const path = window.location.pathname + window.location.hash;
                this.appUserService.setRedirectPath(path);
                this.continueNavigation('/login/signIn');
                return false;
            }
        }
    }

    private displayDesktopAccessModal() {
        this.appLoadingIndicatorService.hide();
        this.dialog.open(AppDialogAcknowledgeComponent, {
            width: '425px',
            disableClose: true,
            data: {
                title: 'Desktop Access Only',
                text: 'This feature is only available on the desktop version of Ving.',
                callback: () => {
                    this.continueNavigation(
                        this.appPermissionService.getBaseRedirectPage(true)
                    );
                },
            },
        });
    }

    private urlIsLoginChildPage(url: string): boolean {
        return (
            (url.indexOf(this.paths.login.name) === 0 &&
                url.length > this.paths.login.length) ||
            (url.indexOf(this.paths.view.name) === 0 &&
                url.length > this.paths.view.length)
        );
    }

    private authenticateAndRedirect(url: string): void {
        this.appHttpService.apiAuthMe(this.appUserService.getToken(), () => {
            this.appUserService.whenUserSet(() => {
                // If the user navigates to the login area, but has a token set
                // the user will be routed to the dashboard area once the user
                // has been logged in.
                if (
                    url.startsWith(this.paths.login.name) ||
                    !this.appPermissionService.canAccess(url)
                ) {
                    this.continueNavigation(
                        this.appPermissionService.getBaseRedirectPage()
                    );
                } else {
                    this.continueNavigation(url);
                }
            });
        });
    }

    private continueNavigation(path: string): void {
        this.router.navigateByUrl(path);
    }
}
