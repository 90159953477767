<div
    [ngStyle]="
        cardPreviewUrl !== null && {
            background: 'url(' + cardPreviewUrl + ')'
        }
    "
    [class]="getPacketClass(packet)"
    (click)="handleClick(packet)"
>
    <div class="card-header">
        <!--Owner Icon-->
        <div
            class="owner"
            *ngIf="packet.share.owner"
            [style.background]="packet.share.owner.hexColor"
        >
            <span [title]="packet.share.owner.name">{{
                get2LetterName(packet.share.owner.name)
            }}</span>
        </div>

        <!--Status Badges-->
        <div class="status" *ngIf="packet.share">
            <i
                [class]="
                    'material-icons icon ' +
                    (packet.share.active ? 'green' : 'red')
                "
                [title]="packet.share.active ? 'Active' : 'Inactive'"
            >
                power_settings_new</i
            >
            <i
                class="material-icons icon blue"
                [title]="
                    'View Access: ' +
                    getPacketViewAccess(packet.share.privacy)['title']
                "
            >
                {{
                    getPacketViewAccess(packet.share.privacy)['icon']
                }}</i
            >
            <i
                [class]="
                    'material-icons icon ' +
                    (packet.share.notify ? 'blue' : 'gray')
                "
                [title]="
                    'Notifications ' +
                    (packet.share.notify ? 'On' : 'Off')
                "
            >
                {{
                    packet.share.notify
                        ? 'notifications'
                        : 'notifications_off'
                }}</i
            >
            <!--Burst Settings-->
            <i
                *ngIf="packet.share.enforceOrder"
                [class]="'material-icons icon blue'"
                [title]="
                    'Enforced Order ' +
                    (packet.share.enforceOrder ? 'On' : 'Off')
                "
            >
                done_all</i
            >
            <i
                [class]="
                    'material-icons icon ' +
                    (packet.share.enforceOrder &&
                    packet.share.consumeMinutes &&
                    packet.share.consumeInterval
                        ? 'blue'
                        : 'gray')
                "
                [title]="
                    'Microbursts ' +
                    (packet.share.enforceOrder &&
                    packet.share.consumeMinutes &&
                    packet.share.consumeInterval
                        ? 'On'
                        : 'Off')
                "
            >
                {{
                    packet.share.enforceOrder &&
                    packet.share.consumeMinutes &&
                    packet.share.consumeInterval
                        ? 'timer'
                        : 'timer_off'
                }}</i
            >
            <i
                *ngIf="packet.premium"
                class="premium-icon material-icons icon"
                title="Premium"
                >stars</i
            >
            <i
                *ngIf="packet.hasUnresolvedFlags"
                class="flagged-icon material-icons icon"
                title="Unresolved"
                >flag_circle</i
            >
        </div>

        <!--Selection Icon-->
        <div *ngIf="origin === 'checklists' || origin === 'vings'"
            [class]="getSelectedIconClass(packet.id)"
            (click)="toggleSelectedId(packet.id)"
        >
            <i class="material-icons icon">check</i>
        </div>
    </div>

    <div class="card-body">
        <!--Packet Title-->
        <div class="title">
            <div
                class="text"
                [title]="packet.title"
                *ngIf="packet.title"
            >
                {{ packet.title }}
            </div>
            <div class="text no-title" *ngIf="!packet.title">
                No title set
            </div>
        </div>

        <!--Packet Summary-->
        <div class="summary">
            <div
                class="text"
                [title]="packet.summary"
                *ngIf="packet.summary"
            >
                <span
                    class="duration"
                    *ngIf="packet.duration"
                >
                    <i class="material-icons icon">timer</i> {{ getDurationMinutesText(packet.duration) }}
                </span>
                {{ packet.summary }}
            </div>
        </div>
    </div>

    <div class="card-footer">
        <!--Labels-->
        <div class="labels" *ngIf="packet.labels.length">
            <ng-container
                *ngFor="let label of packet.labels; let i = index"
            >
                <div
                    class="app-tag-alt no-hover"
                    [title]="label | uppercase"
                    *ngIf="i < 13"
                >
                    <div class="text">
                        {{ label }}
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="no-labels" *ngIf="!packet.labels.length">
            No Labels yet<br />
            Select something to add new label
        </div>
    </div>

    <div class="card-hover" *ngIf="!packet.published && (origin === 'checklists' || origin === 'vings')">
        <button
            class="app-button light"
            (click)="handleEdit(packet)"
        >
            Edit
        </button>
        <button
            class="app-button light"
            (click)="handleDelete(packet)"
        >
            Delete
        </button>
    </div>
</div>
