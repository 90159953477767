import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppLoadingIndicatorService } from '../../services/app.loading-indicator.service';
import { AppMessageService } from '../../services/app.message.service';
import { NgForm } from '@angular/forms';
import { AppHttpService } from '../../services/app.http.service';

@Component({
    templateUrl: './app.dialog-create-category.component.html',
})
export class AppDialogCreateCategoryComponent implements OnInit {
    @Input() labels: any[];
    @Input() type: string;
    @Output() onLoadLabels = new EventEmitter<any>();
    private messages = {
        success: {
            category: 'Folder successfully created.',
        },
        error: {
            category:
                'There was an error creating the folder. Please try again.',
        },
    };
    title = 'Name Folder';
    categoryName = '';
    name: any;

    constructor(
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appHttpService: AppHttpService,
        public dialogRef: MatDialogRef<AppDialogCreateCategoryComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            name: string;
            type: string;
            labels: any;
            callback: Function;
        }
    ) {}

    ngOnInit() {
        // Initialize the items data into something that can be used within the UI.
        this.appLoadingIndicatorService.hide(350);
    }

    createCategory(createCategoryForm: NgForm): void {
        if (createCategoryForm.invalid) {
            this.data.callback();
            return;
        }

        this.appLoadingIndicatorService.show('view');

        const labelNames = this.data.labels.map((label: any) => label.name);
        const name = createCategoryForm.value.categoryName;

        const data = {
            labels: labelNames,
        };

        this.appHttpService.request(
            'POST:api/labels/categories/' + this.data.type + '/' + name + '/',
            data,
            () => {
                createCategoryForm.resetForm();
                this.data.callback();
                this.appMessageService.show(
                    'app',
                    'success',
                    this.messages.success.category,
                    3
                );
                this.appLoadingIndicatorService.hide(200);
                this.dialogRef.close();
            },
            () => {
                this.data.callback();
                this.appMessageService.show(
                    'view',
                    'error',
                    this.messages.error.category,
                    4.0
                );
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    close() {
        this.data.callback();
        this.dialogRef.close();
    }
}
