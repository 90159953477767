<div
    class="{{location}} {{type}} app-alert border-box"
    *ngIf="text && type"
    [@fadeTransition]="'in'"
>
    <div class="alert-content">
        <img
            src="/assets/images/icons/error.png"
            height="20"
            *ngIf="type === 'error'"
        />
        <img
            src="/assets/images/icons/success.png"
            height="20"
            *ngIf="type === 'success'"
        />
        <div class="alert" [innerHTML]="text"></div>
        <div class="close" (click)="closeAlert()" *ngIf="close">
            <i class="material-icons icon">close</i>
        </div>
    </div>
</div>
