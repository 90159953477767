import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable()
export class AppLoadingIndicatorService {
    private defaultText = 'LOADING...';
    private defaultInterval = 250;
    private adjustInterval = 100;
    private currentLocation = undefined;
    private defaultLocations = ['app', 'view', 'modal']; // possible values: 'app', 'view', 'modal', <any selector>
    private html = {
        loadingIndicator: `
            <div id="app-loading-indicator">
                <div class="background"></div>
                <div class="indicator">
                    <div class="inner">
                        <div class="image">
                            <img src="/assets/images/loading-indicator-white.gif" />
                        </div>
                        <div class="text">
                            {{text}}
                        </div>
                    </div>
                </div>
            </div>
        `,
    };

    isShown() {
        return $('#app-loading-indicator').length > 0;
    }

    show(location: any, text: any = this.defaultText, callback?: Function) {
        if (text === '') {
            text = this.defaultText;
        }

        if (!this.isShown()) {
            // Initialize html and set text.
            const html = this.html.loadingIndicator.replace('{{text}}', text);

            // Check location to confirm if default or selector.
            if (this.defaultLocations.indexOf(location) >= 0) {
                // Disable the scrollbar if one of the default locations.
                this.currentLocation = location;

                // Using pre-set location.
                if (location === 'modal') {
                    // modal
                    $('.app-dialog')
                        .append(html)
                        .css({ 'overflow-y': 'hidden' });
                } else {
                    // app, view
                    $('#' + location)
                        .append(html)
                        .css({ 'overflow-y': 'hidden' });
                }
            } else {
                const element = $(location);

                this.currentLocation = undefined;

                // Using any selector.
                if (element.length > 0) {
                    element.append(html);
                } else {
                    console.log('ERROR :: Invalid element selector provided.');
                }
            }

            if (callback) {
                setTimeout(() => {
                    callback();
                }, 200);
            }
        } else {
            console.log(
                'WARNING :: Loading indicator already being displayed on the page.'
            );

            if (callback) {
                setTimeout(() => {
                    callback();
                }, 200);
            }
        }
    }

    hide(interval: number = this.defaultInterval) {
        if (this.isShown()) {
            const loadingIndicator = $('#app-loading-indicator');

            // Fade out the loading indicator from view.
            loadingIndicator.fadeOut(interval);

            // If the current location is a default location, re-enable the y-scrollbar.
            if (
                this.currentLocation &&
                this.defaultLocations.indexOf(this.currentLocation) >= 0
            ) {
                $('#' + this.currentLocation).css({ 'overflow-y': 'auto' });
            }

            // Wait and remove the loading indicator from the DOM.
            setTimeout(() => {
                this.currentLocation = undefined;
                loadingIndicator.remove();
            }, interval + this.adjustInterval);
        }
    }
}
