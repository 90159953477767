import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { AppSelectOption } from '../../../../shared/components/app-select/classes/app.select.option';
import { AppHeaderService } from '../../../../shared/services/app.header.service';
import { AppUserService } from "../../../../shared/services/app.user.service";
import { Packet } from "../../classes/packet";

@Component({
    selector: 'app-vings-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit, OnChanges {
    buttons = [
        // {
        //     name: 'add-vings',
        //     icon: 'person_add',
        //     tooltip: 'Add vings to label',
        //     enabled: false
        // },
        {
            name: 'export-data',
            icon: 'file_open',
            tooltip: 'Export analytics',
            enabled: false,
            hidden: false,
            selectedPacket: false,
        },
        {
            name: 'manage-packets-labels',
            icon: 'local_offer',
            tooltip: 'Manage packets labels',
            enabled: false,
            hidden: false,
            selectedPacket: true,
        },
        {
            name: 'change-owner',
            icon: 'account_box',
            tooltip: 'Change owner of selected packets',
            enabled: false,
            hidden: false,
            selectedPacket: true,
        },
        {
            name: 'archive',
            icon: 'archive',
            tooltip: 'Archive selected packets',
            enabled: false,
            hidden: false,
            selectedPacket: true,
        },
        {
            name: 'unarchive',
            icon: 'unarchive',
            tooltip: 'Unarchive selected packets',
            enabled: false,
            hidden: true,
            selectedPacket: true,
        },
        {
            name: 'invite',
            icon: 'person_add',
            tooltip: 'Invite to packets',
            enabled: false,
            hidden: true,
            selectedPacket: true,
        },
    ];

    @Input() packets: Array<Packet>;
    @Input() selectedPacketIds: Array<number>;
    @Input() excludedPacketIds: Array<number>;
    @Input() selectedAllPackets: boolean;
    @Input() packetsCount: number;
    @Input() selectedLabels: Array<string>;
    @Input() selectedFilters: Array<string>;
    @Input() selectedPacket: Packet;
    @Input() selectedStatus: AppSelectOption;
    @Input() isDetail: boolean;

    @Output() onToggleAllPacketIds = new EventEmitter();
    @Output() onClickHeaderButton = new EventEmitter<any>();

    private jobs = {
        headerButtonState: 'packets-header-button-state',
    };

    constructor(
        private appHeaderService: AppHeaderService,
        private appUserService: AppUserService,
    ) {}

    page: '';

    ngOnInit() {
        this.appHeaderService.page.subscribe((page) => {
            this.page = page;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateButtonStates();
    }

    handleClick(name: string): void {
        if (this.getButton(name).enabled === true) {
            this.onClickHeaderButton.emit(name);
        }
    }

    getClass(name: string): string {
        const button = this.getButton(name),
            css = 'app-button dark x-small no-text ';

        return button.enabled ? css : css + ' disabled';
    }

    toggleSelectAllPackets(): void {
        this.onToggleAllPacketIds.emit();
    }

    getSelectedPacketsCount() {
        let count = 0;
        if (this.selectedAllPackets) {
            count = this.packetsCount - this.excludedPacketIds.length;
        } else {
            count = this.selectedPacketIds.length;
        }

        return count > 0 ? count : 0;
    }

    updateButtonStates(): void {
        let packetsAreSelected = false;
        let archivedPacketsAreSelected = false;
        const labelsAreSelected = this.selectedLabels.length > 0;

        if (
            this.selectedAllPackets &&
            this.packetsCount > this.excludedPacketIds.length
        ) {
            packetsAreSelected = true;
        } else {
            packetsAreSelected = this.selectedPacketIds.length > 0;
        }

        if (this.selectedPacket) {
            packetsAreSelected = true;

            if (this.selectedPacket.status === 'archived') {
                archivedPacketsAreSelected = true;
            }
        }

        if (this.selectedStatus && this.selectedStatus.value === 'archived') {
            archivedPacketsAreSelected = true;
        }

        // Update header buttons based on selection state.
        // this.getButton('add-packets').enabled = labelsAreSelected;
        this.getButton('export-data').enabled = packetsAreSelected && this.appUserService.hasPacketsAccess();
        this.getButton('export-data').hidden = this.isDetail;
        this.getButton('manage-packets-labels').enabled = packetsAreSelected;
        this.getButton('change-owner').enabled = packetsAreSelected;
        this.getButton('archive').enabled = packetsAreSelected;
        this.getButton('archive').hidden = archivedPacketsAreSelected;
        this.getButton('unarchive').enabled =
            packetsAreSelected && archivedPacketsAreSelected;
        this.getButton('unarchive').hidden = !archivedPacketsAreSelected;
        this.getButton('invite').enabled = packetsAreSelected && this.appUserService.hasPacketsAccess();
        this.getButton('invite').hidden = this.isDetail;

    }

    private getButton(name: string) {
        return this.buttons.find((btn) => btn.name === name);
    }

    createPacket(type) {
        this.appHeaderService.events.next(type);
    }
}
