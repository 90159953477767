import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';

import { AppControlsModule } from '../../shared/modules/app.controls.module';
import { AppPipesModule } from '../../shared/modules/app.pipes.module';
import { CertificateRoutingModule } from './certificate.routing.module';
import { CertificateComponent } from './certificate.component';
import { MatTabsModule } from '@angular/material/tabs';

export function getWindow() {
    return window;
}

export function getLocalStorage() {
    return localStorage;
}

export function getLocation() {
    return location;
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};

@NgModule({
    imports: [
        AppControlsModule,
        AppPipesModule,
        CertificateRoutingModule,
        PerfectScrollbarModule,
        MatTabsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [CertificateComponent],
    providers: [
        { provide: 'window', useFactory: getWindow },
        { provide: 'storage', useFactory: getLocalStorage },
        { provide: 'location', useFactory: getLocation },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
    ],
    entryComponents: [
        /*Dialogs*/
    ],
})
export class CertificateModule {}
