import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './app.dialog-image.component.html',
    styleUrls: ['./app.dialog-image.component.less'],
})
export class AppDialogImageComponent {
    constructor(
        public dialogRef: MatDialogRef<AppDialogImageComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            url: string;
        }
    ) {}

    onConfirm() {
        setTimeout(() => {
            this.dialogRef.close();
        }, 100);
    }
}
