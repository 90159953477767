import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { AppUserService } from './app.user.service';
import { AppHelpersService } from './app.helpers.service';
import { AppLoadingIndicatorService } from './app.loading-indicator.service';
import { AppInfoService } from './app-info/app.info.service';
import { AppDataTransform } from '../app.data-transform';
import { AppUser } from '../classes/user/app-user';
import { AppDialogErrorComponent } from '../dialogs/dialog-error/app.dialog-error.component';
import { AppQueryEncoderHelper } from '../app.query-encoder.helper';
import { Utils } from '../app.utils';
import {
    DialogMergeUsersComponent
} from "../../modules/accounts/dialogs/dialog-merge-users/dialog-merge-users.component";

@Injectable()
export class AppHttpService {
    private noAuthEndpoints = [
        'POST:api/auth/login/',
        'POST:api/auth/password/reset/',
        'POST:api/auth/activate/',
        'POST:api/auth/password/reset/confirm/',
        'POST:api/auth/register/',
    ];
    private noCancelEndpoints = [
        // Like: 'api/auth/me/',
        'api/files/generate-presigned-post/',
    ];
    private optionalAuthEndpoints = [
        'GET:api/view/shares/',
        'PUT:api/view/shares/',
    ];
    private oneHour = 3600000;
    private pendingRequests = new Map();
    private previousRequest;
    private currentRequest;

    constructor(
        private http: HttpClient,
        private appUserService: AppUserService,
        private appInfoService: AppInfoService,
        private appHelpersService: AppHelpersService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private dialog: MatDialog,
        @Inject('storage') private storage: Storage
    ) {}

    getEndpoint(url) {
        const urlArray = url.split('?')[0].split('//')[1].split('/');
        urlArray.shift();

        return urlArray.join('/');
    }

    removeCompleted(url) {
        this.pendingRequests.delete(this.getEndpoint(url));
    }

    cancelPendingRequest(url) {
        const endpoint = this.getEndpoint(url);
        if (this.noCancelEndpoints.indexOf(endpoint.toString()) < 0) {
            this.previousRequest = this.pendingRequests.get(endpoint);
            if (this.previousRequest !== undefined) {
                this.previousRequest.unsubscribe();
            }
        }
    }

    addPendingRequest(url, req) {
        const endpoint = this.getEndpoint(url);
        return this.pendingRequests.set(endpoint, req);
    }

    request(
        methodUrl: string,
        data: Object,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        const requestData = this.getRequestData(
            methodUrl,
            data,
            false,
            successCallback,
            errorCallback
        );

        this.cancelPendingRequest(requestData.url);

        const request = this.processRequest(requestData, methodUrl);

        return request;
    }

    requestObserver(url: string, options: Object): Observable<Object> {
        const requestData = this.getRequestData(url, options);
        return this.http.request(
            requestData.method,
            requestData.url,
            requestData.options
        );
    }

    requestBlob(
        methodUrl: string,
        data: Object,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        const requestData = this.getRequestData(
            methodUrl,
            data,
            true,
            successCallback,
            errorCallback
        );

        this.cancelPendingRequest(requestData.url);

        const request = this.processRequest(requestData, methodUrl);

        return request;
    }

    requestObserv(methodUrl: string, data: Object): Observable<any> {
        const requestData = this.getRequestData(methodUrl, data);
        return this.http.request(
            requestData.method,
            requestData.url,
            requestData.options
        );
    }

    processRequest(requestData, methodUrl) {
        // Execute HTTP request.
        this.currentRequest = this.http
            .request(requestData.method, requestData.url, requestData.options)
            .subscribe(
                (responseSuccess) => {
                    this.removeCompleted(requestData.url);
                    if (requestData.successCallback) {
                        requestData.successCallback(responseSuccess);
                    }
                },
                (responseError) => {
                    this.removeCompleted(requestData.url);
                    if (responseError) {
                        const errorData = {
                            status: responseError.status,
                            data: responseError.error,
                        };

                        if (requestData.errorCallback) {
                            requestData.errorCallback(errorData);
                        } else {
                            this.appLoadingIndicatorService.hide();

                            const methodUrlArr = methodUrl.split(':');
                            const url =
                                methodUrlArr && methodUrlArr.length > 1
                                    ? methodUrlArr[1]
                                    : '';
                            let showError = true;

                            if (
                                url.substring(0, 15) === 'api/view/shares' &&
                                responseError.status !== 401 &&
                                responseError.status !== 403
                            ) {
                                // do nothing for now
                                console.log(responseError);

                                if (requestData.method != 'GET') {
                                    showError = false;
                                }
                            }

                            if (showError) {
                                this.dialog.open(AppDialogErrorComponent, {
                                    width: '600px',
                                    disableClose: true,
                                    autoFocus: false,
                                    data: errorData,
                                });
                            }
                        }
                    }
                }
            );

        this.addPendingRequest(requestData.url, this.currentRequest);

        return this.currentRequest;
    }

    apiAwsUploadFile(
        fileData,
        callbackSuccess?: Function,
        callbackError?: Function
    ) {
        const data = {
            filename: encodeURIComponent(fileData.name),
            type: fileData.type,
        };

        return this.request(
            'GET:api/files/generate-presigned-post/',
            data,
            (awsGetResponse) => {
                const postUrl = awsGetResponse.url,
                    fieldKeys = Object.keys(awsGetResponse.fields),
                    awsKey = awsGetResponse.fields.key;

                // POST file to AWS url.
                const formData: FormData = new FormData();
                fieldKeys.forEach((key) => {
                    formData.append(key, awsGetResponse.fields[key]);
                });
                formData.append('file', fileData);

                this.http
                    .request('POST', postUrl, { body: formData })
                    .pipe(timeout(this.oneHour * 2))
                    .subscribe(
                        (responseSuccess) => {
                            if (callbackSuccess) {
                                callbackSuccess(awsKey);
                            }
                        },
                        (responseError) => {
                            console.log(responseError);
                            if (callbackError) {
                                callbackError(responseError);
                            }
                        }
                    );
            },
            (awsGetResponseError) => {
                console.log(awsGetResponseError);
                if (callbackError) {
                    callbackError(awsGetResponseError);
                }
            }
        );
    }

    apiAuthMe(authToken: string, callback?: Function) {
        const data = { authToken: authToken };
        if (!authToken) {
            this.appUserService.logout();
            return false;
        }

        return this.request(
            'GET:api/auth/me/',
            data,
            (appUser: AppUser) => {
                this.appUserService.setUser(appUser);
                this.appInfoService.setAppInfo(appUser.appInfo);

                if (this.appUserService.hasMergeSuggestion()) {
                    const dialogHeight = (appUser.mergeSuggestion.verifyMethods.length * 35) + 225;

                    this.dialog.open(DialogMergeUsersComponent, {
                        width: '450px',
                        height: `${dialogHeight}px`,
                        disableClose: false,
                        data: {
                            title: 'Manage Your Ving Accounts',
                            mergeSuggestion: appUser.mergeSuggestion,
                        },
                    })
                }

                if (callback) {
                    callback();
                }
            },
            () => {
                this.appUserService.logout();
            }
        );
    }

    private getRequestData(
        methodUrl: string,
        data: Object,
        blob = false,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        const baseUrl = this.appHelpersService.getBaseUrl(),
            withAuth = this.noAuthEndpoints.indexOf(methodUrl.toString()) < 0,
            optionalAuth =
                this.optionalAuthEndpoints.indexOf(methodUrl.toString()) < 0,
            methodUrlArray = methodUrl.split(':'),
            method = methodUrlArray[0],
            uriArray = methodUrlArray[1].split('?'),
            options = {},
            authToken = this.getAuthToken(data);
        let headers = new HttpHeaders();

        const queryParams = this.getQueryStringParams(uriArray[1]);
        const queryString = queryParams
            .map(
                (param) =>
                    param.key +
                    '=' +
                    Utils.encURIComponent(param.key, param.value)
            )
            .join('&');
        const url =
            baseUrl + uriArray[0] + (queryString ? '?' + queryString : '');

        // Conditionally add the 'Authorization' and token to the header.
        if (authToken && (withAuth || optionalAuth)) {
            headers = headers.set('Authorization', 'Token ' + authToken);
        }

        // Change the property names to use snake (Python).
        data = AppDataTransform.getConvertedData('snake', data);

        // Conditional logic to include
        if (method === 'GET' || method === 'DELETE') {
            let params = new HttpParams({
                encoder: new AppQueryEncoderHelper(),
            });
            for (const [key, value] of Object.entries(data)) {
                if (Array.isArray(value)) {
                    value.forEach(function (v) {
                        params = params.append(key, v);
                    });
                } else {
                    params = params.append(key, value);
                }
            }
            options['params'] = params;
        } else if (method === 'POST' || method === 'PUT') {
            headers = headers.set(
                'Content-Type',
                'application/json; charset=utf-8'
            );
            options['body'] = data;
        }

        // ResponseType for Blob
        if (blob) {
            options['responseType'] = 'blob';
            options['observe'] = 'response';
        }

        // Add the headers to the 'options' object.
        options['headers'] = headers;

        // Add withCredentials to the 'options' object.
        options['withCredentials'] = true;

        return {
            method: method,
            url: url,
            options: options,
            successCallback: successCallback,
            errorCallback: errorCallback,
        };
    }

    private getAuthToken(data: Object): string {
        const user = this.appUserService.getUser();
        let token: string;

        if (user) {
            token = user.token;
        } else {
            if (data && 'authToken' in data) {
                token = data['authToken'];
            }
        }

        return token;
    }

    private getQueryStringParams(query: string) {
        const queryParams = [],
            queryArr = query
                ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&')
                : [];
        queryArr.map((param) => {
            const [k, val] = param.split('=');
            queryParams.push({
                key: k,
                value: val ? decodeURIComponent(val.replace(/\+/g, ' ')) : '',
            });
        });
        return queryParams;
    }
}
