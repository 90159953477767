import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-content-pending',
    templateUrl: './content.pending.component.html',
    styleUrls: ['./content.pending.component.less'],
})
export class ContentPendingComponent {
    @Input() type: any;
}
