import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppAlertService } from '../../../../shared/services/app.alert.service';
import { AppUserService } from '../../../../shared/services/app.user.service';
import { AppPermissionService } from '../../../../shared/services/app.permission.service';

@Component({
    selector: 'app-sso',
    templateUrl: './sso.component.html',
    styleUrls: ['./sso.component.less'],
})
export class SsoComponent implements OnInit {
    private isMobile = null;

    constructor(
        @Inject('storage') private storage: Storage,
        private router: Router,
        private appHttpService: AppHttpService,
        private appHelpersService: AppHelpersService,
        private appAlertService: AppAlertService,
        private appUserService: AppUserService,
        private appPermissionService: AppPermissionService,
        private deviceService: DeviceDetectorService
    ) {
        this.isMobile = this.deviceService.isMobile();
    }

    ngOnInit() {
        const urlParams = this.appHelpersService.getUrlParams();

        if (urlParams['error']) {
            this.appAlertService.set('view', 'error', urlParams['error']);
            this.router.navigateByUrl('/login/signIn');
        } else {
            this.appHttpService.apiAuthMe(urlParams['auth_token'], () => {
                const redirectPath = this.appUserService.getRedirectPath();
                if (redirectPath) {
                    this.appUserService.clearRedirectPath();
                    this.router.navigateByUrl(redirectPath);
                } else {
                    this.router.navigateByUrl(
                        this.appPermissionService.getBaseRedirectPage(
                            this.isMobile
                        )
                    );
                }
            });
        }
    }
}
