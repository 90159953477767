import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppDocumentComponent } from '../../modules/vings/components/edit/components/app-document/app-document.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxFileDropModule } from 'ngx-file-drop';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PdfViewerModule,
        NgxFileDropModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [AppDocumentComponent],
    exports: [AppDocumentComponent],
})
export class AppDocumentModule {}
