<div class="wrapper">
    <div class="filters">
        <!--Search-->
        <div class="search">
            <div class="app-input-text">
                <input
                    type="text"
                    name="searchText"
                    [(ngModel)]="searchText"
                    (keyup)="handleSearchTextChange($event)"
                    placeholder="Search..."
                />
                <div class="app-clear-search-field-button">
                    <i class="material-icons icon" *ngIf="!searchText"
                        >search</i
                    >
                    <i
                        class="material-icons icon clear"
                        *ngIf="searchText"
                        (click)="handleClearSearchField()"
                        >clear</i
                    >
                </div>
            </div>
        </div>

         <!--Contacts Count-->
        <div class="cards-count">
            {{ contactsCount }}
            {{ 'contact' | pluralize: contactsCount }}
        </div>

        <!--Sort & Status-->
        <div class="sort-and-status">
            <!--Status-->
            <app-select
                rel="status"
                [width]="140"
                [height]="28"
                [shouldIncludeDefaultValue]="false"
                [options]="statuses"
                [selected]="selectedStatus"
                (update)="updateSelectedStatus($event)"
                >
            </app-select>

            <!--Sort-->
            <app-select
                rel="sort"
                [width]="120"
                [height]="28"
                [shouldIncludeDefaultValue]="false"
                [options]="sorts"
                [selected]="selectedSort"
                (update)="updateSelectedSort($event)"
                >
            </app-select>

            <!--Owners-->
            <app-select
                rel="owner"
                [width]="100"
                [height]="28"
                [shouldIncludeDefaultValue]="false"
                [defaultLabel]="'Anyone'"
                [options]="owners"
                [selected]="selectedOwner"
                (update)="updateSelectedOwner($event)"
                >
            </app-select>
        </div>
    </div>

    <!--Contacts-->
    <div class="cards-wrapper" (scroll)="lazyLoadContacts($event)">
        <div id="list-cards" class="cards-list">
            <!--Contact-->
            <app-contact-card *ngFor=" let contact of contacts"
                [origin]="'contacts'"
                [contact]="contact"
                [selectedContactIds]="selectedContactIds"
                [excludedContactIds]="excludedContactIds"
                [selectedAllContacts]="selectedAllContacts"
                (handleToggleContactId)="toggleSelectedContactId($event)"
                (handleSelectContact)="selectContact($event)">
            </app-contact-card>
        </div>
    </div>
</div>
