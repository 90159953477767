<div id="checklists">
    <div id="content" [class]="selectedChecklist ? 'active' : ''">
        <!--Panel One - Filtering-->
        <div id="nav" class="step1">
            <app-filter-navigation
                [page]="'checklists'"
                [searchText]="searchText"
                [entityLists]="entityLists"
                [selectedLabels]="selectedLabels"
                [filters]="filters"
                [selectedFilters]="selectedFilters"
                (onToggleLabel)="onToggleLabel($event)"
                (onToggleFilter)="onToggleFilter($event)"
                (onDeleteLabel)="onDeleteLabel($event)"
                (onEditCategory)="onEditCategory($event)"
                (onDeleteCategory)="onDeleteCategory($event)"
                (onCategoryCreate)="loadLabels(true)"
                (onClearFilters)="onClearFilters()"
                (onRemoveLabelFromList)="removeLabel($event)"
            >
            </app-filter-navigation>
            <!--Reset Button-->
            <div class="reset-button">
                <button
                    *ngIf="
                        selectedLabels.length ||
                        selectedFilters.length ||
                        !!searchText
                    "
                    class="app-button light block"
                    (click)="onResetSearch()"
                >
                    Reset Search
                </button>
            </div>
        </div>

        <!--Panel Two - Objects -->
        <div class="list-wrapper">
            <!--Panel Two-->
            <div id="panel-two" class="step1">
                <div class="container">
                    <app-checklists-cards
                        [searchStr]="searchText"
                        [checklists]="checklists"
                        [checklistsCount]="checklistsCount"
                        [statuses]="statuses"
                        [selectedStatus]="selectedStatus"
                        [sorts]="sorts"
                        [selectedSort]="selectedSort"
                        [owners]="owners"
                        [selectedOwner]="selectedOwner"
                        [selectedChecklist]="selectedChecklist"
                        [shouldDisplayHorizontal]="shouldDisplayHorizontal"
                        [selectedChecklistIds]="selectedChecklistIds"
                        [excludedChecklistIds]="excludedChecklistIds"
                        [selectedAllChecklists]="selectedAllChecklists"
                        [isSchedulerOpen]="scheduledIsOpen"
                        (handleLazyLoadChecklists)="lazyLoadChecklists($event)"
                        (handleSearchChange)="onSearchTextChange($event)"
                        (handleSortOptionChange)="onSortOptionChange($event)"
                        (handleStatusOptionChange)="
                            onStatusOptionChange($event)
                        "
                        (handleOwnerOptionChange)="onOwnerOptionChange($event)"
                        (handleToggleChecklistId)="onToggleChecklistId($event)"
                        (handleClickChecklist)="onClickChecklist($event)"
                        (handleEditChecklist)="onEditChecklist($event)"
                        (handleDeleteChecklist)="onDeleteChecklist($event)"
                        (handleResetSearch)="onDeselectChecklists($event)"
                    >
                    </app-checklists-cards>
                    <div class="app-scroll-loading-indicator">Loading...</div>
                </div>
            </div>

            <!--Panel Four-->
            <div id="panel-four-trigger" (click)="toggleScheduled()" *ngIf="!isDetail">
                <i *ngIf="!scheduledIsOpen" class="material-icons icon">arrow_back_ios</i>
                <i *ngIf="scheduledIsOpen" class="material-icons icon">arrow_forward_ios</i>
            </div>

            <div id="panel-four" class="step1" *ngIf="scheduledIsOpen && !isDetail">
                <div class="view">
                    <app-checklists-scheduled
                        [selectedVingIds]="selectedChecklistIds"
                        [excludedVingIds]="excludedChecklistIds"
                        [selectedAllVings]="selectedAllChecklists"
                        [vings]="checklists"
                        (handleSelectVing)="onClickChecklist($event)"
                        [scheduledInvitations]="scheduledInvitations"
                    ></app-checklists-scheduled>
                </div>
            </div>
        </div>

        <!--Panel Three-->
        <div id="panel-three" class="step2">
            <div class="view">
                <app-checklists-selected-checklist
                    [selectNewChecklist]="selectNewChecklist.asObservable()"
                    (handleSelectChecklist)="onClickChecklist($event)"
                    (handleChecklists)="onUpdateSelectedChecklist($event)"
                >
                </app-checklists-selected-checklist>
            </div>
        </div>
    </div>
    <!--Header-->
    <div id="header">
        <div class="left">
            <div class="arrow" (click)="resetChecklist()" *ngIf="selectedChecklist">
                <i class="material-icons icon">arrow_back</i>
            </div>
            <div class="checklists-title">
                <div class="title-icon">
                    <i class="material-icons icon">checklist</i>
                </div>
                <div class="title-text">Checklists</div>
            </div>
        </div>

        <div class="right">
            <div class="inner">
                <app-checklists-header
                    [checklists]="checklists"
                    [selectedChecklistIds]="selectedChecklistIds"
                    [excludedChecklistIds]="excludedChecklistIds"
                    [selectedAllChecklists]="selectedAllChecklists"
                    [checklistsCount]="checklistsCount"
                    [selectedLabels]="selectedLabels"
                    [selectedFilters]="selectedFilters"
                    [selectedChecklist]="selectedChecklist"
                    [selectedStatus]="selectedStatus"
                    [isDetail]="isDetail"
                    (onClickHeaderButton)="onClickHeaderButton($event)"
                    (onToggleAllChecklistIds)="onToggleAllChecklistIds()"
                >
                </app-checklists-header>
            </div>
        </div>
    </div>
</div>
