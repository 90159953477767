<div class="forgot-password">
    <!--Logo-->
    <div class="logo">
        <img src="/assets/images/app-ving-logo.png" width="150" />
    </div>

    <!--Content-->
    <div class="content">
        <div class="section-header">
            <div class="title">Forgot Password</div>
        </div>

        <div class="section-header">
            <ng-container>
                <div class="alt-button-theme">
                    <button
                        class="social-button"
                        (click)="navigateToGoogleSso()"
                    >
                        <img
                            class="icon"
                            src="/assets/images/clients/google.png"
                            alt="Sign In With Google"
                        />
                    </button>
                </div>
                <div class="alt-button-theme">
                    <button class="social-button" (click)="navigateToAdpSso()">
                        <img
                            class="icon"
                            src="/assets/images/clients/adp.png"
                            alt="Sign In With ADP"
                        />
                    </button>
                </div>
            </ng-container>
        </div>

        <div class="divider-bar"></div>

        <ng-container>
            <p class="text-center">
                Need a Ving account?&nbsp;&nbsp;
                <a
                    class="signup"
                    routerLink="/login/signUp"
                    [queryParams]="{ auth_as: auth_as }"
                    >Sign Up</a
                >
            </p>
        </ng-container>

        <!--Alert-->
        <app-alert></app-alert>

        <!--Reset Password Form-->
        <ng-container>
            <form
                (ngSubmit)="magicLink()"
                #resetPasswordEmailForm="ngForm"
                class="forgot-password-form alt-input-theme"
            >
                <!--Email-->
                <div class="form-group">
                    <label>Email or Phone</label>
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="signInUser.username"
                        #email
                        name="username"
                        autocomplete="off"
                        required
                    />
                </div>
                <!--Buttons-->
                <div class="form-buttons">
                    <button
                        class="app-button large green"
                        [disabled]="!resetPasswordEmailForm.form.valid"
                    >
                        Send Reset Link
                    </button>
                </div>
            </form>
        </ng-container>
    </div>
</div>
