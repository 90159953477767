import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: './app-checkbox.component.html',
    styleUrls: ['./app-checkbox.component.less'],
})
export class AppCheckboxComponent implements OnInit {
    @Input() answer: Array<number>;
    @Input() choiceId: number;

    @Output() updateAnswer = new EventEmitter<any>();

    ngOnInit() {
        if (!this.answer) {
            this.answer = [];
        }
    }

    isSelected(): boolean {
        let selected = false;

        if (this.answer.length) {
            selected = this.answer.indexOf(this.choiceId) >= 0;
        }

        return selected;
    }

    onToggleValue(): void {
        if (this.isSelected()) {
            this.answer.splice(this.answer.indexOf(this.choiceId), 1);
        } else {
            this.answer.push(this.choiceId);
        }

        this.updateAnswer.emit(this.answer);
    }
}
