import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgCircleProgressModule } from 'ng-circle-progress';

import { ViewComponent } from './view.component';
import { ViewRoutingModule } from './view.routing.module';
import { ComponentIndexComponent } from './components/index/component-index.component';
import { AppControlsModule } from '../../shared/modules/app.controls.module';
import { DialogReadonlyComponent } from '../../shared/dialogs/dialog-readonly/dialog.readonly.component';
import { AppDialogErrorComponent } from '../../shared/dialogs/dialog-error/app.dialog-error.component';
import { DialogViewStatusComponent } from './dialogs/dialog-view-status/dialog.view.status.component';
import { ViewVingComponent } from './components/packet/view-ving.component';
import { ViewChecklistComponent } from './components/checklist/view-checklist.component';

export function getWindow() {
    return window;
}

export function getLocalStorage() {
    return localStorage;
}

export function getLocation() {
    return location;
}

@NgModule({
    imports: [
        AppControlsModule,
        ViewRoutingModule,
        NgCircleProgressModule.forRoot(),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        ViewComponent,
        ComponentIndexComponent,
        DialogReadonlyComponent,
        AppDialogErrorComponent,
        DialogViewStatusComponent,
        ViewVingComponent,
        ViewChecklistComponent,
    ],
    providers: [
        { provide: 'window', useFactory: getWindow },
        { provide: 'storage', useFactory: getLocalStorage },
        { provide: 'location', useFactory: getLocation },
    ],
    entryComponents: [
        DialogReadonlyComponent,
        AppDialogErrorComponent,
        DialogViewStatusComponent,
    ],
})
export class ViewModule {}
