import { Component, OnInit } from '@angular/core';
import {
    style,
    animate,
    trigger,
    transition,
    state,
} from '@angular/animations';
import { AppAlertService } from '../../services/app.alert.service';

@Component({
    selector: 'app-alert',
    templateUrl: './app.alert.html',
    styleUrls: ['./app.alert.less'],
    animations: [
        trigger('fadeTransition', [
            state('in', style({ opacity: 1 })),
            transition(':enter', [style({ opacity: 0 }), animate(250)]),
            transition(':leave', animate(350, style({ opacity: 0 }))),
        ]),
    ],
})
export class AppAlertComponent implements OnInit {
    location = '';
    text = '';
    type = '';
    close = true;

    constructor(private appAlertService: AppAlertService) {}

    ngOnInit() {
        this.appAlertService.location.subscribe((location) => {
            this.location = location;
        });
        this.appAlertService.text.subscribe((text) => {
            this.text = text;
        });
        this.appAlertService.type.subscribe((type) => {
            this.type = type;
        });
        this.appAlertService.close.subscribe((close) => {
            this.close = close;
        });
    }

    closeAlert() {
        this.appAlertService.destroy();
    }
}
