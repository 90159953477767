import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppHelpersService } from '../../shared/services/app.helpers.service';
import { AppHttpService } from '../../shared/services/app.http.service';
import { AppLoadingIndicatorService } from '../../shared/services/app.loading-indicator.service';
import { AppUserService } from '../../shared/services/app.user.service';
import { AppPermissionService } from '../../shared/services/app.permission.service';
import { AppJobService } from '../../shared/services/job/app.job.service';
import { View } from './classes/view';
import * as $ from 'jquery';

@Component({
    selector: 'app-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.less'],
})
export class ViewComponent implements OnInit, OnDestroy {
    @ViewChild('video') video: ElementRef;

    isMobile = null;
    isPreview = false;
    previewComponentId;
    view: View;
    private jobs = {
        questionsValidate: 'app-questions-validate-job',
        mediaProgress: 'app-media-progress-job',
        document: 'app-document-job',
    };
    private urlParams: object;
    private packet: any;
    packetView: any;
    checklistView: any;

    constructor(
        private appJobService: AppJobService,
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appUserService: AppUserService,
        private appPermissionService: AppPermissionService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private deviceService: DeviceDetectorService
    ) {
        this.isMobile = this.deviceService.isMobile();
    }

    ngOnInit() {
        this.appLoadingIndicatorService.show('view');

        // Retrieve and set the url params.
        this.urlParams = this.appHelpersService.getUrlParams();

        // Check to see if preview param was passed in.
        if (
            this.urlParams &&
            'preview' in this.urlParams &&
            this.urlParams['preview']
        ) {
            this.isPreview = true;

            if ('componentId' in this.urlParams) {
                const componentId = parseInt(this.urlParams['componentId'], 10);

                if (Number.isInteger(componentId)) {
                    this.previewComponentId = componentId;
                }
            }
        }

        this.initPage();
    }

    ngOnDestroy() {
        // Adjust view style.
        $('#view').css({ 'overflow-y': 'auto' });

        this.appJobService.kill(this.jobs.questionsValidate);
    }
    private initPage() {
        const stub = this.route.snapshot.paramMap.get('stub'),
            data = this.appHelpersService.getUrlParams();

        this.appHttpService.request(
            `GET:api/view/shares/${stub}/`,
            data,
            (view) => {
                this.appLoadingIndicatorService.hide(500);
                this.view = view;
                if (view.checklist) {
                    delete view.packet;
                    this.checklistView = view;
                } else {
                    delete view.checklist;
                    this.packetView = view;
                    // Not a checklist, adjust view style
                    $('#view').css({'overflow-y': 'hidden'});
                }
            }
        );
    }
}
