import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { distinctUntilChanged } from 'rxjs/operators';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { addDays, addMonths, addWeeks } from 'date-fns';
import * as moment from 'moment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Utils } from '../../app.utils';
import { AppHttpService } from '../../services/app.http.service';
import { AppMessageService } from '../../services/app.message.service';
import { AppHelpersService } from '../../services/app.helpers.service';
import { AppLoadingIndicatorService } from '../../services/app.loading-indicator.service';

@Component({
    templateUrl: './dialog.send.invitation.component.html',
    styleUrls: ['./dialog.send.invitation.component.less'],
})
export class DialogSendInvitationComponent implements OnInit {
    private updateData: Object;
    prePopulate: any;
    constructor(
        private http: HttpClient,
        private appHttpService: AppHttpService,
        private appMessageService: AppMessageService,
        private appHelpersService: AppHelpersService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        public dialogRef: MatDialogRef<DialogSendInvitationComponent>,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            allContacts: any;
            selectedContacts: any;
            excludedContacts: any;
            selectedAllContacts: any;
            header: string;
            shareId: any;
            xTableData: any;
            selectedFromList: any;
            prePopulate: any;
            callback: Function;
        }
    ) {}
    messages = {
        success: 'Successfully sent invitation.',
        successScheduleMultiple: 'Successfully scheduled the invitations.',
        successSchedule: 'Successfully scheduled the invitation.',
        successEdit: 'Invitation was successfully updated.',
        error: 'There was an error while sending the invitations.',
        errorEdit: 'There was an error while updating the invitations.',
        countError:
            'Some contact counts have changed. Please review and send again.',
    };
    apis = {
        get: 'GET:api/recipient-search/',
        post: 'POST:api/invitations/',
        put: 'PUT:api/invitations/',
    };
    error = false;
    selectedContactsFromTable = [];
    excludedContactsFromTable = [];
    allContactsFromTable: any = {};
    shareId: any;
    invitationId: any;
    message = '';
    contactMethod = true;
    contactsData: Array<any> = [];
    selectedContacts: Array<string>;
    selectedContactsData: Array<any> = [];
    selectedResults: Array<any> = [];
    sendTo: any = {};
    showSchedulerInForm = false;
    sendMultipleInvites = false;
    selectedFromList: Array<any> = [];
    scheduledDate = new FormControl();
    singleTime = new FormControl();
    quickScheduleInterval = new FormControl();
    quickScheduleTime = new FormControl();
    quickScheduleDate = new FormControl();
    fields: any;
    userForm: FormGroup;
    private inviteData: any;
    private types = {
        contact: 1,
        filter: 2,
        label: 3,
        status: 4,
        table: 5,
        email: 6,
        phone: 7,
    };
    isFuture = 0;
    allInFuture = true;
    isWithinFifteenMinutes = true;
    isMenuOpen: boolean;
    defaultTime = [10];
    timeOfDay = 'AM';

    times = [
        { display: '7:00 AM', forMoment: '07:00 AM', hours: 7, minutes: 0 },
        { display: '7:30 AM', forMoment: '07:30 AM', hours: 7, minutes: 30 },
        { display: '8:00 AM', forMoment: '08:00 AM', hours: 8, minutes: 0 },
        { display: '8:30 AM', forMoment: '08:30 AM', hours: 8, minutes: 30 },
        { display: '9:00 AM', forMoment: '09:00 AM', hours: 9, minutes: 0 },
        { display: '9:30 AM', forMoment: '09:30 AM', hours: 9, minutes: 30 },
        { display: '10:00 AM', forMoment: '10:00 AM', hours: 10, minutes: 0 },
        { display: '10:30 AM', forMoment: '10:30 AM', hours: 10, minutes: 30 },
        { display: '11:00 AM', forMoment: '11:00 AM', hours: 11, minutes: 0 },
        { display: '11:30 AM', forMoment: '11:30 AM', hours: 11, minutes: 30 },
        { display: '12:00 PM', forMoment: '12:00 PM', hours: 12, minutes: 0 },
        { display: '12:30 PM', forMoment: '12:30 PM', hours: 12, minutes: 30 },
        { display: '1:00 PM', forMoment: '01:00 PM', hours: 13, minutes: 0 },
        { display: '1:30 PM', forMoment: '01:30 PM', hours: 13, minutes: 30 },
        { display: '2:00 PM', forMoment: '02:00 PM', hours: 14, minutes: 0 },
        { display: '2:30 PM', forMoment: '02:30 PM', hours: 14, minutes: 30 },
        { display: '3:00 PM', forMoment: '03:00 PM', hours: 15, minutes: 0 },
        { display: '3:30 PM', forMoment: '03:30 PM', hours: 15, minutes: 30 },
        { display: '4:00 PM', forMoment: '04:00 PM', hours: 16, minutes: 0 },
        { display: '4:30 PM', forMoment: '04:30 PM', hours: 16, minutes: 30 },
        { display: '5:00 PM', forMoment: '05:00 PM', hours: 17, minutes: 0 },
        { display: '5:30 PM', forMoment: '05:30 PM', hours: 17, minutes: 30 },
        { display: '6:00 PM', forMoment: '06:00 PM', hours: 18, minutes: 0 },
        { display: '6:30 PM', forMoment: '06:30 PM', hours: 18, minutes: 30 },
        { display: '7:00 PM', forMoment: '07:00 PM', hours: 19, minutes: 0 },
        { display: '7:30 PM', forMoment: '07:30 PM', hours: 19, minutes: 30 },
        { display: '8:00 PM', forMoment: '08:00 PM', hours: 20, minutes: 0 },
        { display: '8:30 PM', forMoment: '08:30 PM', hours: 20, minutes: 30 },
        { display: '9:00 PM', forMoment: '09:00 PM', hours: 21, minutes: 0 },
        { display: '9:30 PM', forMoment: '09:30 PM', hours: 21, minutes: 30 },
        { display: '10:00 PM', forMoment: '10:00 PM', hours: 22, minutes: 0 },
        { display: '10:30 PM', forMoment: '10:30 PM', hours: 22, minutes: 30 },
        { display: '11:00 PM', forMoment: '11:00 PM', hours: 23, minutes: 0 },
        { display: '11:30 PM', forMoment: '11:30 PM', hours: 23, minutes: 30 },
        { display: '12:00 AM', forMoment: '12:00 AM', hours: 0, minutes: 0 },
        { display: '12:30 AM', forMoment: '12:30 AM', hours: 0, minutes: 30 },
        { display: '1:00 AM', forMoment: '01:00 AM', hours: 1, minutes: 0 },
        { display: '1:30 AM', forMoment: '01:30 AM', hours: 1, minutes: 30 },
        { display: '2:00 AM', forMoment: '02:00 AM', hours: 2, minutes: 0 },
        { display: '2:30 AM', forMoment: '02:30 AM', hours: 2, minutes: 30 },
        { display: '3:00 AM', forMoment: '03:00 AM', hours: 3, minutes: 0 },
        { display: '3:30 AM', forMoment: '03:30 AM', hours: 3, minutes: 30 },
        { display: '4:00 AM', forMoment: '04:00 AM', hours: 4, minutes: 0 },
        { display: '4:30 AM', forMoment: '04:30 AM', hours: 4, minutes: 30 },
        { display: '5:00 AM', forMoment: '05:00 AM', hours: 5, minutes: 0 },
        { display: '5:30 AM', forMoment: '05:30 AM', hours: 5, minutes: 30 },
        { display: '6:00 AM', forMoment: '06:00 AM', hours: 6, minutes: 0 },
        { display: '6:30 AM', forMoment: '06:30 AM', hours: 6, minutes: 30 },
    ];

    ngOnInit() {
        this.selectedFromList = this.data.selectedFromList;
        this.shareId = this.data.shareId;

        const tempOptions = [];

        if (this.data.prePopulate) {
            this.prePopulate = this.data.prePopulate;

            if (this.prePopulate.id) {
                // We're editing an existing invitation
                this.invitationId = this.prePopulate.id;
                this.shareId = this.prePopulate.shareId;
                this.message = this.prePopulate.message;
                this.deconstructTimes(this.data.prePopulate);
                this.showSchedulerInForm = true;

                this.scheduledDate.setValue(this.prePopulate.sendDate);
                if (this.data.prePopulate.preferredContactMethod === 'email') {
                    this.contactMethod = true;
                } else {
                    this.contactMethod = false;
                }
            }

            this.setPreselected();
        }

        if (this.selectedFromList && this.selectedFromList.length) {
            this.sendMultipleInvites = true;
        }

        if (!this.invitationId) {
            // This is a new invitation
            this.contactsData = [];
            this.selectedContacts = [];

            if (this.selectedFromList) {
                const today = new Date();
                const tomorrow = new Date(today);
                tomorrow.setDate(tomorrow.getDate() + 1);
                this.quickScheduleInterval.setValue('weekly');
                this.quickScheduleDate.setValue(tomorrow);
                this.quickScheduleTime.setValue('10:00 AM');
                this.selectedFromList.forEach((selected, i) => {
                    tempOptions.push({
                        id: selected.id,
                        label: selected.title,
                        date: selected.scheduledDate,
                        time: '10:00 AM',
                        shareId: selected.share.id,
                    });
                });
            } else {
                this.selectedContactsFromTable = this.data.selectedContacts;
                this.excludedContactsFromTable = this.data.excludedContacts;
                this.allContactsFromTable = this.data.selectedAllContacts;
                const anonViews = this.data.xTableData.rows
                    .filter((i) => i.selectable === false)
                    .map((r) => r.id);
                this.excludedContactsFromTable = [
                    ...this.excludedContactsFromTable,
                    ...anonViews,
                ];
                if (this.allContactsFromTable.status) {
                    const selectedItems = this.data.xTableData.rows
                        .filter((i) => i.selectable === true)
                        .map((r) => r.id);
                    this.selectedContactsFromTable = selectedItems;
                }
            }

            if (this.allContactsFromTable.status) {
                // all contacts from table selected
                const data = {
                    id: 'fromTable',
                    name: 'Selected From Table',
                    group: 'fromTable',
                    contactCount: 'All',
                    type: this.types.table,
                };
                if (this.excludedContactsFromTable.length) {
                    // all contacts selected, some deselected
                    data.contactCount = (
                        this.data.xTableData.rows.length -
                        this.excludedContactsFromTable.length
                    ).toString();
                }

                // push data
                this.contactsData.push(data);
                this.selectedContacts.push(data.id);
                this.selectedResults.push(data);
                this.selectedContactsData.push(data);
                this.prePopulate = [data];
            } else if (
                this.selectedContactsFromTable.length &&
                !this.sendMultipleInvites
            ) {
                // contacts are selected from table, sending single invitation

                const contactsToInvite = [];

                // add group to each item
                this.data.allContacts.map(
                    (contact) => (contact.group = 'Contacts')
                );
                this.selectedContactsFromTable.map((item) =>
                    this.data.allContacts.find((contact) => {
                        if (contact.contactId === item) {
                            contactsToInvite.push(contact);
                        }
                    })
                );
                // adds contact method
                contactsToInvite.forEach((contact) => {
                    contact.method = '| ' + contact.defaultEmailOrPhone;
                    contact.type = this.types.contact;
                });

                // push data
                contactsToInvite.map((item) => this.contactsData.push(item));
                contactsToInvite.map((item) =>
                    this.selectedContacts.push(item.contactId)
                );
                contactsToInvite.map((item) => this.selectedResults.push(item));
                contactsToInvite.map((item) =>
                    this.selectedContactsData.push(item)
                );
            }
        }

        this.fields = {
            isRequired: true,
            type: {
                options: [],
            },
        };
        this.fields.type.options = tempOptions;

        this.userForm = this.formBuilder.group({
            type: this.formBuilder.group({
                options: this.formBuilder.array([]),
            }),
        });

        this.patch();

        this.userForm.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
                this.fields.type.options = value.type.options;
            });
    }

    setPreselected() {
        const preSelected = [];

        if (this.prePopulate) {
            // We have something to work on
            if (Array.isArray(this.prePopulate)) {
                // This is just an array of contacts
                this.prePopulate.forEach((item) => {
                    item.group = 'Contacts';
                    item.name = item.name;
                    preSelected.push(item);
                });
                this.prePopulate.map((contact) => (contact.group = 'Contacts'));
            } else {
                // This is an object
                this.prePopulate.contactLabels.forEach((item) => {
                    const preSelectedLabel = {
                        name: item,
                        group: 'Labels',
                    };
                    preSelected.push(preSelectedLabel);
                });
                this.prePopulate.contactStatuses.forEach((item) => {
                    const preSelectedStatus = {
                        name: item,
                        group: 'Statuses',
                    };
                    preSelected.push(preSelectedStatus);
                });
                // we get contact count back here, but not for labels.
                this.prePopulate.contactFilters.forEach((item) => {
                    item.group = 'Filters';
                    preSelected.push(item);
                });
                this.prePopulate.contacts.forEach((item) => {
                    item.group = 'Contacts';
                    item.name = item.displayName;
                    preSelected.push(item);
                });
            }

            this.selectedResults = preSelected;
        }
    }

    patch() {
        const control = <FormArray>this.userForm.get('type.options');
        this.fields.type.options.forEach((x) => {
            control.push(
                this.formatValues(x.id, x.label, x.date, x.time, x.shareId)
            );
        });
    }

    formatValues(id, label, value, time, shareId) {
        return this.formBuilder.group({
            id: id,
            label: label,
            date: value,
            time: time,
            shareId: shareId,
        });
    }

    handleResultsUpdate(results) {
        this.selectedResults = results;
    }

    contactsSelected() {
        if (this.selectedResults) {
            return this.selectedResults.length;
        }
    }

    setSendTo() {
        const emails = [],
            phones = [],
            filters = [],
            labels = [],
            statuses = [],
            contacts = [];

        this.selectedResults.map((item, index) => {
            if (item.type === this.types.contact) {
                if (item.contactMethod) {
                    if (Utils.validateEmail(item.contactMethod)) {
                        emails.push(item.contactMethod);
                    } else {
                        phones.push(item.contactMethod);
                    }
                } else if (item.defaultEmailOrPhone) {
                    if (Utils.validateEmail(item.defaultEmailOrPhone)) {
                        emails.push(item.defaultEmailOrPhone);
                    } else {
                        phones.push(item.defaultEmailOrPhone);
                    }
                }

                if (item.key) {
                    contacts.push(item.key);
                } else if (item.contactId) {
                    contacts.push(item.contactId);
                } else if (item.id) {
                    contacts.push(item.id);
                }
            } else if (item.type === this.types.filter) {
                filters.push(item.key);
            } else if (item.type === this.types.label) {
                labels.push(item.name);
            } else if (item.type == this.types.status) {
                statuses.push(item.value)
            } else if (item.type === this.types.email) {
                emails.push(item.email);
            } else if (item.type === this.types.phone) {
                phones.push(item.phone);
            }
            return item;
        });

        this.sendTo = {
            emails: emails,
            phones: phones,
            filters: filters,
            labels: labels,
            statuses: statuses,
            contacts: contacts,
        };
    }

    getInviteData(shareId: number, sendDate: any, defaultSendDate: any) {
        return {
            shareId: shareId,
            message: this.message,
            emails: this.sendTo['emails'],
            phones: this.sendTo['phones'],
            contactLabels: this.sendTo['labels'],
            contactFilters: this.sendTo['filters'],
            contactStatuses: this.sendTo['statuses'],
            contacts: this.sendTo['contacts'],
            preferredContactMethod: this.contactMethod
                ? 'email'
                : 'phone',
            sendDate: sendDate ? sendDate : defaultSendDate,
        };
    }

    sendInvitations() {
        this.allInFuture = true;
        this.error = false;
        if (this.contactsSelected()) {
            const url =
                this.error && this.inviteData
                    ? `${this.apis.put}${this.invitationId}/`
                    : this.apis.post;

            this.setSendTo();

            if (this.sendMultipleInvites) {
                //resets isWithinFifteenMinutes
                this.isWithinFifteenMinutes = false;
                const tempInviteData = [];
                this.fields.type.options.forEach((item) => {
                    const timeObj = this.times.find(
                        (time) => time.display === item.time
                    );
                    const hours = timeObj.hours;
                    const minutes = timeObj.minutes;
                    const temp = moment(item.date);
                    const parsedDate = temp
                        .hours(hours)
                        .minutes(minutes)
                        .seconds(0);
                    const now = moment();
                    const parsedDateDiff = moment().diff(parsedDate);
                    const inFifteenMinutesDiff = moment().diff(
                        now.add(15, 'minutes')
                    );
                    if (parsedDateDiff > -1) {
                        this.allInFuture = false;
                    } else if (
                        parsedDateDiff > inFifteenMinutesDiff &&
                        parsedDateDiff < 0
                    ) {
                        this.isWithinFifteenMinutes = true;
                    } else {
                        tempInviteData.push(
                            this.getInviteData(parseInt(item.shareId, 10), parsedDate, 'now')
                        );
                    }
                });
                this.inviteData = tempInviteData;
            } else {
                if (this.singleTime.value) {
                    this.buildTimes();
                }
                this.inviteData = this.getInviteData(parseInt(this.shareId, 10), this.scheduledDate.value, 'now')

                const fromTable = this.selectedResults.some(
                    (contact) => contact.id === 'fromTable'
                );

                if (this.allContactsFromTable.status && fromTable) {
                    this.inviteData['analyticsFilter'] = {
                        requestUrl:
                            this.allContactsFromTable.analyticsFilterUrl,
                        count:
                            this.allContactsFromTable.analyticsFilterCount -
                            this.excludedContactsFromTable.length,
                        excludedIds: this.excludedContactsFromTable,
                    };
                }
            }

            if (!this.allInFuture) {
                this.appMessageService.show(
                    'app',
                    'error',
                    'Please select a date and time in the future.',
                    4
                );
                this.appLoadingIndicatorService.hide();
            } else if (
                this.isWithinFifteenMinutes &&
                this.showSchedulerInForm &&
                this.scheduledDate.value &&
                !this.sendMultipleInvites
            ) {
                this.appMessageService.show(
                    'app',
                    'error',
                    'Please select a time more than 15 minutes from now.',
                    4
                );
                this.appLoadingIndicatorService.hide();
            } else if (
                this.showSchedulerInForm &&
                !this.scheduledDate.value &&
                !this.sendMultipleInvites
            ) {
                this.appMessageService.show(
                    'app',
                    'error',
                    'Please select a date and time to schedule the invitation.',
                    4
                );
                this.appLoadingIndicatorService.hide();
            } else if (
                this.isWithinFifteenMinutes &&
                this.sendMultipleInvites
            ) {
                this.appMessageService.show(
                    'app',
                    'error',
                    'Please select a time more than 15 minutes from now.',
                    4
                );
                this.appLoadingIndicatorService.hide();
            } else {
                this.appLoadingIndicatorService.show('modal', '', () => {
                    this.appHttpService.request(
                        url,
                        this.inviteData,
                        (res) => {
                            this.data.callback();
                            if ('warnings' in res && res.warnings.length) {
                                this.invitationId = res.data.id;

                                // update counts
                                if (
                                    'contactFilterActualCounts' in
                                    res.warnings[0]
                                ) {
                                    res.warnings[0].contactFilterActualCounts.map(
                                        (item, index) => {
                                            const idx =
                                                this.selectedResults.findIndex(
                                                    (x) =>
                                                        x.key === item.id &&
                                                        x.type ===
                                                            this.types.filter
                                                );
                                            this.selectedResults[idx][
                                                'contactCount'
                                            ] = item.count;
                                        }
                                    );
                                }
                                if (
                                    'contactLabelActualCounts' in
                                    res.warnings[0]
                                ) {
                                    res.warnings[0].contactLabelActualCounts.map(
                                        (item, index) => {
                                            const idx =
                                                this.selectedResults.findIndex(
                                                    (x) =>
                                                        x.name === item.name &&
                                                        x.type ===
                                                            this.types.label
                                                );
                                            this.selectedResults[idx][
                                                'objectCount'
                                            ] = item.count;
                                        }
                                    );
                                }
                                if (
                                    'contactAnalyticsActualCounts' in
                                    res.warnings[0]
                                ) {
                                    const idx = this.selectedResults.findIndex(
                                        (x) => x.type === this.types.table
                                    );
                                    this.selectedResults[idx]['contactCount'] =
                                        res.warnings[0].contactAnalyticsActualCounts.count;
                                }

                                const selectedContacts =
                                    this.appHelpersService.getClone(
                                        this.selectedContacts
                                    );
                                this.selectedContacts = selectedContacts;

                                this.error = true;
                            } else {
                                this.dialogRef.close();

                                if (this.sendMultipleInvites) {
                                    this.appMessageService.show(
                                        'app',
                                        'success',
                                        this.messages.successScheduleMultiple,
                                        4
                                    );
                                } else if (this.inviteData.sendDate === 'now') {
                                    this.appMessageService.show(
                                        'app',
                                        'success',
                                        this.messages.success,
                                        4
                                    );
                                } else {
                                    this.appMessageService.show(
                                        'app',
                                        'success',
                                        this.messages.successSchedule,
                                        4
                                    );
                                }
                            }

                            this.appLoadingIndicatorService.hide();
                        },
                        (err) => {
                            this.appLoadingIndicatorService.hide();
                            this.appMessageService.show(
                                'modal',
                                'error',
                                this.messages.error,
                                4
                            );
                        }
                    );
                });
            }
            // }
        }
    }

    updateContactMethod(b: boolean) {
        this.contactMethod = b;
    }

    showScheduler() {
        this.showSchedulerInForm = true;
        this.isMenuOpen = false;
    }

    getScheduleAttribute(item) {
        const attr = 'item.scheduleDate' + item.id.toString();
        return attr;
    }

    cancelScheduling() {
        this.scheduledDate.setValue('now');
        this.singleTime.setValue(null);
        this.showSchedulerInForm = false;
    }

    updateInvitations() {
        this.buildTimes();
        this.setSendTo();

        this.updateData = this.getInviteData(
            parseInt(this.shareId, 10),
            this.scheduledDate.value,
            this.prePopulate.sendDate.split('.')[0] + '.000Z'
        );

        if (!this.allInFuture) {
            this.appMessageService.show(
                'app',
                'error',
                'Please select a date and time in the future.',
                4
            );
            this.appLoadingIndicatorService.hide();
        } else if (this.isWithinFifteenMinutes) {
            this.appMessageService.show(
                'app',
                'error',
                'Please select a time more than 15 minutes from now.',
                4
            );
            this.appLoadingIndicatorService.hide();
        } else {
            this.appHttpService.request(
                `${this.apis.put}${this.prePopulate.id}/`,
                this.updateData,
                (response) => {
                    this.data.callback();
                    this.dialogRef.close();
                    this.appMessageService.show(
                        'app',
                        'success',
                        this.messages.successEdit,
                        4
                    );
                },
                (err) => {
                    this.appMessageService.show(
                        'app',
                        'error',
                        this.messages.errorEdit,
                        4
                    );
                }
            );
        }
    }

    private buildTimes() {
        // formats the times for sending to the server
        const hours = this.singleTime.value.hours;
        const minutes = this.singleTime.value.minutes;
        let parsedDate;
        if (this.prePopulate) {
            // prepopulated data from edit action needs to be a moment obj to adjust hours/seconds
            const temp = moment(this.scheduledDate.value);
            parsedDate = temp.hours(hours).minutes(minutes);
            // updates the variable we send to the server
            this.scheduledDate.setValue(parsedDate);
        } else {
            parsedDate = this.scheduledDate.value.hours(hours).minutes(minutes);
        }

        const now = moment();
        const parsedDateDiff = moment().diff(parsedDate);
        const inFifteenMinutesDiff = moment().diff(now.add(15, 'minutes'));
        // if the time is set for within 15 minutes from now
        if (parsedDateDiff > inFifteenMinutesDiff && parsedDateDiff < 0) {
            this.isWithinFifteenMinutes = true;
        } else {
            this.isWithinFifteenMinutes = false;
        }

        if (moment().diff(parsedDate) > -1) {
            this.allInFuture = false;
        } else {
            this.allInFuture = true;
        }
    }

    private deconstructTimes(prePopulate: any) {
        // formats the times from server to display in UI
        const originalSendDate = new Date(prePopulate.sendDate);
        const asLocalTimeString = originalSendDate.toLocaleTimeString();
        const hour = asLocalTimeString.split(':')[0];
        const amPm = asLocalTimeString.split(' ')[1];
        const seconds = asLocalTimeString.split(':')[2].replace(amPm, '');

        const noSeconds = asLocalTimeString.replace(':' + seconds, ' ');

        // find value in times array, set to time used for build of datetime and display in UI
        this.singleTime.setValue(
            this.times.find((time) => time.display === noSeconds)
        );
    }

    setQuickScheduleStartDate() {
        const today = new Date(this.quickScheduleDate.value);
        const tempOptions = [];

        const timeObj = this.times.find(
            (time) => time.display === this.quickScheduleTime.value
        );

        if (this.quickScheduleInterval.value === 'daily') {
            this.selectedFromList.map((item, i) => {
                item.scheduledDate = addDays(today, i);
                item.scheduledDate.setHours(timeObj.hours);
                item.scheduledDate.setMinutes(timeObj.minutes);
                item.time = timeObj.display;
            });
        } else if (this.quickScheduleInterval.value === 'weekly') {
            this.selectedFromList.map((item, i) => {
                item.scheduledDate = addWeeks(today, i);
                item.scheduledDate.setHours(timeObj.hours);
                item.scheduledDate.setMinutes(timeObj.minutes);
                item.time = timeObj.display;
            });
        } else {
            this.selectedFromList.map((item, i) => {
                item.scheduledDate = addMonths(today, i);
                item.scheduledDate.setHours(timeObj.hours);
                item.scheduledDate.setMinutes(timeObj.minutes);
                item.time = timeObj.display;
            });
        }

        this.selectedFromList.map(function (selected) {
            tempOptions.push({
                id: selected.id,
                label: selected.title,
                date: selected.scheduledDate,
                time: selected.time,
                shareId: selected.share.id,
            });
        });
        this.fields = {
            isRequired: true,
            type: {
                options: [],
            },
        };
        this.fields.type.options = tempOptions;
        this.userForm.get('type.options').setValue(this.fields.type.options);
    }

    removeFromSelected(i: any) {
        const control = <FormArray>this.userForm.get('type.options');
        control.removeAt(i);
        if (!control.length) {
            this.dialogRef.close();
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.fields.type.options,
            event.previousIndex,
            event.currentIndex
        );
        moveItemInArray(
            this.selectedFromList,
            event.previousIndex,
            event.currentIndex
        );
        this.userForm.get('type.options').setValue(this.fields.type.options);
    }
}
