<div class="app-dialog dialog-edit-contact">
    <div class="header">Edit Contact</div>

    <form (ngSubmit)="save()" #editContactForm="ngForm">
        <div class="body">
            <div class="app-tabs">
                <div *ngFor="let tab of tabs" [class]="getTabClass(tab.name)">
                    <div class="text" (click)="selectTab(tab.name)">
                        {{ tab.label }}
                    </div>
                </div>
            </div>

            <div class="app-tabs-container">
                <!--Profile-->
                <div [class]="getTabContainerClass('profile')">
                    <!--Name-->
                    <app-form-field
                        type="text"
                        label="Name"
                        name="name"
                        placeholder="Full name..."
                        maxlength="75"
                        [required]="false"
                        [(ngModel)]="editContact.name"
                    >
                    </app-form-field>
                    <div class="row">
                        <div class="column">
                            <!--Emails-->
                            <app-form-field
                                type="textarea"
                                label="Emails"
                                name="emails"
                                placeholder="Add emails, one on each line..."
                                [required]="false"
                                [(ngModel)]="editContact.emails"
                                [hasError]="!isValid.emails"
                                errorText="One of the emails is invalid or duplicated."
                            >
                            </app-form-field>
                        </div>
                        <div class="column">
                            <!--Phones-->
                            <app-form-field
                                type="textarea"
                                label="Phones"
                                name="phones"
                                placeholder="Add phones, one on each line..."
                                [required]="false"
                                [(ngModel)]="editContact.phones"
                                [hasError]="!isValid.phones"
                                errorText="One of the phones is invalid or duplicated."
                            >
                            </app-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="column checkbox">
                            <!--Do not contact-->
                            <app-form-checkbox
                                label="Do Not Contact"
                                name="blacklist"
                                [(value)]="editContact.blacklist"
                            >
                            </app-form-checkbox>
                            <i class="material-icons icon tooltip"
                                >speaker_notes_off</i
                            >
                        </div>
                    </div>
                </div>

                <!--Details-->
                <div [class]="getTabContainerClass('details')">
                    <!--Job title-->
                    <app-form-field
                        type="text"
                        label="Job Title"
                        name="title"
                        placeholder="Job Title..."
                        maxlength="75"
                        [required]="false"
                        [(ngModel)]="editContact.title"
                    >
                    </app-form-field>
                    <!--Start Date-->
                    <app-form-field
                        type="date"
                        label="Start Date"
                        name="hireDate"
                        placeholder="MM/DD/YYYY"
                        [required]="false"
                        [(ngModel)]="editContact.hireDate"
                    >
                    </app-form-field>
                    <!--Separation Date-->
                    <app-form-field
                        type="date"
                        label="Separation Date"
                        name="separationDate"
                        placeholder="MM/DD/YYYY"
                        [required]="false"
                        [(ngModel)]="editContact.separationDate"
                    >
                    </app-form-field>
                    <!--Internal ID-->
                    <app-form-field
                        type="text"
                        label="Internal ID"
                        name="internalId"
                        placeholder="Internal ID..."
                        maxlength="75"
                        [required]="false"
                        [hasError]="!isValid.internalId"
                        [(ngModel)]="editContact.internalId"
                    >
                    </app-form-field>
                </div>

                <!--Avatar-->
                <div [class]="getTabContainerClass('avatar')">
                    <app-avatar-cropper
                        *ngIf="avatarTabHasBeenSelected"
                        type="edit"
                        dimensions="429x322"
                        name="contactFile"
                        cancelBtnLbl="Back"
                        saveBtnLbl="Set"
                        [replaceable]="false"
                        [showActions]="false"
                        [originalAvatar]="originalAvatar"
                        [originalAvatarUrl]="originalAvatarUrl"
                        (updateImage)="updateImage($event)"
                    >
                    </app-avatar-cropper>
                </div>
            </div>
        </div>

        <div class="footer">
            <button
                mat-dialog-close
                class="app-button light w-90 mr-1"
                (click)="close()"
            >
                Cancel
            </button>
            <button
                type="submit"
                class="app-button green w-90"
                [disabled]="!editContactForm.form.valid || !allFieldsValid()"
            >
                Save
            </button>
        </div>
    </form>
</div>
