<div class="app-dialog dialog-create-components">
    <div class="header">Selected Files</div>

    <div class="body">
        <ngx-file-drop
            class="file-drag-drop {{
                componentFilesDropped ? 'file-over' : ''
            }}"
            dropZoneClassName="file-dropper-area"
            (onFileDrop)="componentFilesDropped($event)"
            (onFileOver)="componentsIsFileOver = true"
            (onFileLeave)="componentsIsFileOver = false"
        >
            <ng-template
                ngx-file-drop-content-tmp
                let-openFileSelector="openFileSelector"
            >
                <div class="">
                    <!--                    <div class="file-list">-->
                    <!--                        &lt;!&ndash;Title&ndash;&gt;-->
                    <div class="file-list-title tac">
                        <div class="sub">
                            Drag-and-drop or browse to select additional files.
                        </div>
                        <div class="browse mt-1">
                            <input
                                type="file"
                                style="margin-left: 75px"
                                #selectFileField
                                (change)="selectFiles($event)"
                                multiple
                            />
                        </div>
                    </div>
                    <!--                    </div>-->
                </div>
            </ng-template>
        </ngx-file-drop>

        <!--List-->
        <div id="file-list" class="file-list-file-container">
            <div
                class="file-list-file"
                *ngFor="let componentFile of componentFiles"
            >
                <div class="file-list-item">
                    <!--                    <div class="type">-->
                    <i class="type_icon material-icons icon">
                        {{ icons[componentFile.type] }}
                    </i>
                    <!--                    </div>-->
                    <div class="name" [title]="componentFile.name">
                        {{ componentFile.name }}
                    </div>
                    <div class="size">
                        {{ componentFile.size }}
                    </div>
                    <div class="status">
                        <!--Loading-->
                        <div
                            class="loading-indicator"
                            *ngIf="
                                componentFile.status === 1 &&
                                componentFile.valid
                            "
                        >
                            <img src="/assets/images/loading-indicator.gif" />
                        </div>

                        <!--Complete-->
                        <div
                            class="check"
                            *ngIf="
                                componentFile.status === 2 &&
                                componentFile.valid
                            "
                        >
                            <i class="material-icons icon">check</i>
                        </div>

                        <!--Error-->
                        <div
                            class="upload-error"
                            *ngIf="
                                componentFile.status === 3 &&
                                componentFile.valid
                            "
                        >
                            <span
                                class="text"
                                title="File upload failed. Click the 'Retry' button to upload the file again."
                            >
                                Error
                            </span>

                            <i
                                class="material-icons icon"
                                title="Retry file upload."
                                (click)="retry(componentFile.id)"
                                >refresh</i
                            >
                        </div>

                        <!--Wrong File-->
                        <div class="upload-error" *ngIf="!componentFile.valid">
                            <span
                                class="text"
                                title="This file was discarded because of missing or invalid extension."
                            >
                                Invalid
                            </span>
                        </div>
                    </div>

                    <div class="delete">
                        <i
                            class="material-icons icon"
                            title="Remove file upload."
                            (click)="remove(componentFile.id)"
                            >cancel</i
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer">
        <button class="app-button light w-90 mr-1" (click)="cancel()">
            Cancel
        </button>
        <button
            class="app-button green w-90"
            (click)="create()"
            [disabled]="!shouldEnableCreateComponents()"
        >
            Create Components
        </button>
    </div>
</div>
