<div class="selected-contact-analytics">
    <div class="notice" *ngIf="!orgHasAccess">
        Your organization has limited access to this feature. You'll need to upgrade to create assignments. To learn more, contact:  <a href="mailto:sales@vingapp.com">sales@vingapp.com</a>
    </div>
    <!--Chart-->
    <div id="contacts-analytics-chart" *ngIf="analytics">
        <!--If chart has data to display-->
        <div
            id="standard-doughnut-chart"
            class="selected-view-chart-inner"
        ></div>

        <!--If chart has no data to display-->
        <div class="no-chart-data" *ngIf="displayChart === false"></div>

        <div class="chart-center-info">
            <div class="center-info-label">
                {{ analytics.stats.total }}
            </div>
            <div class="center-info-value">
                {{ entityName | pluralize: analytics.stats.total }}
            </div>
        </div>
        <div class="chart-key">
            <div
                *ngFor="let item of chartKeyItems"
                [class]="'chart-key-item ' + item.name"
            >
                <div class="item-color">
                    <div class="color-box" style="background-color: {{ item.color }};"></div>
                </div>
                <div class="item-label">
                    {{ item.label }}
                </div>
                <div class="item-value">
                    {{ analytics.stats[item.name] }}
                </div>
            </div>
        </div>
    </div>

    <!--Vings Table-->
    <div class="contacts-analytics-table">
        <app-x-table
            *ngIf="isTableReady"
            [page]="'CONTACT-ASSIGNMENTS'"
            [id]="contact.id"
            [results]="results"
            [contactName]="contact.name"
            [canSelectRows]="true"
            [microburst]="true"
            [xTableData]="xTableData"
            [xTableFilters]="xTableFilters"
            [analyticsData]="analyticsFilterData"
            [numberOfPages]="numberOfPages"
            [numberOfResults]="numberOfResults"
            [numbersPerPage]="currentPageSize"
            [currentPageIndex]="currentPageIndex"
            [selectedRows]="selectedVings"
            [excludedRows]="excludedVings"
            [selectedAll]="selectedAllVings"
            [entityName]="entityName"
            [noDataText]="noDataText"
            [infoLink]="infoLink"
            [searchDelayMs]="searchDelayMs"
            [updatePaging]="updatePaging.asObservable()"
            (handleSearchTextChange)="handleSearchTextChange($event)"
            (handleFiltersChange)="handleFiltersChange($event)"
            (handlePageChange)="handlePageChange($event)"
            (handleSelectedRows)="handleSelectedRows($event)"
            (handleExcludedRows)="handleExcludedRows($event)"
            (handleUpdateUserTables)="initializeAnalytics(null, true)"
            (handleSelectedAllRows)="handleSelectedAllRows($event)"
            (export)="export()"
        >
        </app-x-table>
    </div>
</div>
