import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { NgForm } from '@angular/forms';
import { AppHttpService } from '../../../../shared/services/app.http.service';

@Component({
    templateUrl: './app.dialog-reset-password.component.html',
})
export class AppDialogResetPasswordComponent implements OnInit {
    private messages = {
        success: {
            password: 'Password successfully updated.',
        },
        error: {
            password:
                'There was an error updating the password. Please try again.',
        },
    };
    title = 'Reset Password';
    newPassword = '';
    confirmPassword = '';

    constructor(
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appHttpService: AppHttpService,
        public dialogRef: MatDialogRef<AppDialogResetPasswordComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            items: Array<object>;
            owner: any;
            callback: Function;
        }
    ) {}

    ngOnInit() {
        // Initialize the items data into something that can be used within the UI.
        if (this.data.title) {
            this.title = this.data.title;
        }

        this.appLoadingIndicatorService.hide(350);
    }
    handlePasswords(passwordForm: NgForm): void {
        if (passwordForm.invalid) {
            return;
        }

        this.appLoadingIndicatorService.show('view');

        const data = {
            new_password: passwordForm.value.newPassword,
            re_new_password: passwordForm.value.confirmPassword,
        };
        this.appHttpService.request(
            'POST:api/auth/password/',
            data,
            () => {
                passwordForm.resetForm();
                this.appMessageService.show(
                    'app',
                    'success',
                    this.messages.success.password,
                    3
                );
                this.appLoadingIndicatorService.hide(200);
                this.dialogRef.close();
            },
            () => {
                this.appMessageService.show(
                    'view',
                    'error',
                    this.messages.error.password,
                    4.0
                );
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }
}
