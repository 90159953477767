import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PacketComponent } from '../../../vings/classes/packet-component';

@Component({
    selector: 'app-component-index',
    templateUrl: './component-index.html',
    styleUrls: ['./component-index.component.less'],
})
export class ComponentIndexComponent {
    icons = {
        image: 'photo',
        file: 'insert_drive_file',
        document: 'content_copy',
        other: 'insert_drive_file',
        text: 'title',
        link: 'link',
        audio: 'volume_up',
        video: 'videocam',
        questions: 'assignment',
    };

    @Input() isPreview: boolean;
    @Input() selectedComponentId: number;
    @Input() components: Array<PacketComponent>;

    @Output() selectComponent = new EventEmitter();

    handleClickComponent(component: PacketComponent): void {
        if (component.locked !== true) {
            this.setSelectedComponent(component);
        }
    }

    hasThumbnail(type: string) {
        return type === 'image' || type === 'video';
    }

    shouldDisplayLocked(component: PacketComponent): boolean {
        let shouldDisplay = false;

        if (!this.isPreview && component.locked === true) {
            shouldDisplay = true;
        }

        return shouldDisplay;
    }

    getComponentClass(component: PacketComponent): string {
        let css = 'component';

        if (component.locked === true) {
            css += ' locked';
        }

        if (component.id === this.selectedComponentId) {
            css += ' selected';
        }

        return css;
    }

    getProgress(component: PacketComponent): number {
        let val = 0;
        if (component.progress) {
            val = component.progress * 100;
        }
        return Math.round(val);
    }

    private setSelectedComponent(component: PacketComponent): void {
        this.selectedComponentId = component.id;
        this.selectComponent.emit(component.id);
    }
}
