import { Injectable } from '@angular/core';
import { Job } from './job';

@Injectable()
export class AppJobService {
    private defaultInterval = 60000; // default: 60000 ms (one minute).
    private minInterval = 200;
    private nameMinLength = 3;
    private jobs = {}; // k,v pair of job names and Job objects.

    create(
        name: string,
        work: Function,
        interval: number = this.defaultInterval
    ) {
        // Validate that 'name' is unique and at least 'nameMinLength' characters.
        // Validate that 'work' is of type function.
        // Validate that the 'interval' is a number >= 'minInterval'.
        const paramsAreValid =
            this.isNameValid(name) &&
            this.isWorkValid(work) &&
            this.isIntervalValid(interval);

        // If params are valid, create and start the new job.
        if (paramsAreValid) {
            this.start(new Job(name, work, interval));
        } else {
            console.log('ERROR :: Attempted to create an invalid job.');
        }
    }

    kill(name: string) {
        // Ensure that name provided is that of an existing job.
        if (this.isExistingJob(name)) {
            clearInterval(this.jobs[name]);
            delete this.jobs[name];
            console.log('JOB KILLED :: ' + name);
        } else {
            console.log('ERROR :: Specified job name does not exist.');
        }
    }

    killAll() {
        Object.keys(this.jobs).forEach((jobName) => {
            this.kill(jobName);
        });
    }

    private start(job: Job) {
        job.dateStart = new Date();

        this.jobs[job.name] = setInterval(() => {
            job.work();
            job.dateLastRun = new Date();
            job.countTimesRun += 1;
        }, job.interval);
    }

    private isNameValid(name) {
        const isString = typeof name === 'string',
            isUnique = !(name in this.jobs),
            isLongEnough = name.length >= this.nameMinLength;

        return isString && isUnique && isLongEnough;
    }

    private isWorkValid(work) {
        return typeof work === 'function';
    }

    private isIntervalValid(interval) {
        const isNumber = typeof interval === 'number',
            isLongEnough = interval >= this.minInterval;

        return isNumber && isLongEnough;
    }

    private isExistingJob(name) {
        const isString = typeof name === 'string',
            nameExists = Object.keys(this.jobs).indexOf(name) >= 0;

        return isString && nameExists;
    }
}
