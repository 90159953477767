<div
    *ngFor="let component of components"
    [class]="getComponentClass(component)"
    (click)="handleClickComponent(component)"
>
    <!--Type-->
    <div class="type">
        <i class="material-icons icon">{{icons[component.type]}}</i>
    </div>

    <!--Title-->
    <div class="title">{{component.title}}</div>

    <!--Status-->
    <div class="status">
        <i
            class="material-icons icon progress"
            *ngIf="component.completed === true"
            >check</i
        >
        <i class="material-icons icon" *ngIf="shouldDisplayLocked(component)"
            >lock</i
        >
        <span class="progress" *ngIf="component.completed === false"
            >{{getProgress(component)}}%</span
        >
    </div>
</div>
