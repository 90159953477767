export class QuestionValidations {
    questionText = {
        min: undefined,
        max: undefined,
    };

    answerText = {
        min: undefined,
        max: undefined,
    };

    constructor(qMin: number, qMax: number, aMin: number, aMax: number) {
        this.questionText.min = qMin;
        this.questionText.max = qMax;
        this.answerText.min = aMin;
        this.answerText.max = aMax;
    }
}
