import { Component, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Chart } from 'chart.js';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import * as fileSaver from 'file-saver';

import { AppSelectOption } from '../../../../shared/components/app-select/classes/app.select.option';
import {
    XTable,
    XtCell,
    XtHeader,
    XtRow,
} from '../../../../shared/components/app-power-table/classes/XTable';
import { XtFilter } from '../../../../shared/components/app-power-table/classes/XTableFilter';
import { columnWidths } from '../../../../shared/components/app-power-table/defaults';
import { Analytics } from '../../../vings/components/detail/classes/analytics';
import { AnalyticsLabel } from '../../../../shared/classes/analytics/analytics.label';
import { ColumnAlignEnum } from '../../../../shared/classes/enums/column.align.enum';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppUserService } from '../../../../shared/services/app.user.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppInfoService } from '../../../../shared/services/app-info/app.info.service';
import { AppDialogAcknowledgeComponent } from '../../../../shared/dialogs/dialog-acknowledge/app.dialog-acknowledge.component';
import { Utils } from '../../../../shared/app.utils';
import { AnalyticsFilters } from '../../../../shared/classes/analytics/analytics.filters';

@Component({
    selector: 'app-selected-contact-assignments',
    templateUrl: './assignments.component.html',
    styleUrls: ['./assignments.component.less'],
})
export class ContactAssignmentsComponent implements OnInit, OnDestroy {
    orgHasAccess: boolean;
    displayChart: boolean;
    analytics: Analytics = null;
    selectors = {
        analytics: '.details-and-analytics .analytics',
        tableBody: '.contacts-analytics-table .power-table-body',
    };
    chartKeyItems = [
        { name: 'completed', label: 'Completed', color: this.appInfoService.getAppInfo().colorsInfo.analyticsChart.completed },
        { name: 'viewed', label: 'Viewed', color: this.appInfoService.getAppInfo().colorsInfo.analyticsChart.viewed },
        { name: 'unviewed', label: 'Not Viewed', color: this.appInfoService.getAppInfo().colorsInfo.analyticsChart.unviewed },
    ];
    labels: Array<any> = [];
    updatePaging = new Subject();
    entityName = 'assignment';
    noDataText: string;
    infoLink: string;

    /*XTable*/
    isTableReady = false;
    xTableData: XTable;
    xTableFilters: Array<XtFilter> = [];
    numberOfPages: number;
    numberOfResults: number;
    currentPageIndex: number;
    currentPageSize: number;
    selectedVings: Array<number> = [];
    excludedVings: Array<number> = [];
    selectedAllVings = false;
    results: any;
    analyticsFilterData: {};

    searchDelayMs: number;
    contactSubscription: any;
    name: string;

    private searchText: string;
    private selected = new AnalyticsFilters(null, null, null);
    private contact: any;
    private analyticsInterval = null;

    @Input() selectNewContactAssignments: Observable<any>;

    constructor(
        @Inject('window') private window,
        @Inject('location') private location,
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appInfoService: AppInfoService,
        private appUserService: AppUserService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.orgHasAccess = this.appUserService.hasPacketsAccess()
        const appInfo = this.appInfoService.getAppInfo();
        this.currentPageSize =
            appInfo.analyticsInfo.contactAssignmentsAnalyticsInfo.pageSize;
        this.searchDelayMs =
            appInfo.analyticsInfo.contactAssignmentsAnalyticsInfo.searchDelayMs;
        this.infoLink = appInfo.analyticsInfo.contactAssignmentsAnalyticsInfo.infoLink;
        this.noDataText = appInfo.analyticsInfo.contactAssignmentsAnalyticsInfo.noDataText;

        this.contactSubscription = this.selectNewContactAssignments.subscribe(
            (newContact: any) => {
                this.currentPageIndex = 0;
                this.isTableReady = false;
                this.analytics = null;
                this.labels = [];
                this.xTableFilters = [];
                this.searchText = '';
                this.selectedVings = [];
                this.excludedVings = [];
                this.selectedAllVings = false;

                this.initializeAnalytics(newContact);
            }
        );

        const pageRefreshMs = appInfo.contactsInfo.pageRefreshMs;

        this.analyticsInterval = setInterval(() => {
            if (this.contact && document.hasFocus()) {
                this.appLoadingIndicatorService.show(
                    this.selectors.tableBody,
                    '',
                    () => {
                        if (this.contact) {
                            this.initializeAnalytics(null, false);
                        } else {
                            this.appLoadingIndicatorService.hide(350);
                        }
                    }
                );
            }
        }, pageRefreshMs);
    }

    ngOnDestroy() {
        this.contactSubscription.unsubscribe();

        if (this.analyticsInterval) {
            clearInterval(this.analyticsInterval);
        }
    }

    // XTable
    handleSearchTextChange(searchText): void {
        this.appLoadingIndicatorService.show(this.selectors.tableBody);
        this.searchText = searchText;
        // Init analytics table page.
        this.currentPageIndex = 0;
        this.initializeAnalytics();
    }

    handleFiltersChange(update): void {
        const currentFilter = this.selected[update.filterName],
            newFilter = update.newFilterValue,
            type = Array.isArray(currentFilter) ? 'multi' : 'single';

        if (
            (type === 'single' &&
                this.selectedFilterHasChanged(currentFilter, newFilter)) ||
            (type === 'multi' &&
                this.selectedFiltersHaveChanged(currentFilter, newFilter))
        ) {
            this.appLoadingIndicatorService.show(this.selectors.tableBody);

            if (type === 'single') {
                this.selected[update.filterName] = update.newFilterValue;
            } else if (type === 'multi') {
                this.selected[update.filterName] =
                    this.appHelpersService.getClone(update.newFilterValue);
            }

            // Init analytics table page.
            this.currentPageIndex = 0;
            this.initializeAnalytics();
        }
    }

    handlePageChange(currentPageIndex): void {
        this.appLoadingIndicatorService.show(this.selectors.tableBody);
        this.currentPageIndex = currentPageIndex;
        this.initializeAnalytics(null, false);
    }

    handleSelectedRows(selectedRows): void {
        this.selectedVings = selectedRows;
    }

    handleExcludedRows(excludedRows): void {
        this.excludedVings = excludedRows;
    }

    handleSelectedAllRows(allSelected): void {
        this.selectedAllVings = allSelected;
    }

    export(): void {
        this.appLoadingIndicatorService.show(this.selectors.tableBody);
        this.exportData();
    }

    private initializeAnalytics(
        contact?: any,
        shouldInitPagingData = true
    ): void {
        if (contact) {
            this.contact = contact;
        }
        this.appLoadingIndicatorService.show(
            this.selectors.analytics,
            '',
            () => {
                const data = this.buildAnalyticsData(),
                    url = `GET:api/data/assignments/contact/${this.contact.id}/`;

                // HTTP call to archive the contact.
                this.appHttpService.request(url, data, (response) => {
                    if (shouldInitPagingData) {
                        this.currentPageIndex = 0;
                        this.results = response;
                        this.numberOfResults = response.data.count;
                        this.numberOfPages = Math.ceil(
                            this.numberOfResults / this.currentPageSize
                        );
                        setTimeout(() => {
                            this.updatePaging.next();
                        }, 10);
                    }

                    this.analytics = response;

                    if (contact) {
                        this.name = this.contact.name;
                        // Initialize labels, table, & filters.
                        this.labels = this.buildLabels(this.analytics.labels);
                        this.initXFilters();
                        this.initTable();
                    } else {
                        // Update table.
                        this.xTableData.rows = this.initRows(
                            response.data.results
                        );
                    }

                    // Initialize chart.
                    this.setChartData();

                    // Redraw chart.
                    this.setChartData();
                });
            }
        );
    }

    private initXFilters(): void {
        // Init above-table drop-downs.
        const appInfo = this.appInfoService.getAppInfo(),
            filtersOptions = this.buildOptions(
                appInfo.analyticsInfo.contactAssignmentsAnalyticsInfo.filters
            ),
            sortsData = appInfo.analyticsInfo.contactAssignmentsAnalyticsInfo.sorts,
            sortsOptions = this.buildOptions(sortsData);
        // Clear previous table filters list.
        this.xTableFilters = [];

        // Filters
        this.xTableFilters.push(
            new XtFilter(
                'multi',
                'Filters',
                'filters',
                115,
                79,
                filtersOptions,
                null,
                true
            )
        );

        // Labels
        this.xTableFilters.push(
            new XtFilter(
                'multi',
                'Labels',
                'labels',
                115,
                105,
                this.labels,
                null,
                true
            )
        );

        // Sorts
        this.xTableFilters.push(
            new XtFilter(
                'single',
                'Sort',
                'sort',
                115,
                105,
                sortsOptions,
                this.getSelectedSorts(sortsData),
                false
            )
        );
    }

    private initTable() {
        const packets = this.analytics.data.results,
            rows: Array<XtRow> = this.initRows(packets),
            headers: Array<XtHeader> = [
                new XtHeader('', columnWidths['smallIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('', columnWidths['smallIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('Title', columnWidths['name']),
                new XtHeader('', columnWidths['bigIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('', columnWidths['bigIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('Assigned', columnWidths['date'], ColumnAlignEnum.CENTER),
                new XtHeader('Last Invited', columnWidths['date'], ColumnAlignEnum.CENTER, 'hide-narrow-window'),
                new XtHeader('Opens', columnWidths['visits'], ColumnAlignEnum.CENTER, 'hide-narrow-window'),
                new XtHeader('Visits', columnWidths['visits'], ColumnAlignEnum.CENTER, 'hide-narrow-window'),
                new XtHeader('Due', columnWidths['date'], ColumnAlignEnum.CENTER),
                new XtHeader('Viewed', columnWidths['viewed'], ColumnAlignEnum.CENTER),
                new XtHeader('Progress', columnWidths['progress'], ColumnAlignEnum.CENTER),
                new XtHeader('Score', columnWidths['score'], ColumnAlignEnum.CENTER),
                new XtHeader('Completed', columnWidths['date'], ColumnAlignEnum.CENTER),
            ];

        this.xTableData = new XTable(headers, rows);

        setTimeout(() => {
            this.isTableReady = true;
        }, 100);
    }

    private initRows(assignments): Array<XtRow> {
        const rows: Array<XtRow> = [];

        assignments.forEach((assignment) => {
            const isViewSet = !this.appHelpersService.isEmptyObject(
                    assignment.packetProgress
                ),
                columnTwoIcon =
                    isViewSet && assignment.packetProgress.verified ? 'verified_user' : '',
                dateAssignedDate = this.getDisplayDate(
                    assignment.dateAssigned
                ),
                dateAssignedTime = this.getDisplayTime(
                    assignment.dateAssigned
                ),
                dateDueDate = this.getDisplayDate(
                    assignment.dateDue
                ),
                dateDueTime = this.getDisplayTime(
                    assignment.dateDue
                ),
                lastInvitedDate = this.getDisplayDate(
                    assignment.lastInvited
                ),
                lastInvitedTime = this.getDisplayTime(
                    assignment.lastInvited
                ),
                dateCompleted = isViewSet
                    ? this.getDisplayDate(assignment.packetProgress.completedDate)
                    : '',
                dateCompletedTime = isViewSet
                    ? this.getDisplayTime(assignment.packetProgress.completedDate)
                    : '',
                opensCount = assignment.lastInvited ? assignment.opensCount : '--',
                visitsCount = isViewSet ? assignment.packetProgress.visitsCount : '',
                viewedDuration = isViewSet
                    ? Utils.getDurationCompleteValue(assignment.packetProgress.isComplete, assignment.packetProgress.durationComplete, assignment.packet.duration)
                    : '--',
                scoreColor =
                    isViewSet &&
                    assignment.packetProgress.score !== null &&
                    assignment.packetProgress.score >= 0
                        ? this.appHelpersService.getScoresColor(
                              assignment.packetProgress.score
                          )
                        : null,
                score =
                    isViewSet &&
                    assignment.packetProgress.score !== null &&
                    assignment.packetProgress.score >= 0
                        ? `${(assignment.packetProgress.score * 100).toFixed(0)}%`
                        : '--',
                isComplete = isViewSet ? assignment.packetProgress.isComplete : false,
                lastInvitationMethod = assignment.lastInvitationMethod;

            const progress = isViewSet ? assignment.packetProgress.progress : 0;
            let columnThreeIcon = '',
                columnThreeError = null,
                progressCell: XtCell;

            if (assignment.invitationFailed) {

            }

            switch (lastInvitationMethod) {
                case 'email':
                    columnThreeIcon = 'email';
                    if (assignment.invitationFailed) {
                        columnThreeError = 'Last invitation undeliverable'
                    }
                    break;
                case 'phone':
                    columnThreeIcon = 'sms';
                    if (assignment.invitationFailed) {
                        columnThreeError = 'Last invitation undeliverable'
                    }
                    break;
                case null:
                    if (assignment.invitationFailed) {
                        columnThreeIcon = 'email';
                        columnThreeError = 'No active contact methods'
                    } else {
                        columnThreeIcon = 'open_in_new';
                    }
                    break;
            }

            // Set progress cell.
            if (isComplete) {
                progressCell = new XtCell(
                    'progress',
                    null,
                    null,
                    null,
                    null,
                    null,
                    true
                );
            } else {
                progressCell = new XtCell(
                    'progress',
                    null,
                    null,
                    null,
                    null,
                    this.getProgressValue(progress)
                );
            }

            const cells = [
                new XtCell(null, null, null, null, columnTwoIcon),
                new XtCell(null, null, null, null, columnThreeIcon, null, null, columnThreeError),
                new XtCell(
                    'title',
                    assignment.packet.title,
                    null,
                    this.getAssignmentContactMethod(assignment)
                ),
                new XtCell('sentiment', null, null, null, assignment.sentiment.icon, null, null, null, null, assignment.sentiment.color),
                new XtCell('pdf'),
                new XtCell('dateAssigned', dateAssignedDate, null, dateAssignedTime),
                new XtCell('lastInvited', lastInvitedDate, 'hide-narrow-window', lastInvitedTime),
                new XtCell('opens', opensCount, 'hide-narrow-window'),
                new XtCell('visits', visitsCount, 'hide-narrow-window'),
                new XtCell('dateDue', dateDueDate, null, dateDueTime),
                new XtCell('viewedDuration', viewedDuration),
                progressCell,
                new XtCell(
                    'score',
                    score,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    scoreColor
                ),
                new XtCell('dateCompleted', dateCompleted, null, dateCompletedTime),
            ];

            rows.push(new XtRow(assignment.packet.packetId, cells, true));
        });

        return rows;
    }

    private exportData(): void {
        const data = this.buildAnalyticsData(false);
        data['export'] = true;
        data['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

        this.appHttpService.request(
            `GET:api/data/assignments/contact/${this.contact.id}/`,
            data,
            (response) => {
                this.appLoadingIndicatorService.hide();

                this.dialog.open(AppDialogAcknowledgeComponent, {
                    width: '425px',
                    disableClose: true,
                    data: {
                        title: 'Data Export',
                        text: 'Your requested export is being generated and you will receive it via email soon!',
                    },
                });
            }
        );
    }

    private buildOptions(list): Array<AppSelectOption> {
        const options: Array<AppSelectOption> = [];

        list.forEach((item) => {
            options.push(new AppSelectOption(item.name, item.value));
        });

        return options;
    }

    private buildLabels(
        labels: Array<AnalyticsLabel>
    ): Array<AppSelectOption> {
        const contactLabels: Array<AppSelectOption> = [];

        if (labels !== undefined) {
            labels.forEach((label) => {
                contactLabels.push(
                    new AppSelectOption(label.text, label.id.toString())
                );
            });
        }

        return contactLabels;
    }

    private getSelectedSorts(list): Array<AppSelectOption> {
        const options: Array<AppSelectOption> = [];

        const selectedItem = list.find((filter) => filter.selected === true);
        options.push(
            new AppSelectOption(selectedItem.name, selectedItem.value)
        );

        return options;
    }

    private selectedFilterHasChanged(
        currentFilter: AppSelectOption,
        newFilter: AppSelectOption
    ): boolean {
        const currentValue = currentFilter ? currentFilter.value : undefined,
            newValue = newFilter ? newFilter.value : undefined;

        return currentValue !== newValue;
    }

    private selectedFiltersHaveChanged(
        currentFilter: Array<AppSelectOption>,
        newFilter: Array<AppSelectOption>
    ): boolean {
        let hasChanged = false;

        // If the lengths of the new and old filters have changed, this is a sign
        // that the selected values have changed.
        if (currentFilter.length !== newFilter.length) {
            hasChanged = true;
        } else {
            // Build the list of current and new values joined into a concatenated
            // strings. This allows us to compare the current and new values in
            // subsequent steps.
            const currentValues = currentFilter
                    .map((filter) => filter.value)
                    .sort()
                    .join(''),
                newValues = newFilter
                    .map((filter) => filter.value)
                    .sort()
                    .join('');

            // If the lengths of the string values of the new and old filters have
            // changed or if the string values representation are different then this
            // tells us that the values have changed.
            if (
                currentValues.length !== newValues.length ||
                currentValues !== newValues
            ) {
                hasChanged = true;
            }
        }

        return hasChanged;
    }

    private buildAnalyticsData(limit = true): object {
        const data = {};

        // Limit
        if (limit) {
            data['limit'] = this.currentPageSize;
        }

        // Query search text.
        if (this.searchText && this.searchText.length > 0) {
            const filterText = Utils.trim(this.searchText);
            data['packetQuery'] = filterText;
        }

        // Conditionally set the offset.
        if (this.currentPageIndex + 1 > 0 && limit) {
            data['offset'] = this.currentPageIndex * this.currentPageSize;
        }

        // Conditionally apply 'filters', 'sorts', & 'labels'.
        ['filters', 'labels', 'sort'].forEach((filter) => {
            if (filter === 'filters') {
                if (this.selected[filter].length > 0) {
                    data[filter] = this.selected[filter].map(
                        (item) => item.value
                    );
                }
            } else if (filter === 'labels') {
                if (this.selected[filter].length > 0) {
                    data['packetLabels'] = this.selected[filter].map(
                        (item) => item.value
                    );
                }
            } else if (filter === 'sort') {
                if (this.selected[filter]) {
                    data[filter] = this.selected[filter].value;
                } else {
                    const appInfo = this.appInfoService.getAppInfo(),
                        sortsData =
                            appInfo.analyticsInfo.contactAssignmentsAnalyticsInfo.sorts,
                        sortsOptions = this.buildOptions(sortsData);
                    if (sortsOptions && sortsOptions.length) {
                        data[filter] = sortsOptions[0].value;
                    }
                }
            }
        });

        this.analyticsFilterData = data;

        return data;
    }

    private getProgressValue(progress: number): number {
        return Math.ceil(progress * 100);
    }

    private getAssignmentContactMethod(assignment): string {
        let method = '';

        if (assignment.lastInvitationEmailOrPhone) {
            method = assignment.lastInvitationEmailOrPhone;
        } else if (assignment.contact.defaultEmailOrPhone) {
            method = assignment.contact.defaultEmailOrPhone;
        }

        return method;
    }

    private getDisplayDate(date): string {
        return date ? moment(date).format('L') : '--';
    }

    private getDisplayTime(time): string {
        return time ? moment(time).format('LT') : '';
    }

    // Charts
    private setChartData() {
        this.appHelpersService.whenElementPresentDo(
            '#standard-doughnut-chart',
            () => {
                const chartInvited = document.getElementById(
                    'standard-doughnut-chart'
                );
                if (this.analytics) {
                    const stats = this.appHelpersService.getClone(
                            this.analytics.stats
                        ),
                        statsTotal =
                            stats.viewed + stats.completed + stats.unviewed;

                    Chart.defaults.global.defaultFontColor = '#757575';
                    Chart.defaults.global.defaultFontFamily =
                        'Lato, sans-serif';
                    Chart.defaults.global.elements.line.fill = false;

                    chartInvited.innerHTML =
                        '<canvas id="canvas-standard-chart"></canvas>';
                    const context = document
                        .getElementById('canvas-standard-chart')
                        ['getContext']('2d');

                    const chartData = {
                        labels: ['Viewed', 'Completed', 'Not Viewed'],
                        datasets: [
                            {
                                data: [
                                    stats.viewed,
                                    stats.completed,
                                    stats.unviewed,
                                ],
                                backgroundColor: [
                                    this.appInfoService.getAppInfo().colorsInfo
                                        .analyticsChart.viewed,
                                    this.appInfoService.getAppInfo().colorsInfo
                                        .analyticsChart.completed,
                                    this.appInfoService.getAppInfo().colorsInfo
                                        .analyticsChart.unviewed,
                                ],
                            },
                        ],
                    };

                    this.drawChart('', context, chartData);
                    setTimeout(() => {
                        this.displayChart = statsTotal > 0;
                        this.appLoadingIndicatorService.hide(350);
                    }, 150);
                } else {
                    this.displayChart = false;
                    this.appLoadingIndicatorService.hide(350);
                }
            }
        );
    }

    private drawChart(title, context, data) {
        const chart = new Chart(context, {
            type: 'doughnut',
            data: data,
            options: this.getChartOptionsDoughnut(title),
        });
    }

    private getChartOptionsDoughnut(title) {
        return {
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            title: {
                display: false,
                text: title,
            },
            tooltips: {
                mode: 'label',
                backgroundColor: '@app-dark',
            },
            responsive: true,
        };
    }
}
