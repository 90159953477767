// 'name': Descriptive name of the job. Used for unique identification. Passed in to stop a given job.
// 'work': Function-contained block of logic to execute upon each interval/run of the job.
// 'timeStart': Date object indicating the time the job started.
// 'timeLastRun': Date object indicating the time the job last ran.
// 'countTimesRun': Number of times the job has been run to completion since it was started.

export class Job {
    constructor(
        public name: string,
        public work: Function,
        public interval: number,
        public dateStart?: Date,
        public dateLastRun?: Date,
        public countTimesRun?: number
    ) {}
}
