import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './dialog.qr.code.component.html',
    styleUrls: ['./dialog.qr.code.component.less'],
})
export class DialogQrCodeComponent {
    constructor(
        public dialogRef: MatDialogRef<DialogQrCodeComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            img: string;
            callback?: Function;
        }
    ) {}

    onConfirm() {
        if (this.data.callback) {
            this.data.callback(this.dialogRef);
        } else {
            this.dialogRef.close();
        }
    }
}
