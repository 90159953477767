import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppUserService } from '../../services/app.user.service';

@Component({
    templateUrl: './app.dialog-error.component.html',
    styleUrls: ['./app.dialog-error.component.less'],
})
export class AppDialogErrorComponent implements OnInit {
    title: string;
    message: string;
    avatar: string;
    redirect_to: string;
    redirect: string = null;
    auth_as: string;
    status: number;

    constructor(
        private router: Router,
        private appUserService: AppUserService,
        public dialogRef: MatDialogRef<AppDialogErrorComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            data: any;
            status: number;
        }
    ) {}

    ngOnInit() {
        this.status = this.data.status;
        if (this.status === 401 || this.status === 403) {
            this.title =
                typeof this.data.data === 'object' && 'title' in this.data.data
                    ? this.data.data.title
                    : 'Access Denied';
            this.message =
                typeof this.data.data === 'object' &&
                'message' in this.data.data
                    ? this.data.data.message
                    : 'It looks like you don`t have access to be here.';
            this.avatar = this.data.data.avatar;
            this.redirect_to =
                typeof this.data.data === 'object' &&
                'redirect_to' in this.data.data
                    ? this.data.data.redirect_to
                    : 'reload';
            this.auth_as = this.data.data.login_or_signup_as
                ? this.data.data.login_or_signup_as
                : null;
            this.redirect = window.location.pathname;
        } else if (this.status === 500 || this.status === 502) {
            this.title = 'Oops... something went wrong';
            this.message =
                'Looks like something went wrong. Please refresh your screen.';
            this.redirect_to = 'restore';
        } else {
            this.title = 'Oops... something went wrong';
            this.message =
                'Please check your internet connection and refresh your browser to continue working.';
            this.redirect_to = 'restore';
        }
    }

    reload() {
        this.dialogRef.close();

        setTimeout(() => {
            const user = this.appUserService.getUser();
            if (user) {
                window.location.href = '/user/assignments';
            } else {
                this.appUserService.logout();
            }
        }, 100);
    }

    restore() {
        this.dialogRef.close();

        setTimeout(() => {
            const user = this.appUserService.getUser();
            if (user) {
                if (this.status === 401 || this.status === 404) {
                    window.location.href = '/user/assignments';
                } else {
                    window.location.reload(true);
                }
            } else {
                this.appUserService.logout();
            }
        }, 100);
    }
}
