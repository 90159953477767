<!--Buttons-->
<div class="vings-buttons">
    <ng-container *ngIf="!selectedPacket">
    <div class="create-ving-button">
        <button
            title="Create"
            class="app-button small"
            (click)="createPacket('CREATE_PACKET')"
        >
            Create
        </button>
    </div>
    </ng-container>
    <div class="ving-action-buttons" [class]="isDetail ? 'selected-ving active' : ''">
        <ng-container *ngFor="let button of buttons">
            <button
                [title]="button.tooltip"
                [class]="getClass(button.name)"
                (click)="handleClick(button.name)"
                *ngIf="
                    !button.hidden &&
                    (!selectedPacket || (selectedPacket && button.selectedPacket))
                "
            >
                <i class="material-icons icon">
                    {{ button.icon }}
                </i>
            </button>
        </ng-container>
    </div>
    <!--Selected info-->
    <ng-container *ngIf="!selectedPacket">
        <div class="app-header-selected-info">
            <!--Selected vings count-->
            <div class="selected-count">{{ getSelectedPacketsCount() }} selected</div>

            <!--Select all packets-->
            <div class="select-all-items" *ngIf="packets.length > 0">
                <a (click)="toggleSelectAllPackets()">
                    <span *ngIf="!selectedAllPackets"> Select all </span>
                    <span *ngIf="selectedAllPackets"> Deselect all </span>
                </a>
            </div>
        </div>
    </ng-container>
</div>
