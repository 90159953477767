import {
    AfterViewInit,
    Component,
    ChangeDetectorRef,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { AppService } from '../../app.service';
import { AppLoadingIndicatorService } from '../../shared/services/app.loading-indicator.service';
import { AppJobService } from '../../shared/services/job/app.job.service';
import { AppMessageService } from '../../shared/services/app.message.service';
import { AppHttpService } from '../../shared/services/app.http.service';
import { AppHelpersService } from '../../shared/services/app.helpers.service';
import { AppHeaderService } from '../../shared/services/app.header.service';
import { AppUserService } from '../../shared/services/app.user.service';
import { AppFilterService } from '../../shared/services/app.filter.service';
import { AppInfoService } from '../../shared/services/app-info/app.info.service';
import { AppInfoListItem } from '../../shared/services/app-info/app.info.list.item';
import { AppDialogConfirmComponent } from '../../shared/dialogs/dialog-confirm/app.dialog-confirm.component';
import { AppDialogChangeOwnerComponent } from '../../shared/dialogs/dialog-change-owner/app.dialog-change-owner.component';
import { AppDataTransform } from '../../shared/app.data-transform';
import { AppSelectOption } from '../../shared/components/app-select/classes/app.select.option';
import { AppLabel } from '../../shared/classes/app-label';
import * as $ from 'jquery';
import { AppDialogEditCategoryComponent } from '../../shared/dialogs/dialog-edit-category/app.dialog-edit-category.component';
import { DialogManageLabelsComponent } from '../../shared/dialogs/dialog-manage-labels/dialog.manage-labels.component';
import { addWeeks } from 'date-fns';
import { DialogReadonlyComponent } from '../../shared/dialogs/dialog-readonly/dialog.readonly.component';
import { DialogSendInvitationComponent } from '../../shared/dialogs/dialog-send-invitation/dialog.send.invitation.component';
import {Checklist} from "./classes/checklist";
import {ListQueryParams} from "../../shared/classes/list.query-params";
import {
    AppDialogExportDataComponent
} from "../../shared/dialogs/dialog-export-data/app.dialog-export-data.component";

@Component({
    selector: 'app-checklists',
    templateUrl: './checklists.component.html',
    styleUrls: ['./checklists.component.less'],
})
export class ChecklistsComponent implements OnInit, AfterViewInit, OnDestroy {
    messages = {
        success: {
            changeOwner: 'The checklist owner has been changed successfully.',
        },
        error: {},
    };
    jobs = {
        panelOneWatcher: 'panel-one-watcher',
    };
    shouldDisplayHorizontal = false;
    checklists: Array<Checklist> = [];
    labels: Array<AppLabel> = [];
    selectedLabels: Array<string> = [];
    selectedChecklistIds: Array<number> = [];
    excludedChecklistIds: Array<number> = [];
    selectedAllChecklists = false;
    selectedChecklist: Checklist;
    searchText = '';
    filters: Array<AppInfoListItem> = [];
    selectedFilters: Array<string> = [];
    sorts: Array<AppSelectOption> = [];
    selectedSort: AppSelectOption;
    statuses: Array<AppSelectOption> = [];
    selectedStatus: AppSelectOption;
    owners: Array<AppSelectOption> = [];
    selectedOwner: AppSelectOption;
    selectNewChecklist = new Subject();
    isDetail = false;

    // Lazy loading checklists
    isLazyLoading = false;
    loadIndex = 0;
    lastScrollTop = 0;
    checklistsCount = 0;
    entityLists = {
        custom: [],
        labels: [],
        categories: [],
        lists: [],
    };

    private packetId: any = '';
    private headerEventRef: Subscription = null;
    private defaultPageSize: number;
    private pagingScrollPercent: number;
    private keepFilters = false;
    private navState: any;
    scheduledIsOpen = false;
    private scheduledInvitations: any;

    constructor(
        @Inject('window') private window,
        private appService: AppService,
        private appHttpService: AppHttpService,
        private appInfoService: AppInfoService,
        private appJobService: AppJobService,
        private appHelpersService: AppHelpersService,
        private appMessageService: AppMessageService,
        private appHeaderService: AppHeaderService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appUserService: AppUserService,
        private appFilterService: AppFilterService,
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        setTimeout(
            () => (this.appService.selected.navOption = 'checklists'),
            0
        );
        this.navState = history.state;
        // Set header
        this.appHeaderService.set('CHECKLISTS');
        this.headerEventRef = this.appHeaderService.events.subscribe((ev) => {
            if (ev === 'CREATE_CHECKLIST') {
                this.appLoadingIndicatorService.show('app', '');

                this.appHttpService.request(
                    'POST:api/checklists/',
                    {},
                    (response) => {
                        this.router.navigateByUrl(
                            '/checklists/' + response.id + '/edit'
                        );
                    },
                    () => {
                        this.router.navigate(['/dashboard'], {
                            queryParams: { error: 'creatingChecklist' },
                        });
                    }
                );
            }
        });

        this.getScheduledInvites();

        this.route.params.subscribe((params) => {
            if (!params.id && this.packetId !== params.id) {
                this.reset();
            }
            this.packetId = params.id;
            if (!params.id.length) {
                this.isDetail = false;
            } else {
                this.isDetail = true;
            }
        });
    }

    ngAfterViewInit() {
        // Check packetId
        const packetId = this.route.snapshot.paramMap.get('id');

        this.watchPanelOneSize();

        this.appLoadingIndicatorService.show('#checklists');

        const appInfo = this.appInfoService.getAppInfo();

        this.defaultPageSize = appInfo.packetsInfo.pageSize;
        this.pagingScrollPercent = appInfo.packetsInfo.pagingScrollPercent;

        this.initializeOthers();

        // Check to see if packetId is present within the URL. If so, query it and display within panel 3.
        if (packetId) {
            this.appHttpService.request(
                'GET:api/checklists/' + packetId + '/',
                {},
                (response) => {
                    this.selectedChecklist = response;
                    this.selectNewChecklist.next(this.selectedChecklist);
                }
            );
        } else {
            this.loadLabels(false);
            this.queryChecklists(false);
        }

        this.cd.detectChanges();
    }

    ngOnDestroy() {
        // Destroy header
        this.appHeaderService.destroy();
        if (this.headerEventRef) {
            this.headerEventRef.unsubscribe();
        }

        this.appJobService.kill(this.jobs.panelOneWatcher);
    }

    reset() {
        this.appLoadingIndicatorService.show('#checklists');

        this.packetId = undefined;
        this.selectedChecklist = undefined;

        const resetChecklists =
            (this.selectedLabels.length ||
                this.selectedFilters.length ||
                !!this.selectedSort ||
                !!this.selectedStatus ||
                !!this.selectedOwner ||
                !!this.searchText) &&
            !this.keepFilters;

        if (this.keepFilters) {
            this.keepFilters = false;
        } else {
            this.selectNewChecklist.next(this.selectedChecklist);
            this.selectedChecklistIds = [];
            this.excludedChecklistIds = [];
            this.selectedAllChecklists = false;
            this.searchText = '';
            this.sorts = [];
            this.statuses = [];
            this.owners = [];
            this.selectedLabels = [];
            this.selectedFilters = [];
            this.selectedSort = undefined;
            this.selectedStatus = undefined;
            this.selectedOwner = undefined;

            this.initializeOthers();
        }

        this.loadLabels(false, () => {
            if (this.checklists && this.checklists.length && !resetChecklists) {
                this.appLoadingIndicatorService.hide(350);
            } else {
                this.queryChecklists(false);
            }
        });

        this.getScheduledInvites();
    }

    resetChecklist() {
        this.keepFilters = true;
        this.router.navigateByUrl('/checklists/');
    }

    // Checklists List :: Lazy Loading

    lazyLoadChecklists($event) {
        // Check to see if the lazy-loader is not already loading and
        // that the mouse movement was a scroll down, not scroll up.
        if (this.canLazyLoadMore($event)) {
            this.lastScrollTop = $event.target.scrollTop;

            if (
                $event.target.scrollTop /
                    ($event.target.scrollHeight - $event.target.clientHeight) >=
                this.pagingScrollPercent
            ) {
                $('.app-scroll-loading-indicator').fadeIn(200);
                this.isLazyLoading = true;
                this.loadIndex++;

                this.queryChecklists(false, () => {
                    this.isLazyLoading = false;
                    $('.app-scroll-loading-indicator').fadeOut(350);
                });
            }
        }
    }

    watchPanelOneSize() {
        const panelTwo = $('#panel-two');
        this.appJobService.create(
            this.jobs.panelOneWatcher,
            () => {
                this.shouldDisplayHorizontal = panelTwo.outerWidth() <= 450;
            },
            250
        );
    }

    onToggleChecklistId(id: number): void {
        // Stop the click event of the select-icon from triggering the click
        // event of the checklist card itself.
        event.stopPropagation();

        if (this.selectedAllChecklists) {
            let excludedIds = [];
            const index = this.excludedChecklistIds.indexOf(id);

            // Copy existing excluded checklist ids.
            excludedIds = this.appHelpersService.getClone(
                this.excludedChecklistIds
            );

            if (index >= 0) {
                excludedIds.splice(index, 1);
            } else {
                excludedIds.push(id);
            }

            // Taking this step to trigger the child component onChanges event.
            this.excludedChecklistIds = excludedIds;
        } else {
            let selectedIds = [];
            const index = this.selectedChecklistIds.indexOf(id);

            // Copy existing selected checklist ids.
            selectedIds = this.appHelpersService.getClone(
                this.selectedChecklistIds
            );

            if (index >= 0) {
                selectedIds.splice(index, 1);
            } else {
                selectedIds.push(id);
            }

            // Taking this step to trigger the child component onChanges event.
            this.selectedChecklistIds = selectedIds;
        }
    }

    onToggleAllChecklistIds(): void {
        this.selectedAllChecklists = !this.selectedAllChecklists;
        this.selectedChecklistIds.length = 0;
        this.excludedChecklistIds.length = 0;
    }

    onDeselectChecklists($event): void {
        this.selectedAllChecklists = false;
        this.selectedChecklistIds.length = 0;
        this.excludedChecklistIds.length = 0;
    }

    onClickHeaderButton(name: string): void {
        switch (name) {
            case 'manage-checklists-labels':
                this.displayManageChecklistsLabelsModal();
                break;
            case 'change-owner':
                this.displayChangeOwnerModal();
                break;
            case 'archive':
                this.displayArchiveChecklistsModal(true);
                break;
            case 'unarchive':
                this.displayArchiveChecklistsModal(false);
                break;
            case 'invite':
                this.displayInviteModal(this.selectedChecklistIds);
                break;
            case 'export-data':
                this.displayExportDataModal();
                break;
        }
    }

    private displayExportDataModal() {
        const selectedChecklist = this.selectedChecklist ? true : false;
        this.initExportDataModal(
            {
                page: 'checklists',
                filterKey: 'checklistFilter',
                baseUrl: 'api/checklist/',
            },
            selectedChecklist
        );
    }

    private initExportDataModal(params, selectedChecklist = false) {
        this.appLoadingIndicatorService.show('view', '', () => {
            const queries = this.buildManageQueries(selectedChecklist);
            const updatedQueries = AppDataTransform.getConvertedData(
                'snake',
                queries
            );

            this.dialog.open(AppDialogExportDataComponent, {
                width: '450px',
                height: '375px',
                disableClose: true,
                data: {
                    page: params.page,
                    filterKey: params.filterKey,
                    baseUrl: params.baseUrl,
                    queries: updatedQueries,
                    selectedIds: selectedChecklist
                        ? [this.selectedChecklist.id]
                        : this.selectedChecklistIds,
                    selectedAll: selectedChecklist
                        ? false
                        : this.selectedAllChecklists,
                    excludedIds: selectedChecklist
                        ? []
                        : this.excludedChecklistIds,
                    callback: (dialog, message) => {
                        dialog.close();
                        this.appMessageService.show(
                            'app',
                            'success',
                            message,
                            3
                        );
                    }
                }
            });
        });
    }

    onDeleteCategory(category: any): void {
        // Display confirmation dialog.
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            data: {
                text: 'Are you sure you want to delete this folder?',
                callback: () => {
                    this.appHttpService.request(
                        'DELETE:api/labels/categories/checklist/' +
                            category.name +
                            '/',
                        {},
                        (response) => {
                            this.appLoadingIndicatorService.show(
                                'view',
                                '',
                                () => {
                                    this.onClearFilters();
                                    this.loadLabels(true, () => {
                                        this.queryChecklists(false, () => {
                                            this.appMessageService.show(
                                                'app',
                                                'success',
                                                'Folder successfully removed.'
                                            );
                                        });
                                    });
                                }
                            );
                        }
                    );
                },
            },
        });
    }
    onEditCategory(category: any): void {
        this.dialog.open(AppDialogEditCategoryComponent, {
            height: '200px',
            width: '425px',
            data: {
                name: category.name,
                type: 'packet',
                callback: () => {
                    this.loadLabels(true);
                },
            },
        });
    }

    onToggleLabel(item): void {
        const labels = [],
            index = this.selectedLabels.indexOf(item.name);

        // Copy existing selected checklist ids.
        for (let i = 0; i < this.selectedLabels.length; i++) {
            labels.push(this.selectedLabels[i]);
        }

        if (index >= 0) {
            labels.splice(index, 1);
        } else {
            labels.push(item.name);
        }

        // Deselect selected Checklists
        if (this.selectedLabels !== labels) {
            this.selectedAllChecklists = false;
            this.selectedChecklistIds = [];
            this.excludedChecklistIds = [];
        }

        // Taking this step to trigger the child component onChanges event.
        this.selectedLabels = labels;

        this.queryChecklists(true);
    }

    onToggleFilter(value: string): void {
        const filters = [],
            index = this.selectedFilters.indexOf(value);

        // Copy existing selected checklist ids.
        for (let i = 0; i < this.selectedFilters.length; i++) {
            filters.push(this.selectedFilters[i]);
        }

        if (index >= 0) {
            filters.splice(index, 1);
        } else {
            filters.push(value);
        }

        // Deselect selected Checklists
        if (this.selectedFilters !== filters) {
            this.selectedAllChecklists = false;
            this.selectedChecklistIds = [];
            this.excludedChecklistIds = [];
        }

        // Taking this step to trigger the child component onChanges event.
        this.selectedFilters = filters;

        this.queryChecklists(true);
    }

    onClearFilters(): void {
        this.selectedLabels = [];
    }

    onResetSearch(): void {
        // Taking this step to trigger the child component onChanges event.
        this.selectedFilters = [];
        this.selectedLabels = [];
        this.searchText = '';

        this.selectedAllChecklists = false;
        this.excludedChecklistIds = [];
        this.selectedChecklistIds = [];

        // clears class for labels
        const elems = document.querySelectorAll('.mat-chip.label-selected');

        [].forEach.call(elems, function (el) {
            el.classList.remove('label-selected');
        });

        this.queryChecklists(true);
    }

    onClickChecklist(id): void {
        this.appLoadingIndicatorService.show('#checklists', '', () => {
            this.appHttpService.request(
                'GET:api/checklists/' + id + '/',
                {},
                (response) => {
                    this.selectedChecklist = response;
                    this.selectNewChecklist.next(this.selectedChecklist);
                    this.router.navigateByUrl('/checklists/' + id);
                }
            );
        });
    }

    onEditChecklist(id): void {
        this.appLoadingIndicatorService.show('app', '', () => {
            this.appHttpService.request(
                'GET:api/checklists/' + id + '/',
                {},
                (response) => {
                    this.selectedChecklist = response;
                    this.selectNewChecklist.next(this.selectedChecklist);
                    this.router.navigateByUrl('/checklists/' + id + '/edit');
                }
            );
        });
    }

    onDeleteChecklist(id): void {
        // Prompt user with confirmation modal before proceeding.
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            data: {
                text: 'Are you sure you want to delete this?',
                callback: () => {
                    this.appHttpService.request(
                        'DELETE:api/checklists/' + id + '/',
                        {},
                        (response) => {
                            this.appLoadingIndicatorService.show(
                                'app',
                                '',
                                () => {
                                    // Update Checklists list.
                                    this.queryChecklists();
                                }
                            );
                        }
                    );
                },
            },
        });
    }

    onUpdateSelectedChecklist(checklist): void {
        const selectedChecklist: any = this.checklists.find(
            (c) => c.id === checklist.id
        );
        if (selectedChecklist) {
            selectedChecklist.share = checklist.share;
        }
    }

    onSearchTextChange(searchText: string): void {
        this.searchText = searchText;
        this.clearLazyLoadingData();
        this.queryChecklists(true);
    }

    onSortOptionChange(selectedSort: AppSelectOption): void {
        this.selectedSort = selectedSort;
        this.clearLazyLoadingData();
        this.queryChecklists(true);
    }

    onStatusOptionChange(selectedStatus: AppSelectOption): void {
        this.selectedStatus = selectedStatus;
        this.clearLazyLoadingData();
        this.queryChecklists(true);
    }

    onOwnerOptionChange(selectedOwner: AppSelectOption): void {
        this.selectedOwner = selectedOwner;
        this.clearLazyLoadingData();
        this.queryChecklists(true);
    }

    onDeleteLabel(label: string): void {
        // Prompt user with confirmation modal before proceeding.
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            data: {
                text: 'Are you sure you want to remove this label from all checklists?',
                callback: () => {
                    this.appHttpService.request(
                        'DELETE:api/labels/checklist/' + label + '/',
                        {},
                        (response) => {
                            this.appLoadingIndicatorService.show(
                                'view',
                                '',
                                () => {
                                    this.loadLabels(true, () => {
                                        this.queryChecklists(false, () => {
                                            this.appMessageService.show(
                                                'app',
                                                'success',
                                                'Label successfully removed.'
                                            );
                                        });
                                    });
                                }
                            );
                        }
                    );
                },
            },
        });
    }

    private canLazyLoadMore(event): boolean {
        const isNotCurrentlyLoading = this.isLazyLoading === false,
            isScrollingDown =
                isNotCurrentlyLoading &&
                event.target.scrollTop > this.lastScrollTop,
            hasMoreToLoad =
                isScrollingDown &&
                this.checklists.length < this.checklistsCount;

        return isNotCurrentlyLoading && isScrollingDown && hasMoreToLoad;
    }

    private clearLazyLoadingData(): void {
        $('#panel-two .cards-list').scrollTop(0);
        this.loadIndex = 0;
        this.lastScrollTop = 0;
    }

    private initializeOthers(): void {
        // Initialize lists and default values for sort and status.
        const checklistsInfo =
            this.appInfoService.getAppInfoProperty('checklistsInfo');
        // Statuses
        checklistsInfo['statuses'].forEach((status) => {
            this.statuses.push(new AppSelectOption(status.name, status.value));
        });
        let selectedStatus = checklistsInfo['statuses'].find(
            (status) => status.selected === true
        );

        const urlParams = this.appHelpersService.getUrlParams();
        if (urlParams) {
            if ('status' in urlParams) {
                selectedStatus = checklistsInfo['statuses'].find(
                    (status) => status.value.toString() === urlParams.status.toString()
                );
                const url = window.location.href;
                window.history.replaceState(null, null, url.split('?')[0]);
            }
        }

        this.selectedStatus = new AppSelectOption(
            selectedStatus.name,
            selectedStatus.value
        );

        // Sorts
        checklistsInfo['sorts'].forEach((sort) => {
            this.sorts.push(new AppSelectOption(sort.name, sort.value));
        });
        const selectedSort = checklistsInfo['sorts'].find(
            (sort) => sort.selected === true
        );
        this.selectedSort = new AppSelectOption(
            selectedSort.name,
            selectedSort.value
        );

        // Init owners
        this.owners.unshift(new AppSelectOption('Anyone', ''));
        this.appUserService.whenUserSet(() => {
            const user = this.appUserService.getUser();

            if (user.assignableOwners) {
                user.assignableOwners.forEach((owner) => {
                    this.owners.push(new AppSelectOption(owner.name, owner.id));
                });
            }
        });
    }

    public loadLabels(reset = false, callback?: Function): void {
        const runCallback = (res) => {
            this.entityLists.categories = Object.keys(res).map(
                (categoryKey) => {
                    const categoryLabels = res[categoryKey];

                    return {
                        name: categoryKey,
                        labels: categoryLabels,
                    };
                }
            );
            let createNew = {};
            createNew = {
                name: 'Create New Folder',
                labels: [],
            };
            this.entityLists.categories.unshift(createNew);
            const index = this.entityLists.categories
                .map((e) => e.name)
                .indexOf('uncategorized');
            if (index !== -1) {
                this.entityLists.categories.splice(
                    1,
                    0,
                    this.entityLists.categories.splice(index, 1)[0]
                );
            }

            const allLabels = [];

            Object.keys(res).forEach((category) => {
                allLabels.push(res[category].map((label) => label.name));
            });

            const flattenedLabels = allLabels.flat();

            const labels = [];
            this.selectedLabels.forEach((label) => {
                const isExisted = flattenedLabels.find((lbl) => lbl === label);
                if (isExisted) {
                    labels.push(label);
                }
            });
            this.selectedLabels = labels;
            this.entityLists.labels = flattenedLabels;

            if (callback) {
                callback();
            }
        };

        this.appFilterService
            .getChecklistFilters('labels', reset)
            .subscribe((labels) => {
                runCallback(labels);
            });
    }

    private queryChecklists(
        showLoadingIndicator = false,
        callback?: Function,
        reset = false
    ): void {
        if (showLoadingIndicator) {
            this.appLoadingIndicatorService.show('#panel-two .cards-list');
        }

        const params = new ListQueryParams(
            this.selectedSort.value,
            this.selectedStatus.value,
            this.defaultPageSize,
            this.loadIndex * this.defaultPageSize,
            this.searchText.length ? this.searchText : null,
            this.selectedFilters.length ? this.selectedFilters : null,
            this.selectedLabels.length ? this.selectedLabels : null,
            this.selectedOwner && this.selectedOwner.value
                ? this.selectedOwner.value
                : null
        );

        const runCallback = () => {
            this.appLoadingIndicatorService.hide(350);
            if (callback) {
                callback();
            }
        };

        if (this.packetId && !reset) {
            runCallback();
        } else {
            this.appHttpService.request(
                'GET:api/checklists/',
                params,
                (response) => {
                    this.checklistsCount = response.count;

                    if (this.loadIndex === 0) {
                        this.checklists = response.results;
                    } else {
                        response.results.forEach((checklist) => {
                            this.checklists.push(checklist);
                        });
                    }

                    runCallback();
                }
            );
        }
    }

    // Header Buttons
    private displayManageChecklistsLabelsModal(): void {
        this.appLoadingIndicatorService.show('view', '', () => {
            const selectedChecklist = this.selectedChecklist ? true : false,
                url = 'GET:api/labels/checklist/',
                queries = this.buildManageQueries(selectedChecklist);
            const updatedQueries = AppDataTransform.getConvertedData(
                'snake',
                queries
            );

            // HTTP request to retrieve the list of packet labels; then display modal.
            this.appHttpService.request(url, queries, (items) => {
                this.dialog.open(DialogManageLabelsComponent, {
                    width: '450px',
                    height: '500px',
                    disableClose: true,
                    data: {
                        page: 'checklists',
                        items: items.results,
                        selectedItemIds: selectedChecklist
                            ? [this.selectedChecklist.id]
                            : this.selectedChecklistIds,
                        selectedAllItems: selectedChecklist
                            ? false
                            : this.selectedAllChecklists,
                        excludedItemIds: selectedChecklist
                            ? []
                            : this.excludedChecklistIds,
                        itemCount: selectedChecklist ? 1 : this.checklistsCount,
                        queries: updatedQueries,
                        callback: (dialog, messageSuccess) => {
                            // Clear the selected checklist ids.
                            this.selectedChecklistIds = [];
                            this.selectedAllChecklists = false;
                            this.excludedChecklistIds = [];

                            if (this.selectedChecklist) {
                                // Reset Labels
                                this.appFilterService.resetPacketFilters(
                                    'labels'
                                );

                                // Reset Checklist list
                                this.checklistsCount = 0;
                                this.checklists = [];

                                dialog.close();
                                this.appMessageService.show(
                                    'app',
                                    'success',
                                    messageSuccess,
                                    3
                                );
                            } else {
                                // Update Labels
                                this.loadLabels(true, () => {
                                    // Clear the selected checklist ids.
                                    this.selectedChecklistIds = [];
                                    this.selectedAllChecklists = false;
                                    this.excludedChecklistIds = [];

                                    // Update Checklists list
                                    this.queryChecklists(
                                        false,
                                        () => {
                                            dialog.close();
                                            this.appMessageService.show(
                                                'app',
                                                'success',
                                                messageSuccess,
                                                3
                                            );
                                        },
                                        true
                                    );
                                });
                            }
                        },
                    },
                });
            });
        });
    }

    private displayArchiveChecklistsModal(archived = true): void {
        const selectedChecklist = this.selectedChecklist ? true : false;
        const text =
            'Are you sure you want to ' +
            (archived ? 'archive ' : 'unarchive ') +
            (selectedChecklist
                ? 'this?'
                : 'these?');
        const message =
            (selectedChecklist
                ? 'This has '
                : 'These have ') +
            'been ' +
            (archived ? 'archived ' : 'unarchived ') +
            'successfully';
        // Prompt user with confirmation modal before proceeding.
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            data: {
                text: text,
                callback: () => {
                    const params = {
                        archived: archived,
                    };

                    this.updateChecklists(params, () => {
                        this.appLoadingIndicatorService.show('view', '', () => {
                            // Check to see if one of the archived/unarchived checklists is selected.
                            if (this.selectedChecklist) {
                                this.selectedChecklist.status = archived
                                    ? 'archived'
                                    : 'published';
                                this.selectedChecklist =
                                    this.appHelpersService.getClone(
                                        this.selectedChecklist
                                    );

                                this.appLoadingIndicatorService.hide(200);

                                this.appMessageService.show(
                                    'app',
                                    'success',
                                    message,
                                    3
                                );
                            } else {
                                // Clear the selected checklist ids.
                                this.selectedChecklistIds = [];
                                this.selectedAllChecklists = false;
                                this.excludedChecklistIds = [];

                                // Update Checklists list.
                                this.queryChecklists(false, () => {
                                    this.appMessageService.show(
                                        'app',
                                        'success',
                                        message,
                                        3
                                    );
                                });
                            }
                        });
                    });
                },
            },
        });
    }

    private displayChangeOwnerModal(): void {
        this.appLoadingIndicatorService.show('view', '', () => {
            this.appUserService.whenUserSet(() => {
                const user = this.appUserService.getUser();

                if (user.assignableOwners) {
                    this.dialog.open(AppDialogChangeOwnerComponent, {
                        width: '450px',
                        height: '500px',
                        disableClose: true,
                        data: {
                            title: 'Change Owner',
                            items: user.assignableOwners,
                            owner:
                                this.selectedChecklist &&
                                this.selectedChecklist.share.owner
                                    ? this.selectedChecklist.share.owner
                                    : null,
                            callback: (dialog, ownerId) => {
                                const params = {
                                    owner_id: ownerId,
                                };

                                this.appLoadingIndicatorService.show(
                                    'modal',
                                    '',
                                    () => {
                                        this.updateChecklists(params, () => {
                                            if (this.selectedChecklist) {
                                                // Reset ving list
                                                this.checklistsCount = 0;
                                                this.checklists = [];

                                                // Update selected checklist
                                                this.appHttpService.request(
                                                    'GET:api/checklists/' +
                                                        this.selectedChecklist
                                                            .id +
                                                        '/',
                                                    {},
                                                    (response) => {
                                                        this.selectedChecklist =
                                                            response;
                                                        this.selectNewChecklist.next(
                                                            this
                                                                .selectedChecklist
                                                        );

                                                        dialog.close();
                                                        this.appMessageService.show(
                                                            'app',
                                                            'success',
                                                            this.messages
                                                                .success
                                                                .changeOwner,
                                                            3
                                                        );
                                                    }
                                                );
                                            } else {
                                                // Clear the selected checklist ids.
                                                this.selectedChecklistIds = [];
                                                this.selectedAllChecklists =
                                                    false;
                                                this.excludedChecklistIds = [];

                                                // Update Checklists list.
                                                this.queryChecklists(
                                                    false,
                                                    () => {
                                                        dialog.close();
                                                        this.appMessageService.show(
                                                            'app',
                                                            'success',
                                                            this.messages
                                                                .success
                                                                .changeOwner,
                                                            3
                                                        );
                                                    },
                                                    true
                                                );
                                            }
                                        });
                                    }
                                );
                            },
                        },
                    });
                }
            });
        });
    }

    private isChecklistSelected(id: number): boolean {
        let selected = false;
        if (this.selectedAllChecklists) {
            if (this.excludedChecklistIds.indexOf(id) === -1) {
                selected = true;
            }
        } else {
            if (this.selectedChecklistIds.indexOf(id) >= 0) {
                selected = true;
            }
        }

        return selected;
    }

    private updateChecklists(params: Object, callback?: Function): void {
        const selectedChecklist = this.selectedChecklist ? true : false,
            url = 'PUT:api/checklists/?',
            queries = this.buildManageQueries(selectedChecklist);
        const updatedQueries = AppDataTransform.getConvertedData(
            'snake',
            queries
        );
        const queryString = Object.keys(updatedQueries)
            .map((key) => key + '=' + updatedQueries[key])
            .join('&');

        this.appHttpService.request(url + queryString, params, (response) => {
            if (callback) {
                callback(response);
            }
        });
    }

    private buildManageQueries(selectedChecklist = false) {
        const queries = {};
        if (selectedChecklist) {
            queries['selectedIds'] = this.selectedChecklist.id.toString();
        } else {
            queries['status'] = this.selectedStatus.value;
            if (this.selectedOwner && this.selectedOwner.value) {
                queries['owner_id'] = this.selectedOwner.value;
            }
            queries['selectedIds'] = this.selectedChecklistIds.join(',');
            if (this.selectedFilters.length) {
                queries['filters'] = this.selectedFilters;
            }
            if (this.selectedLabels.length) {
                queries['labels'] = this.selectedLabels;
            }
            if (this.selectedAllChecklists) {
                queries['selectedAll'] = true;
                queries['selectedCount'] =
                    this.checklistsCount - this.excludedChecklistIds.length;
                queries['excludedIds'] = this.excludedChecklistIds.join(',');
                if (this.searchText) {
                    queries['query'] = this.searchText;
                }
            }
        }

        return queries;
    }
    removeLabel($event: any) {
        const indexOfCategory = this.entityLists.categories
            .map((e) => e.name)
            .indexOf($event.category);
        const indexOfLabel = this.entityLists.categories[indexOfCategory].labels
            .map((e) => e)
            .indexOf($event.label);
        this.entityLists.categories[indexOfCategory].labels.splice(
            indexOfLabel,
            1
        );
    }

    displayInviteModal(checklists): void {
        let shareIds = [];
        let selectedFromList = [];

        if (this.selectedAllChecklists) {
            shareIds = this.checklists.map((item) => item.share.id);

            // filters out excludedChecklists
            selectedFromList = this.checklists.filter(
                (el) => !this.excludedChecklistIds.includes(el.id)
            );
        } else {
            checklists.forEach((checklistId) => {
                shareIds.push(
                    this.checklists.find((item) => item.id === checklistId)
                        .share.id
                );
                selectedFromList.push(
                    this.checklists.find((item) => item.id === checklistId)
                );
            });
        }

        // set send dates for multiple scheduled checklists to be weekly by default
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        selectedFromList.map((item, i) => {
            item.scheduledDate = addWeeks(tomorrow, i);
            item.scheduledDate.setHours(10);
            item.scheduledDate.setMinutes(0, 0);
        });

        // call dialogs, limit for scheduling checklists is 60 at a time.
        if (selectedFromList.length > 60) {
            this.dialog.open(DialogReadonlyComponent, {
                width: '400px',
                height: '200px',
                disableClose: true,
                data: {
                    title: 'Scheduling Limit Reached',
                    text: 'Please only select up to 60 checklists for scheduling at a time.',
                },
            });
        } else {
            this.dialog.open(DialogSendInvitationComponent, {
                width: '700px',
                minHeight: '400px',
                height: 'auto',
                disableClose: true,
                data: {
                    header: "Send Invitations",
                    shareId: shareIds,
                    selectedFromList: selectedFromList,
                    callback: () => {
                        this.getScheduledInvites();
                    },
                },
            });
        }
    }

    toggleScheduled() {
        const panelTwo = document.getElementById('panel-two');
        const trigger = document.getElementById('panel-four-trigger');
        const listCards = document.getElementById('list-cards');
        if (this.scheduledIsOpen) {
            this.scheduledIsOpen = false;
            // get element by id #panel 2 and right 0px
            panelTwo.style.right = '0px';
            if (trigger) {
                trigger.style.right = '0px';
            }
        } else {
            this.scheduledIsOpen = true;
            // get element by id #panel 2 and right 340px
            panelTwo.style.right = '340px';
            trigger.style.right = '340px';
        }
    }

    private getScheduledInvites() {
        this.appHttpService.request(
            'GET:api/invitations/checklist/unsent/',
            {},
            (response) => {
                this.scheduledInvitations = response.results;
                this.scheduledInvitations.map((invite) => {
                    if (invite.sendDate !== null) {
                        invite.sendDate = new Date(invite.sendDate);
                        invite.sendDate = [
                            this.padTo2Digits(invite.sendDate.getMonth() + 1),
                            this.padTo2Digits(invite.sendDate.getDate()),
                            invite.sendDate.getFullYear(),
                        ].join('/');
                    }
                });

                const panelTwo = document.getElementById('panel-two');
                const listCards = document.getElementById('list-cards');
                const trigger = document.getElementById('panel-four-trigger');
                if (this.scheduledInvitations.length) {
                    this.scheduledIsOpen = true;
                    // get element by id #panel 2 and right 340px
                    panelTwo.style.right = '340px';
                    trigger.style.right = '340px';
                } else {
                    this.scheduledIsOpen = false;
                    // get element by id #panel 2 and right 0px
                    panelTwo.style.right = '30px';
                    if (trigger) {
                        trigger.style.right = '0px';
                    }
                }
            }
        );
    }

    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
}
