export class ListQueryParams {
    sort: string;
    status: string;
    limit: number;
    offset: number;
    query?: string;
    filters?: Array<string>;
    labels?: Array<string>;
    owner_id?: string;

    constructor(
        sort: string,
        status: string,
        limit: number,
        offset: number,
        query?: string,
        filters?: Array<string>,
        labels?: Array<string>,
        owner_id?: string
    ) {
        this.sort = sort;
        this.status = status;
        this.limit = limit;
        this.offset = offset;

        if (query) {
            this.query = query;
        }

        if (filters) {
            this.filters = filters;
        }

        if (labels) {
            this.labels = labels;
        }

        if (owner_id) {
            this.owner_id = owner_id;
        }
    }
}
