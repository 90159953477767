import {
    OnInit,
    OnDestroy,
    AfterViewInit,
    Component,
    Inject,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { NewUser } from './classes/new-user';
import { VerificationCode } from './classes/verification-code';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppAlertService } from '../../../../shared/services/app.alert.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppUserService } from '../../../../shared/services/app.user.service';
import { Utils } from '../../../../shared/app.utils';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.less'],
})
export class SignUpComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('email') inputEmail: ElementRef;
    @ViewChild('code') inputCode: ElementRef;
    messages = {
        error: 'Email or Phone already in use. Please specify another.',
        error2: 'Verification code is wrong. Please try again.',
        password:
            'Passwords must be 8-50 characters and contain letters, numbers and special characters. You may not use over 3 repeated or sequential characters.',
        password_match: 'Passwords must match.',
    };
    newUser = new NewUser('', '', '', '', '');
    verificationCode = new VerificationCode('', '', '', '');
    captcha = '';
    error = false;
    step = 1;
    auth_as: string = null;

    constructor(
        @Inject('location') private location: Location,
        private router: Router,
        private appHttpService: AppHttpService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appHelpersService: AppHelpersService,
        private appAlertService: AppAlertService,
        private appUserService: AppUserService
    ) {}

    ngOnInit() {
        this.appUserService.setLoggedInUIState('false');

        const urlParams = this.appHelpersService.getUrlParams();
        if (urlParams && 'auth_as' in urlParams && urlParams['auth_as']) {
            this.newUser.username = urlParams['auth_as'];
            this.auth_as = urlParams['auth_as'];
        }
        if (urlParams && 'redirect' in urlParams && urlParams['redirect']) {
            this.appUserService.setRedirectPath(urlParams['redirect']);
        }
    }

    ngAfterViewInit() {
        this.appLoadingIndicatorService.hide(350);
        setTimeout(() => this.inputEmail.nativeElement.focus());
    }

    ngOnDestroy() {
        this.appAlertService.destroy();
    }

    processSignUp(newUserForm: NgForm) {
        if (this.captcha) {
            this.appLoadingIndicatorService.show('view');
            this.appAlertService.destroy();
            this.error = false;

            this.appHttpService.request(
                'POST:api/auth/register/',
                this.newUser,
                (response) => {
                    newUserForm.resetForm();
                    this.verificationCode.verification_key =
                        response['verificationKey'];
                    if (Utils.validateEmail(response['verifyEmailOrPhone'])) {
                        this.verificationCode.email =
                            response['verifyEmailOrPhone'];
                    } else {
                        this.verificationCode.phone =
                            response['verifyEmailOrPhone'];
                    }
                    this.step = 2;
                    setTimeout(() => this.inputCode.nativeElement.focus());
                    this.appLoadingIndicatorService.hide(200);
                },
                (error) => {
                    this.newUser.password = '';
                    this.newUser.confirm_password = '';
                    if (
                        error &&
                        error.status &&
                        error.status === 400 &&
                        error.data
                    ) {
                        const msg = error.data[Object.keys(error.data)[0]][0];
                        this.appAlertService.set('view', 'error', msg);
                    } else {
                        this.appAlertService.set(
                            'view',
                            'error',
                            this.messages.error
                        );
                    }
                    this.appLoadingIndicatorService.hide(200);
                }
            );
        }
    }

    processVerify(verifyForm: NgForm) {
        this.appLoadingIndicatorService.show('view');
        this.error = false;

        this.appHttpService.request(
            'POST:api/auth/verify/',
            this.verificationCode,
            (response) => {
                verifyForm.resetForm();
                this.appLoadingIndicatorService.hide(200);
                this.appHttpService.apiAuthMe(response['authToken'], () => {
                    const redirectPath = this.appUserService.getRedirectPath();
                    if (redirectPath) {
                        this.appUserService.clearRedirectPath();
                        this.router.navigateByUrl(redirectPath);
                    } else {
                        this.router.navigateByUrl('/dashboard');
                    }
                });
            },
            () => {
                this.error = true;
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    navigateToGoogleSso() {
        const baseUrl = this.appHelpersService.getBaseUrl();
        location.href = baseUrl + 'api/auth/social/login/google-oauth2';
    }
}
