import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppHttpService } from '../../../../../../../../shared/services/app.http.service';
import { AppMessageService } from '../../../../../../../../shared/services/app.message.service';
import { AppLoadingIndicatorService } from '../../../../../../../../shared/services/app.loading-indicator.service';
import { AppHelpersService } from '../../../../../../../../shared/services/app.helpers.service';
import { AppMessage } from '../../../../../../../../shared/app.messages';
import { Image } from '../../../../../../../../shared/classes/images/image';
import { ImageCrop } from '../../../../../../../../shared/classes/images/image.crop';

@Component({
    templateUrl: './dialog.questions.image.component.html',
    styleUrls: ['./dialog.questions.image.component.less'],
})
export class DialogQuestionsImageComponent implements OnInit {
    mode: string;
    title: string;
    existingImage: object;
    existingImageUrl: string;

    private selectedImage;
    private vingImage: Image;

    constructor(
        public dialogRef: MatDialogRef<DialogQuestionsImageComponent>,
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            mode: string;
            title: string;
            existingImage: object;
            existingImageUrl: string;
            callback: Function;
        }
    ) {}

    ngOnInit() {
        this.mode = this.data.mode;
        this.title = this.data.title;
        this.existingImage = this.data.existingImage;
        this.existingImageUrl = this.data.existingImageUrl;
        this.selectedImage = {
            image: this.existingImage,
            selectedFile: null,
        };
    }

    save() {
        this.appLoadingIndicatorService.show('modal', 'Saving...', () => {
            if (this.isNewImage()) {
                // New image.
                this.appHttpService.apiAwsUploadFile(
                    this.selectedImage.selectedFile,
                    (fileKey) => {
                        const crop = this.selectedImage.image.crop,
                            vingImageCrop = new ImageCrop(
                                crop.startX,
                                crop.startY,
                                crop.width,
                                crop.height
                            );

                        this.vingImage = new Image(fileKey, vingImageCrop);
                        this.data.callback(this.dialogRef, this.vingImage);
                    },
                    () => {
                        this.appLoadingIndicatorService.hide(350);
                        this.appMessageService.show(
                            'modal',
                            'error',
                            AppMessage.get('awsUploadError'),
                            5
                        );
                    }
                );
            } else {
                if (this.isEditCropForExistingImage()) {
                    // Existing image, change to crop.
                    const crop = this.selectedImage.image.crop,
                        vingImageCrop = new ImageCrop(
                            crop.startX,
                            crop.startY,
                            crop.width,
                            crop.height
                        );

                    this.vingImage = new Image(
                        this.existingImage['key'],
                        vingImageCrop
                    );
                    this.data.callback(this.dialogRef, this.vingImage);
                } else if (this.isImageRemoved()) {
                    // Removing image.
                    this.data.callback(this.dialogRef, {});
                } else {
                    // Keep old image.
                    this.data.callback(this.dialogRef, this.existingImage);
                }
            }
        });
    }

    updateImage(imageData) {
        // Set image data.
        this.selectedImage = imageData;

        if (this.isImageRemoved()) {
            // Set image data.
            this.existingImage = null;
            this.existingImageUrl = null;
        }
    }

    private isImageRemoved(): boolean {
        return (
            'imageRemoved' in this.selectedImage &&
            this.selectedImage.imageRemoved === true
        );
    }

    private isNewImage(): boolean {
        const file = this.selectedImage.selectedFile;
        return Boolean(file) && typeof file === 'object';
    }

    private isEditCropForExistingImage(): boolean {
        const file = this.selectedImage.selectedFile;
        return Boolean(file) && typeof file === 'boolean' && file === true;
    }
}
