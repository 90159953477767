import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import {
    NgxFileDropEntry,
    FileSystemFileEntry,
    FileSystemDirectoryEntry,
} from 'ngx-file-drop';

import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppAlertService } from '../../../../shared/services/app.alert.service';
import { ContactService } from '../../services/contact.service';
import { AppMessage } from '../../../../shared/app.messages';
import { ContactFile } from '../../classes/contact-file';
import * as $ from 'jquery';

@Component({
    templateUrl: './dialog-upload-contacts.component.html',
    styleUrls: ['./dialog-upload-contacts.component.less'],
})
export class DialogUploadContactsComponent {
    messages = {
        success:
            'Your contacts have successfully started importing. We will display the results within your <a href="/user/profile#history">History</a>.',
        error: 'Please ensure that the file is valid.',
    };
    contactIsFileOver = false;
    contactFile: ContactFile;
    private selectedFile = undefined;
    private tempAwsRequest = undefined;
    success = false;
    error = false;

    constructor(
        private cd: ChangeDetectorRef,
        private http: HttpClient,
        private appHttpService: AppHttpService,
        private appHelpersService: AppHelpersService,
        private appAlertService: AppAlertService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private contactService: ContactService,
        public dialogRef: MatDialogRef<DialogUploadContactsComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            callback: any;
        }
    ) {}

    close() {
        this.appAlertService.destroy();
    }

    openFileDialog() {
        $('input[type="file"]').trigger('click');
    }

    save() {
        if (this.isFileSet()) {
            this.appLoadingIndicatorService.show('modal');

            if (this.contactFile.fileKey) {
                this.apiUploadContacts();
            } else {
                this.tempAwsRequest = this.appHttpService.apiAwsUploadFile(
                    this.selectedFile,
                    (key) => {
                        delete this.tempAwsRequest;
                        this.contactFile.fileKey = key;
                        this.apiUploadContacts();
                    },
                    () => {
                        this.error = true;
                        this.appLoadingIndicatorService.hide(350);
                        this.appAlertService.set(
                            'view',
                            'error',
                            AppMessage.get('awsUploadError'),
                            false
                        );
                    }
                );
            }
        }
    }

    reset() {
        this.contactFile = undefined;
        this.selectedFile = undefined;

        // Kill the request.
        if (this.tempAwsRequest) {
            this.tempAwsRequest.unsubscribe();
        }

        this.appAlertService.destroy();
        this.contactService.killValidationJobs();

        // Reset the flags
        this.success = false;
        this.error = false;
    }

    selectFile(event: any): void {
        this.appAlertService.destroy();
        this.contactIsFileOver = false;

        const files = this.getFilesArrayFromFileList(event.target.files);

        // Validate each dropped in file.
        files.forEach((file: File) => {
            if (this.isValidFile(file)) {
                // Create new ContactFile object from the uploadFile.
                const cFile = <ContactFile>{};

                cFile.id = this.appHelpersService.generateUniqueId();
                cFile.name = file.name;
                cFile.size = this.appHelpersService.bytesToSize(file.size);
                this.contactFile = cFile;
                this.selectedFile = file;
            } else {
                this.appAlertService.set(
                    'view',
                    'error',
                    'Invalid file selected.',
                    false
                );
            }
        });

        this.cd.detectChanges();
    }

    contactFilesDropped(files: NgxFileDropEntry[]) {
        this.appAlertService.destroy();
        this.contactIsFileOver = false;

        for (let droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    // Here you can access the real file
                    if (this.isValidFile(file)) {
                        const cFile = <ContactFile>{};

                        cFile.id = this.appHelpersService.generateUniqueId();
                        cFile.name = file.name;

                        fileEntry.file((f: File) => {
                            cFile.size = this.appHelpersService.bytesToSize(
                                f.size
                            );
                            this.contactFile = cFile;
                            this.selectedFile = f;
                        });

                        this.cd.detectChanges();
                    }
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry =
                    droppedFile.fileEntry as FileSystemDirectoryEntry;
                droppedFile = null;
                this.contactIsFileOver = false;
                this.appAlertService.set(
                    'view',
                    'error',
                    'Invalid file selected.',
                    false
                );
            }
        }
    }

    removeFromFilesList(id: string) {
        this.contactFile = undefined;
        this.selectedFile = undefined;

        // Kill the request.
        if (this.tempAwsRequest) {
            this.tempAwsRequest.unsubscribe();
        }

        this.cd.detectChanges();
        this.appAlertService.destroy();
    }

    isFileSet() {
        return Boolean(this.selectedFile) && Boolean(this.contactFile);
    }

    private apiUploadContacts() {
        this.appAlertService.destroy();
        if (this.contactFile.fileKey) {
            const uploadData = {
                file_key: this.contactFile.fileKey,
            };
            this.appLoadingIndicatorService.show('modal');
            this.contactService.apiUploadContacts(
                uploadData,
                () => {
                    this.data.callback(() => {
                        this.success = true;
                        this.appAlertService.set(
                            'view',
                            'success',
                            this.messages.success,
                            false
                        );
                    });
                },
                (err) => {
                    this.error = true;
                    const error =
                        err.data &&
                        this.appHelpersService.isValidString(err.data)
                            ? err.data
                            : this.messages.error;
                    this.appLoadingIndicatorService.hide(350);
                    this.appAlertService.set('view', 'error', error, false);
                }
            );
        } else {
            this.appLoadingIndicatorService.hide(350);
            this.appAlertService.set(
                'view',
                'error',
                this.messages.error,
                false
            );
        }
    }

    private getFilesArrayFromFileList(filesObject: FileList) {
        const files: Array<File> = [];

        Object.keys(filesObject).forEach((key) => {
            files.push(filesObject[key]);
        });

        return files;
    }

    private isValidFile(file: File) {
        return file && this.appHelpersService.isValidContactFile(file);
    }
}
