<div class="app-dialog-browser-compatibility">
    <div class="header">Browser Compatibility</div>

    <div class="body">
        <p>
            The browser (or browsing mode) that you're using doesn't fully
            support our application's requirements. You can try disabling
            private browsing but for the best possible experience, please use
            one of the following:
        </p>

        <div class="browsers">
            <div class="item">
                <div class="img">
                    <img src="/assets/images/browsers/firefox.png" />
                </div>
                <span>Mozila Firefox</span>
            </div>
            <div class="item">
                <div class="img">
                    <img src="/assets/images/browsers/chrome.png" />
                </div>
                <span>Google Chrome</span>
            </div>
            <!-- <div class="item">
                <div class="img">
                    <img src="/assets/images/browsers/edge.png" />
                </div>
                <span>Microsoft Edge</span>
            </div>
            <div class="item">
                <div class="img">
                    <img src="/assets/images/browsers/safari.png" />
                </div>
                <span>Safari</span>
            </div> -->
        </div>
    </div>

    <div class="footer">
        <button class="app-button light w-90" (click)="onConfirm()">
            Cancel
        </button>
    </div>
</div>
