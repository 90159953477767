import {
    Component,
    Input,
    OnInit,
    OnChanges,
    AfterViewInit,
    OnDestroy,
    EventEmitter,
    forwardRef,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

@Component({
    selector: 'app-form-field',
    templateUrl: './app.form-field.html',
    styleUrls: ['./app.form.field.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AppFormFieldComponent),
            multi: true,
        },
    ],
})
export class AppFormFieldComponent
    implements
        ControlValueAccessor,
        OnInit,
        OnChanges,
        AfterViewInit,
        OnDestroy
{
    private _value: any = '';

    fieldName: string;
    fieldCss: string;
    fieldLabel: string;
    fieldOptions: Array<string>;
    fieldPlaceholder: string;
    fieldHelpText: string;
    fieldIsRequired: boolean;
    fieldIsDisabled: boolean;
    fieldErrorText: string;
    fieldMin: number;
    fieldMax: number;
    fieldMarginSize: string;
    mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]; // For datepicker
    maskedInput: any; // For datepicker

    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() type: string; // text, password, select, readonly
    @Input() name: string;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() options: Array<string>; // For select type
    @Input() helpText: string;
    @Input() hasError: boolean;
    @Input() errorText: string;
    @Input() minlength: number = null;
    @Input() maxlength: number = null;
    @Input() marginSize: string;
    @Input() validate = false;

    @ViewChild('date', { read: ViewContainerRef }) public date; // For datepicker

    ngOnInit() {
        this.fieldPlaceholder = this.placeholder ? this.placeholder : '';
        this.hasError = Boolean(this.hasError === true ? true : false);
        this.fieldIsRequired = Boolean(this.required === true ? true : false);
        this.fieldIsDisabled = Boolean(this.disabled === true ? true : false);
        this.fieldName = this.name;
        this.fieldCss = this.type.toString().toLowerCase();
        this.fieldLabel = this.label ? this.label : '';
        this.fieldOptions = this.options ? this.options : [];
        this.fieldHelpText = this.helpText;
        this.fieldErrorText = this.errorText;
        this.fieldMin = this.minlength;
        this.fieldMax = this.maxlength;
        this.fieldMarginSize = this.marginSize ? this.marginSize : '';
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.type === 'date') {
                this.maskedInput = textMask.maskInput({
                    inputElement: this.date.element.nativeElement,
                    mask: this.mask,
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.maskedInput) {
            this.maskedInput.destroy();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.required &&
            changes.required.previousValue !== changes.required.currentValue
        ) {
            this.fieldIsRequired = Boolean(
                changes.required.currentValue === true ? true : false
            );
        }
    }

    // Placeholders for the callbacks
    private _onTouchedCallback: () => {};
    private _onChangeCallback: (_: any) => {};

    // Get accessor
    get value(): any {
        return this._value;
    }

    // Set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            const val = v ? (typeof v === 'string' ? v.trim() : v) : v;
            this._onChangeCallback(val);
        }
    }

    // From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this._value) {
            this._value = value;
        }
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this._onTouchedCallback = fn;
    }

    // Set touched on blur
    onTouched() {
        this._checkError();
        this._onTouchedCallback();
    }

    // Set status on focus
    onFocus() {
        this._checkError();
    }

    // Set status on keyup
    onKeyup() {
        this._checkError();
    }

    private _checkError(): void {
        if (this.validate && this.type !== 'date') {
            if (
                this.value &&
                this.value.trim().length >= this.fieldMin &&
                this.value.trim().length <= this.fieldMax
            ) {
                this.hasError = false;
            } else if (this.value && !this.fieldMin && !this.fieldMax) {
                this.hasError = false;
            } else {
                this.hasError = true;
            }
        }
    }
}
