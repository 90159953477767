import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssignmentsComponent } from './assignments.component';
import { AuthGuard } from '../../shared/guards/auth.guard';

const assignmentsRoutes: Routes = [
    {
        path: 'user/assignments',
        component: AssignmentsComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(assignmentsRoutes)],
    exports: [RouterModule],
})
export class AssignmentsRoutingModule {}
