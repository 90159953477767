<ng-select
    [(ngModel)]="selectedResults"
    (ngModelChange)="onChange()"
    [multiple]="true"
    [items]="results$ | async"
    [loading]="peopleLoading"
    [typeahead]="searchInput$"
    [trackByFn]="trackByFn"
    [minTermLength]="2"
    bindLabel="id"
    class="custom scheduled-filter"
    groupBy="group"
    placeholder="Search by name, email or phone..."
    autofocus
>
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <div *ngIf="item.group === 'Contacts'">
            <div class="contact">
                <i class="material-icons icon select-icon">person</i>
                <span class="name"> {{ item.name }} </span>
                <span class=""> {{ item.method }} </span>
                <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                >
            </div>
        </div>
        <div *ngIf="item.group === 'Filters'">
            <div class="filter">
                <i class="material-icons icon select-icon"
                    >filter_list</i
                >
                <span class="name selected-name-count">
                    {{ item.name }} ({{ item.count }})</span
                >
                <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                >
            </div>
        </div>
        <div *ngIf="item.group === 'Labels'">
            <div class="labels">
                <i class="material-icons icon select-icon"
                    >local_offer</i
                >
                <span class="name selected-name-count">
                    {{ item.name }} <span *ngIf="item.count">({{ item.count }})</span>
                </span>
                <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                >
            </div>
        </div>
        <div *ngIf="item.group === 'Status'">
            <div class="labels">
                <i class="material-icons icon select-icon"
                    >category</i
                >
                <span class="name selected-name-count">
                    {{ item.name }} ({{ item.count }})
                </span>
                <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                >
            </div>
        </div>
        <div *ngIf="item.group === 'fromTable'">
            <div class="lists">
                <i class="material-icons icon select-icon" style="width: 10px;">table</i>
                <span class="name selected-name-count">
                    {{ item.name }} ({{item.contactCount}})</span>
                <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                >
            </div>
        </div>
        <div *ngIf="item.group === 'New Contacts'">
            <div
                *ngIf="item.type === this.types.phone"
                class="new_contact"
            >
                <i class="material-icons icon select-icon">sms</i>
                <span class="name"> {{ item.phone }} </span>
                <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                >
            </div>
            <div
                *ngIf="item.type === this.types.email"
                class="new_contact"
            >
                <i class="material-icons icon select-icon">email</i>
                <span class="name"> {{ item.email }} </span>
                <span
                    class="ng-value-icon right"
                    (click)="clear(item)"
                    aria-hidden="true"
                    >×</span
                >
            </div>
        </div>
    </ng-template>
    <ng-template
        ng-option-tmp
        let-item="item"
        let-index="index"
        let-search="searchTerm"
        class="select2-results__option"
    >
        <div *ngIf="item.type === this.types.contact">
            <div
                class="select2-result-contacts contact {{ item.class }}"
            >
                <i class="material-icons icon select-icon">person</i>
                <span class="name"> {{ item.name }} </span>
                <span *ngIf="item.method" class=""> {{ item.method }}</span>
            </div>
        </div>
        <div *ngIf="item.type === this.types.filter">
            <div class="filter">
                <i class="material-icons icon select-icon"
                    >filter_list</i
                >
                <span class="name"> {{ item.name }} </span> |
                <span *ngIf="item.count !== 1" class="name"
                    >{{ item.count }} people</span
                >
                <span *ngIf="item.count == 1" class="name"
                    >{{ item.count }} person</span
                >
            </div>
        </div>
        <div *ngIf="item.type === this.types.label">
            <div class="labels">
                <i class="material-icons icon select-icon"
                    >local_offer</i
                >
                <span class="name"> {{ item.name }} </span> |
                <span *ngIf="item.count !== 1" class="name"
                    >{{ item.count }} people</span
                >
                <span *ngIf="item.count == 1" class="name"
                    >{{ item.count }} person</span
                >
            </div>
        </div>
        <div *ngIf="item.type === this.types.status">
            <div class="statuses">
                <i class="material-icons icon select-icon"
                    >category</i
                >
                <span class="name"> {{ item.name }} </span> |
                <span *ngIf="item.count !== 1" class="name"
                    >{{ item.count }} people</span
                >
                <span *ngIf="item.count == 1" class="name"
                    >{{ item.count }} person</span
                >
            </div>
        </div>
        <div *ngIf="item.group === 'New Contacts'">
            <div
                class="new_contact"
                *ngIf="item.type === this.types.email"
            >
                <i class="material-icons icon select-icon">email</i>
                <span class="name"> {{ item.email }} </span>
            </div>
            <div
                class="new_contact"
                *ngIf="item.type === this.types.phone"
            >
                <i class="material-icons icon select-icon">sms</i>
                <span class="name"> {{ item.phone }} </span>
            </div>
        </div>
    </ng-template>
</ng-select>
