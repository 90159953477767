import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AppInfoService } from '../../../../shared/services/app-info/app.info.service';
import { AppSelectOption } from '../../../../shared/components/app-select/classes/app.select.option';
import { Utils } from '../../../../shared/app.utils';

@Component({
    selector: 'app-contact-card',
    styleUrls: ['./contact.card.component.less'],
    templateUrl: './contact.card.component.html',
})
export class ContactCardComponent implements OnInit {
    private lastSearchText = '';
    private searchSubject: Subject<string> = new Subject();

    searchText: string;
    @Input() contact: any;
    @Input() origin: any;
    @Input() selectedStatus: AppSelectOption;
    @Input() selectedContactIds: Array<number>;
    @Input() excludedContactIds: Array<number>;
    @Input() selectedAllContacts: boolean;
    @Input() selectedContact: any;

    @Output() handleToggleContactId = new EventEmitter<any>();
    @Output() handleSelectContact = new EventEmitter<any>();

    constructor(
        private router: Router,
        private appInfoService: AppInfoService
    ) {}

    ngOnInit() {
        const appInfo = this.appInfoService.getAppInfo();
    }

    getContactClass(id: number): string {
        const isContactSelected =
            this.selectedContact && this.selectedContact.id === id;
        return isContactSelected ? 'contact selected' : 'contact';
    }

    getSelectedContactClass(contactId) {
        let contactIsSelected = false;
        if (this.selectedAllContacts) {
            contactIsSelected =
                this.excludedContactIds.indexOf(contactId) === -1;
        } else {
            contactIsSelected = this.selectedContactIds.indexOf(contactId) >= 0;
        }
        return contactIsSelected ? 'selected-icon selected' : 'selected-icon';
    }
    toggleSelectedContactId(id: number): void {
        this.handleToggleContactId.emit(id);
    }

    selectContact(contact) {
        this.handleSelectContact.emit(contact);
    }

    get2LetterName(name: string) {
        return Utils.get2LetterName(name);
    }
}
