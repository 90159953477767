export class AppMessage {
    static get(name: string) {
        const messages = {
            awsUploadError:
                'A problem occurred while uploading the file. Please try again.',
            messageCannotBeFound: '--- Message cannot be found ---',
        };

        return name in messages
            ? messages[name]
            : messages['messageCannotBeFound'];
    }
}
