<div class="app-dialog dialog-question-image">
    <div class="header">{{ title }} Image</div>

    <div class="body">
        <app-avatar-cropper
            *ngIf="mode === 'create'"
            type="create"
            dimensions="429x322"
            name="questionImage"
            cancelBtnLbl="Back"
            saveBtnLbl="Set"
            [replaceable]="false"
            [showActions]="false"
            (updateImage)="updateImage($event)"
        >
        </app-avatar-cropper>

        <app-avatar-cropper
            *ngIf="mode === 'edit'"
            type="edit"
            dimensions="429x322"
            name="questionImage"
            cancelBtnLbl="Back"
            saveBtnLbl="Set"
            [replaceable]="false"
            [showActions]="false"
            [originalAvatar]="existingImage"
            [originalAvatarUrl]="existingImageUrl"
            (updateImage)="updateImage($event)"
        >
        </app-avatar-cropper>
    </div>

    <div class="footer">
        <button mat-dialog-close class="app-button light w-90 mr-1">
            Cancel
        </button>
        <button type="submit" class="app-button green w-90" (click)="save()">
            Save
        </button>
    </div>
</div>
