<div class="app-dialog dialog-custom-filter">
    <div class="header">
        {{ originalCustomFilter ? 'Update' : 'Create' }} Custom Contact Filter
    </div>

    <form #customFilterForm="ngForm">
        <div class="body">
            <label>Save custom contact filter as:</label>
            <div class="input-group">
                <input
                    type="text"
                    name="filtername"
                    [(ngModel)]="customFilter.name"
                    required
                />
            </div>
            <label>Search Query</label>
            <p>
                <span
                    class="item"
                    *ngFor="let query of customFilter.queries"
                    [title]="query"
                >
                    {{ query }}
                </span>
            </p>
            <label>Labels</label>
            <p>
                <span
                    class="item"
                    *ngFor="let label of customFilter.labels"
                    [title]="label | uppercase"
                >
                    {{ label | uppercase }}
                </span>
            </p>
        </div>

        <div class="footer">
            <button mat-dialog-close class="app-button light w-90 mr-1">
                Cancel
            </button>
            <button
                type="submit"
                class="app-button green w-90"
                [disabled]="
                    !customFilterForm.form.valid || !customFilterForm.form.dirty
                "
                (click)="saveCustomFilter()"
            >
                Save
            </button>
        </div>
    </form>
</div>
