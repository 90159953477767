import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppHelpersService } from '../../services/app.helpers.service';
import { AppSelectOption } from '../app-select/classes/app.select.option';

@Component({
    selector: 'app-select-multi',
    templateUrl: './app.select.multi.component.html',
    styleUrls: ['./app.select.multi.component.less'],
})
export class AppSelectMultiComponent implements OnInit {
    values = {
        calculated: {
            width: undefined,
            optionsWidth: undefined,
            height: undefined,
            lineHeight: undefined,
            scrollHeight: undefined,
            top: undefined,
            fontSize: undefined,
        },
        display: {
            width: undefined,
            optionsWidth: undefined,
            height: undefined,
            lineHeight: undefined,
            scrollHeight: undefined,
            top: undefined,
            fontSize: undefined,
        },
    };
    isClicked = false;
    selectedValues: Array<string> = [];
    optionsList: Array<AppSelectOption> = [];
    optionsRight: string;

    private defaultDisplayDirection = 'bottom'; // 'top', 'bottom'; Future: 'left', 'right', 'auto'
    private defaultHeight = 30;
    private defaultScrollHeight = 105;
    private defaultFontSize = 12;
    private defaultWidth = 125;
    private defaultOptionsRight = '-5px';
    private selectedOptions: Array<AppSelectOption> = [];

    @Input() label: string;
    @Input() options: Array<AppSelectOption>;
    @Input() selected: Array<AppSelectOption> = [];
    @Input() width: number;
    @Input() height: number;
    @Input() roundBorders: boolean;
    @Input() displayDirection: string;
    @Input() scrollHeight: number;

    @Output() update: EventEmitter<Array<AppSelectOption>> = new EventEmitter<
        Array<AppSelectOption>
    >();

    constructor(private appHelpersService: AppHelpersService) {}

    ngOnInit() {
        // Initialize scroll height & options right.
        this.scrollHeight = this.scrollHeight
            ? this.scrollHeight
            : this.defaultScrollHeight;

        // Initialize columns.
        this.initializeColumnsLists();

        // Initialize direction.
        if (!this.displayDirection) {
            this.displayDirection = this.defaultDisplayDirection;
        }

        // Calculate values.
        this.values.calculated.width = this.width
            ? this.width
            : this.defaultWidth;
        this.values.calculated.optionsWidth = this.calculateOptionsWidth();
        this.values.calculated.height = this.height
            ? this.height
            : this.defaultHeight;
        this.values.calculated.lineHeight = this.values.calculated.height + 2;
        this.values.calculated.scrollHeight = this.scrollHeight;
        this.values.calculated.top = this.calculateTop();
        this.values.calculated.fontSize = this.height
            ? this.height * 0.4
            : this.defaultFontSize;
        this.optionsRight = this.calculateOptionsRight();

        // Using values, calculate display values.
        Object.keys(this.values.calculated).forEach((value) => {
            this.values.display[value] = this.values.calculated[value] + 'px';
        });

        this.selected.forEach((option) => {
            this.selectedValues.push(option.value);
        });
        this.selectedOptions = this.selected;
    }

    isOptionSelected(option: AppSelectOption): boolean {
        return this.selectedValues.indexOf(option.value) >= 0;
    }

    toggleOptionClick(option: AppSelectOption): void {
        if (this.isOptionSelected(option)) {
            // Remove from selected values.
            const index = this.selectedValues.indexOf(option.value);
            this.selectedValues.splice(index, 1);

            // Remove from selected options.
            const optionsIndex = this.appHelpersService.findIndexByProperty(
                this.selectedOptions,
                'value',
                option.value
            );
            this.selectedOptions.splice(optionsIndex, 1);
        } else {
            // Add to selected values.
            this.selectedValues.push(option.value);

            // Add to selected options.
            this.selectedOptions.push(option);
        }

        // Sort selected values.
        this.selectedValues.sort();

        // Process changes.
        this.update.emit(this.selectedOptions);
    }

    hideMenuAndProcessChanges(): void {
        // Process changes.
        this.update.emit(this.selectedOptions);

        // Hide menu.
        this.isClicked = false;
    }

    getNumberItemsSelected(): string {
        let numberSelected = '';

        if (this.selectedValues.length > 0) {
            numberSelected = ' (' + this.selectedValues.length + ')';
        }

        return numberSelected;
    }

    calculateOptionsWidth(): number {
        const width = this.width ? this.width : this.defaultWidth;
        return width + 10;
    }

    calculateOptionsRight(): string {
        const dropDownWidthAdjustment = this.values.calculated.width / 2;
        const optionsRight = this.defaultOptionsRight;

        return optionsRight;
    }

    calculateTop(): number {
        let style = 0;

        if (this.displayDirection === 'bottom') {
            style = this.values.calculated.height;
        } else if (this.displayDirection === 'top') {
            style = this.values.calculated.scrollHeight * -1;
        }

        return style;
    }

    private initializeColumnsLists(): void {
        for (let i = 0; i < this.options.length; i++) {
            this.optionsList.push(this.options[i]);
        }
    }
}
