import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AppAlertService {
    public location = new BehaviorSubject(null);
    public text = new BehaviorSubject(null);
    public type = new BehaviorSubject(null);
    public close = new BehaviorSubject(true);

    set(location, type, text, close = true) {
        this.location.next(location);
        this.text.next(text);
        this.type.next(type);
        this.close.next(close);
    }

    destroy() {
        this.location.next(null);
        this.text.next(null);
        this.type.next(null);
        this.close.next(true);
    }
}
