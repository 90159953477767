import { ImageCrop } from './image.crop';

export class Image {
    public key: string;
    public crop: ImageCrop;
    public url: string;

    constructor(fileKey: string, crop?: ImageCrop, url?: string) {
        this.key = fileKey;

        if (crop) {
            this.crop = new ImageCrop(
                crop.startX,
                crop.startY,
                crop.width,
                crop.height
            );
        } else {
            this.crop = new ImageCrop(5, 5, 25, 25);
        }

        this.url = url ? url : null;
    }

    public update(fileKey: string, crop?: ImageCrop) {
        this.key = fileKey;
        this.crop = crop;
    }

    public updateCrop(crop: ImageCrop) {
        this.crop = crop;
    }
}
