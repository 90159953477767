import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactsComponent } from './contacts.component';
import { AuthGuard } from '../../shared/guards/auth.guard';

const contactsRoutes: Routes = [
    {
        path: 'contacts',
        redirectTo: 'contacts/',
        canActivate: [AuthGuard],
        pathMatch: 'full',
    },
    {
        path: 'contacts/',
        component: ContactsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'contacts/:page/:id',
        component: ContactsComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(contactsRoutes)],
    exports: [RouterModule],
})
export class ContactsRoutingModule {}
