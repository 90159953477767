import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialog,
} from '@angular/material/dialog';

import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { EditRequirement } from '../../classes/edit-requirement';
import {
    FileSystemDirectoryEntry,
    FileSystemFileEntry,
    NgxFileDropEntry,
} from 'ngx-file-drop';
import { RequirementFile } from '../../classes/requirement-file';
import { RequirementFileStatus } from '../../classes/requirement-file-status';
import { NewRequirement } from '../../classes/new-requirement';
import { AppAlertService } from '../../../../shared/services/app.alert.service';
import * as $ from 'jquery';
import { AppDialogConfirmComponent } from '../../../../shared/dialogs/dialog-confirm/app.dialog-confirm.component';

@Component({
    templateUrl: './dialog-edit-requirement.component.html',
    styleUrls: ['./dialog-edit-requirement.component.less'],
})
export class DialogEditRequirementComponent implements OnInit {
    messages = {
        error: 'Please ensure that the fields are valid.',
        contactHasNotChanged: 'No changes to the requirement have been made.',
    };
    editRequirement = new EditRequirement('', '', new Date(), new Date(), '');
    updatedRequirement: any;
    originalData: any;
    // Avatar
    requirementFile: any;
    requirementFiles: Array<RequirementFile> = [];
    requirementIsFileOver = false;
    selectedFile = undefined;
    originalFile = undefined;
    originalFileUrl = undefined;
    avatarTabHasBeenSelected = false;
    imageRemoved = false;
    private selectedFiles: object = {};
    private newRequirement = new NewRequirement(
        '',
        '',
        new Date(),
        new Date(),
        ''
    );
    requirement: any;
    private filesProcessed = 0;
    private validFiles = 0;
    tempAwsRequests = {};
    private filesSelected: NgxFileDropEntry[];
    clearKey = false;
    multipleSelected = false;
    origin = '';
    allReqs = [];
    originalFileThumbnail: any;
    private imageUrl: string | ArrayBuffer;
    isPDF = false;

    constructor(
        private appHttpService: AppHttpService,
        private appMessageService: AppMessageService,
        private appAlertService: AppAlertService,
        private appHelpersService: AppHelpersService,
        private dialog: MatDialog,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        public dialogRef: MatDialogRef<DialogEditRequirementComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            origin: string;
            allReqs: [];
            requirement: any;
            contactId: number;
            callback: any;
        }
    ) {
        this.originalFile = this.data.requirement.certificateUrl;
        this.originalFileThumbnail =
            this.data.requirement.certificateThumbnailUrl;
        this.originalFileUrl = this.data.requirement.avatarUrl;
    }

    ngOnInit() {
        // Prepare the contact for display within the UI edit form.
        this.initializeRequirement(this.data.requirement);
        // Set the original lists data. Will be used to detect changes when the user saves.
        const originalRequirement = this.appHelpersService.getClone(
            this.data.requirement
        );
        this.origin = this.data.origin;
        this.allReqs = this.data.allReqs;
        delete originalRequirement['id'];
        delete originalRequirement['avatar_url'];
        delete originalRequirement['labels'];
        delete originalRequirement['lists'];
        this.originalData = originalRequirement;
        setTimeout(() => {
            this.appLoadingIndicatorService.hide();
        }, 200);
    }
    save() {
        if (this.origin === 'req-title') {
            this.updatedRequirement = {};
            this.buildUpdatedRequirement();

            // Initial check to see if contact has changed.
            if (!this.hasReqChanged()) {
                this.dialogRef.close();
                this.appMessageService.show(
                    'app',
                    'general',
                    this.messages.contactHasNotChanged,
                    3
                );
            } else {
                this.dialog.open(AppDialogConfirmComponent, {
                    height: '165px',
                    width: '425px',
                    data: {
                        text: 'This will update this requirement for everyone that shares it. Do you want to continue?',
                        callback: () => {
                            this.apiUpdateRequirement();
                        },
                    },
                });
            }
        } else {
            this.updatedRequirement = {
                file: {},
            };
            this.buildUpdatedContactRequirement();

            // Initial check to see if contact has changed.
            if (!this.hasReqChanged()) {
                this.dialogRef.close();
                this.appMessageService.show(
                    'app',
                    'general',
                    this.messages.contactHasNotChanged,
                    3
                );
            } else {
                if (this.requirementFile) {
                    this.apiUpdateContact(this.requirementFile.key);
                } else {
                    this.apiUpdateContact();
                }
            }
        }
    }

    private initializeRequirement(requirement: any) {
        this.editRequirement.title = requirement.title;
        this.editRequirement.summary = requirement.summary;
        this.editRequirement.completed = requirement.completed;
        this.editRequirement.expiration = requirement.expiration;
        this.editRequirement.certificate_key = requirement.certificate_key;
    }

    private apiUpdateContact(file?) {
        const id = this.data.requirement['id'];

        this.appLoadingIndicatorService.show('modal');
        if (file) {
            if (this.clearKey) {
                this.updatedRequirement.certificate_key = '';
            } else {
                this.updatedRequirement.certificate_key = file;
            }
        } else {
            if (this.clearKey) {
                this.updatedRequirement.certificate_key = '';
            } else {
                this.updatedRequirement.certificate_key = null;
            }
        }
        const dateStr = this.updatedRequirement.expiration;
        const completedDateStr = this.updatedRequirement.completed;

        if (this.updatedRequirement.expiration) {
            const date = new Date(dateStr);
            this.updatedRequirement.expiration =
                date.toISOString().split('.')[0] + 'Z';
        } else {
            this.updatedRequirement.expiration = null;
        }

        if (this.updatedRequirement.completed) {
            const completedDate = new Date(completedDateStr);
            this.updatedRequirement.completed =
                completedDate.toISOString().split('.')[0] + 'Z';
        } else {
            this.updatedRequirement.completed = null;
        }
        this.appHttpService.request(
            'PUT:api/requirements/contact/' + this.data.requirement.id + '/',
            {
                completed: this.updatedRequirement.completed,
                expiration: this.updatedRequirement.expiration,
                certificate_key: this.updatedRequirement.certificate_key,
            },
            (requirement) => {
                this.appLoadingIndicatorService.hide(200);
                this.appMessageService.show(
                    'app',
                    'success',
                    'The requirement has been successfully updated',
                    3
                );
                this.dialogRef.close();
            },
            (error) => {
                const msg =
                    error.status === 400 && error.data
                        ? error.data[Object.keys(error.data)[0]][0]
                        : this.messages.error;
                this.appMessageService.show('modal', 'error', msg, 5.0);
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    private apiUpdateRequirement() {
        const id = this.data.requirement['id'];
        this.appLoadingIndicatorService.show('modal');
        this.appHttpService.request(
            'PUT:api/requirements/' + this.data.requirement.requirementId,
            {
                title: this.updatedRequirement.title,
                summary: this.updatedRequirement.summary,
            },
            (requirement) => {
                this.appLoadingIndicatorService.hide(200);
                this.appMessageService.show(
                    'app',
                    'success',
                    'The requirement has been successfully updated',
                    3
                );
                this.dialogRef.close();
            },
            (error) => {
                const msg =
                    error.status === 400 && error.data
                        ? error.data[Object.keys(error.data)[0]][0]
                        : this.messages.error;
                this.appMessageService.show('modal', 'error', msg, 5.0);
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    setContactRequirementData(requirement, requirementPrime) {
        requirementPrime.completed = requirement.completed
            ? requirement.completed
            : null;
        requirementPrime.expiration = requirement.expiration
            ? requirement.expiration
            : null;
    }
    setRequirementData(requirement, requirementPrime) {
        requirementPrime.title = requirement.title ? requirement.title : null;
        requirementPrime.summary = requirement.summary
            ? requirement.summary
            : null;
    }

    private buildUpdatedContactRequirement() {
        this.setContactRequirementData(
            this.editRequirement,
            this.updatedRequirement
        );
    }

    private buildUpdatedRequirement() {
        this.setRequirementData(this.editRequirement, this.updatedRequirement);
    }

    private hasReqChanged() {
        let hasChanged = false;
        if (
            this.clearKey ||
            this.originalData.completed !== this.updatedRequirement.completed ||
            this.originalData.expiration !==
                this.updatedRequirement.expiration ||
            this.requirementFile
        ) {
            hasChanged = true;
        }
        return hasChanged;
    }

    // file uploading

    updateImage(data) {
        // Set file data.
        this.selectedFile = data.selectedFile;
    }

    private isAvatarSet() {
        const hasSelectedFile = Boolean(this.requirementFiles);

        return hasSelectedFile;
    }

    private isValidFile(file: File) {
        return file && this.appHelpersService.isValidComponentFile(file);
    }
    // file uploading

    requirementFileDropped(files: NgxFileDropEntry[]) {
        if (files.length > 1) {
            this.multipleSelected = true;
            this.requirementFiles = [];
            this.selectedFiles = [];
            this.filesProcessed = 0;
            this.validFiles = 0;
            return;
        } else {
            this.multipleSelected = false;
        }
        // resets from previous selection
        this.requirementFiles = [];
        this.selectedFiles = [];
        this.filesProcessed = 0;
        this.validFiles = 0;

        this.appLoadingIndicatorService.show('modal');

        this.requirementIsFileOver = false;

        const count = {
            filesProcessed: this.filesProcessed,
            validFiles: this.validFiles,
        };
        this.filesSelected = files;
        // Validate each dropped in file.
        for (let droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                const reader = new FileReader();
                fileEntry.file((file: File) => {
                    if (
                        this.isValidFile(file) &&
                        this.isFileAllowed(file.name)
                    ) {
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            this.imageUrl = reader.result;
                        };
                        // Create new ComponentFile object from the uploadFile.
                        const requirementFile = <RequirementFile>{};

                        requirementFile.id =
                            this.appHelpersService.generateUniqueId();
                        requirementFile.name = file.name;

                        fileEntry.file((f: File) => {
                            requirementFile.size =
                                this.appHelpersService.bytesToSize(f.size);
                            requirementFile.status =
                                RequirementFileStatus.uploading;
                            requirementFile.valid = this.isValidFile(file);
                            this.requirementFiles.push(requirementFile);
                            this.selectedFiles[requirementFile.id] = f;
                            this.validFiles++;
                            this.filesProcessed++;
                        });
                    } else {
                        if (!this.isFileAllowed(file.name)) {
                            this.displayOnlyCertainFileNotification();
                            this.appLoadingIndicatorService.hide(300);
                        }
                    }
                });
            } else {
                const fileEntry =
                    droppedFile.fileEntry as FileSystemDirectoryEntry;
                droppedFile = null;
                this.requirementIsFileOver = false;
                this.appAlertService.set(
                    'view',
                    'error',
                    'Invalid file selected.',
                    false
                );
            }
        }
        // File upload process...
        const waitAndProcessFileUploads = () => {
            setTimeout(() => {
                if (this.requirementFiles.length === this.filesProcessed) {
                    if (this.requirementFiles.length !== this.validFiles) {
                        const wrongFiles =
                            this.requirementFiles.length - this.validFiles;
                        this.displayWrongFileNotification(wrongFiles);
                    }
                    this.processFileUploads();
                } else {
                    waitAndProcessFileUploads();
                }
            }, 250);
        };

        setTimeout(() => {
            waitAndProcessFileUploads();
        }, 1000);
    }

    isFileAllowed(fileName: string) {
        let isFileAllowed = false;
        const allowedFiles = ['.pdf', '.jpg', '.jpeg', '.png'];
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(fileName);
        if (undefined !== extension && null !== extension) {
            const allowed = allowedFiles.filter(
                (ext) => ext === extension[0].toLowerCase()
            );
            if (allowed[0] === '.pdf') {
                this.isPDF = true;
            }
            if (allowed.length) {
                isFileAllowed = true;
            } else {
            }
        }
        return isFileAllowed;
    }

    private processFileUploads() {
        const requirementFileId = Object.keys(this.selectedFiles);

        requirementFileId.forEach((id) => {
            this.processFileUpload(id);
        });
    }

    private processFileUpload(id: string) {
        const requirementFile = this.requirementFiles.find(
            (rf) => rf.id === id
        );
        if (requirementFile) {
            requirementFile.status = RequirementFileStatus.uploading;

            this.appHttpService.apiAwsUploadFile(
                this.selectedFiles[id],
                (key) => {
                    this.clearKey = false;
                    delete this.selectedFiles[id];
                    delete this.tempAwsRequests[id];
                    requirementFile.key = key;
                    requirementFile.status = RequirementFileStatus.ready;

                    this.requirementFile = requirementFile;
                    this.appLoadingIndicatorService.hide(350);
                },
                () => {
                    requirementFile.status = RequirementFileStatus.error;
                    this.appLoadingIndicatorService.hide(350);
                }
            );
        }
    }

    private displayWrongFileNotification(num = 0): void {
        if (num > 0) {
            this.appMessageService.show(
                'app',
                'error',
                'The file has been discarded because of missing or invalid extension.',
                3
            );
        }
    }
    private displayOnlyCertainFileNotification(): void {
        this.appMessageService.show(
            'app',
            'error',
            'The file has been discarded because of an invalid extension, please only upload images and pdfs.',
            3
        );
    }

    openFileDialog() {
        $('input[type="file"]').trigger('click');
    }

    clearRequirementFiles() {
        this.requirementFiles = [];
        this.selectedFiles = [];
        this.clearKey = true;
    }

    openInNewTab() {
        window.open(this.originalFile, '_blank');
    }

    clearOriginalFile() {
        this.clearKey = true;
        this.originalFile = null;
    }
}
