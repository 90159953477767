import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';

import { AppControlsModule } from '../../shared/modules/app.controls.module';
import { AppPipesModule } from '../../shared/modules/app.pipes.module';
import { SubmissionsRoutingModule } from './submissions.routing.module';
import { SubmissionsComponent } from './submissions.component';
import { DialogListMessagesComponent } from './dialogs/dialog-list-messages/dialog-list-messages.component';
import { MatTabsModule } from '@angular/material/tabs';

export function getWindow() {
    return window;
}

export function getLocalStorage() {
    return localStorage;
}

export function getLocation() {
    return location;
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};

@NgModule({
    imports: [
        AppControlsModule,
        AppPipesModule,
        SubmissionsRoutingModule,
        PerfectScrollbarModule,
        MatTabsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [SubmissionsComponent, DialogListMessagesComponent],
    providers: [
        { provide: 'window', useFactory: getWindow },
        { provide: 'storage', useFactory: getLocalStorage },
        { provide: 'location', useFactory: getLocation },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
    ],
    entryComponents: [
        /*Dialogs*/
        DialogListMessagesComponent,
    ],
})
export class SubmissionsModule {}
