import { NgModule } from '@angular/core';
import {
    RecaptchaModule,
    RECAPTCHA_SETTINGS,
    RecaptchaSettings,
} from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';

import { AppControlsModule } from '../../shared/modules/app.controls.module';
import { AccountsRoutingModule } from './accounts.routing.module';

import { LoginComponent } from './components/login/login.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import {ActivateComponent} from "./components/activate/activate.component";
import {LogoutComponent} from "./components/logout/logout.component";
import {ForgotPasswordComponent} from "./components/forgot-password/forgot-password.component";
import {SsoComponent} from "./components/sso/sso.component";
import {DialogMergeUsersComponent} from "./dialogs/dialog-merge-users/dialog-merge-users.component";

@NgModule({
    imports: [
        AppControlsModule,
        AccountsRoutingModule,
        RecaptchaModule,
        RecaptchaFormsModule,
    ],
    declarations: [
        LoginComponent,
        SignInComponent,
        SignUpComponent,
        ActivateComponent,
        LogoutComponent,
        ForgotPasswordComponent,
        SsoComponent,
        DialogMergeUsersComponent,
    ],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: '6LeXo5sUAAAAAIKXo4vO_5Ya75XRr-lQqv1rINRc',
            } as RecaptchaSettings,
        },
    ],
})
export class AccountsModule {}
