<div class="app-dialog dialog-reset-password">
    <form
        (ngSubmit)="handlePasswords(passwordForm)"
        #passwordForm="ngForm"
        novalidate
    >
        <div class="header">
            {{ title }}
        </div>

        <div class="body">
            <div class="container">
                <!--New Password-->
                <app-form-field
                    type="password"
                    name="newPassword"
                    [required]="true"
                    [(ngModel)]="newPassword"
                    label="New Password"
                    helpText="Passwords must be 8-50 characters and contain letters, numbers and special characters. You may not use over 3 repeated or sequential characters."
                    [hasError]="newPassword == confirmPassword && newPassword.length && !passwordForm.form.valid"
                    errorText="Your password does not meet the requirements."
                    minlength="8"
                    maxlength="50"
                    #password="ngModel"
                    password
                    required
                >
                </app-form-field>

                <!--New Password Confirm-->
                <app-form-field
                    type="password"
                    name="confirmPassword"
                    [required]="true"
                    [(ngModel)]="confirmPassword"
                    label="Confirm Password"
                    minlength="6"
                    maxlength="50"
                    [hasError]="newPassword !== confirmPassword"
                    errorText="Passwords must match."
                    required
                >
                </app-form-field>
            </div>
        </div>
        <div class="footer">
            <button mat-dialog-close class="app-button light w-90 mr-1">
                Cancel
            </button>
            <button
                type="submit"
                class="app-button green w-90"
                [disabled]="
                    !passwordForm.form.valid || newPassword !== confirmPassword
                "
            >
                Update
            </button>
        </div>
    </form>
</div>
