import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AuthGuard } from '../../shared/guards/auth.guard';
import {SsoComponent} from "./components/sso/sso.component";
import {ActivateComponent} from "./components/activate/activate.component";
import {LogoutComponent} from "./components/logout/logout.component";
import {ForgotPasswordComponent} from "./components/forgot-password/forgot-password.component";

const loginRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'signIn', component: SignInComponent },
            { path: 'signUp', component: SignUpComponent },
        ],
    },
    { path: 'sso', component: SsoComponent },
    { path: 'activate', component: ActivateComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'reset-password', component: ForgotPasswordComponent },
];

@NgModule({
    imports: [RouterModule.forChild(loginRoutes)],
    exports: [RouterModule],
})
export class AccountsRoutingModule {}
