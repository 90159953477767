<div class="app-dialog-list-messages">
    <div class="header">
        Messages

        <button class="close" (click)="onConfirm()">&times;</button>
    </div>

    <div class="body">
        <div class="message" *ngFor="let msg of data.messages">
            <div class="media">
                <img
                    width="45"
                    [src]="
                        msg.sender?.avatarUrl
                            ? msg.sender.avatarUrl
                            : '/assets/images/contacts/contact-image-not-available-opaque-bg.png'
                    "
                    alt="Avatar"
                />
                <div class="media-body">
                    <div class="sender">{{ msg.sender?.name }}</div>
                    <div class="date">{{ getDisplayDate(msg.sent) }}</div>
                </div>
            </div>
            <div class="body" *ngIf="msg.message">
                {{ msg.message }}
            </div>
        </div>
    </div>
</div>
