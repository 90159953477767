import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { Chart } from 'chart.js';

import { AppService } from '../../app.service';
import { AppHttpService } from '../../shared/services/app.http.service';
import { AppHelpersService } from '../../shared/services/app.helpers.service';
import { AppLoadingIndicatorService } from '../../shared/services/app.loading-indicator.service';
import { AppMessageService } from '../../shared/services/app.message.service';
import { AppInfoService } from '../../shared/services/app-info/app.info.service';
import { AppUserService } from '../../shared/services/app.user.service';
import { AppPrototypes } from '../../shared/app.prototypes';
import { AppDialogErrorComponent } from '../../shared/dialogs/dialog-error/app.dialog-error.component';
import { Utils } from '../../shared/app.utils';
import { DialogReadonlyComponent } from '../../shared/dialogs/dialog-readonly/dialog.readonly.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less'],
})
export class DashboardComponent implements OnInit {
    dashboardInfo: any;
    packets = [];
    contacts = [];
    checklists = [];
    activity: any = null;
    submissions: any = null;
    engagement: any = null;
    modified: string;
    lastUpdated: string;
    enforceOrders = {
        public: 'Anyone',
        verified: 'Verified',
        invited: 'Assigned Only',
    };
    contactCardProperties = ['name', 'contactMethods[active]'];
    displayContactChart = false;
    displayAssignmentChart = false;
    displaySubmissionsChart = false;
    chartKeyItems = [
        { name: 'completed', label: 'Completed', color: this.appInfoService.getAppInfo().colorsInfo.analyticsChart.completed },
        { name: 'viewed', label: 'Viewed', color: this.appInfoService.getAppInfo().colorsInfo.analyticsChart.viewed },
        { name: 'unviewed', label: 'Not Viewed', color: this.appInfoService.getAppInfo().colorsInfo.analyticsChart.unviewed },
    ];

    private messages = {
        error: {
            gettingPacket:
                'There was a problem retrieving this from the server. Please try again.',
        },
    };

    constructor(
        private appHttpService: AppHttpService,
        private appService: AppService,
        private appHelpersService: AppHelpersService,
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appInfoService: AppInfoService,
        private appUserService: AppUserService,
        private router: Router,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.appLoadingIndicatorService.show('app', '');
        setTimeout(() => (this.appService.selected.navOption = 'dashboard'), 0);
        const urlParams = this.appHelpersService.getUrlParams();
        this.dashboardInfo = this.appInfoService.getAppInfo().dashboardInfo;

        // Create observables for GET/packets, GET/checklists, and GET/contacts calls.
        const requestObservables = [],
            packetsData = {
                status: this.dashboardInfo.packetsStatus,
                sort: this.dashboardInfo.packetsSort,
                limit: this.dashboardInfo.packetsLimit,
            },
            contactsData = {
                status: this.dashboardInfo.contactsStatus,
                sort: this.dashboardInfo.contactsSort,
                limit: this.dashboardInfo.contactsLimit,
            },
            checklistsData = {
                status: this.dashboardInfo.checklistsStatus,
                sort: this.dashboardInfo.checklistsSort,
                limit: this.dashboardInfo.packetsLimit,
            },
            dashboardData = {};

        requestObservables.push(
            this.appHttpService.requestObserv('GET:api/packets/', packetsData)
        );
        requestObservables.push(
            this.appHttpService.requestObserv('GET:api/contacts/', contactsData)
        );
        requestObservables.push(
            this.appHttpService.requestObserv('GET:api/checklists/', checklistsData)
        );
        requestObservables.push(
            this.appHttpService.requestObserv('GET:api/data/dashboard/', dashboardData)
        );

        forkJoin(requestObservables).subscribe(
            (response) => {
                this.packets = response[0]['results'];
                this.contacts = response[1]['results'];
                this.checklists = response[2]['results'];
                this.engagement = response[3]['engagement'];
                this.activity = response[3]['activity'];
                this.submissions = response[3]['submissions'];
                this.modified = response[3]['modified'];
                this.activity.viewed =
                    this.activity.viewed - this.activity.completed;

                // Initialize chart.
                this.setContactChartData('contacts-chart', this.engagement);
                this.setAssignmentsChartData('vings-chart', this.activity);
                this.setSubmissionsChartData('submissions-chart', this.submissions);
                this.lastUpdated = this.appHelpersService.getDisplayTime(this.modified);

                setTimeout(() => {
                    this.appLoadingIndicatorService.hide(350);
                }, 200);

                this.checkUrlParamsError(urlParams);
            },
            (error) => {
                const errorData = {
                    status: error.status,
                    data: error.error,
                };

                this.appLoadingIndicatorService.hide();
                this.dialog.open(AppDialogErrorComponent, {
                    width: '600px',
                    disableClose: true,
                    data: errorData,
                });
            }
        );
    }

    selectContact(id: number): void {
        this.router.navigateByUrl(`${this.appUserService.getContactDetailsBaseUrl()}/${id}`);
    }

    selectPacket(id: number): void {
        this.router.navigateByUrl('/vings/' + id);
    }

    selectChecklist(id: number): void {
        this.router.navigateByUrl('/checklists/' + id);
    }

    displayInfoDialog(type: string): void {
        if (type === 'activity') {
            this.dialog.open(DialogReadonlyComponent, {
                width: '400px',
                height: '200px',
                disableClose: true,
                data: {
                    title: this.activity.title,
                    text: this.activity.information,
                    link: this.activity.informationLink,
                    link_title: 'Learn more...',
                },
            });
        } else if (type === 'engagement') {
            this.dialog.open(DialogReadonlyComponent, {
                width: '400px',
                height: '200px',
                disableClose: true,
                data: {
                    title: this.engagement.title,
                    text: this.engagement.information,
                    link: this.engagement.informationLink,
                    link_title: 'Learn more...',
                },
            });
        } else {
            this.dialog.open(DialogReadonlyComponent, {
                width: '400px',
                height: '200px',
                disableClose: true,
                data: {
                    title: this.submissions.title,
                    text: this.submissions.information,
                    link: this.submissions.informationLink,
                    link_title: 'Learn more...',
                },
            });
        }
    }

    getPacketViewAccess(privacy: string): any {
        let icon = '';
        let title = '';
        switch (privacy) {
            case 'public':
                icon = 'public';
                title = 'Anyone';
                break;

            case 'verified':
                icon = 'verified_user';
                title = 'Verified';
                break;

            case 'invited':
                icon = 'lock';
                title = 'Invited';
                break;
        }
        return {
            icon: icon,
            title: title,
        };
    }

    get2LetterName(name: string) {
        return Utils.get2LetterName(name);
    }

    private setContactChartData(element: string, data) {
        this.appHelpersService.whenElementPresentDo('#view-chart', () => {
            const chartInvited = document.getElementById(element),
                stats = this.appHelpersService.getClone(data);

            Chart.defaults.global.defaultFontColor = '#757575';
            Chart.defaults.global.defaultFontFamily = 'Lato, sans-serif';
            Chart.defaults.global.elements.line.fill = false;

            chartInvited.innerHTML =
                '<canvas id="canvas-contact-chart"></canvas>';
            const context = document
                .getElementById('canvas-contact-chart')
                ['getContext']('2d');
            const chartData = {
                labels: stats.sentiments.map((sentiment) => sentiment.label),
                datasets: [
                    {
                        data: stats.sentiments.map(
                            (sentiment) => sentiment.count
                        ),
                        backgroundColor: stats.sentiments.map(
                            (sentiment) => sentiment.sentiment
                        ),
                    },
                ],
            };

            this.displayContactChart = stats.total > 0;
            this.drawChart('', context, chartData);
        });
    }

    private setAssignmentsChartData(element: string, data) {
        this.appHelpersService.whenElementPresentDo('#view-chart', () => {
            const chartInvited = document.getElementById(element),
                stats = this.appHelpersService.getClone(data),
                statsTotal = stats.viewed + stats.completed + stats.unviewed;

            Chart.defaults.global.defaultFontColor = '#757575';
            Chart.defaults.global.defaultFontFamily = 'Lato, sans-serif';
            Chart.defaults.global.elements.line.fill = false;

            chartInvited.innerHTML = '<canvas id="canvas-ving-chart"></canvas>';
            const context = document
                .getElementById('canvas-ving-chart')
                ['getContext']('2d');
            const chartData = {
                labels: this.chartKeyItems.map((item) => item.label),
                datasets: [
                    {
                        data: [stats.completed, stats.viewed, stats.unviewed],
                        backgroundColor: [
                            this.appInfoService.getAppInfo().colorsInfo
                                .analyticsChart.completed,
                            this.appInfoService.getAppInfo().colorsInfo
                                .analyticsChart.viewed,
                            this.appInfoService.getAppInfo().colorsInfo
                                .analyticsChart.unviewed,
                        ],
                    },
                ],
            };

            this.displayAssignmentChart = statsTotal > 0;
            this.drawChart('', context, chartData);
        });
    }

    private setSubmissionsChartData(element: string, data) {
        this.appHelpersService.whenElementPresentDo('#view-chart', () => {
            const chartInvited = document.getElementById(element),
                stats = this.appHelpersService.getClone(data),
                statsTotal = stats.total;

            Chart.defaults.global.defaultFontColor = '#757575';
            Chart.defaults.global.defaultFontFamily = 'Lato, sans-serif';
            Chart.defaults.global.elements.line.fill = false;

            chartInvited.innerHTML =
                '<canvas id="canvas-submission-chart"></canvas>';
            const context = document
                .getElementById('canvas-submission-chart')
                ['getContext']('2d');
            const chartData = {
                labels: stats.items.map((item) => item.label),
                datasets: [
                    {
                        data: stats.items.map(
                            (item) => item.count
                        ),
                        backgroundColor: stats.items.map(
                            (item) => item.color
                        ),
                    },
                ],
            };

            this.displaySubmissionsChart = statsTotal > 0;
            this.drawChart('', context, chartData);
        });
    }

    private drawChart(title, context, data) {
        const chart = new Chart(context, {
            type: 'doughnut',
            data: data,
            options: this.getChartOptionsDoughnut(title),
        });
    }

    private getChartOptionsDoughnut(title) {
        return {
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            title: {
                display: false,
                text: title,
            },
            tooltips: {
                mode: 'label',
                backgroundColor: '@app-dark',
            },
            responsive: true,
        };
    }

    private checkUrlParamsError(urlParams): void {
        if (urlParams) {
            if ('error' in urlParams) {
                this.appMessageService.show(
                    'app',
                    'error',
                    this.messages.error[urlParams.error],
                    3.5
                );
            }
        }
    }
}
