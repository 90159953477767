<button
    class="app-button small blue fl-right add-req-button"
    (click)="manageRequirements()"
    [disabled]="!orgHasAccess"
>
    Add Requirement
</button>
<div *ngIf="allReqs" class="selected-contact-requirements">
    <!--No Access Notice-->
    <div class="notice" *ngIf="!orgHasAccess">
        Your organization has limited access to this feature. You'll need to upgrade to create and assign requirements. To learn more, contact:  <a href="mailto:sales@vingapp.com">sales@vingapp.com</a>
    </div>
    <!--Requirements Table-->
    <div class="contacts-requirements-table">
        <app-x-table
            *ngIf="isTableReady"
            [page]="'CONTACT-REQUIREMENTS'"
            [id]="contact.id"
            [results]="results"
            [allReqs]="allReqs"
            [contactName]="contact.name"
            [canSelectRows]="false"
            [microburst]="false"
            [xTableData]="xTableData"
            [xTableFilters]="xTableFilters"
            [analyticsData]="analyticsFilterData"
            [numberOfPages]="numberOfPages"
            [numberOfResults]="results.length"
            [numbersPerPage]="currentPageSize"
            [currentPageIndex]="currentPageIndex"
            [entityName]="entityName"
            [searchDelayMs]="searchDelayMs"
            [updatePaging]="updatePaging.asObservable()"
            (handleUpdateUserTables)="initializeAnalytics(null, true)"
        >
        </app-x-table>
    </div>
</div>
