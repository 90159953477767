export class CreateContact {
    constructor(
        public name: string,
        public emails: string,
        public phones: string,
        public title: string,
        public hireDate: string,
        public separationDate: string,
        public internalId: string
    ) {}
}
