<div class="app-dialog dialog-create-contact">
    <div class="header">Create</div>

    <form (ngSubmit)="save()" #createContactForm="ngForm">
        <div class="body">
            <div class="app-tabs">
                <div *ngFor="let tab of tabs" [class]="getTabClass(tab.name)">
                    <div class="text" (click)="selectTab(tab.name)">
                        {{ tab.label }}
                    </div>
                </div>
            </div>

            <div class="app-tabs-container">
                <!--Profile-->
                <div [class]="getTabContainerClass('profile')">
                    <!--Name-->
                    <app-form-field
                        type="text"
                        label="Name"
                        name="name"
                        placeholder="Full name..."
                        maxlength="75"
                        [required]="true"
                        [(ngModel)]="createContact.name"
                    >
                    </app-form-field>
                    <div class="row">
                        <div class="column">
                            <!--Emails-->
                            <app-form-field
                                type="textarea"
                                label="Emails"
                                name="emails"
                                placeholder="Add emails, one on each line..."
                                [required]="false"
                                [(ngModel)]="createContact.emails"
                                [hasError]="!isValid.emails"
                                errorText="One of the emails is invalid or duplicated."
                            >
                            </app-form-field>
                        </div>
                        <div class="column">
                            <!--Phones-->
                            <app-form-field
                                type="textarea"
                                label="Phones"
                                name="phones"
                                placeholder="Add phones, one on each line..."
                                [required]="false"
                                [(ngModel)]="createContact.phones"
                                [hasError]="!isValid.phones"
                                errorText="One of the phones is invalid or duplicated."
                            >
                            </app-form-field>
                        </div>
                    </div>
                </div>

                <!--Details-->
                <div [class]="getTabContainerClass('details')">
                    <!--Job title-->
                    <app-form-field
                        type="text"
                        label="Job Title"
                        name="title"
                        placeholder="Job Title..."
                        maxlength="75"
                        [required]="false"
                        [(ngModel)]="createContact.title"
                    >
                    </app-form-field>
                    <!--Start Date-->
                    <app-form-field
                        type="date"
                        label="Start Date"
                        name="hireDate"
                        placeholder="MM/DD/YYYY"
                        [required]="false"
                        [(ngModel)]="createContact.hireDate"
                    >
                    </app-form-field>
                    <!--Separation Date-->
                    <app-form-field
                        type="date"
                        label="Separation Date"
                        name="separationDate"
                        placeholder="MM/DD/YYYY"
                        [required]="false"
                        [(ngModel)]="createContact.separationDate"
                    >
                    </app-form-field>
                    <!--Internal ID-->
                    <app-form-field
                        type="text"
                        label="Internal ID"
                        name="internalId"
                        placeholder="Internal ID..."
                        maxlength="75"
                        [required]="false"
                        [hasError]="!isValid.internalId"
                        [(ngModel)]="createContact.internalId"
                    >
                    </app-form-field>
                </div>

                <!--Avatar-->
                <div [class]="getTabContainerClass('avatar')">
                    <app-avatar-cropper
                        type="create"
                        dimensions="429x322"
                        name="contactFile"
                        cancelBtnLbl="Back"
                        saveBtnLbl="Set"
                        [replaceable]="true"
                        [showActions]="false"
                        (updateImage)="updateImage($event)"
                    >
                    </app-avatar-cropper>
                </div>
            </div>
        </div>

        <div class="footer">
            <button
                mat-dialog-close
                class="app-button light w-90 mr-1"
                (click)="close()"
            >
                Cancel
            </button>
            <button
                type="submit"
                class="app-button green w-90"
                [disabled]="!createContactForm.form.valid || !allFieldsValid()"
            >
                Create
            </button>
        </div>
    </form>
</div>
