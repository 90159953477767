import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SubmissionsComponent } from './submissions.component';
import { AuthGuard } from '../../shared/guards/auth.guard';

const submissionsRoutes: Routes = [
    {
        path: 'user/checklists',
        component: SubmissionsComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(submissionsRoutes)],
    exports: [RouterModule],
})
export class SubmissionsRoutingModule {}
