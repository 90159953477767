<div class="app-dialog dialog-edit-category">
    <form
        (ngSubmit)="editCategory(categoryForm, $event)"
        #categoryForm="ngForm"
        novalidate
    >
        <div class="header">
            {{ title }}
        </div>

        <div class="body">
            <div class="container">
                <!--Category Name-->
                <app-form-field
                    type="text"
                    name="categoryName"
                    [(ngModel)]="categoryName"
                    maxlength="50"
                    label="New Folder Name:"
                    #category="ngModel"
                    category
                    required
                >
                </app-form-field>
            </div>
        </div>
        <div class="footer">
            <button type="submit" class="app-button green w-90 ms-12">
                Update
            </button>
            <button mat-dialog-close class="app-button light w-90 ms-12">
                Cancel
            </button>
        </div>
    </form>
</div>
