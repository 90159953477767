import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppUser } from '../classes/user/app-user';
import * as $ from 'jquery';

@Injectable()
export class AppUserService {
    private user: AppUser;
    private logoutInterval = 400;
    private isLoggedInSelector = '#app[is-logged-in]';
    public isAuthenticating = false;

    constructor(
        private router: Router,
        @Inject('storage') private storage: Storage
    ) {}

    isLoggedIn(): boolean {
        return !(this.user === null || this.user === undefined);
    }

    isPasswordResetRequired(): boolean {
        if (this.isLoggedIn()) {
            return this.user.passwordResetRequired;
        }
    }

    hasMergeSuggestion(): boolean {
        if (this.isLoggedIn()) {
            if (this.user.mergeSuggestion) {
                if (this.user.mergeSuggestion.verifyMethods && this.user.mergeSuggestion.verifyMethods.length > 0) {
                    return true;
                }
            }
        }

        return false;
    }

    isTokenPresentAndNotLoggedIn() {
        return this.isTokenSet() && !this.isLoggedIn();
    }

    logout(interval: number = this.logoutInterval) {
        $(this.isLoggedInSelector).attr('is-logged-in', 'false');
        this.clearToken();
        this.clearRedirectPath();
        this.user = null;

        setTimeout(() => {
            this.router.navigateByUrl('/login/signIn');
        }, interval);
    }

    setUser(appUser: AppUser) {
        this.user = appUser;

        this.storage['token'] = this.user.token;

        this.whenElementPresentDo(this.isLoggedInSelector, () => {
            this.setLoggedInUIState();
        });

        setTimeout(() => {
            this.isAuthenticating = false;
        }, 100);
    }

    setRedirectPath(path: string) {
        this.storage['redirect'] = path;
    }

    setLoggedInUIState(view = 'true'): void {
        $(this.isLoggedInSelector).attr('is-logged-in', view);
    }

    getUser() {
        return this.user;
    }

    getUserLevel() {
        return this.isLoggedIn() ? this.user.level : null;
    }

    hasPacketsAccess() {
        return this.isLoggedIn() && this.user.organization ? this.user.organization.access.packets : false
    }

    hasChecklistsAccess() {
        return this.isLoggedIn() && this.user.organization ? this.user.organization.access.checklists : false
    }

    hasRequirementsAccess() {
        return this.isLoggedIn() && this.user.organization ? this.user.organization.access.requirements : false
    }

    hasContactsAccess() {
        return this.isLoggedIn() && this.user.organization ? this.user.organization.access.contacts : false
    }

    getContactDetailsBaseUrl() {
        if (this.hasPacketsAccess()) {
            return '/contacts/assignments';
        } else if (this.hasChecklistsAccess()) {
            return '/contacts/submissions';
        } else if (this.hasRequirementsAccess()) {
            return '/contacts/requirements';
        }
    }

    getDisplayName(): string {
        return this.user.firstName + ' ' + this.user.lastName;
    }

    getAvatar(): string {
        return this.user.avatarUrl
            ? this.user.avatarUrl
            : '/assets/images/contacts/contact-image-not-available-opaque-bg.png';
    }

    getToken() {
        return this.isTokenSet() ? this.storage['token'] : null;
    }

    clearToken() {
        if (this.isTokenSet()) {
            delete this.storage['token'];
        }
    }

    isTokenSet() {
        return 'token' in this.storage && this.storage['token'].length;
    }

    getRedirectPath() {
        return this.isRedirectPathSet() ? this.storage['redirect'] : null;
    }

    clearRedirectPath() {
        if (this.isRedirectPathSet()) {
            delete this.storage['redirect'];
        }
    }

    isRedirectPathSet() {
        return 'redirect' in this.storage && this.storage['redirect'].length;
    }

    whenUserSet(callback: Function) {
        const userSetInterval = setInterval(() => {
            if (this.getUser() && 'token' in this.getUser()) {
                clearInterval(userSetInterval);
                callback();
            }
        }, 50);
    }

    whenElementPresentDo(selector: string, callback: Function) {
        const checkElementExists = setInterval(() => {
            if ($(selector).length > 0) {
                callback();
                clearInterval(checkElementExists);
            }
        }, 25); // default: 25
    }
}
