import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppUserService } from '../../../../shared/services/app.user.service';
import { AppFilterService } from '../../../../shared/services/app.filter.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';

@Component({
    template: ``,
})
export class LogoutComponent implements OnInit {
    constructor(
        private router: Router,
        private appUserService: AppUserService,
        private appFilterService: AppFilterService,
        private appHttpService: AppHttpService,
        private appLoadingIndicatorService: AppLoadingIndicatorService
    ) {}

    ngOnInit() {
        this.appLoadingIndicatorService.show('app', '', () => {
            if (this.appUserService.isTokenSet()) {
                this.appHttpService.request('POST:api/auth/logout/', {}, () => {
                    this.appFilterService.reset();
                    this.appUserService.logout();
                });
            } else {
                this.router.navigateByUrl('/login/signIn');
            }
        });
    }
}
