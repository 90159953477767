<div class="sign-up">
    <!--Logo-->
    <div class="logo">
        <img src="/assets/images/app-ving-logo.png" width="150" />
    </div>

    <!--Content-->
    <div class="content">
        <ng-container *ngIf="step === 1">
            <div class="section-header">
                <div class="title">Sign Up</div>

                <div class="alt-button-theme">
                    <button
                        class="social-button"
                        (click)="navigateToGoogleSso()"
                    >
                        <img
                            class="icon"
                            src="/assets/images/clients/google.png"
                            alt="Sign In With Google"
                        />
                    </button>
                </div>
                <div class="alt-button-theme">
                    <button class="social-button" (click)="navigateToAdpSso()">
                        <img
                            class="icon"
                            src="/assets/images/clients/adp.png"
                            alt="Sign In With ADP"
                        />
                    </button>
                </div>
            </div>

            <div class="divider-bar"></div>

            <p class="text-center">
                Already have a Ving account?&nbsp;&nbsp;
                <a
                    routerLink="/login/signIn"
                    [queryParams]="{ auth_as: auth_as }"
                    >Sign In</a
                >
            </p>

            <!--Alert-->
            <app-alert></app-alert>

            <!--Sign-Up Form-->
            <form
                (ngSubmit)="processSignUp(newUserForm)"
                #newUserForm="ngForm"
                class="sign-up-form alt-input-theme"
            >
                <!--Email-->
                <div class="form-group">
                    <label>Email or Phone</label>
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="newUser.username"
                        #email
                        name="username"
                        autofocus
                        autocomplete="off"
                        required
                    />
                </div>
                <!--First Name-->
                <div class="form-group">
                    <label>First Name</label>
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="newUser.firstName"
                        name="firstName"
                        autocomplete="off"
                        required
                    />
                </div>
                <!--Last Name-->
                <div class="form-group">
                    <label>Last Name</label>
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="newUser.lastName"
                        name="lastName"
                        autocomplete="off"
                        required
                    />
                </div>
                <!--Password-->
                <div class="form-group">
                    <label>Password</label>
                    <input
                        type="password"
                        class="form-control"
                        [(ngModel)]="newUser.password"
                        name="password"
                        minlength="6"
                        maxlength="50"
                        #password="ngModel"
                        password
                        required
                    />
                    <span
                        class="{{
                            (password.dirty || password.touched) &&
                            (password.invalid || password.errors)
                                ? 'error'
                                : 'none'
                        }}"
                        >{{ messages.password }}</span
                    >
                </div>
                <!--Confirm Password-->
                <div class="form-group">
                    <label>Confirm Password</label>
                    <input
                        type="password"
                        class="form-control"
                        [(ngModel)]="newUser.confirm_password"
                        name="confirm_password"
                        minlength="6"
                        maxlength="50"
                        #confirm_password="ngModel"
                        required
                    />
                    <span
                        class="{{
                            (confirm_password.dirty ||
                                confirm_password.touched) &&
                            password.valid &&
                            newUser.password != newUser.confirm_password
                                ? 'error'
                                : 'none'
                        }}"
                        >{{ messages.password_match }}</span
                    >
                </div>

                <!--Google ReCaptcha-->
                <div style="width: 100%; text-align: center; margin-top: 25px">
                    <re-captcha
                        style="display: inline-block"
                        [(ngModel)]="captcha"
                        name="captcha"
                        required
                    ></re-captcha>
                </div>

                <!--Buttons-->
                <div class="form-buttons">
                    <button
                        type="submit"
                        class="app-button large green full-width"
                        [disabled]="
                            !newUserForm.form.valid ||
                            newUser.password != newUser.confirm_password
                        "
                    >
                        Sign Up
                    </button>
                </div>
            </form>
        </ng-container>

        <ng-container *ngIf="step === 2">
            <div class="section-header">
                <div class="title">Verification</div>

                <p class="text-center">
                    Check your Email or SMS for your verification code.
                </p>
            </div>

            <div class="divider-bar"></div>

            <!--Alert-->
            <app-alert></app-alert>

            <!--Verification Form-->
            <form
                (ngSubmit)="processVerify(verifyForm)"
                #verifyForm="ngForm"
                class="sign-up-form alt-input-theme"
            >
                <!--Verification Code-->
                <div class="form-group">
                    <label>Verification Code</label>
                    <input
                        type="text"
                        class="form-control {{ error ? 'error' : null }}"
                        [(ngModel)]="verificationCode.verification_code"
                        #code
                        name="verification_code"
                        autocomplete="off"
                        required
                    />
                    <span class="{{ error ? 'error' : 'none' }}">{{
                        messages.error2
                    }}</span>
                </div>
                <!--Buttons-->
                <div class="form-buttons">
                    <button
                        type="submit"
                        class="app-button large green full-width"
                        [disabled]="!verifyForm.form.valid"
                    >
                        Verify Your Account
                    </button>
                </div>
                <!--Missing Code-->
                <p class="text-center">
                    <a
                        class="blue"
                        href="mailto:support@vingapp.com?body=I am not receiving a verification code."
                        >Didn't receive a code?</a
                    >
                </p>
            </form>
        </ng-container>

        <!--Terms of Service-->
        <p class="text-center term">
            By creating an account, you agree to Ving's
            <a
                href="https://f.hubspotusercontent30.net/hubfs/1615786/legal/terms-of-service.pdf"
                target="_blank"
                >Terms of Service</a
            >
            and
            <a
                href="https://f.hubspotusercontent30.net/hubfs/1615786/legal/privacy-policy.pdf"
                target="_blank"
                >Privacy Policy</a
            >.
        </p>
    </div>
</div>
