import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { AppSelectOption } from '../../../../shared/components/app-select/classes/app.select.option';
import { AppHeaderService } from '../../../../shared/services/app.header.service';
import { AppUserService } from "../../../../shared/services/app.user.service";
import { Checklist } from "../../classes/checklist";

@Component({
    selector: 'app-checklists-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit, OnChanges {
    buttons = [
        // {
        //     name: 'add-checklists',
        //     icon: 'person_add',
        //     tooltip: 'Add checklists to label',
        //     enabled: false
        // },
        {
            name: 'export-data',
            icon: 'file_open',
            tooltip: 'Export analytics',
            enabled: false,
            hidden: false,
            selectedChecklist: false,
        },
        {
            name: 'manage-checklists-labels',
            icon: 'local_offer',
            tooltip: 'Manage checklists labels',
            enabled: false,
            hidden: false,
            selectedChecklist: true,
        },
        {
            name: 'change-owner',
            icon: 'account_box',
            tooltip: 'Change owner of selected checklists',
            enabled: false,
            hidden: false,
            selectedChecklist: true,
        },
        {
            name: 'archive',
            icon: 'archive',
            tooltip: 'Archive selected checklists',
            enabled: false,
            hidden: false,
            selectedChecklist: true,
        },
        {
            name: 'unarchive',
            icon: 'unarchive',
            tooltip: 'Unarchive selected checklists',
            enabled: false,
            hidden: true,
            selectedChecklist: true,
        },
        {
            name: 'invite',
            icon: 'person_add',
            tooltip: 'Invite to checklists',
            enabled: false,
            hidden: true,
            selectedChecklist: true,
        },
    ];

    @Input() checklists: Array<Checklist>;
    @Input() selectedChecklistIds: Array<number>;
    @Input() excludedChecklistIds: Array<number>;
    @Input() selectedAllChecklists: boolean;
    @Input() checklistsCount: number;
    @Input() selectedLabels: Array<string>;
    @Input() selectedFilters: Array<string>;
    @Input() selectedChecklist: Checklist;
    @Input() selectedStatus: AppSelectOption;
    @Input() isDetail: boolean;

    @Output() onToggleAllChecklistIds = new EventEmitter();
    @Output() onClickHeaderButton = new EventEmitter<any>();

    private jobs = {
        headerButtonState: 'checklists-header-button-state',
    };

    constructor(
        private appHeaderService: AppHeaderService,
        private appUserService: AppUserService,
    ) {}
    page: '';

    ngOnInit() {
        this.appHeaderService.page.subscribe((page) => {
            this.page = page;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateButtonStates();
    }

    handleClick(name: string): void {
        if (this.getButton(name).enabled === true) {
            this.onClickHeaderButton.emit(name);
        }
    }

    getClass(name: string): string {
        const button = this.getButton(name),
            css = 'app-button dark x-small no-text ';

        return button.enabled ? css : css + ' disabled';
    }

    toggleSelectAllChecklists(): void {
        this.onToggleAllChecklistIds.emit();
    }

    getSelectedChecklistsCount() {
        let count = 0;
        if (this.selectedAllChecklists) {
            count = this.checklistsCount - this.excludedChecklistIds.length;
        } else {
            count = this.selectedChecklistIds.length;
        }

        return count > 0 ? count : 0;
    }

    updateButtonStates(): void {
        let checklistsAreSelected = false;
        let archivedChecklistsAreSelected = false;
        const labelsAreSelected = this.selectedLabels.length > 0;

        if (
            this.selectedAllChecklists &&
            this.checklistsCount > this.excludedChecklistIds.length
        ) {
            checklistsAreSelected = true;
        } else {
            checklistsAreSelected = this.selectedChecklistIds.length > 0;
        }

        if (this.selectedChecklist) {
            checklistsAreSelected = true;

            if (this.selectedChecklist.status === 'archived') {
                archivedChecklistsAreSelected = true;
            }
        }

        if (this.selectedStatus && this.selectedStatus.value === 'archived') {
            archivedChecklistsAreSelected = true;
        }

        // Update header buttons based on selection state.
        // this.getButton('add-vings').enabled = labelsAreSelected;
        this.getButton('export-data').enabled = checklistsAreSelected && this.appUserService.hasChecklistsAccess();
        this.getButton('export-data').hidden = this.isDetail;
        this.getButton('manage-checklists-labels').enabled =
            checklistsAreSelected;
        this.getButton('change-owner').enabled = checklistsAreSelected;
        this.getButton('archive').enabled = checklistsAreSelected;
        this.getButton('archive').hidden = archivedChecklistsAreSelected;
        this.getButton('unarchive').enabled =
            checklistsAreSelected && archivedChecklistsAreSelected;
        this.getButton('unarchive').hidden = !archivedChecklistsAreSelected;
        this.getButton('invite').enabled = checklistsAreSelected && this.appUserService.hasChecklistsAccess();
        this.getButton('invite').hidden = this.isDetail;
    }

    private getButton(name: string) {
        return this.buttons.find((btn) => btn.name === name);
    }

    createChecklist(type) {
        this.appHeaderService.events.next(type);
    }
}
