import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ElementRef,
    Renderer2,
} from '@angular/core';
import { Document } from '../../../../../../shared/classes/documents/document';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-document',
    templateUrl: './app-document.component.html',
    styleUrls: ['./app-document.component.less'],
})
export class AppDocumentComponent implements OnInit {
    private defaultZoomValue = window.innerWidth > 767 ? 0.95 : 0.98;

    numberOfPages: number;
    documentUrl: string;
    currentPageIndex: number;
    currentPageNumber: number;
    currentZoomViewerValue = this.defaultZoomValue;
    currentZoomControlValue = this.getZoomValuePercent(
        this.currentZoomViewerValue
    );
    shouldDisplayLoadingIndicator = true;
    zoomSliderMin = 10;
    zoomSliderMax = 180;
    fullScreenMode = false;

    @ViewChild('pdfWrapper') private pdfWrapper: ElementRef;
    @ViewChild('pdfContainer') private pdfContainer: ElementRef;

    @Input() document: Document;
    @Input() mode: string; // 'edit', 'consume'
    @Input() clearSelectedDocument: Observable<any>;
    @Input() navigationClass?: string;

    @Output() newDocumentFileKey = new EventEmitter();
    @Output() updateDocumentPageView = new EventEmitter<any>();

    constructor(public dialog: MatDialog, private renderer: Renderer2) {}

    ngOnInit() {
        this.clearSelectedDocument.subscribe((newDocument: Document) => {
            const isOldDocument = this.document.url === newDocument.url;

            this.shouldDisplayLoadingIndicator = true;

            this.document = newDocument;
            this.resetComponentProperties();
            this.initDocument();

            // Hide loadingbar if new document is same with old one.
            if (isOldDocument) {
                this.shouldDisplayLoadingIndicator = false;
            }
        });

        this.initDocument();
    }

    shouldDisplayButton(type: string): boolean {
        // type: ['previous', 'next']
        let shouldDisplay = false;

        if (type === 'previous') {
            if (
                this.currentPageIndex > 0 &&
                this.currentPageIndex <= this.numberOfPages - 1
            ) {
                shouldDisplay = true;
            }
        } else if (type === 'next') {
            if (
                this.currentPageIndex >= 0 &&
                this.currentPageIndex < this.numberOfPages - 1
            ) {
                shouldDisplay = true;
            }
        }

        return shouldDisplay;
    }

    displayNextPage(): void {
        this.displayNewPage(this.currentPageIndex + 1);
        this.emitPageViewUpdate();
    }

    displayPreviousPage(): void {
        this.displayNewPage(this.currentPageIndex - 1);
        this.emitPageViewUpdate();
    }

    updateZoom(plus: boolean) {
        const step = 0.1;
        if (plus && this.currentZoomViewerValue * 100 <= this.zoomSliderMax) {
            this.currentZoomViewerValue =
                Math.round((this.currentZoomViewerValue + step) * 100) / 100;
        } else if (
            !plus &&
            this.currentZoomViewerValue * 100 >= this.zoomSliderMin
        ) {
            this.currentZoomViewerValue =
                Math.round((this.currentZoomViewerValue - step) * 100) / 100;
        }
        this.currentZoomControlValue = this.getZoomValuePercent(
            this.currentZoomViewerValue
        );
    }

    fitZoomToFullScreen() {
        const element = this.pdfContainer
            ? this.pdfContainer.nativeElement
            : null;
        if (this.mode === 'consume' && element) {
            if (this.fullScreenMode) {
                this.fullScreenMode = false;
                this.renderer.removeClass(document.body, 'fullscreen');
                this.renderer.removeClass(element, 'fullscreen');
            } else {
                this.fullScreenMode = true;
                this.renderer.addClass(document.body, 'fullscreen');
                this.renderer.addClass(element, 'fullscreen');
            }
        }
    }

    afterLoadComplete(pdf: any) {
        this.shouldDisplayLoadingIndicator = false;
    }

    private displayNewPage(index: number): void {
        this.currentPageIndex = index;
        this.currentPageNumber = index + 1;

        try {
            this.pdfWrapper.nativeElement.scrollTop = 0;
        } catch (err) {
            // add to sentry
            console.log(err);
        }
    }

    private getZoomValuePercent(zoom: number): string {
        return Math.round(zoom * 100).toString();
    }

    private resetComponentProperties(): void {
        this.documentUrl = '';
        this.currentPageIndex = undefined;
        this.currentPageNumber = undefined;
        this.currentZoomViewerValue = this.defaultZoomValue;
        this.currentZoomControlValue = this.getZoomValuePercent(
            this.currentZoomViewerValue
        );
        this.numberOfPages = undefined;
    }

    private initDocument(): void {
        // Set default zoom and the number of pages.
        this.currentZoomViewerValue = this.defaultZoomValue;
        this.numberOfPages = this.document.pageCount;
        this.documentUrl = this.document.url;

        // Load first page.
        if (this.numberOfPages > 0) {
            this.displayNewPage(0);
        }
    }

    private emitPageViewUpdate(): void {
        if (this.mode === 'consume') {
            this.updateDocumentPageView.emit(this.currentPageIndex);
        }
    }
}
