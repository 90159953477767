<div class="scheduled-vings">
    <div class="scheduled-vings-filters">
        <div
            style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            "
        >
            <p class="scheduled-vings-title m-0">Scheduled</p>
            <div class="filter-by-selected">
                <div class="checkbox">
                    <span
                        (click)="filterbySelectedPackets()"
                        [class]="
                            filterBySelected ? 'circle selected' : 'circle'
                        "
                    >
                        <i class="material-icons icon">check</i>
                    </span>
                </div>
                <p class="checkbox-filter-text">Filter by Selected</p>
            </div>
        </div>
        <app-select2
            (handleResults)="handleResultsUpdate($event)"
        >
        </app-select2>
    </div>
    <div class="scheduled-vings-table">
        <div class="scheduled-vings-table-header"></div>
        <div class="scheduled-vings-table-body">
            <div
                class="no-scheduled-vings-message"
                *ngIf="!scheduledInvitations.length"
            >
                There are no scheduled invitations.
            </div>
            <div
                *ngFor="let invitation of scheduledInvitations"
                class="scheduled-vings-item"
            >
                <div
                    (click)="handleClickTitle(invitation)"
                    class="scheduled-vings-item-title"
                >
                    {{ invitation.title }}
                </div>
                <div class="scheduled-vings-item-date">
                    {{ invitation.sendDate }}
                </div>
                <i
                    class="scheduled-vings-item-icon icon material-icons"
                    (click)="editInvitation(invitation)"
                    >edit</i
                >
                <i
                    class="
                        scheduled-vings-item-icon
                        delete-scheduled-trigger
                        icon
                        material-icons
                    "
                    (click)="deleteInvitation(invitation)"
                    >delete</i
                >
            </div>
        </div>
    </div>
</div>
