import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-radio',
    templateUrl: './app-radio.component.html',
    styleUrls: ['./app-radio.component.less'],
})
export class AppRadioComponent {
    @Input() answer: number;
    @Input() choiceId: number;

    @Output() updateAnswer = new EventEmitter<any>();

    onSelectValue(selectedAnswer) {
        this.updateAnswer.emit(selectedAnswer);
    }
}
