import { AppSelectOption } from '../../components/app-select/classes/app.select.option';

export class AnalyticsFilters {
    filters: Array<AppSelectOption>;
    labels: Array<AppSelectOption>;
    sort: AppSelectOption;
    source?: AppSelectOption;

    constructor(
        filters: Array<AppSelectOption>,
        labels: Array<AppSelectOption>,
        sort: AppSelectOption,
        source?: AppSelectOption
    ) {
        this.filters = filters ? filters : [];
        this.labels = labels ? labels : [];
        this.sort = sort;
        this.source = source ? source : null;
    }
}
