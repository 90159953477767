<div
    class="app-select {{ style }}"
    tabindex="1"
    (blur)="isClicked = false"
    [style.borderRadius]="roundBorders ? '2px' : '0'"
    [style.height]="values.display.height"
    [style.width]="values.display.width"
>
    <div
        class="selected-option"
        [style.height]="values.display.height"
        [style.lineHeight]="values.display.lineHeight"
        [style.fontSize]="values.display.fontSize"
        (click)="toggleSelect()"
    >
        {{ getLabel() }}
    </div>

    <div
        class="handle"
        (click)="toggleSelect()"
        [style.lineHeight]="values.display.lineHeight"
    >
        <i
            [style.fontSize]="values.display.fontSize"
            [class]="
                isClicked
                    ? 'material-icons icon selected'
                    : 'material-icons icon'
            "
            >play_arrow</i
        >
    </div>

    <div
        class="options"
        *ngIf="isClicked"
        [style.width]="values.display.optionsWidth"
        [style.minHeight]="values.display.scrollHeight"
        [style.top]="values.display.top"
    >
        <div class="column">
            <div
                *ngFor="let option of options; let i = index"
                [class]="getOptionClass(i)"
                (click)="handleOptionClick(option, i)"
            >
                <div class="selection-icon">
                    <i
                        *ngIf="isOptionSelected(option)"
                        class="material-icons icon"
                        >done</i
                    >
                </div>
                <div class="label">
                    {{ option.label }}
                </div>
            </div>
        </div>
    </div>
</div>
