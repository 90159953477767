export class NewContact {
    constructor(
        public name: string,
        public emails: Array<string>,
        public phones: Array<string>,
        public blacklist: boolean,
        public title: string,
        public hireDate: string,
        public separationDate: string,
        public internalId: string
    ) {}
}
