<div class="app-dialog dialog-get-link">
    <div class="body">
        <h4>Shareable link</h4>

        <!--Link-->
        <div class="input-group">
            <a
                href="javascript:"
                class="input-group-addon"
                ngxClipboard
                [cbContent]="link"
                (cbOnSuccess)="handleCbSuccess()"
                (cbOnError)="handleCbError()"
            >
                <i class="material-icons icon">link</i>
            </a>
            <input
                type="text"
                class="input"
                [(ngModel)]="link"
                #inputLink
                (keydown)="onTypeStartTag($event)"
                (keyup)="onTypeEndTag($event)"
                [disabled]="isDisabled"
            />
        </div>

        <!--Actions-->
        <p>
            Press Ctrl+C to copy
            <a href="javascript:" (click)="updateTag()"
                >[{{ isDisabled ? 'Add' : 'Clear' }} Tag]</a
            >
        </p>
    </div>
</div>
