<div class="app-dialog dialog-readonly">
    <div class="header">
        {{ title }}
    </div>

    <div class="body">
        {{ text }}

        <p *ngIf="text2">{{ text2 }}</p>
        <div class="link-area">
            <a target="_blank" *ngIf="link" href="{{ link }}">{{
                link_title
            }}</a>
        </div>
    </div>

    <div class="footer">
        <button class="app-button small dark" (click)="close()">Close</button>
    </div>
</div>
