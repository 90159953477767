import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

import { GroupOption } from '../../../../../../shared/classes/group-option';

@Component({
    templateUrl: './dialog.edit-details.component.html',
    styleUrls: ['./dialog.edit-details.component.less'],
})
export class DialogEditDetailsComponent implements OnInit {
    title: string;
    summary: string;
    privacy: string;
    enforceOrder: boolean;

    validations: any = {};
    viewAccessOptions: Array<GroupOption> = [];
    enforceOrderOptions: Array<GroupOption> = [];

    constructor(
        public dialogRef: MatDialogRef<DialogEditDetailsComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            summary: string;
            privacy: string;
            enforceOrder: boolean;
            validations: any;
            viewAccessOptions: Array<GroupOption>;
            enforceOrderOptions: Array<GroupOption>;
            callback: Function;
        }
    ) {}

    ngOnInit() {
        this.title = this.data.title;
        this.summary = this.data.summary;
        this.privacy = this.data.privacy;
        this.enforceOrder = this.data.enforceOrder;
        this.validations = this.data.validations;
        this.viewAccessOptions = this.data.viewAccessOptions;
        this.enforceOrderOptions = this.data.enforceOrderOptions;
    }

    handleDetails(detailForm: NgForm): void {
        if (detailForm.invalid) {
            return;
        }

        const data = {
            title: this.title,
            summary: this.summary,
            privacy: this.privacy,
            enforceOrder: this.enforceOrder,
        };
        this.data.callback(data, this.dialogRef);
    }
}
