<div class="app-dialog dialog-export-data">
    <div class="header">
        {{ title }}
        <div class="select-export-type" *ngIf="data.page === 'contacts'">
            <mat-button-toggle-group
                class="export-type-toggle"
                aria-label="Export Data Type"
            >
                <mat-button-toggle
                    [ngClass]="exportType === exportTypeAssignments ? 'checked' : 'unchecked'"
                    (click)="updateExportType(exportTypeAssignments)"
                    value="assignments"
                    ><i
                        class="
                            material-icons
                            export-type-assignments-toggle-icon
                            icon
                        "
                    >perm_media</i>
                </mat-button-toggle>
                <mat-button-toggle
                    [ngClass]="exportType === exportTypeSubmissions ? 'checked' : 'unchecked'"
                    (click)="updateExportType(exportTypeSubmissions)"
                    value="submissions"
                    ><i
                        class="
                            material-icons
                            export-type-submissions-toggle-icon
                            icon
                        "
                    >checklist</i>
                </mat-button-toggle>
                <mat-button-toggle
                    [ngClass]="exportType === exportTypeRequirements ? 'checked' : 'unchecked'"
                    (click)="updateExportType(exportTypeRequirements)"
                    value="requirements"
                    ><i
                        class="
                            material-icons
                            export-type-requirements-toggle-icon
                            icon
                        "
                    >workspace_premium</i>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <div class="body">
        <div class="container">
            <div class="export-summary">
                {{ summary }}
            </div>
            <div class="export-date-selection">
                <div class="export-date-field-wrapper">
                    <div class="export-date-field-label">
                        <strong>Start Date:</strong>
                    </div>
                    <div class="export-date-field-picker">
                        <mat-form-field appearance="fill">
                            <input
                                matInput
                                [matDatepicker]="startDatePicker"
                                [formControl]="startDate"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="startDatePicker"
                                style="margin-top: -25px"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #startDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="export-date-field-wrapper">
                    <div class="export-date-field-label">
                        <strong>End Date:</strong>
                    </div>
                    <div class="export-date-field-picker">
                        <mat-form-field appearance="fill">
                            <input
                                matInput
                                [matDatepicker]="endDatePicker"
                                [formControl]="endDate"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="endDatePicker"
                                style="margin-top: -25px"
                            ></mat-datepicker-toggle>
                            <mat-datepicker #endDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer">
        <button mat-dialog-close class="app-button light w-90 mr-1">Close</button>
        <button class="app-button green w-90" (click)="export()">Generate Export</button>
    </div>
</div>
