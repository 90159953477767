import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-form-checkbox',
    templateUrl: './app.form.checkbox.html',
    styleUrls: ['./app.form.checkbox.less'],
})
export class AppFormCheckboxComponent {
    @Input() label: any;
    @Input() name: any;
    @Input() value: any;

    @Output() valueChange = new EventEmitter<any>();

    onToggleValue(): void {
        const newValue = !this.value;
        this.value = newValue;
        this.valueChange.emit(this.value);
    }
}
