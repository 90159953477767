import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NewRequirement } from '../../classes/new-requirement';
import { CreateRequirement } from '../../classes/create-requirement';
import {
    FileSystemDirectoryEntry,
    FileSystemFileEntry,
    NgxFileDropEntry,
} from 'ngx-file-drop';
import { RequirementFileStatus } from '../../classes/requirement-file-status';
import { RequirementFile } from '../../classes/requirement-file';
import { AppAlertService } from '../../../../shared/services/app.alert.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs/Subscription';

@Component({
    templateUrl: './dialog-requirements-manager.component.html',
    styleUrls: ['./dialog-requirements-manager.component.less'],
})
export class DialogManageRequirementsComponent implements OnInit {
    myControl = new FormControl();
    @ViewChild('selectFileField') selectFileField: ElementRef;
    messages = {
        success: 'Requirement successfully created.',
        error: 'There was an error creating the requirement. Please try again.',
        errorAssign: 'There was an error assigning the new requirement.',
        noResults: 'There are no assignable requirements.',
    };
    formMessages = {
        error: 'Please ensure that the fields are valid.',
    };
    createRequirement = new CreateRequirement('', '', null, null, '');
    // File
    requirementFile: any;
    requirementFiles: Array<RequirementFile> = [];
    requirementIsFileOver = false;
    selectedFile = undefined;
    selectedItems = [];
    deSelectedItems = [];
    originalData = '';
    itemsCount: number;
    text: string;
    validation = {
        labels: {
            min: 2,
            max: 40,
        },
    };
    private editRequirement: any;
    private selectedFiles: object = {};
    origin: string;
    requirement: any;
    private filesProcessed = 0;
    private validFiles = 0;
    tempAwsRequests = {};
    clearKey = false;
    multipleSelected = false;
    filteredOptions: Subscription;
    existingRequirement = false;
    private selectedFromAutoComplete: any;
    private imageUrl: string | ArrayBuffer;
    isPDF = false;

    constructor(
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appAlertService: AppAlertService,
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        public dialogRef: MatDialogRef<DialogManageRequirementsComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            origin: string;
            editRequirement: any;
            contactId: number;
            selectedItems: Array<object>;
            deSelectedItems: Array<object>;
            queries: any;
            callback: any;
        }
    ) {}

    ngOnInit() {
        this.editRequirement = this.data.editRequirement;
        this.selectedItems = this.data.selectedItems;
        this.selectedItems.forEach((item) => (item.state = 'selected'));
        this.deSelectedItems = this.data.deSelectedItems;
        this.deSelectedItems.forEach((item) => (item.state = 'deselected'));

        this.filteredOptions = this.myControl.valueChanges
            .pipe(debounceTime(500)) // wait 500ms after the last keyup
            .pipe(distinctUntilChanged()) // only emit if value is different from previous value
            .subscribe((text) => {
                this.existingRequirement = false;

                if (text) {
                    const getURL = `GET:api/requirements/?query=${text}`;

                    this.appHttpService.request(getURL, {}, (response) => {
                        this.deSelectedItems = response.results.filter(
                            (req) =>
                                !this.selectedItems.some(
                                    (selectedItem) =>
                                        req.title === selectedItem.title
                                )
                        );
                        if (this.deSelectedItems.length === 0) {
                            this.messages.noResults =
                                'There are no results for your search.';
                        }
                    });
                } else {
                    const getURL = `GET:api/requirements/`;

                    this.appHttpService.request(getURL, {}, (response) => {
                        this.deSelectedItems = response.results.filter(
                            (req) =>
                                !this.selectedItems.some(
                                    (selectedItem) =>
                                        req.title === selectedItem.title
                                )
                        );
                        if (this.deSelectedItems.length === 0) {
                            this.messages.noResults = 'No results';
                        }
                    });
                }
            });
        this.appLoadingIndicatorService.hide(350);
    }

    getRequirementData() {
        const url =
            `GET:api/requirements/contact/?contact_id=` + this.data.contactId;

        this.appHttpService.request(url, {}, (response) => {
            this.selectedItems = response.results;
            this.selectedItems.forEach((item) => (item.state = 'selected'));
            this.appLoadingIndicatorService.hide(350);
        });

        const getURL = `GET:api/requirements/`;

        this.appHttpService.request(getURL, {}, (response) => {
            this.deSelectedItems = response.results.filter(
                (req) =>
                    !this.selectedItems.some(
                        (selectedItem) => req.title === selectedItem.title
                    )
            );
            this.deSelectedItems.forEach((item) => (item.state = 'deselected'));
        });
    }

    openFileDialog() {
        $('input[type="file"]').trigger('click');
    }

    // Create Requirements

    save() {
        if (this.requirementFile) {
            this.apiCreateRequirement(this.requirementFile.key);
        } else {
            this.apiCreateRequirement();
        }
    }

    private apiCreateRequirement(file?) {
        const newRequirement: NewRequirement = this.buildNewContact(
            this.createRequirement
        );

        // Initial validation.
        this.appLoadingIndicatorService.show('modal');
        if (file) {
            if (this.clearKey) {
                newRequirement.certificate_key = null;
            } else {
                newRequirement.certificate_key = file;
            }
        }
        if (!this.existingRequirement) {
            this.appHttpService.request(
                'POST:api/requirements/',
                {
                    title: this.myControl.value,
                    summary: newRequirement.summary,
                },
                (requirement) => {
                    this.appLoadingIndicatorService.hide();
                    this.appMessageService.show(
                        'app',
                        'success',
                        'The requirement has been successfully created',
                        3
                    );
                    this.editAfterCreate(newRequirement, requirement);
                },
                (error) => {
                    const msg =
                        error.status === 400 && error.data
                            ? error.data[Object.keys(error.data)[0]][0]
                            : this.messages.error;
                    this.appLoadingIndicatorService.hide(350);
                    // this.showFormFieldErrors(error);
                    this.appMessageService.show('modal', 'error', msg, 5.0);
                }
            );
        } else {
            if (this.selectedFromAutoComplete) {
                this.editAfterCreate(
                    newRequirement,
                    this.selectedFromAutoComplete
                );
            }
        }
    }

    editAfterCreate(newRequirement, response) {
        this.getRequirementData();
        this.appHttpService.request(
            'POST:api/requirements/contact/',
            {
                contact_id: this.data.contactId,
                requirement_id: response.id,
                completed: newRequirement.completed,
                expiration: newRequirement.expiration,
                certificate_key: newRequirement.certificate_key,
            },
            (response) => {
                this.appLoadingIndicatorService.hide(200);
                this.appMessageService.show(
                    'app',
                    'success',
                    'The requirement has been successfully assigned',
                    3
                );
                this.dialogRef.close();
            },
            (error) => {
                const msg =
                    error.status === 400 && error.data
                        ? error.data[Object.keys(error.data)[0]][0]
                        : this.messages.errorAssign;
                this.appMessageService.show('modal', 'error', msg, 5.0);
                this.appLoadingIndicatorService.hide(200);
            }
        );
    }

    setRequirementData(requirement, requirementPrime) {
        requirementPrime.title = requirement.title
            ? requirement.title.trim()
            : null;
        requirementPrime.summary = requirement.summary
            ? requirement.summary.trim()
            : null;
        requirementPrime.expiration = requirement.expiration
            ? requirement.expiration
            : null;
        requirementPrime.completed = requirement.completed
            ? requirement.completed
            : null;
        requirementPrime.certificate_key = requirement.certificate_key
            ? requirement.certificate_key
            : null;
    }

    private buildNewContact(createRequirement: CreateRequirement) {
        const dateStr = this.createRequirement.expiration;
        const completedDateStr = this.createRequirement.completed;
        if (this.createRequirement.expiration) {
            const date = new Date(dateStr);
            this.createRequirement.expiration =
                date.toISOString().split('.')[0] + 'Z';
        } else {
            this.createRequirement.expiration = null;
        }

        if (this.createRequirement.completed) {
            const completedDate = new Date(completedDateStr);
            this.createRequirement.completed =
                completedDate.toISOString().split('.')[0] + 'Z';
        } else {
            this.createRequirement.completed = null;
        }
        const newRequirement: NewRequirement = new NewRequirement(
            null,
            null,
            null,
            null,
            null
        );
        this.setRequirementData(createRequirement, newRequirement);
        return newRequirement;
    }

    private isValidFile(file: File) {
        return file && this.appHelpersService.isValidComponentFile(file);
    }

    requirementFileDropped(files: NgxFileDropEntry[]) {
        if (files.length > 1) {
            this.multipleSelected = true;
            this.requirementFiles = [];
            this.selectedFiles = [];
            this.filesProcessed = 0;
            this.validFiles = 0;
            return;
        } else {
            this.multipleSelected = false;
        }
        this.requirementFiles = [];
        this.selectedFiles = [];
        this.filesProcessed = 0;
        this.validFiles = 0;
        this.appLoadingIndicatorService.show('modal');

        this.requirementIsFileOver = false;

        // Validate each dropped in file.
        for (let droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                const reader = new FileReader();
                fileEntry.file((file: File) => {
                    if (
                        this.isValidFile(file) &&
                        this.isFileAllowed(file.name)
                    ) {
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            this.imageUrl = reader.result;
                        };
                        // Create new ComponentFile object from the uploadFile.
                        const requirementFile = <RequirementFile>{};

                        requirementFile.id =
                            this.appHelpersService.generateUniqueId();
                        requirementFile.name = file.name;

                        fileEntry.file((f: File) => {
                            requirementFile.size =
                                this.appHelpersService.bytesToSize(f.size);
                            requirementFile.status =
                                RequirementFileStatus.uploading;
                            requirementFile.valid = this.isValidFile(file);
                            this.requirementFiles.push(requirementFile);
                            this.selectedFiles[requirementFile.id] = f;
                            this.validFiles++;
                            this.filesProcessed++;
                        });
                    } else {
                        if (!this.isFileAllowed(file.name)) {
                            this.displayOnlyCertainFileNotification();
                            this.appLoadingIndicatorService.hide(300);
                        }
                    }
                });
            } else {
                const fileEntry =
                    droppedFile.fileEntry as FileSystemDirectoryEntry;
                droppedFile = null;
                this.requirementIsFileOver = false;
                this.appAlertService.set(
                    'view',
                    'error',
                    'Invalid file selected.',
                    false
                );
            }
        }
        // File upload process...
        const waitAndProcessFileUploads = () => {
            setTimeout(() => {
                if (this.requirementFiles.length === this.filesProcessed) {
                    if (this.requirementFiles.length !== this.validFiles) {
                        const wrongFiles =
                            this.requirementFiles.length - this.validFiles;
                        this.displayWrongFileNotification(wrongFiles);
                    }
                    this.processFileUploads();
                } else {
                    waitAndProcessFileUploads();
                }
            }, 250);
        };

        setTimeout(() => {
            waitAndProcessFileUploads();
        }, 1000);
    }

    isFileAllowed(fileName: string) {
        let isFileAllowed = false;
        const allowedFiles = ['.pdf', '.jpg', '.jpeg', '.png'];
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(fileName);
        if (undefined !== extension && null !== extension) {
            const allowed = allowedFiles.filter(
                (ext) => ext === extension[0].toLowerCase()
            );
            if (allowed[0] === '.pdf') {
                this.isPDF = true;
            }
            if (allowed.length) {
                isFileAllowed = true;
            } else {
            }
        }
        return isFileAllowed;
    }

    private processFileUploads() {
        const requirementFileId = Object.keys(this.selectedFiles);

        requirementFileId.forEach((id) => {
            this.processFileUpload(id);
        });
    }

    private processFileUpload(id: string) {
        const requirementFile = this.requirementFiles.find(
            (rf) => rf.id === id
        );
        if (requirementFile) {
            requirementFile.status = RequirementFileStatus.uploading;

            this.appHttpService.apiAwsUploadFile(
                this.selectedFiles[id],
                (key) => {
                    delete this.selectedFiles[id];
                    delete this.tempAwsRequests[id];
                    requirementFile.key = key;
                    requirementFile.status = RequirementFileStatus.ready;

                    this.requirementFile = requirementFile;
                    this.appLoadingIndicatorService.hide(350);
                },
                () => {
                    this.clearKey = false;
                    requirementFile.status = RequirementFileStatus.error;
                    this.appMessageService.show(
                        'app',
                        'error',
                        'The file upload has failed, please try again.',
                        3
                    );
                    this.appLoadingIndicatorService.hide(350);
                }
            );
        }
    }

    private displayWrongFileNotification(num = 0): void {
        if (num > 0) {
            this.appMessageService.show(
                'app',
                'error',
                'The file has been discarded because of missing or invalid extension.',
                3
            );
        }
    }

    clearRequirementFiles() {
        this.requirementFiles = [];
        this.selectedFiles = [];
        this.clearKey = true;
    }

    updateRequirement(requirement) {
        this.selectedFromAutoComplete = requirement;
        this.existingRequirement = true;
        this.createRequirement.summary = requirement.summary;
    }

    private displayOnlyCertainFileNotification(): void {
        this.appMessageService.show(
            'app',
            'error',
            'The file has been discarded because of an invalid extension, please only upload images and pdfs.',
            3
        );
    }
}
