import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ClipboardModule } from 'ngx-clipboard';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VingsRoutingModule } from './vings.routing.module';
import { AppControlsModule } from '../../shared/modules/app.controls.module';
import { AppPipesModule } from '../../shared/modules/app.pipes.module';
import { VingsComponent } from './vings.component';
import { ListComponent } from './components/list/list.component';
import { HeaderComponent } from './components/header/header.component';
import { DetailComponent } from './components/detail/detail.component';
import { DialogGetLinkComponent } from './dialogs/dialog-get-link/dialog.get.link.component';
import { DialogQrCodeComponent } from './dialogs/dialog-qr-code/dialog.qr.code.component';
import { MatChipsModule } from '@angular/material/chips';
import { AngularSplitModule } from 'angular-split';
import { FilterNavigationModule } from '../../shared/components/app-filter-navigation/app.filter.navigation.module';
import { VingsService } from './services/vings.service';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { VingCardComponent } from './components/card/ving.card.component';
import { EditComponent } from "./components/edit/edit.component";
import { AppRecordAudioComponent } from "./components/edit/components/app-record-audio/app.record-audio.component";
import { ContentPendingComponent } from "./components/edit/components/content-pending/content.pending.component";
import { DialogUpdateTextComponent } from "./components/edit/dialogs/update-text/dialog.update-text.component";
import { DialogEditDetailsComponent } from "./components/edit/dialogs/edit-details/dialog.edit-details.component";
import {
    DialogCreateComponentsComponent
} from "./components/edit/dialogs/create-components/dialog.create-components.component";
import {
    DialogQuestionsImageComponent
} from "./components/edit/components/app-questions/dialogs/questions-image/dialog.questions.image.component";
import { AppQuestionsComponent } from "./components/edit/components/app-questions/app-questions.component";
import { QuestionsValidationService } from "./components/edit/services/questions.validation.service";

export function getWindow() {
    return window;
}

export function getLocalStorage() {
    return localStorage;
}

export function getLocation() {
    return location;
}

@NgModule({
    imports: [
        ClipboardModule,
        NgSelectModule,
        FormsModule,
        AppControlsModule,
        AppPipesModule,
        VingsRoutingModule,
        MatChipsModule,
        AngularSplitModule,
        FilterNavigationModule,
        NgxMatDatetimePickerModule,
        ReactiveFormsModule,
        DragDropModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        EditComponent,
        AppRecordAudioComponent,
        ContentPendingComponent,
        AppQuestionsComponent,
        VingsComponent,
        ListComponent,
        ScheduleComponent,
        HeaderComponent,
        DetailComponent,
        VingCardComponent,
        /*Dialogs*/
        DialogGetLinkComponent,
        DialogQrCodeComponent,
        DialogUpdateTextComponent,
        DialogEditDetailsComponent,
        DialogCreateComponentsComponent,
        DialogQuestionsImageComponent,
    ],
    providers: [
        VingsService,
        { provide: 'window', useFactory: getWindow },
        { provide: 'storage', useFactory: getLocalStorage },
        { provide: 'location', useFactory: getLocation },
        QuestionsValidationService,
    ],
    entryComponents: [
        /*Dialogs*/
        DialogGetLinkComponent,
        DialogQrCodeComponent,
        DialogUpdateTextComponent,
        DialogEditDetailsComponent,
        DialogCreateComponentsComponent,
        DialogQuestionsImageComponent,
    ],
    exports: [VingCardComponent],
})
export class VingsModule {}
