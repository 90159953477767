import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ViewComponent } from './view.component';
import { AuthGuard } from '../../shared/guards/auth.guard';

const viewRoutes: Routes = [
    {
        path: 'view/:stub',
        component: ViewComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(viewRoutes)],
    exports: [RouterModule],
})
export class ViewRoutingModule {}
