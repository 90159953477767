import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppFilterNavigationComponent } from './app.filter.navigation';
import { AngularSplitModule } from 'angular-split';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { AppControlsModule } from '../../modules/app.controls.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppMultiDragDropComponent } from '../app-multi-drag-and-drop/app.multi-drag-and-drop.component';
import { AppMouseEnterLeaveDebounceDirective } from './app.filter.navigation.debounce.directive';

export function getWindow() {
    return window;
}

export function getLocalStorage() {
    return localStorage;
}

export function getLocation() {
    return location;
}

@NgModule({
    imports: [
        AngularSplitModule,
        CommonModule,
        MatChipsModule,
        AppControlsModule,
        MatExpansionModule,
        DragDropModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: 'window', useFactory: getWindow },
        { provide: 'storage', useFactory: getLocalStorage },
        { provide: 'location', useFactory: getLocation },
    ],
    exports: [AppFilterNavigationComponent],
    declarations: [
        AppFilterNavigationComponent,
        AppMultiDragDropComponent,
        AppMouseEnterLeaveDebounceDirective,
    ],
})
export class FilterNavigationModule {}
