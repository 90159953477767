<div class="app-questions">
    <!--List-->
    <div class="question-list">
        <div class="header">
            Questions

            <!--Add Questions-->
            <div class="add-questions">
                <div class="options">
                    <app-select
                        [width]="140"
                        [height]="21"
                        [fontSize]="10"
                        [scrollHeight]="154"
                        [shouldIncludeDefaultValue]="false"
                        [roundBorders]="true"
                        [options]="questionTypes"
                        [selected]="selectedQuestionType"
                        (update)="updateSelectedQuestionType($event)"
                    >
                    </app-select>
                </div>

                <div class="add-button">
                    <button
                        (click)="addQuestion()"
                        [disabled]="!shouldAllowAddQuestions()"
                        class="app-button dark x-small"
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>

        <!--Questions List-->
        <div class="body questions" cdkDropList #autoscrollQuestion>
            <ng-container *ngIf="questions.length > 0">
                <div
                    *ngFor="let question of questions; let i = index"
                    [attr.data-index]="i"
                    [class]="getQuestionClass(i)"
                    (click)="selectQuestion(i)"
                    [cdkDragData]="i"
                    cdkDrag
                    (cdkDragDropped)="dropped($event)"
                >
                    <div
                        class="column grabber grabable"
                        (click)="$event.stopPropagation()"
                    >
                        <i class="material-icons icon grabable">unfold_more</i>
                    </div>

                    <div class="column number">#{{ i + 1 }}</div>

                    <div class="column text-and-type">
                        <div class="text" *ngIf="question.text.length > 0">
                            {{ question.text }}
                        </div>
                        <div
                            class="text no-question-text"
                            *ngIf="question.text.length === 0"
                        >
                            No question text...
                        </div>
                        <div class="type">
                            {{ questionText[question.type] }}
                        </div>
                    </div>

                    <div class="column image">
                        <img
                            *ngIf="objectHasImage(question)"
                            [src]="question.imageUrl"
                        />
                    </div>

                    <div
                        class="column delete"
                        (click)="displayConfirmDeleteQuestionDialog(i)"
                    >
                        <i class="material-icons icon">delete</i>
                    </div>
                </div>
            </ng-container>
        </div>

        <!--Add Questions Message-->
        <div class="body add-questions-message" *ngIf="questions.length === 0">
            <div class="app-message-text">
                Add questions by selecting the<br />
                type and clicking the '+' button above.
            </div>
        </div>

        <div class="footer">
            <span *ngIf="questions.length > 0">
                # Questions: {{ questions.length }}
            </span>

            <div
                class="error-no-questions"
                *ngIf="!isJustAdded && questions.length === 0"
            >
                Ensure that the questions list has at least one question.
            </div>
        </div>
    </div>

    <!--Details-->
    <div class="question-details">
        <div class="header">Question Details</div>

        <!--Selected Question-->
        <div class="body selected-question" *ngIf="selectedQuestionIndex >= 0">
            <!--Title/Header-->
            <div class="question-title">
                <!--Question Number-->
                <div class="number-and-type">
                    Question # {{ selectedQuestionIndex + 1 }} ::
                    {{ questionText[getSelectedQuestion().type] }}
                </div>

                <!--Question Type-->
                <div class="add-image-button">
                    <div
                        class="image-button"
                        (click)="displayQuestionImageDialog()"
                    >
                        <i class="material-icons icon">image</i>
                    </div>
                </div>
            </div>

            <!--Question Text & Image-->
            <div [class]="getQuestionContainerClass()">
                <div class="question-text">
                    <textarea
                        placeholder="Enter question text..."
                        [style.borderBottom]="getQuestionTextClass()"
                        [minlength]="validations.questionText.min"
                        [maxlength]="validations.questionText.max"
                        [(ngModel)]="getSelectedQuestion().text"
                        (blur)="onBlurTextarea()"
                    >
                    </textarea>
                </div>

                <div class="question-image">
                    <img
                        *ngIf="isImageSet('question')"
                        [src]="getSelectedQuestion().imageUrl"
                    />
                </div>

                <div
                    class="question-text-error"
                    *ngIf="shouldDisplayQuestionTextError()"
                >
                    {{ questionErrorText }}
                </div>
            </div>

            <!--Answer Choices-->
            <div
                class="question-choices"
                *ngIf="getSelectedQuestion().type !== 'shortAnswer'"
            >
                <!--Title-->
                <div class="title" *ngIf="!isQuestionType(['short_answer'])">
                    <div class="text">Answer Choices</div>

                    <div
                        class="add"
                        *ngIf="
                            isQuestionType([
                                'multiple_choice',
                                'multiple_response'
                            ])
                        "
                    >
                        <button
                            (click)="addChoice()"
                            [disabled]="!shouldAllowAddChoices()"
                            class="app-button dark x-small"
                        >
                            Add
                        </button>
                    </div>
                </div>

                <!--Choices List-->
                <!--True/False, Yes/No-->
                <div
                    class="choices"
                    *ngIf="isQuestionType(['true_false', 'yes_no'])"
                >
                    <div
                        class="choice"
                        *ngFor="let choice of getSelectedQuestion().choices"
                    >
                        <div class="tf-yn">
                            <div class="text">
                                {{ choice.text }}
                            </div>
                            <div
                                class="is-correct"
                                title="Correct choice"
                                (click)="clickIsCorrect($event, choice)"
                            >
                                <i
                                    class="material-icons icon correct"
                                    *ngIf="choice.correct"
                                >
                                    check_circle
                                </i>
                                <i
                                    class="material-icons icon not-correct"
                                    *ngIf="!choice.correct"
                                >
                                    radio_button_unchecked
                                </i>
                                <input
                                    type="checkbox"
                                    [(ngModel)]="choice.correct"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <!--Likert-->
                <div class="choices" *ngIf="isQuestionType(['likert'])">
                    <div
                        class="choice"
                        *ngFor="let choice of getSelectedQuestion().choices"
                    >
                        <div class="likert">
                            {{ choice.text }}
                        </div>
                    </div>
                </div>

                <!--Multiple Choice, Multiple Response-->
                <div
                    class="choices"
                    cdkDropList
                    *ngIf="
                        isQuestionType(['multiple_choice', 'multiple_response'])
                    "
                    #autoscrollAnswer
                >
                    <div
                        class="choice"
                        *ngFor="
                            let choice of getSelectedQuestion().choices;
                            let i = index
                        "
                        [attr.data-index]="i"
                        [cdkDragData]="i"
                        cdkDrag
                        (cdkDragDropped)="droppedChoice($event)"
                    >
                        <div class="mc-rc">
                            <!--Grabber :: Drag/Drop-->
                            <div
                                class="grabber grabable"
                                (click)="$event.stopPropagation()"
                            >
                                <i class="material-icons icon grabable"
                                    >unfold_more</i
                                >
                            </div>

                            <!--Answer Choice :: Image-->
                            <!--If image is not set-->
                            <div class="image" *ngIf="!isImageSet('choice', i)">
                                <div
                                    class="image-button"
                                    (click)="displayChoiceImageDialog(i)"
                                >
                                    <i class="material-icons icon">image</i>
                                </div>
                            </div>
                            <!--If image is set-->
                            <div
                                class="image"
                                *ngIf="isImageSet('choice', i)"
                                title="Edit image"
                                (click)="displayChoiceImageDialog(i)"
                            >
                                <img [src]="choice.imageUrl" />
                                <div class="edit-icon">
                                    <i class="material-icons icon">edit</i>
                                </div>
                            </div>

                            <!--Answer Choice :: Text-->
                            <div class="text">
                                <input
                                    type="text"
                                    [minlength]="validations.answerText.min"
                                    [maxlength]="validations.answerText.max"
                                    placeholder="Enter answer choice text..."
                                    [(ngModel)]="choice.text"

                                    [class]="
                                        choiceTextHasError(
                                            selectedQuestionIndex,
                                            i
                                        )
                                    "
                                />
                            </div>

                            <!--Answer Choice :: Correct choice indicator-->
                            <div
                                class="is-correct"
                                title="Correct choice"
                                (click)="clickIsCorrect($event, choice)"
                            >
                                <i
                                    class="material-icons icon correct"
                                    *ngIf="choice.correct"
                                >
                                    check_circle
                                </i>
                                <i
                                    class="material-icons icon not-correct"
                                    *ngIf="!choice.correct"
                                >
                                    radio_button_unchecked
                                </i>
                                <input
                                    type="checkbox"
                                    [(ngModel)]="choice.correct"
                                />
                            </div>

                            <!--Answer Choice :: Delete button-->
                            <div
                                class="delete allowed"
                                *ngIf="shouldAllowDeleteChoices()"
                                (click)="displayConfirmDeleteChoiceDialog(i)"
                            >
                                <i class="material-icons icon allow">delete</i>
                            </div>
                            <div
                                class="delete not-allowed"
                                *ngIf="!shouldAllowDeleteChoices()"
                            >
                                <i
                                    class="material-icons icon"
                                    title="Cannot delete. Must have at least two answer choices."
                                >
                                    delete
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--No Question Selected-->
        <div
            class="body no-question-selected"
            *ngIf="selectedQuestionIndex === undefined"
        >
            <div class="app-message-text">
                To see a question's details,<br />
                please select one from the list at left.
            </div>
        </div>

        <div class="footer" *ngIf="selectedQuestionIndex >= 0">
            # Answers: {{ getSelectedQuestion().choices.length }}
        </div>
    </div>
</div>
