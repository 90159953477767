import { Injectable } from '@angular/core';
import { AppHttpService } from '../../../shared/services/app.http.service';
import { AppJobService } from '../../../shared/services/job/app.job.service';

@Injectable()
export class VingsService {
    constructor(
        private appHttpService: AppHttpService,
        private appJobService: AppJobService
    ) {}
    apiDeleteFilter(
        type: string,
        idx: any,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        let uri = '';
        if (type === 'category') {
            uri = 'labels/categories/packet/';
        } else if (type === 'label') {
            uri = 'labels/packet/';
        } else if (type === 'list') {
            uri = 'lists/';
        }
        this.appHttpService.request(
            'DELETE:api/' + uri + idx + '/',
            {},
            (contact) => {
                if (successCallback) {
                    successCallback(contact);
                }
            },
            (error) => {
                if (errorCallback) {
                    errorCallback(error);
                }
            }
        );
    }
    apiCreateCategory(
        category: string,
        labels: any,
        successCallback?: Function,
        errorCallback?: Function
    ) {
        this.appHttpService.request(
            'POST:api/labels/categories/packet/' + category + '/',
            labels,
            (contact) => {
                if (successCallback) {
                    successCallback(contact);
                }
            },
            (contact) => {
                if (errorCallback) {
                    errorCallback(contact);
                }
            }
        );
    }
}
