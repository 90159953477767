import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ElementRef,
    Input,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PacketComponent } from '../../../vings/classes/packet-component';
import { QuestionType } from '../../../../shared/classes/questions/question-type';
import { AppJobService } from '../../../../shared/services/job/app.job.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppPermissionService } from '../../../../shared/services/app.permission.service';
import { AppUserService } from '../../../../shared/services/app.user.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { Question } from '../../../../shared/classes/questions/question';
import { DialogReadonlyComponent } from '../../../../shared/dialogs/dialog-readonly/dialog.readonly.component';
import * as $ from 'jquery';
import { Choice } from '../../../../shared/classes/questions/choice';
import { ConsumePacket } from '../../classes/consume.packet';
import { DialogViewStatusComponent } from '../../dialogs/dialog-view-status/dialog.view.status.component';
import { AppDialogLightBoxComponent } from '../../../../shared/dialogs/dialog-light-box/app.dialog-light-box.component';
import {View} from "../../classes/view";

@Component({
    selector: 'app-view-ving',
    templateUrl: './view-ving.component.html',
    styleUrls: ['./view-ving.component.less'],
})
export class ViewVingComponent implements OnInit, OnDestroy {
    @ViewChild('video') video: ElementRef;

    isMobile = null;
    isPreview = false;
    previewComponentId;
    viewIsInitialized = false;
    lastView: View;
    selectedComponent: PacketComponent;
    selectedComponentId: number;
    clearSelectedQuestion = new Subject();
    clearSelectedDocument = new Subject();
    showList = false;
    displayFeedback = false;
    ongoingCall: boolean;
    isComplete: boolean;
    durationComplete: number;

    private radioButtonTypes = [
        QuestionType.trueFalse,
        QuestionType.yesNo,
        QuestionType.multipleChoice,
        QuestionType.likert,
    ];
    private lastQuestionsJson: string;
    private questionsJobEnabled = true;
    private intervals = {
        questions: 500,
        document: 3000,
        media: 500,
    };
    private jobs = {
        questionsValidate: 'app-questions-validate-job',
        mediaProgress: 'app-media-progress-job',
        document: 'app-document-job',
    };
    private currentPersistRequest: any;
    private urlParams: object;
    private nextButtonClicked = 0;
    private packet: any;

    @Input() view: View;

    constructor(
        private appJobService: AppJobService,
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appUserService: AppUserService,
        private appPermissionService: AppPermissionService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private deviceService: DeviceDetectorService
    ) {
        this.isMobile = this.deviceService.isMobile();
    }

    ngOnInit() {
        this.appLoadingIndicatorService.show('view');

        // Adjust view style.
        $('#view').css({ 'overflow-y': 'hidden' });

        // Retrieve and set the url params.
        this.urlParams = this.appHelpersService.getUrlParams();

        // Check to see if preview param was passed in.
        if (
            this.urlParams &&
            'preview' in this.urlParams &&
            this.urlParams['preview']
        ) {
            this.isPreview = true;

            if ('componentId' in this.urlParams) {
                const componentId = parseInt(this.urlParams['componentId'], 10);

                if (Number.isInteger(componentId)) {
                    this.previewComponentId = componentId;
                }
            }
        }

        this.initView(this.view);
        // this.initPage();

        if (this.isMobile) {
            if (typeof window.orientation === 'undefined') {
                const detector = window.matchMedia('(orientation: portrait)');
                detector.addListener((m) => {
                    if (m.matches) {
                        this.closeVideoFullScreen();
                    } else {
                        this.openVideoFullScreen();
                    }
                });
            } else {
                window.addEventListener('orientationchange', () => {
                    if (
                        window.orientation === 90 ||
                        window.orientation === -90
                    ) {
                        this.openVideoFullScreen();
                    } else {
                        this.closeVideoFullScreen();
                    }
                });
            }
        }
    }

    ngOnDestroy() {
        // Adjust view style.
        $('#view').css({ 'overflow-y': 'auto' });

        this.appJobService.kill(this.jobs.questionsValidate);
    }

    isComponentSelected(): boolean {
        return Boolean(this.selectedComponent);
    }

    lastComponentIsSelected(): boolean {
        return (
            this.getSelectedComponentIndex() + 1 ===
            this.view.packet.components.length
        );
    }

    shouldDisplayButton(type): boolean {
        let shouldDisplay = true;

        if (
            this.isComponentSelected() &&
            ((this.selectedComponent.type === 'questions' &&
                ((!this.isUndoAble() && this.displayFeedback) ||
                    !this.selectedComponent.scored ||
                    !this.selectedComponent.completed)) ||
                this.selectedComponent.type !== 'questions')
        ) {
            // need to check button status if question has been passed and the user is on feedback view
            const index = this.getSelectedComponentIndex(),
                prevIndex = index - 1,
                nextIndex = index + 1;

            if (type === 'previous') {
                if (
                    prevIndex < 0 ||
                    this.view.packet.components[prevIndex].locked === true
                ) {
                    shouldDisplay = false;
                }
            } else if (type === 'next') {
                if (this.view.enforceOrder) {
                    if (
                        this.view.packet.components[index].completed !== true ||
                        (!this.lastComponentIsSelected() &&
                            this.view.packet.components[nextIndex].locked ===
                                true)
                    ) {
                        shouldDisplay = false;
                    }
                }
            }
        }

        return shouldDisplay;
    }

    displayComponent(type: string): void {
        const display = this.shouldDisplayButton(type);

        if (display) {
            // type: 'previous', 'next'
            const currentIndex = this.getSelectedComponentIndex();

            let newIndex: number;
            if (type === 'previous') {
                newIndex = currentIndex - 1;
            } else if (type === 'next') {
                newIndex = currentIndex + 1;
            }

            // Display immediate feedback view for Questions
            if (
                this.selectedComponent &&
                this.selectedComponent.scored &&
                this.selectedComponent.type === 'questions' &&
                !this.isPreview
            ) {
                this.displayFeedback = true;
                if (this.nextButtonClicked === 0) {
                    this.lastView = this.appHelpersService.getClone(this.view);
                }
                this.nextButtonClicked++;

                // Revert changes if user change something on immediate feedback view
                if (
                    this.lastView &&
                    JSON.stringify(this.lastView) !== JSON.stringify(this.view)
                ) {
                    this.view = this.appHelpersService.getClone(this.lastView);
                }
            }

            // Move to next component
            if (
                !this.displayFeedback ||
                (this.displayFeedback && this.nextButtonClicked > 1)
            ) {
                if (this.isUndoAble() && !this.isPreview) {
                    // when 'passed' is False, back to the component referenced by the "sectionIndex"
                    const newComponent =
                        this.view.packet.components[
                            this.selectedComponent.sectionIndex
                        ];
                    if (
                        newComponent &&
                        this.shouldSelectComponent(newComponent)
                    ) {
                        this.selectComponent(newComponent.id);
                    }
                } else {
                    if (
                        this.lastComponentIsSelected() &&
                        type === 'next' &&
                        this.isPreview
                    ) {
                        window.close();
                    } else if (
                        this.lastComponentIsSelected() &&
                        type === 'next'
                    ) {
                        this.redirectOutside();
                    } else {
                        const newComponent =
                            this.view.packet.components[newIndex];
                        if (
                            newComponent &&
                            this.shouldSelectComponent(newComponent)
                        ) {
                            this.selectComponent(newComponent.id);
                        }
                    }
                }
            }
        }
    }

    selectComponent(id) {
        this.displayFeedback = false;
        this.nextButtonClicked = 0;
        this.appLoadingIndicatorService.show('view');

        this.appJobService.kill(this.jobs.mediaProgress);
        this.appJobService.kill(this.jobs.document);
        this.appJobService.kill(this.jobs.questionsValidate);
        this.lastQuestionsJson = '';
        this.selectedComponent = this.findComponentById(id);
        this.selectedComponentId = id;
        this.selectedComponent.visitsCount++;
        this.selectedComponent.viewed = true;

        const type = this.selectedComponent.type;
        if (type === 'document') {
            // Initialize documents.
            this.startDocumentJob();
        } else if (type === 'questions') {
            // Initialize questions JSON.
            this.lastQuestionsJson = this.buildAnswersJson();
            this.startQuestionsJob();
        } else if (type === 'audio' || type === 'video') {
            // Initialize audio and video.
            this.startMediaJob(type);
        }

        this.persistView();

        // Clear document.
        if (type !== 'document') {
            this.clearSelectedQuestion.next();
        }

        // Hide List
        this.showList = false;

        // Scroll top on next component
        $('html').scrollTop(0);
        $('.view-body .view').scrollTop(0);

        // delay the loading indicator for 1.0s while loading the images
        this.appLoadingIndicatorService.hide(1000);
    }

    shouldDisplayRadio(question: Question): boolean {
        return this.radioButtonTypes.indexOf(question.type) >= 0;
    }

    shouldDisplayCheckbox(question: Question): boolean {
        return question.type === QuestionType.multipleResponse;
    }

    updateAnswer(newValue, question): void {
        this.selectedComponent.questions.find(
            (q) => q.id === question.id
        ).answer = newValue;
    }

    updateAnswerByType(newValue, question): void {
        if (this.shouldDisplayRadio(question)) {
            this.selectedComponent.questions.find(
                (q) => q.id === question.id
            ).answer = newValue;
        } else if (this.shouldDisplayCheckbox(question)) {
            const answer = this.selectedComponent.questions.find(
                (q) => q.id === question.id
            ).answer;
            if (answer === null) {
                this.selectedComponent.questions.find(
                    (q) => q.id === question.id
                ).answer = [];
                this.selectedComponent.questions
                    .find((q) => q.id === question.id)
                    .answer.push(newValue);
            } else if (answer.indexOf(newValue) === -1) {
                this.selectedComponent.questions
                    .find((q) => q.id === question.id)
                    .answer.push(newValue);
            } else {
                answer.splice(answer.indexOf(newValue), 1);
            }
        }
    }

    onClickVisitButton(): void {
        this.selectedComponent.progress = 1;

        this.persistView();
    }

    onUpdateDocumentPageView(viewedPageIndex: number): void {
        this.selectedComponent.document.pageViews[viewedPageIndex]
            .visitsCount++;
    }

    displayPacketSummaryDialog(): void {
        this.dialog.open(DialogReadonlyComponent, {
            width: '400px',
            height: '200px',
            disableClose: true,
            data: {
                title: 'Summary',
                text: this.view.packet.summary,
            },
        });
    }

    displayComponentList(): void {
        this.showList = !this.showList;

        if (this.showList) {
            const type = this.selectedComponent.type;
            if (type === 'audio' || type === 'video') {
                setTimeout(() => {
                    const selector = `consume-${type}`;
                    const media = <HTMLMediaElement>(
                        document.getElementById(selector)
                    );
                    if (media) {
                        media.pause();
                    }
                }, 0);
            }
        }
    }

    objHasImage(item): boolean {
        const itemHasOwnProperty = Object.prototype.hasOwnProperty.call(
            item,
            'imageUrl'
        );
        return itemHasOwnProperty && item.imageUrl && item.imageUrl.length > 0;
    }

    isShortAnswer(question: Question): boolean {
        return question.type === QuestionType.shortAnswer;
    }

    choiceHasImage(choice: Choice): boolean {
        const choiceHasOwnProperty = Object.prototype.hasOwnProperty.call(
            choice,
            'imageUrl'
        );
        return (
            choiceHasOwnProperty &&
            choice.imageUrl &&
            choice.imageUrl.length > 0
        );
    }

    getNextButtonIcon(): string {
        if (this.isUndoAble() && this.displayFeedback && !this.isPreview) {
            return 'undo';
        }

        if (
            this.shouldDisplayButton('next') &&
            this.lastComponentIsSelected() &&
            ((this.selectedComponent.type !== 'questions' || !this.selectedComponent.scored) ||
                (this.displayFeedback && !this.isPreview))
        ) {
            return 'check';
        }

        return 'arrow_forward';
    }

    getProgress(): number {
        let val = 0;

        if (this.selectedComponent.progress) {
            val = this.selectedComponent.progress * 100;
        }

        return Math.round(val);
    }

    getViewClass(): string {
        let cls = 'ving-view-container';
        cls += this.isPreview ? ' preview' : '';
        return cls;
    }

    getScoreColor(score: any): string {
        let color = null;
        if (score >= 0) {
            const colors = [
                [0, '#F25F5C'],
                [0.6, '@app-yellow'],
                [0.8, '#6AB33F'],
            ];
            const filtered = colors.filter((clr) => clr[0] <= score);
            color = filtered[filtered.length - 1][1];
        }
        return color;
    }

    getScorePercentage(score: any): string {
        let val = 0;
        if (score) {
            val = score * 100;
        }
        return `${Math.round(val)}%`;
    }

    private initView(view: object) {
        this.viewIsInitialized = true;
        this.appLoadingIndicatorService.hide(500);
        const pkt = view['packet'],
            packet = new ConsumePacket(
                pkt.title,
                pkt.summary,
                this.getLogoUrl(view['owner']),
                pkt.visitsCount ? pkt.visitsCount : 0,
                this.buildComponents(pkt.components),
                pkt.componentCount,
                pkt.duration
            );

        this.view = new View(
            'packet',
            view['stub'],
            packet,
            view['nonUserDoneRedirectUrl'],
            view['enforceOrder'],
            view['consumeMinutes'],
            view['consumeInterval'],
        );

        // Initialize selected component.
        this.setSelectedFirstComponent();
        this.persistView();
    }

    private persistView(callback?: Function): void {
        this.ongoingCall = true;
        if (
            this.currentPersistRequest &&
            this.currentPersistRequest.closed === false
        ) {
            this.currentPersistRequest.unsubscribe();
        }

        let url = `PUT:api/view/shares/${this.view.stub}/`;

        if (JSON.stringify(this.urlParams) !== '{}') {
            url += `?${$.param(this.urlParams)}`;
        }

        this.currentPersistRequest = this.appHttpService.request(
            url,
            this.view,
            (view) => {
                view.packet.components = this.filterComponents(
                    view.packet.components
                );
                this.updateComponents(view.packet.components);
                this.setComponentCompletionData();

                if (this.selectedComponent) {
                    this.selectedComponent =
                        this.view.packet.components[
                            this.getSelectedComponentIndex()
                        ];
                }

                this.currentPersistRequest = undefined;
                this.ongoingCall = false;

                if (callback) {
                    callback();
                }
            }
        );
    }

    private isValueOneOrMore(value: number): boolean {
        return value >= 1;
    }

    private getSelectedComponentIndex(): number {
        return this.appHelpersService.findIndexByProperty(
            this.view.packet.components,
            'id',
            this.selectedComponent.id
        );
    }

    private findComponentById(id: number): PacketComponent {
        return this.view.packet.components.find(
            (component) => component.id === id
        );
    }

    private filterComponents(data) {
        // Filter by "ignore" flag
        let components = [];
        if (this.isPreview) {
            components = data;
        } else {
            components = data.filter(
                (component: any) => component.ignore === false
            );
        }

        return components;
    }

    private getLogoUrl(item) {
        if (item.organization != null && item.organization.logoUrl != null) {
            return item.organization.logoUrl;
        }

        return undefined;
    }

    private setSelectedFirstComponent(): void {
        const components = this.view.packet.components;
        const completedComponents = [];
        let selectedComponentId = this.view.packet.components[0].id;
        if (
            components.length < 2 ||
            !this.view.enforceOrder ||
            this.isPreview
        ) {
            this.selectComponent(selectedComponentId);
        } else {
            for (let i = 0; i < components.length; i++) {
                if (components[i].completed) {
                    completedComponents.push(components[i]);
                }
                if (!components[i].locked) {
                    selectedComponentId = components[i].id;
                    if (
                        components[i].type === 'questions' &&
                        (!components[i].completed ||
                            (components[i].completed &&
                                components[i].scored &&
                                !components[i].passed))
                    ) {
                        const component =
                            components[components[i].sectionIndex];
                        if (component) {
                            selectedComponentId = component.id;
                        }
                        break;
                    }
                }
            }
            if (
                completedComponents.length === components.length &&
                this.view.enforceOrder
            ) {
                selectedComponentId = components[0].id;
            }
            this.appHelpersService.whenElementPresentDo(
                '.view-navigation',
                () => {
                    this.selectComponentByCondition(selectedComponentId);
                }
            );
        }
    }

    private selectComponentByCondition(id) {
        setTimeout(() => {
            if (this.isPreview && this.previewComponentId) {
                this.selectComponent(this.previewComponentId);
            } else if (id) {
                this.selectComponent(id);
            } else {
                this.selectComponent(this.view.packet.components[0].id);
            }
        }, 350);
    }

    private buildComponents(packetComponents): Array<PacketComponent> {
        const components: Array<PacketComponent> = [];

        packetComponents.forEach((packetComponent) => {
            const component = <PacketComponent>{};

            // Map key values into new VingComponent object.
            Object.keys(packetComponent).forEach((key) => {
                component[key] = packetComponent[key];
            });

            components.push(component);
        });

        return this.filterComponents(components);
    }

    private updateComponents(packetComponents): Array<PacketComponent> {
        const componentKeys = [
            'id',
            'title',
            'text',
            'duration',
            'durationComplete',
            'thumbnailUrl',
            'locked',
            'ignore',
            'completed',
            'passed',
            'progress',
            'visitsCount',
            'scored',
            'score',
            'questions',
            'sectionIndex',
        ];
        const questionKeys = [
            'id',
            'text',
            'image',
            'imageUrl',
            'answerIsCorrect',
            'choices',
        ];
        const choiceKeys = ['id', 'text', 'image', 'imageUrl', 'correct'];
        const components = [];

        packetComponents.forEach((component) => {
            const updateComponent = this.findComponentById(component.id);

            if (updateComponent) {
                // Map key values into new VingComponent object.
                componentKeys.forEach((key) => {
                    if (
                        (key !== 'visitsCount' ||
                            component[key] > updateComponent[key]) &&
                        typeof component[key] !== 'undefined'
                    ) {
                        if (key === 'questions') {
                            const questions = [];
                            component[key].forEach((question) => {
                                const updateQuestion = updateComponent[
                                    key
                                ].find((q) => q.id === question.id);
                                if (updateQuestion) {
                                    questionKeys.forEach((qKey) => {
                                        if (
                                            typeof question[qKey] !==
                                            'undefined'
                                        ) {
                                            if (qKey === 'choices') {
                                                const choices = [];
                                                question[qKey].forEach(
                                                    (choice) => {
                                                        const updateChoice =
                                                            updateQuestion[
                                                                qKey
                                                            ].find(
                                                                (c) =>
                                                                    c.id ===
                                                                    choice.id
                                                            );
                                                        if (updateChoice) {
                                                            choiceKeys.forEach(
                                                                (cKey) => {
                                                                    if (
                                                                        typeof choice[
                                                                            cKey
                                                                        ] !==
                                                                        'undefined'
                                                                    ) {
                                                                        updateChoice[
                                                                            cKey
                                                                        ] =
                                                                            choice[
                                                                                cKey
                                                                            ];
                                                                    }
                                                                }
                                                            );
                                                            choices.push(
                                                                updateChoice
                                                            );
                                                        } else {
                                                            const newChoice = <
                                                                Choice
                                                            >{};
                                                            Object.keys(
                                                                choice
                                                            ).forEach(
                                                                (ckey) => {
                                                                    newChoice[
                                                                        ckey
                                                                    ] =
                                                                        choice[
                                                                            ckey
                                                                        ];
                                                                }
                                                            );
                                                            choices.push(
                                                                newChoice
                                                            );
                                                        }
                                                    }
                                                );

                                                // Remove old choices from revise and Sort
                                                if (
                                                    this.appHelpersService.isEqual(
                                                        choices,
                                                        updateQuestion[qKey]
                                                    )
                                                ) {
                                                    const tempChoices =
                                                        this.appHelpersService.getClone(
                                                            choices
                                                        );
                                                    tempChoices.sort((a, b) => {
                                                        return (
                                                            question[
                                                                qKey
                                                            ].findIndex(
                                                                (c) =>
                                                                    c.id ===
                                                                    a.id
                                                            ) -
                                                            question[
                                                                qKey
                                                            ].findIndex(
                                                                (c) =>
                                                                    c.id ===
                                                                    b.id
                                                            )
                                                        );
                                                    });
                                                    updateQuestion[qKey] =
                                                        this.appHelpersService.getClone(
                                                            tempChoices
                                                        );
                                                }
                                            } else {
                                                updateQuestion[qKey] =
                                                    question[qKey];
                                            }
                                        }
                                    });
                                    questions.push(updateQuestion);
                                } else {
                                    const newQuestion = <Question>{};
                                    Object.keys(question).forEach((qkey) => {
                                        newQuestion[qkey] = question[qkey];
                                    });
                                    questions.push(newQuestion);
                                }
                            });
                            // was code for revise and sort questions here but it didnt seem to effect revising and sort
                        } else {
                            updateComponent[key] = component[key];
                        }
                    }
                });
                components.push(updateComponent);
            } else {
                const newComponent = <PacketComponent>{};
                Object.keys(component).forEach((key) => {
                    newComponent[key] = component[key];
                });
                components.push(newComponent);
            }
        });

        // Remove old components from revise and Sort
        if (
            !this.appHelpersService.isEqual(
                components,
                this.view.packet.components
            )
        ) {
            const tempComponents = this.appHelpersService.getClone(components);
            tempComponents.sort((a, b) => {
                return (
                    packetComponents.findIndex((c) => c.id === a.id) -
                    packetComponents.findIndex((c) => c.id === b.id)
                );
            });
            this.view.packet.components =
                this.appHelpersService.getClone(tempComponents);

            // select first component if selected component is removed
            const tempComponent = this.view.packet.components.find(
                (c) => c.id === this.selectedComponentId
            );
            if (!tempComponent) {
                this.setSelectedFirstComponent();
            }
        }

        return;
    }

    private shouldSelectComponent(component: PacketComponent): boolean {
        const enforceOrder = this.view.enforceOrder;
        let shouldSelect = false;

        if (!enforceOrder) {
            shouldSelect = true;
        } else {
            if (!component.locked) {
                shouldSelect = true;
            }
        }

        return shouldSelect;
    }

    private setDuration(mediaElement: object, component: PacketComponent) {
        if (component.mediaProgress && !component.mediaProgress.duration) {
            const checkDurationSet = () => {
                setTimeout(() => {
                    if (
                        'duration' in mediaElement &&
                        mediaElement['duration']
                    ) {
                        component.mediaProgress.duration =
                            mediaElement['duration'];
                    } else {
                        checkDurationSet();
                    }
                }, 200);
            };

            checkDurationSet();
        }
    }

    private startDocumentJob(): void {
        let lastDocumentJson = JSON.stringify(
            this.selectedComponent.document.pageViews
        );

        this.clearSelectedDocument.next(this.selectedComponent.document);
        this.selectedComponent.document.pageViews[0].visitsCount++;

        // Interval job that looks for changes and persists them when found.
        this.appJobService.create(
            this.jobs.document,
            () => {
                if (
                    this.selectedComponent &&
                    this.selectedComponent.type === 'document'
                ) {
                    const currentDocumentJson = JSON.stringify(
                        this.selectedComponent.document.pageViews
                    );

                    if (lastDocumentJson !== currentDocumentJson) {
                        lastDocumentJson = currentDocumentJson;
                        this.persistView();
                    }
                } else {
                    this.appJobService.kill(this.jobs.document);
                }
            },
            this.intervals.document
        );
    }

    private startQuestionsJob(): void {
        this.appJobService.create(
            this.jobs.questionsValidate,
            () => {
                if (
                    this.selectedComponent &&
                    this.selectedComponent.type === 'questions'
                ) {
                    if (this.questionsJobEnabled && !this.displayFeedback) {
                        // Disable job while processing.
                        this.questionsJobEnabled = false;

                        const initialCompletedState =
                                this.selectedComponent.completed,
                            currentJson = this.buildAnswersJson();

                        const completedHasChanged =
                                initialCompletedState !==
                                this.selectedComponent.completed,
                            jsonHasChanged =
                                currentJson !== this.lastQuestionsJson;

                        // If there is a change in the completed state, persist the change.
                        if (completedHasChanged || jsonHasChanged) {
                            if (jsonHasChanged) {
                                this.lastQuestionsJson = currentJson;
                            }
                            this.persistView(() => {
                                this.questionsJobEnabled = true;
                            });
                        } else {
                            this.questionsJobEnabled = true;
                        }
                    }
                } else {
                    this.appJobService.kill(this.jobs.questionsValidate);
                }
            },
            this.intervals.questions
        );
    }

    private startMediaJob(type: string) {
        const selector = `consume-${type}`;

        // Wait until the media element has loaded into
        // view, then start the media progress job.
        this.appHelpersService.whenElementPresentDo(`#${selector}`, () => {
            const mediaElement = document.getElementById(selector);
            let lastPersist = new Date().getTime(),
                lastPersistType = '';

            // Set 'duration' property.
            this.setDuration(mediaElement, this.selectedComponent);

            // Creating and running tracking job for audio and video.
            this.appJobService.create(
                this.jobs.mediaProgress,
                () => {
                    if (
                        this.selectedComponent &&
                        (this.selectedComponent.type === 'audio' ||
                            this.selectedComponent.type === 'video')
                    ) {
                        const played = mediaElement['played'],
                            playedLastIndex = played.length;

                        this.selectedComponent.mediaProgress.detail = [];
                        for (let i = 0; i < playedLastIndex; i++) {
                            const arr = [];
                            arr.push(played.start(i));
                            arr.push(played.end(i));
                            this.selectedComponent.mediaProgress.detail.push(
                                arr
                            );
                        }

                        if (
                            mediaElement['ended'] !== true &&
                            mediaElement['paused'] !== true
                        ) {
                            // On a defined interval, persist progress to the server.
                            if (
                                this.selectedComponent.mediaProgress.duration &&
                                new Date().getTime() - lastPersist >= 3500
                            ) {
                                lastPersist = new Date().getTime();
                                this.persistView();
                                lastPersistType = 'interval';
                            }
                        } else {
                            // When the media is paused or ended, persist progress to the server.
                            // But, only do it once, hence the check of 'lastPersistType'.
                            if (lastPersistType !== 'stopped') {
                                this.persistView();
                                lastPersistType = 'stopped';
                            }
                        }
                    } else {
                        this.appJobService.kill(this.jobs.mediaProgress);
                    }
                },
                this.intervals.media
            );

            // Landscape Mode
            if (
                window.innerHeight < window.innerWidth &&
                type === 'video' &&
                this.isMobile
            ) {
                this.openVideoFullScreen();
            }
        });
    }

    private buildAnswersJson(): string {
        return JSON.stringify(
            this.selectedComponent.questions.map((q) => q.answer)
        );
    }

    private setComponentCompletionData() {
        this.view.durationComplete = 0;

        const completedComponents = [];

        for (let i = 0; i < this.view.packet.components.length; i++) {
            this.view.durationComplete += this.view.packet.components[i].durationComplete;
            if (this.view.packet.components[i].completed) {
                completedComponents.push(this.view.packet.components[i])
            }
        }

        this.view.isComplete = this.view.packet.componentCount === completedComponents.length;
    }

    private redirectOutside() {
        const components = this.view.packet.components;
        const statusDialog = this.dialog.open(DialogViewStatusComponent, {
            width: '400px',
            height: '250px',
            disableClose: true,
            data: {
                view: this.view,
                packet: this.view.packet,
            },
        });

        statusDialog.afterClosed().subscribe((result) => {
            // if action is continue, enforced order is false, and there are components that have been skipped
            if (
                result.event === 'continue' &&
                components.some((component) => component.completed === false)
            ) {
                // go to the component that was first skipped
                this.selectComponent(
                    components.find(
                        (component) => component.completed === false
                    ).id
                );
            } else {
                // any other case
                if (this.appUserService.isLoggedIn()) {
                    // user is logged in - go to assignments
                    this.router.navigateByUrl('/user/assignments');
                } else {
                    // user is not logged in - go to redirect URL
                    window.location.assign(this.view.nonUserDoneRedirectUrl);
                }
            }
        });
        return statusDialog;
    }

    private isUndoAble() {
        return (
            this.selectedComponent &&
            this.selectedComponent.type === 'questions' &&
            this.selectedComponent.scored &&
            !this.selectedComponent.passed &&
            this.view.enforceOrder
        );
    }

    private openVideoFullScreen() {
        if (this.video) {
            const element = this.video.nativeElement as HTMLVideoElement;
            if (element['requestFullscreen']) {
                element['requestFullscreen']();
            } else if (element['mozRequestFullScreen']) {
                element['mozRequestFullScreen']();
            } else if (element['webkitRequestFullscreen']) {
                element['webkitRequestFullscreen']();
            } else if (element['msRequestFullscreen']) {
                element['msRequestFullscreen']();
            } else if (element['enterFullScreen']) {
                element['enterFullScreen']();
            } else if (element['mozEnterFullScreen']) {
                element['mozEnterFullScreen']();
            } else if (element['webkitEnterFullscreen']) {
                element['webkitEnterFullscreen']();
            } else if (element['msEnterFullScreen']) {
                element['msEnterFullScreen']();
            }
        }
    }

    private closeVideoFullScreen() {
        if (this.video) {
            if (
                document['fullscreenElement'] ||
                document['mozFullScreenElement'] ||
                document['webkitFullscreenElement'] ||
                document['msFullscreenElement']
            ) {
                if (document['exitFullscreen']) {
                    document['exitFullscreen']();
                } else if (document['mozExitFullScreen']) {
                    document['mozExitFullScreen']();
                } else if (document['webkitExitFullscreen']) {
                    document['webkitExitFullscreen']();
                } else if (document['msExitFullscreen']) {
                    document['msExitFullscreen']();
                } else if (document['cancelFullScreen']) {
                    document['cancelFullScreen']();
                } else if (document['mozCancelFullScreen']) {
                    document['mozCancelFullScreen']();
                } else if (document['webkitCancelFullScreen']) {
                    document['webkitCancelFullScreen']();
                } else if (document['msCancelFullScreen']) {
                    document['msCancelFullScreen']();
                }
            }
        }
    }

    openLightBox(url) {
        this.dialog.open(AppDialogLightBoxComponent, {
            width: '100vw',
            maxWidth: '100vw',
            maxHeight: '100vh',
            disableClose: false,
            data: {
                url: url,
            },
        });
        const container = document.getElementsByClassName(
                'cdk-overlay-dark-backdrop'
            )[0],
            matDialog = document.getElementsByClassName(
                'mat-dialog-container'
            )[0];
        container.classList.add('display-cdk-darker');
        matDialog.classList.add('mat-dialog-transparent');
    }
}
