import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as $ from 'jquery';

@Component({
    selector: 'app-list-field',
    templateUrl: './app.list-field.component.html',
    styleUrls: ['./app.list-field.component.less'],
})
export class AppListFieldComponent implements OnInit {
    wrapperId: string;

    @Input() entityName: string;
    @Input() emptyPlaceholder: string;
    @Input() items: Array<string> = [];
    @Input() disabled = false;

    @Output() addField: EventEmitter<any> = new EventEmitter<any>();
    @Output() removeField: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit() {
        this.initializeValues();
    }

    addItem() {
        this.addField.emit();
    }

    removeItem(item) {
        this.removeField.emit(item);
    }

    private initializeValues() {
        // Init the outer wrapping div id.
        let id = this.entityName.toLowerCase();

        // Replace one or more spaces w/ single space.
        id = id.replace(/\s+/, '-');

        // Replace any non letters with a hyphen.
        id = id.replace(/[^a-zA-Z]/, '-');

        this.wrapperId = 'app-list-field-' + id;
    }
}
