<div class="app-questions">
    <!--Details-->
    <div class="question-details">

        <div class="question-details-header">
            <div class="header">Question Details</div>
            <div class="toggles">
                <div class="question-required">
                    <div
                        class="question-required-checkbox"
                        title="Required"
                        label="Required"
                        (click)="clickQuestionIsRequired($event, selectedQuestion.required)"
                    >
                        <i
                            class="material-icons icon required"
                            *ngIf="selectedQuestion.required"
                        >
                            check_box
                        </i>
                        <i
                            class="material-icons icon not-correct"
                            *ngIf="!selectedQuestion.required"
                        >
                            check_box_outline_blank
                        </i>
                        <input
                            type="checkbox"
                            [(ngModel)]="selectedQuestion.required"
                        />
                        <div class="question-required-label">Required</div>
                    </div>
                </div>
                <div class="question-flag">
                    <div
                        class="question-flag-checkbox"
                        title="Flag question"
                        label="Flag Question"
                        (click)="clickQuestionIsFlag($event, selectedQuestion.flag)"
                    >
                        <i
                            class="material-icons icon flag"
                            *ngIf="selectedQuestion.flag"
                        >
                            flag_circle
                        </i>
                        <i
                            class="material-icons icon not-correct"
                            *ngIf="!selectedQuestion.flag"
                        >
                            radio_button_unchecked
                        </i>
                        <input
                            type="checkbox"
                            [(ngModel)]="selectedQuestion.flag"
                        />
                        <div class="question-flag-label">Flag Question</div>
                    </div>
                </div>
            </div>
        </div>

        <!--Selected Question-->
        <div class="body selected-question question-details-content" [class]="getQuestionClass(selectedQuestion.id)">
            <!--Title/Header-->
            <div class="question-title">
                <!--Question Number-->
                <div class="number-and-type">
                    {{ questionText[selectedQuestion.type] }}
                </div>
                <!--Question Type-->
                <div class="add-image-button">
                    <div
                        class="image-button"
                        (click)="displayQuestionImageDialog()"
                    >
                        <i class="material-icons icon">image</i>
                    </div>
                </div>
            </div>

            <!--Question Text & Image-->
            <div [class]="getQuestionContainerClass()">
                <div class="question-text checklist-question-text" [class]="getQuestionClass(selectedQuestion.id)">
                    <app-textarea
                        [placeholder]="'Enter question text...'"
                        [minLength]="validations.questionText.min"
                        [maxLength]="validations.questionText.max"
                        [answer]="selectedQuestion.text"
                        [(ngModel)]="selectedQuestion"
                        (ngModelChange)="onBlurTextarea()"
                        (updateAnswer)="updateTitle($event)"
                    >
                    </app-textarea>
                </div>

                <div class="question-image" *ngIf="isImageSet('question')">
                    <img
                        [src]="selectedQuestion.imageUrl"
                    />
                </div>

            </div>
            <div
                    class="question-text-error"
                    *ngIf="shouldDisplayQuestionTextError()"
                >
                    {{ questionErrorText }}
                </div>

            <!--Answer Choices-->
            <div
                class="question-choices"
                *ngIf="!isQuestionType([
                                'short_answer'
                          ])
                        "
            >
                <!--Title-->
                <div class="title" *ngIf="!isQuestionType(['short_answer'])">
                    <div class="text">Answer Choices</div>

                    <div
                        class="add"
                        *ngIf="
                            isQuestionType([
                                'multiple_choice',
                                'multiple_response'
                            ])
                        "
                    >
                        <button
                            (click)="addChoice()"
                            [disabled]="!shouldAllowAddChoices()"
                            class="app-button dark x-small"
                        >
                            Add
                        </button>
                    </div>
                </div>

                <!--Choices List-->
                <!--True/False, Yes/No-->
                <div
                    class="choices"
                    *ngIf="isQuestionType(['true_false', 'yes_no'])"
                >
                    <div
                        class="choice"
                        *ngFor="let choice of selectedQuestion.choices"
                    >
                        <div class="tf-yn">
                            <div class="text">
                                {{ choice.text }}
                            </div>
                            <div
                                class="is-correct"
                                title="Flag choice"
                                (click)="clickIsFlag($event, choice)"
                            >
                                <i
                                    class="material-icons icon flag"
                                    *ngIf="choice.flag"
                                >
                                    flag_circle
                                </i>
                                <i
                                    class="material-icons icon not-correct"
                                    *ngIf="!choice.flag"
                                >
                                    radio_button_unchecked
                                </i>
                                <input
                                    type="checkbox"
                                    [(ngModel)]="choice.flag"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <!--Likert-->
                <div class="choices" *ngIf="isQuestionType(['likert'])">
                    <div
                        class="choice"
                        *ngFor="let choice of selectedQuestion.choices"
                    >
                        <div class="likert">
                            {{ choice.text }}
                        </div>
                    </div>
                </div>

                <!--Multiple Choice, Multiple Response-->
                <div
                    class="choices"
                    cdkDropList
                    *ngIf="
                        isQuestionType(['multiple_choice', 'multiple_response'])
                    "
                    #autoscrollAnswer
                >
                    <div
                        class="choice"
                        *ngFor="
                            let choice of selectedQuestion.choices;
                            let i = index
                        "
                        [attr.data-index]="i"
                        [cdkDragData]="i"
                        cdkDrag
                        (cdkDragDropped)="droppedChoice($event)"
                    >
                        <div class="mc-rc">
                            <!--Grabber :: Drag/Drop-->
                            <div
                                class="grabber grabable"
                                (click)="$event.stopPropagation()"
                            >
                                <i class="material-icons icon grabable"
                                    >unfold_more</i
                                >
                            </div>

                            <!--Answer Choice :: Image-->
                            <!--If image is not set-->
                            <div class="image" *ngIf="!isImageSet('choice', i)">
                                <div
                                    class="image-button"
                                    (click)="displayChoiceImageDialog(i)"
                                >
                                    <i class="material-icons icon">image</i>
                                </div>
                            </div>
                            <!--If image is set-->
                            <div
                                class="image"
                                *ngIf="isImageSet('choice', i)"
                                title="Edit image"
                                (click)="displayChoiceImageDialog(i)"
                            >
                                <img [src]="choice.imageUrl" />
                                <div class="edit-icon">
                                    <i class="material-icons icon">edit</i>
                                </div>
                            </div>

                            <!--Answer Choice :: Text-->
                            <div class="text">
                                <input
                                    type="text"
                                    [minlength]="validations.answerText.min"
                                    [maxlength]="validations.answerText.max"
                                    placeholder="Enter answer choice text..."
                                    [(ngModel)]="choice.text"
                                    (ngModelChange)="updateQuestionEmitter()"
                                    [class]="getChoiceClass(choice)"
                                />
                            </div>

                            <!--Answer Choice :: Flagged choice indicator-->
                            <div
                                class="is-correct"
                                title="Flag choice"
                                (click)="clickIsFlag($event, choice)"
                            >
                                <i
                                    class="material-icons icon flag"
                                    *ngIf="choice.flag"
                                >
                                    flag_circle

                                </i>
                                <i
                                    class="material-icons icon not-correct"
                                    *ngIf="!choice.flag"
                                >
                                    radio_button_unchecked
                                </i>
                                <input
                                    type="checkbox"
                                    [(ngModel)]="choice.flag"
                                />
                            </div>

                            <!--Answer Choice :: Delete button-->
                            <div
                                class="delete allowed"
                                *ngIf="shouldAllowDeleteChoices()"
                                (click)="displayConfirmDeleteChoiceDialog(i)"
                            >
                                <i class="material-icons icon allow">delete</i>
                            </div>
                            <div
                                class="delete not-allowed"
                                *ngIf="!shouldAllowDeleteChoices()"
                            >
                                <i
                                    class="material-icons icon"
                                    title="Cannot delete. Must have at least two answer choices."
                                >
                                    delete
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--No Question Selected-->
        <div
            class="body no-question-selected"
            *ngIf="selectedQuestionIndex === undefined"
        >
            <div class="app-message-text">
                To see a question's details,<br />
                please select one from the list at left.
            </div>
        </div>
</div>
