<div
    [class]="getContactClass(contact.id)"
    (click)="selectContact(contact)"
>
    <div class="card-header">
        <!--Owner Icon-->
        <div
            class="owner"
            *ngIf="contact.owner"
            [style.background]="contact.owner.hexColor"
        >
            <span [title]="contact.owner.name">{{
                get2LetterName(contact.owner.name)
            }}</span>
        </div>

        <!--Do Not Contact Icon-->
        <div
            class="no-contact-icon"
            *ngIf="contact.blacklist"
            title="Do Not Contact"
        >
            <i class="material-icons icon"
                >speaker_notes_off</i
            >
        </div>

        <!--Contact Method Flag-->
        <div
            class="no-contact-method-icon"
            *ngIf="contact.contactMethods.active.length === 0"
            title="No active contact methods"
        >
            <i class="material-icons icon"
                >error</i
            >
        </div>

        <!--Selection Icon-->
        <div
            class="selected-icon"
            *ngIf="origin==='contacts'"
            [class]="
                getSelectedContactClass(contact.id)
            "
            (click)="toggleSelectedContactId(contact.id)"
        >
            <i class="material-icons icon">check</i>
        </div>
    </div>

    <div class="card-body">
        <!--Avatar-->
        <div class="avatar">
            <div
                class="image avatar"
                *ngIf="contact.avatarUrl"
                [ngStyle]="{
                    background:
                        'url(\'' +
                        contact.avatarUrl +
                        '\')',
                    borderColor: contact.sentiment.color
                }"
            ></div>
            <div
                class="image no-avatar"
                *ngIf="!contact.avatarUrl"
            >
                <i
                    class="material-icons icon"
                    [ngStyle]="{
                        color: contact.sentiment.color
                    }"
                    >{{
                        contact.sentiment.icon
                    }}</i
                >
            </div>
        </div>

        <!--Contact Details-->
        <div class="details">
            <div [class]="'field name'">
                <i
                    *ngIf="contact.isUser"
                    class="
                        is-user-icon
                        material-icons
                        icon
                    "
                    >verified_user</i
                >
                {{ contact.name }}
            </div>
            <div
                [class]="'field contactMethods'"
                *ngIf="
                    contact.contactMethods['active'][0]
                "
            >
                {{
                    contact.contactMethods['active'][0]
                        | formatPhone
                }}
            </div>
            <div
                class="field app-message-text"
                *ngIf="
                    !contact.contactMethods['active']
                "
            >
                No Contact Methods
            </div>
        </div>
    </div>
</div>
