<div class="app-dialog dialog-change-owner">
    <div class="header">
        {{ title }}
    </div>

    <div class="body">
        <div class="container">
            <div class="filter">
                <div class="app-input-text">
                    <!--Field-->
                    <input
                        type="text"
                        name="filterText"
                        [(ngModel)]="filterText"
                        (keyup)="filterItems()"
                        maxlength="40"
                        placeholder="Search the owner..."
                    />

                    <!--Clear button-->
                    <div class="clear-filter">
                        <i class="material-icons icon" *ngIf="!filterText"
                            >search</i
                        >
                        <i
                            class="material-icons icon clear"
                            *ngIf="filterText"
                            (click)="clearFilter()"
                            >clear</i
                        >
                    </div>
                </div>
            </div>

            <div class="entities">
                <div
                    class="entity"
                    *ngFor="let item of items"
                    [style.display]="item.visible ? 'block' : 'none'"
                    (click)="toggleItem(item)"
                >
                    <div class="control">
                        <!--Radiobox-->
                        <div class="radiobox">
                            <i
                                class="material-icons icon all"
                                *ngIf="item.id == ownerId"
                                >lens</i
                            >
                        </div>
                    </div>

                    <!--Name-->
                    <div class="name">
                        {{ item.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer">
        <button mat-dialog-close class="app-button light w-90 mr-1">
            Cancel
        </button>
        <button class="app-button green w-90" (click)="save()">Save</button>
    </div>
</div>
