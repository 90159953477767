<div class="app-dialog-acknowledge">
    <div class="header">
        {{ title }}
    </div>

    <div class="body">
        {{ data.text }}
    </div>

    <div class="footer">
        <button class="app-button green w-90" (click)="onConfirm()">
            {{ label }}
        </button>
    </div>
</div>
