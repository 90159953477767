import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppHttpService } from './app.http.service';
import { AppInfoService } from './app-info/app.info.service';

@Injectable()
export class AppFilterService {
    private contact = {
        filters: {
            data: undefined,
            fetchedAt: null,
        },
        labels: {
            data: undefined,
            fetchedAt: null,
        },
        lists: {
            data: undefined,
            fetchedAt: null,
        },
    };
    private packet = {
        labels: {
            data: undefined,
            fetchedAt: null,
        },
    };
    private checklist = {
        labels: {
            data: undefined,
            fetchedAt: null,
        },
    };
    private endpoints = {
        contact: {
            filters: 'GET:api/filters/contact/',
            labels: 'GET:api/labels/contact/',
            lists: 'GET:api/lists/',
        },
        packet: {
            labels: 'GET:api/labels/packet/',
        },
        checklist: {
            labels: 'GET:api/labels/checklist/',
        },
    };
    private refreshMinutes = {
        contact: 0,
        packet: 0,
        checklist: 0,
    };

    constructor(
        private appHttpService: AppHttpService,
        private appInfoService: AppInfoService
    ) {
        const appInfo = this.appInfoService.getAppInfo();

        this.refreshMinutes.contact = appInfo.contactsInfo.labelsRefreshMinutes;
        this.refreshMinutes.packet = appInfo.packetsInfo.labelsRefreshMinutes;
    }

    getContactFilters(key: string, reset = false): Observable<any> {
        return new Observable((observer) => {
            if (key && key in this.contact) {
                const passedTime =
                    Math.floor(Date.now() / 1000) - this.contact[key].fetchedAt;
                if (
                    this.contact[key].data === undefined ||
                    passedTime >= this.refreshMinutes.contact * 60 ||
                    reset
                ) {
                    this.fetchContactFilters(key, () => {
                        observer.next(this.contact[key].data);
                    });
                } else {
                    observer.next(this.contact[key].data);
                }
            } else {
                observer.next([]);
            }
        });
    }

    getPacketFilters(key: string, reset = false): Observable<any> {
        return new Observable((observer) => {
            if (key && key in this.packet) {
                const passedTime =
                    Math.floor(Date.now() / 1000) - this.packet[key].fetchedAt;
                if (
                    this.packet[key].data === undefined ||
                    passedTime >= this.refreshMinutes.packet * 60 ||
                    reset
                ) {
                    this.fetchPacketFilters(key, () => {
                        observer.next(this.packet[key].data);
                    });
                } else {
                    observer.next(this.packet[key].data);
                }
            } else {
                observer.next([]);
            }
        });
    }

    getChecklistFilters(key: string, reset = false): Observable<any> {
        return new Observable((observer) => {
            if (key && key in this.checklist) {
                const passedTime =
                    Math.floor(Date.now() / 1000) -
                    this.checklist[key].fetchedAt;
                if (
                    this.checklist[key].data === undefined ||
                    passedTime >= this.refreshMinutes.checklist * 60 ||
                    reset
                ) {
                    this.fetchChecklistFilters(key, () => {
                        observer.next(this.checklist[key].data);
                    });
                } else {
                    observer.next(this.checklist[key].data);
                }
            } else {
                observer.next([]);
            }
        });
    }

    reset() {
        this.contact = {
            filters: {
                data: undefined,
                fetchedAt: null,
            },
            labels: {
                data: undefined,
                fetchedAt: null,
            },
            lists: {
                data: undefined,
                fetchedAt: null,
            },
        };
        this.packet = {
            labels: {
                data: undefined,
                fetchedAt: null,
            },
        };
        this.checklist = {
            labels: {
                data: undefined,
                fetchedAt: null,
            },
        };
    }

    resetContactFilters(key: string) {
        if (key && key in this.contact) {
            this.contact[key].data = undefined;
        }
    }

    resetPacketFilters(key: string) {
        if (key && key in this.packet) {
            this.packet[key].data = undefined;
        }
    }
    resetChecklistFilters(key: string) {
        if (key && key in this.packet) {
            this.checklist[key].data = undefined;
        }
    }

    private fetchContactFilters(key: string, callback?: Function) {
        switch (key) {
            case 'filters':
                this.appHttpService.request(
                    this.endpoints.contact.filters,
                    {},
                    (response) => {
                        this.contact.filters = {
                            data: response.results,
                            fetchedAt: Math.floor(Date.now() / 1000),
                        };

                        if (callback) {
                            callback();
                        }
                    }
                );
                break;

            case 'labels':
                this.appHttpService.request(
                    this.endpoints.contact.labels,
                    {},
                    (response) => {
                        this.contact.labels = {
                            data: response.results,
                            fetchedAt: Math.floor(Date.now() / 1000),
                        };

                        if (callback) {
                            callback();
                        }
                    }
                );
                break;

            case 'lists':
                this.appHttpService.request(
                    this.endpoints.contact.lists,
                    {},
                    (response) => {
                        this.contact.lists = {
                            data: response.results,
                            fetchedAt: Math.floor(Date.now() / 1000),
                        };

                        if (callback) {
                            callback();
                        }
                    }
                );
                break;

            default:
                if (callback) {
                    callback();
                }
                break;
        }
    }

    private fetchPacketFilters(key: string, callback?: Function) {
        switch (key) {
            case 'labels':
                this.appHttpService.request(
                    this.endpoints.packet.labels,
                    {},
                    (response) => {
                        this.packet.labels = {
                            data: response.results,
                            fetchedAt: Math.floor(Date.now() / 1000),
                        };

                        if (callback) {
                            callback();
                        }
                    }
                );
                break;

            default:
                if (callback) {
                    callback();
                }
                break;
        }
    }

    private fetchChecklistFilters(key: string, callback?: Function) {
        switch (key) {
            case 'labels':
                this.appHttpService.request(
                    this.endpoints.checklist.labels,
                    {},
                    (response) => {
                        this.checklist.labels = {
                            data: response.results,
                            fetchedAt: Math.floor(Date.now() / 1000),
                        };

                        if (callback) {
                            callback();
                        }
                    }
                );
                break;

            default:
                if (callback) {
                    callback();
                }
                break;
        }
    }
}
