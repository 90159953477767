<as-split
    class="as-split-nav"
    *ngIf="page === 'contacts'"
    gutterSize="30"
    direction="vertical"
>
    <!--Labels & Lists-->
    <as-split-area [maxSize]="90" [minSize]="10" [size]="50" cdkScrollable>
        <div class="entities">
            <!--Headers-->
            <div class="header">
                Labels
                <!--Entity Icon - top-right watermark-->
                <div class="entity-icon">
                    <i class="material-icons icon">local_offer</i>
                </div>
            </div>
            <div class="items">
                <!--Labels-->
                <mat-accordion cdkDropListGroup multi="true">
                    <mat-expansion-panel
                        (opened)="category.open = true"
                        (closed)="category.open = false"
                        (mouseenter)="openCategory($event, category)"
                        *ngFor="let category of entityLists['categories']"
                        [expanded]="category.open"
                        [class.create-new]="category.name == 'Create New Folder'"
                        class="category"
                    >
                        <mat-expansion-panel-header class="categories-item">
                            <mat-panel-title class="categories-panel-title">
                                <i
                                    *ngIf="category.name === 'Create New Folder'"
                                    class="material-icons icon"
                                    style="font-size: 18px; padding-right: 5px"
                                    >create_new_folder</i
                                >
                                <i
                                    *ngIf="category.name !== 'Create New Folder'"
                                    class="material-icons icon"
                                    style="font-size: 18px; padding-right: 5px"
                                    >folder_open</i
                                >
                                <div class="categories-panel-title-text">
                                    {{category.name}}
                                </div>
                                <div
                                    *ngIf="category.name !== 'Create New Folder' && !category.count"
                                    style="padding-left: 5px"
                                >
                                    ({{ category.labels.length }})
                                </div>
                                <div
                                    class="selected-category-labels"
                                    *ngIf="category.name !== 'Create New Folder' && category.count"
                                    style="padding-left: 5px"
                                >
                                    ({{category.count}}/{{
                                    category.labels.length }})
                                </div>
                                <div
                                    *ngIf="category.name !== 'uncategorized' && category.name !== 'Create New Folder'"
                                    class="edit-category-icon"
                                    (click)="editCategory($event, category)"
                                >
                                    <i
                                        class="
                                            material-icons
                                            icon
                                            delete-trigger
                                        "
                                        >create</i
                                    >
                                </div>
                                <div
                                    *ngIf="category.name !== 'uncategorized' && category.name !== 'Create New Folder'"
                                    class="delete-category-icon"
                                    (click)="deleteCategory($event, category)"
                                >
                                    <i
                                        class="
                                            material-icons
                                            icon
                                            delete-trigger
                                        "
                                        >delete</i
                                    >
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div>
                            <app-multi-drag-drop
                                [page]="'contact'"
                                [items]="category.labels"
                                [selected]="selectedLabels"
                                [category]="category.name"
                                (toggled)="toggleLabel($event)"
                                (createCategory)="categoryCreate($event)"
                                (removeLabelFromList)="removeLabelFromList($event)"
                                (isDragging)="getIsDragging($event)"
                            >
                                <ng-template let-item>
                                    {{ item.name }}
                                </ng-template>
                            </app-multi-drag-drop>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </as-split-area>
    <as-split-area [maxSize]="90" [minSize]="10" [size]="50">
        <div class="entities">
            <!--Headers-->
            <div class="header">
                Custom Filters
                <!--Entity Icon - top-right watermark-->
                <div class="entity-icon">
                    <i class="material-icons icon">filter_list</i>
                </div>
            </div>
            <div class="items">
                <!--Custom Filters-->
                <div>
                    <div *ngIf="entityLists['custom'].length === 0">
                        <div class="app-message-text">
                            <br />
                            No custom filters.
                        </div>
                    </div>
                    <div *ngIf="entityLists['custom'].length > 0">
                        <div
                            *ngFor="let item of entityLists['custom']"
                            class="item custom-filter"
                            (click)="toggleSelectedItem('custom', item)"
                        >
                            <div class="selected-icon">
                                <i
                                    class="material-icons icon"
                                    [style.display]="selected['custom'].indexOf(item.id) >= 0 ? 'block' : 'none'"
                                    >check</i
                                >
                            </div>
                            <div class="title" [title]="item.name">
                                <div class="title-text">{{item.name}}</div>
                                <div
                                    class="custom-filter-icon"
                                    (click)="editCustomFilter($event, item.id)"
                                >
                                    <i
                                        class="
                                            material-icons
                                            icon
                                            delete-trigger
                                        "
                                        >create</i
                                    >
                                </div>
                                <div
                                    class="custom-filter-icon"
                                    (click)="deleteCustomFilter($event, item.id)"
                                >
                                    <i
                                        class="
                                            material-icons
                                            icon
                                            delete-trigger
                                        "
                                        >delete</i
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>
<as-split
    class="as-split-nav"
    *ngIf="page === 'packets'"
    gutterSize="30"
    direction="vertical"
>
    <as-split-area
        id="labels"
        class="entities"
        [maxSize]="90"
        [minSize]="10"
        [size]="70"
        cdkScrollable
    >
        <!--Headers-->
        <div class="header">
            Labels
            <!--Entity Icon - top-right watermark-->
            <div class="entity-icon">
                <i class="material-icons icon">local_offer</i>
            </div>
        </div>
        <div class="items">
            <mat-accordion cdkDropListGroup multi="true">
                <mat-expansion-panel
                    *ngFor="let category of entityLists['categories']"
                    (mouseenter)="openCategory($event, category)"
                    (opened)="category.open = true"
                    (closed)="category.open = false"
                    [expanded]="category.open"
                    class="category"
                >
                    <mat-expansion-panel-header class="categories-item">
                        <mat-panel-title class="categories-panel-title">
                            <i
                                *ngIf="category.name === 'Create New Folder'"
                                class="material-icons icon"
                                style="font-size: 18px; padding-right: 5px"
                                >create_new_folder</i
                            >
                            <i
                                *ngIf="category.name !== 'Create New Folder'"
                                class="material-icons icon"
                                style="font-size: 18px; padding-right: 5px"
                                >folder_open</i
                            >
                            <div class="categories-panel-title-text">
                                {{category.name}}
                            </div>
                            <div
                                *ngIf="category.name !== 'Create New Folder' && !category.count"
                                style="padding-left: 5px"
                            >
                                ({{ category.labels.length }})
                            </div>
                            <div
                                class="selected-category-labels"
                                *ngIf="category.name !== 'Create New Folder' && category.count"
                                style="padding-left: 5px"
                            >
                                ({{category.count}}/{{ category.labels.length
                                }})
                            </div>
                            <div
                                *ngIf="category.name !== 'uncategorized' && category.name !== 'Create New Folder'"
                                class="edit-category-icon"
                                (click)="editCategory($event, category)"
                            >
                                <i class="material-icons icon delete-trigger"
                                    >create</i
                                >
                            </div>
                            <div
                                *ngIf="category.name !== 'uncategorized' && category.name !== 'Create New Folder'"
                                class="delete-category-icon"
                                (click)="deleteCategory($event, category)"
                            >
                                <i class="material-icons icon delete-trigger"
                                    >delete</i
                                >
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <app-multi-drag-drop
                            [page]="'packet'"
                            [items]="category.labels"
                            [selected]="selectedLabels"
                            [category]="category.name"
                            (toggled)="toggleLabel($event)"
                            (createCategory)="categoryCreate($event)"
                            (isDragging)="getIsDragging($event)"
                            (removeLabelFromList)="removeLabelFromList($event)"
                        >
                            <ng-template let-item>
                                {{ item.name }}
                            </ng-template>
                        </app-multi-drag-drop>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </as-split-area>
    <as-split-area
        id="filters"
        class="entities"
        [maxSize]="70"
        [minSize]="20"
        [size]="30"
    >
        <!--Entity Icon - top-right watermark-->

        <!--Headers-->
        <div class="header">
            Filters
            <div class="entity-icon">
                <i class="material-icons icon">filter_list</i>
            </div>
        </div>
        <div class="items">
            <!--Labels-->
            <div *ngIf="filters.length === 0">
                <div class="app-message-text">
                    <br />
                    No filters.
                </div>
            </div>
            <div *ngIf="filters.length > 0">
                <div
                    class="item"
                    *ngFor="let filter of filters"
                    (click)="toggleFilter(filter.value)"
                >
                    <div class="selected-icon">
                        <i
                            class="material-icons icon"
                            [style.display]="getStyleFilter(filter.value)"
                            >check</i
                        >
                    </div>
                    <div class="title">{{filter.name}}</div>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>
<as-split
    class="as-split-nav"
    *ngIf="page === 'checklists'"
    gutterSize="30"
    direction="vertical"
>
    <as-split-area
        class="entities"
        [maxSize]="90"
        [minSize]="10"
        [size]="70"
        cdkScrollable
    >
        <!--Headers-->
        <div class="header">
            Labels
            <!--Entity Icon - top-right watermark-->
            <div class="entity-icon">
                <i class="material-icons icon">local_offer</i>
            </div>
        </div>
        <div class="items">
            <mat-accordion cdkDropListGroup multi="true">
                <mat-expansion-panel
                    *ngFor="let category of entityLists['categories']"
                    (mouseenter)="openCategory($event, category)"
                    (opened)="category.open = true"
                    (closed)="category.open = false"
                    [expanded]="category.open"
                    class="category"
                >
                    <mat-expansion-panel-header class="categories-item">
                        <mat-panel-title class="categories-panel-title">
                            <i
                                *ngIf="category.name === 'Create New Folder'"
                                class="material-icons icon"
                                style="font-size: 18px; padding-right: 5px"
                                >create_new_folder</i
                            >
                            <i
                                *ngIf="category.name !== 'Create New Folder'"
                                class="material-icons icon"
                                style="font-size: 18px; padding-right: 5px"
                                >folder_open</i
                            >
                            <div class="categories-panel-title-text">
                                {{category.name}}
                            </div>
                            <div
                                *ngIf="category.name !== 'Create New Folder' && !category.count"
                                style="padding-left: 5px"
                            >
                                ({{ category.labels.length }})
                            </div>
                            <div
                                class="selected-category-labels"
                                *ngIf="category.name !== 'Create New Folder' && category.count"
                                style="padding-left: 5px"
                            >
                                ({{category.count}}/{{ category.labels.length
                                }})
                            </div>
                            <div
                                *ngIf="category.name !== 'uncategorized' && category.name !== 'Create New Folder'"
                                class="edit-category-icon"
                                (click)="editCategory($event, category)"
                            >
                                <i class="material-icons icon delete-trigger"
                                    >create</i
                                >
                            </div>
                            <div
                                *ngIf="category.name !== 'uncategorized' && category.name !== 'Create New Folder'"
                                class="delete-category-icon"
                                (click)="deleteCategory($event, category)"
                            >
                                <i class="material-icons icon delete-trigger"
                                    >delete</i
                                >
                            </div>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <app-multi-drag-drop
                            [page]="'checklist'"
                            [items]="category.labels"
                            [selected]="selectedLabels"
                            [category]="category.name"
                            (toggled)="toggleLabel($event)"
                            (createCategory)="categoryCreate($event)"
                            (isDragging)="getIsDragging($event)"
                            (removeLabelFromList)="removeLabelFromList($event)"
                        >
                            <ng-template let-item>
                                {{ item.name }}
                            </ng-template>
                        </app-multi-drag-drop>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </as-split-area>
</as-split>
