<div class="app-dialog dialog-manage-ving-labels">
    <div class="header">Manage Labels</div>

    <div class="body">
        <div class="container">
            <div class="filter">
                <div class="app-input-text">
                    <!--Field-->
                    <input
                        type="text"
                        name="filterText"
                        [(ngModel)]="filterText"
                        (keyup)="onTextChange(filterText)"
                        alphanumeric
                        maxlength="40"
                        placeholder="Search or add new label..."
                    />

                    <!--Clear button-->
                    <div class="clear-filter">
                        <i class="material-icons icon" *ngIf="!filterText"
                            >search</i
                        >
                        <i
                            class="material-icons icon clear"
                            *ngIf="filterText"
                            (click)="clearFilter()"
                            >clear</i
                        >
                    </div>
                </div>
            </div>

            <div class="entities">
                <div class="deselected-items">
                    <fieldset>
                        <legend>Select to Add:</legend>
                        <mat-chip-list multiple aria-label="labels">
                            <mat-chip
                                (click)="toggleItemState(item, 'deselected')"
                                *ngFor="let item of deSelectedItems"
                                [style.display]="
                                    item.visible ? 'block' : 'none'
                                "
                                [ngClass]="{
                                    'active-label': item.state === 'all',
                                    'some-label': item.state === 'some'
                                }"
                            >
                                {{ item.name }}
                            </mat-chip>
                        </mat-chip-list>
                        <mat-chip-list multiple aria-label="new labels">
                            <mat-chip
                                (click)="toggleItemState(newItem, 'new')"
                                [style.display]="
                                    newItem.state === 'all' ? 'none' : 'block'
                                "
                                *ngFor="let newItem of newItems"
                                [ngClass]="{
                                    'active-label': newItem.state === 'all',
                                    'some-label': newItem.state === 'some'
                                }"
                            >
                                {{ newItem.name }}
                            </mat-chip>
                        </mat-chip-list>
                    </fieldset>
                </div>

                <div class="suggested" *ngIf="suggestedItems.length > 0">
                    <fieldset>
                        <legend>Suggested:</legend>

                        <mat-chip-list multiple aria-label="suggested labels">
                            <mat-chip
                                (click)="
                                    toggleItemState(suggestedItem, 'suggested')
                                "
                                *ngFor="let suggestedItem of suggestedItems"
                                [ngClass]="{
                                    'active-label':
                                        suggestedItem.state === 'all',
                                    'some-label': suggestedItem.state === 'some'
                                }"
                            >
                                {{ suggestedItem.name }}
                            </mat-chip>
                        </mat-chip-list>
                    </fieldset>
                </div>
            </div>
            <div class="selected-items">
                <fieldset>
                    <legend>Selected Labels:</legend>
                    <span class="no-labels" *ngIf="!selectedItems.length"
                        >No Labels Selected</span
                    >
                    <mat-chip-list
                        class="selected-items-list"
                        multiple
                        aria-label="labels"
                        *ngIf="selectedItems.length > 0"
                    >
                        <mat-chip
                            (click)="toggleItemState(item, 'selected')"
                            *ngFor="let item of selectedItems"
                            [ngClass]="{
                                'active-label': item.state === 'all',
                                'some-label': item.state === 'some'
                            }"
                        >
                            {{ item.name }}
                        </mat-chip>
                    </mat-chip-list>
                </fieldset>
            </div>
        </div>
    </div>

    <div class="footer">
        <button mat-dialog-close class="app-button light w-90 mr-1">
            Cancel
        </button>
        <button
            class="app-button green w-90"
            (click)="save()"
            [disabled]="!hasChanged"
        >
            Save
        </button>
    </div>
</div>
