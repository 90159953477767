<div class="app-dialog-confirm">
    <div class="header">Confirm</div>

    <div class="body">
        {{ data.text }}
        <div *ngIf="data.secondaryText">{{ data.secondaryText }}</div>
    </div>

    <div class="footer">
        <button mat-dialog-close class="app-button light w-90 mr-1">No</button>
        <button class="app-button green w-90" (click)="onConfirm()">Yes</button>
    </div>
</div>
