<div class="app-dialog app-dialog-light-box">
    <div class="app-dialog-light-box-body">
        <div>
            <div class="app-dialog-light-box-image-wrapper">
                <pinch-zoom [limit-zoom]="40">
                    <img [src]="data.url" alt="Image" />
                </pinch-zoom>
                <i
                    (click)="onConfirm()"
                    class="
                        app-dialog-light-box-image-action-icon
                        material-icons
                        icon
                    "
                    >close</i
                >
            </div>
        </div>
    </div>
</div>
