import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppUserService } from '../../../../shared/services/app.user.service';

@Component({
    selector: 'app-activate',
    templateUrl: './activate.component.html',
    styleUrls: ['./activate.component.less'],
})
export class ActivateComponent implements OnInit {
    constructor(
        @Inject('storage') private storage: Storage,
        private router: Router,
        private appHttpService: AppHttpService,
        private appHelpersService: AppHelpersService,
        private appUserService: AppUserService
    ) {}

    ngOnInit() {
        const urlParams = this.appHelpersService.getUrlParams(),
            data = {
                uid: urlParams['uid'],
                token: urlParams['token'],
            };

        this.appHttpService.request(
            'POST:api/auth/activate/',
            data,
            (response) => {
                this.appHttpService.apiAuthMe(response['authToken'], () => {
                    this.router.navigateByUrl('/dashboard');
                });
            }
        );
    }
}
