import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './app.dialog-verification.component.html',
    styleUrls: ['./app.dialog-verification.component.less'],
})
export class AppDialogVerificationComponent {
    field: string;

    constructor(
        public dialogRef: MatDialogRef<AppDialogVerificationComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            text: string;
            callback: Function;
        }
    ) {}

    handleVerification() {
        this.data.callback(this.field);

        setTimeout(() => {
            this.dialogRef.close();
        }, 200);
    }
}
