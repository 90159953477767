<ng-container *ngIf="status === 'init'">
    <div class="intro">Start recording by clicking button below</div>

    <div class="buttons">
        <!--Start Recording-->
        <button
            class="app-button green"
            (click)="startRecording()"
            [disabled]="isDisabled"
            title="{{
                !isSupported
                    ? 'This feature is not supported in current browser'
                    : ''
            }}"
        >
            <i class="material-icons icon">mic</i> Record Audio
        </button>
    </div>
</ng-container>

<ng-container *ngIf="status === 'recording'">
    <div class="intro">Recording your audio</div>

    <div class="timer">
        {{ displayTime }}
    </div>

    <div class="buttons">
        <!--Pause Recording-->
        <button
            class="app-button light small no-text"
            id="pause"
            title="Pause"
            (click)="pauseRecording()"
            [disabled]="isDisabled"
        >
            <i class="material-icons icon">pause</i>
        </button>

        <!--Stop Recording-->
        <button
            class="app-button light small no-text"
            id="stop"
            title="Stop"
            (click)="stopRecording()"
            [disabled]="isDisabled"
        >
            <i class="material-icons icon">stop</i>
        </button>
    </div>
</ng-container>
