import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    OnChanges,
    Output,
    SimpleChanges,
    ChangeDetectorRef,
} from '@angular/core';
import { AppMessageService } from '../../services/app.message.service';
import { AppLoadingIndicatorService } from '../../services/app.loading-indicator.service';
import { AppHelpersService } from '../../services/app.helpers.service';
import {
    NgxFileDropEntry,
    FileSystemFileEntry,
    FileSystemDirectoryEntry,
} from 'ngx-file-drop';

import * as $ from 'jquery';
import { ContactFile } from '../../../modules/contacts/classes/contact-file';

@Component({
    selector: 'app-avatar-cropper',
    templateUrl: './app.avatar.cropper.component.html',
    styleUrls: ['./app.avatar.cropper.component.less'],
})
export class AppAvatarCropperComponent implements OnInit, OnChanges {
    allowableFileExtensions = '.jpg,.jpeg,.png,.gif';
    selectedFile = undefined;
    image = {
        key: '',
        crop: {
            startX: undefined,
            startY: undefined,
            width: undefined,
            height: undefined,
        },
    };
    originalFileData = undefined;
    cropper = new Object({ x1: 5, y1: 5, x2: 25, y2: 25 });
    workspaceDimensions = { width: undefined, height: undefined };
    messages = {
        error: {
            dimensionsNotSetConsole:
                'ERROR :: Image cropper could not be initialized - [dimensions] property not set.',
            dimensionsNotSetModal:
                'ERROR :: Image cropper could not be initialized.',
        },
    };
    isConfirmedAvatarUpdate = false;
    componentsIsFileOver = false;

    @Input() aspectRatio = 1;
    @Input() resizeToWidth = 200;
    @Input() maintainAspectRatio = true;
    @Input() fileExt = 'png';
    @Input() name: string;
    @Input() originalAvatar = undefined;
    @Input() originalAvatarUrl: string = undefined;
    @Input() type: string; // 'create', 'edit'
    @Input() dimensions: string;
    @Input() cancelBtnLbl = 'Cancel';
    @Input() saveBtnLbl = 'Save';
    @Input() replaceable = false;
    @Input() showActions = true;

    @Output() updateImage: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appHelpersService: AppHelpersService,
        private appMessageService: AppMessageService,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.initDimensions();
        this.initType();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.originalAvatar &&
            'previousValue' in changes.originalAvatar &&
            'currentValue' in changes.originalAvatar &&
            changes.originalAvatar.previousValue !==
                changes.originalAvatar.currentValue
        ) {
            // Init Avatar Cropper
            this.cancelUpdateAvatar();
        }
    }

    openFileDialog() {
        $('#' + this.name + ' input[type="file"]').trigger('click');
    }

    handleRemoveAvatar() {
        // Emit the image removal to the parent component.
        this.updateImage.emit({
            imageRemoved: true,
            image: this.image,
            selectedFile: this.selectedFile,
        });
    }

    haneldUpdateAvatar() {
        this.setImageData();
    }

    cancelUpdateAvatar() {
        this.removeEditingAvatar();
        this.isConfirmedAvatarUpdate = false;

        if (!this.isAvatarExists()) {
            this.type = 'create';
        }
        this.cd.detectChanges();
    }

    updateCurrentAvatar() {
        if (!this.replaceable && this.originalAvatar) {
            const selector = '#' + this.name;

            this.appLoadingIndicatorService.show(
                selector,
                'Processing...',
                () => {
                    this.appHelpersService.getFileDataFromUrl(
                        this.originalAvatar.url,
                        (base64Data) => {
                            const block = base64Data.split(';'),
                                contentType = block[0].split(':')[1],
                                byteString = atob(block[1].split(',')[1]),
                                ab = new ArrayBuffer(byteString.length),
                                ia = new Uint8Array(ab);
                            for (let i = 0; i < byteString.length; i++) {
                                ia[i] = byteString.charCodeAt(i);
                            }
                            const blob = new Blob([ia], { type: contentType });
                            this.originalFileData = base64Data;

                            try {
                                this.selectedFile = new File(
                                    [blob],
                                    'image.' + contentType.split('/')[1]
                                );
                            } catch (err) {
                                // Workaround for IE 11
                                const file: any = blob;
                                file.lastModifiedDate = new Date();
                                file.name =
                                    'image.' + contentType.split('/')[1];
                                this.selectedFile = file;
                            }

                            this.isConfirmedAvatarUpdate = true;
                            this.cd.detectChanges();

                            this.appLoadingIndicatorService.hide(350);
                        }
                    );
                }
            );
        } else {
            this.isConfirmedAvatarUpdate = true;
            this.cd.detectChanges();
        }
    }

    fileChangeEvent(event: any): void {
        const selector = '#' + this.name;

        this.appLoadingIndicatorService.show(selector, 'Processing...', () => {
            this.selectedFile = event.target.files[0];

            const reader = new FileReader();
            reader.onloadend = (e) => {
                this.originalFileData = reader.result;
                this.cd.detectChanges();
                this.appLoadingIndicatorService.hide();
            };
            reader.readAsDataURL(event.target.files[0]);
        });
    }

    imageCropped(event: any) {
        if (!this.showActions) {
            this.setImageData();
        }
    }

    loadImageFailed(event) {
        console.log('--- Load Image Failed ---');
    }

    isFileSelected() {
        return this.selectedFile !== undefined;
    }

    isAvatarExists() {
        return (
            this.type === 'edit' &&
            this.originalAvatar &&
            'url' in this.originalAvatar
        );
    }

    componentFilesDropped(files: NgxFileDropEntry[]) {
        const selector = '#' + this.name + ' .content';

        this.appLoadingIndicatorService.show(selector, 'Processing...', () => {
            for (let droppedFile of files) {
                if (droppedFile.fileEntry.isFile) {
                    const fileEntry =
                        droppedFile.fileEntry as FileSystemFileEntry;

                    fileEntry.file((file: File) => {
                        const fileExt =
                            this.appHelpersService.getFileExtensionByFileName(
                                file.name
                            );
                        if (
                            this.allowableFileExtensions.indexOf(fileExt) >= 0
                        ) {
                            const reader: FileReader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onloadend = (e) => {
                                this.type = 'edit';
                                this.originalFileData = reader.result;
                                this.selectedFile = file;
                                this.isConfirmedAvatarUpdate = true;
                                this.componentsIsFileOver = false;
                                this.cd.detectChanges();
                                this.appLoadingIndicatorService.hide();
                            };
                        } else {
                            this.appLoadingIndicatorService.hide();
                            droppedFile = null;
                            this.componentsIsFileOver = false;
                            const isModalDisplayed =
                                    $('mat-dialog-container').length === 1,
                                location = isModalDisplayed ? 'modal' : 'view';

                            this.appMessageService.show(
                                location,
                                'error',
                                'Invalid file selected.',
                                2
                            );
                        }
                    });
                }
            }
        });
    }

    handleImageSize(type) {
        const minLength = 150,
            maxLength = 260;
        let length = 0;
        if (this.aspectRatio === 1) {
            length = minLength;
        } else if (this.aspectRatio < 1) {
            length =
                type === 'width' ? this.aspectRatio * minLength : minLength;
        } else if (this.aspectRatio > maxLength / minLength) {
            length =
                type === 'width' ? maxLength : maxLength / this.aspectRatio;
        } else {
            length =
                type === 'width' ? this.aspectRatio * minLength : minLength;
        }
        return length + 4;
    }

    private removeEditingAvatar() {
        // Clear selected file and original avatar for create/edit workflows.
        $('#' + this.name + ' input[type="file"]').val('');
        this.selectedFile = undefined;
        this.componentsIsFileOver = false;
        this.image.key = '';
        this.image.crop.startX = undefined;
        this.image.crop.startY = undefined;
        this.image.crop.width = undefined;
        this.image.crop.height = undefined;
    }

    private initDimensions() {
        const showErrorMessage = () => {
            const isModalDisplayed = $('mat-dialog-container').length === 1,
                location = isModalDisplayed ? 'modal' : 'view';

            this.appMessageService.show(
                location,
                'error',
                this.messages.error.dimensionsNotSetModal
            );
        };

        const getDimensions = () => {
            let dimensions;

            if (
                this.dimensions.indexOf('x') >= 0 &&
                this.dimensions.length >= 5
            ) {
                const dimensionsArray = this.dimensions.split('x'),
                    width = parseInt(dimensionsArray[0], 10),
                    height = parseInt(dimensionsArray[1], 10);

                if (
                    dimensionsArray.length === 2 &&
                    typeof width === 'number' &&
                    typeof height === 'number'
                ) {
                    dimensions = { width: width, height: height };
                }
            }

            return dimensions;
        };

        if (!this.dimensions) {
            showErrorMessage();
        } else {
            const parsedDimensions = getDimensions();

            if (!parsedDimensions) {
                showErrorMessage();
            } else {
                // If proper dimension values were passed in, set the workspace dimensions.
                this.workspaceDimensions.width = parsedDimensions.width;
                this.workspaceDimensions.height = parsedDimensions.height;
            }
        }
    }

    private initType() {
        if (this.type === 'edit' && !this.isAvatarExists()) {
            this.type = 'create';
        }
    }

    private setImageData() {
        this.appHelpersService.whenElementPresentDo(
            '#' + this.name + ' image-cropper .cropper',
            () => {
                const imageCrop = this.image.crop,
                    imageCropper = $('#' + this.name + ' image-cropper').find(
                        '.cropper'
                    );

                if (imageCropper.length === 1) {
                    // Set cropper information.
                    imageCrop.startX = parseInt(imageCropper.css('left'), 10);
                    imageCrop.startY = parseInt(imageCropper.css('top'), 10);
                    imageCrop.width = parseInt(imageCropper.css('width'), 10);
                    imageCrop.height = parseInt(imageCropper.css('height'), 10);

                    this.scaleImageData();
                } else {
                    const isModalDisplayed =
                            $('mat-dialog-container').length === 1,
                        location = isModalDisplayed ? 'modal' : 'view';

                    this.appMessageService.show(
                        location,
                        'general',
                        'Cannot process image. There are multiple ' +
                            'croppers displayed at this time.',
                        5
                    );
                }
            }
        );
    }

    private scaleImageData() {
        const img = $('#' + this.name).find('.source-image')[0],
            naturalWidth = img['naturalWidth'],
            naturalHeight = img['naturalHeight'],
            scaleBy = naturalWidth > naturalHeight ? 'x' : 'y',
            propertySuffix = scaleBy === 'x' ? 'Width' : 'Height';

        if (img['natural' + propertySuffix] > img['client' + propertySuffix]) {
            const crop = this.image.crop,
                scale =
                    img['natural' + propertySuffix] /
                    img['client' + propertySuffix];

            ['startX', 'startY', 'width', 'height'].forEach((key) => {
                let val = Math.floor(crop[key] * scale);

                // Adjust width.
                if (
                    (key === 'width' || key === 'startX') &&
                    val > naturalWidth
                ) {
                    val = naturalWidth;
                }

                // Adjust height.
                if (
                    (key === 'height' || key === 'startY') &&
                    val > naturalHeight
                ) {
                    val = naturalHeight;
                }

                crop[key] = val >= 0 ? val : 0;
            });
        }

        // Emit the image change to the parent component.
        this.updateImage.emit({
            imageEdited: this.isAvatarExists() ? true : false,
            image: this.image,
            selectedFile: this.selectedFile,
        });
    }
}
