import { PacketComponent } from '../../vings/classes/packet-component';

export class ConsumePacket {
    title: string;
    summary: string;
    visitsCount: number;
    components: Array<PacketComponent>;
    componentCount: number;
    duration: number;
    logoUrl: string;

    constructor(
        title: string,
        summary: string,
        logoUrl: string,
        visitsCount: number,
        components: Array<PacketComponent>,
        componentCount: number,
        duration: number,
    ) {
        this.title = title;
        this.summary = summary;
        this.logoUrl = logoUrl;
        this.visitsCount = visitsCount;
        this.components = components;
        this.componentCount = componentCount;
        this.duration = duration;
    }
}
