import { Component, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppHelpersService } from '../../services/app.helpers.service';
import { AppHttpService } from '../../services/app.http.service';
import { AppMessageService } from '../../services/app.message.service';
import { AppLoadingIndicatorService } from '../../services/app.loading-indicator.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../app.utils';

@Component({
    templateUrl: './dialog.manage-labels.component.html',
    styleUrls: ['./dialog.manage-labels.component.less'],
})
export class DialogManageLabelsComponent implements OnInit {
    messages = {
        success: 'Labels successfully updated.',
        error: 'There was an error updating the labels. Please try again.',
        emptyItems:
            'One or more of the newly added labels has an error. Please review.',
        itemsHaveNotChanged: 'No changes to the labels have been made.',
        newItemsError: '',
    };
    items = [];
    suggestedItems = [];
    selectedItems = [];
    originalSelected = [];
    deSelectedItems = [];
    newItems = [];
    hasChanged = false;
    originalData = '';
    itemsCount: number;
    filterText: string;
    text: string;
    textChanged: Subject<string> = new Subject<string>();
    validation = {
        labels: {
            min: 2,
            max: 40,
        },
    };

    constructor(
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        public dialogRef: MatDialogRef<DialogManageLabelsComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            page: string;
            items: Array<object>;
            selectedItems: Array<object>;
            deSelectedItems: Array<object>;
            selectedItemIds: Array<number>;
            selectedAllItems: boolean;
            excludedItemIds: Array<number>;
            itemCount: number;
            countPropertyName: string;
            queries: any;
            callback: any;
        }
    ) {
        this.filterItems();
    }

    ngOnInit() {
        // Initialize messages text.
        const msgs = this.messages,
            min = this.validation.labels.min,
            max = this.validation.labels.max;

        msgs.newItemsError =
            'Ensure that all new labels are unique and between ' +
            min +
            ' and ' +
            max +
            ' characters.';

        // Initialize the items data into something that can be used within the UI.
        this.items = this.initializeItems(this.data.items, false);

        // Once this data is built, capture a copy of it in string form to denote original state.
        // Will be used to confirm data change when user attempts to save changes.
        this.originalData = JSON.stringify(this.items);

        // Initialize the items count :: for use when filtering.
        this.itemsCount = this.items.length;

        this.text = this.filterText ? this.filterText.toLowerCase() : '';

        this.appLoadingIndicatorService.hide(350);
    }

    onTextChange(text: string) {
        this.textChanged.next(text.toLowerCase());
    }

    filterItems() {
        this.textChanged
            .pipe(debounceTime(500)) // wait 500ms after the last keyup
            .pipe(distinctUntilChanged()) // only emit if value is different from previous value
            .subscribe((text) => {
                this.suggestedItems = this.suggestedItems.filter(function (
                    item
                ) {
                    return item.state !== 'none';
                });
                this.newItems = this.newItems.filter(function (item) {
                    return item.state !== 'none';
                });

                for (let i = 0; i < this.itemsCount; i++) {
                    if (text === '') {
                        this.items[i].visible = true;
                    } else {
                        this.items[i].visible =
                            this.items[i].name.toLowerCase().indexOf(text) !==
                            -1;
                    }
                }

                if (text) {
                    let getURL = '';
                    if (this.data.page === 'contacts') {
                        getURL = `GET:api/labels/contact/?suggest=true?limit=10&query=${text}`;
                    } else if (this.data.page === 'vings') {
                        getURL = `GET:api/labels/packet/?suggest=true?limit=10&query=${text}`;
                    } else {
                        getURL = `GET:api/labels/checklist/?suggest=true?limit=10&query=${text}`;
                    }
                    this.appHttpService.request(getURL, {}, (response) => {
                        const suggestedItems = this.initializeItems(
                            response.results,
                            true
                        );
                        suggestedItems.forEach((item) => {
                            if (
                                !this.getItemByName(item.name) &&
                                !this.searchItemByName(
                                    this.suggestedItems,
                                    item.name
                                )
                            ) {
                                this.suggestedItems.push(item);
                            }
                        });
                    });

                    const trimedText = Utils.trim(text);
                    if (
                        !this.getItemByName(trimedText) &&
                        !this.isExistingLabel(trimedText) &&
                        !this.searchItemByName(this.newItems, trimedText) &&
                        trimedText.length >= this.validation.labels.min &&
                        trimedText.length <= this.validation.labels.max
                    ) {
                        this.newItems.push({
                            id: new Date().getTime(),
                            name: trimedText,
                            hasError: false,
                            state: 'none',
                        });
                    }
                }
            });
    }

    clearFilter() {
        this.onTextChange('');
        this.filterText = '';
        this.filterItems();
    }

    toggleItemState(item, type) {
        const original = JSON.stringify(this.originalSelected);
        if (item.state === 'all') {
            item.state = 'none';
            this.deSelectedItems.unshift(item);
            this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
        } else if (item.state === 'some') {
            item.state = 'all';
        } else {
            item.state = 'all';
            this.selectedItems.unshift(item);
            this.deSelectedItems.splice(this.deSelectedItems.indexOf(item), 1);
            this.clearFilter();
        }
        this.hasSelectedChanged(original);
    }

    newItemsHaveErrors() {
        return this.newItems.filter((newItem) => newItem.hasError === true)
            .length;
    }

    hasSelectedChanged(original) {
        this.hasChanged = original !== JSON.stringify(this.selectedItems);
    }

    save(): void {
        // Initial check to see if lists have changed.
        if (!this.haveItemsChanged()) {
            this.dialogRef.close();
            this.appMessageService.show(
                'app',
                'general',
                this.messages.itemsHaveNotChanged,
                3
            );
        } else {
            const data = this.buildData();

            if (this.areNewItemsValid(data)) {
                this.appLoadingIndicatorService.show('modal');

                // Clear out 'type' and 'id' properties from all data.items.
                data.items.forEach((item) => {
                    delete item['type'];
                    delete item['id'];
                });

                // Re-name the data.items to the correct type-specific property name.
                data['labels'] = data.items;
                delete data['items'];
                const queryString = Object.keys(this.data.queries)
                    .map((key) => {
                        let str = '';
                        if (Array.isArray(this.data.queries[key])) {
                            this.data.queries[key].forEach(function (query) {
                                str += str === '' ? '' : '&';
                                str += key + '=' + query;
                            });
                        } else {
                            str += key + '=' + this.data.queries[key];
                        }
                        return str;
                    })
                    .join('&');

                if (this.data.page === 'contacts') {
                    this.appHttpService.request(
                        'POST:api/labels/contact/?' + queryString,
                        data,
                        () => {
                            this.data.callback(
                                this.dialogRef,
                                this.messages.success
                            );
                        },
                        (err) => {
                            const error =
                                err.data &&
                                Array.isArray(err.data) &&
                                this.appHelpersService.isValidString(
                                    err.data[0]
                                )
                                    ? err.data[0]
                                    : this.messages.error;
                            this.appMessageService.show(
                                'modal',
                                'error',
                                error,
                                5
                            );
                            this.appLoadingIndicatorService.hide(200);
                        }
                    );
                } else if (this.data.page === 'vings') {
                    this.appHttpService.request(
                        'POST:api/labels/packet/?' + queryString,
                        data,
                        () => {
                            this.data.callback(
                                this.dialogRef,
                                this.messages.success
                            );
                        },
                        (err) => {
                            const error =
                                err.data &&
                                Array.isArray(err.data) &&
                                this.appHelpersService.isValidString(
                                    err.data[0]
                                )
                                    ? err.data[0]
                                    : this.messages.error;
                            this.appMessageService.show(
                                'modal',
                                'error',
                                error,
                                5
                            );
                            this.appLoadingIndicatorService.hide(200);
                        }
                    );
                } else {
                    this.appHttpService.request(
                        'POST:api/labels/checklist/?' + queryString,
                        data,
                        () => {
                            this.data.callback(
                                this.dialogRef,
                                this.messages.success
                            );
                        },
                        (err) => {
                            const error =
                                err.data &&
                                Array.isArray(err.data) &&
                                this.appHelpersService.isValidString(
                                    err.data[0]
                                )
                                    ? err.data[0]
                                    : this.messages.error;
                            this.appMessageService.show(
                                'modal',
                                'error',
                                error,
                                5
                            );
                            this.appLoadingIndicatorService.hide(200);
                        }
                    );
                }
            } else {
                this.appMessageService.show(
                    'modal',
                    'error',
                    this.messages.emptyItems,
                    5
                );
                this.appLoadingIndicatorService.hide(200);
            }
        }
    }

    private haveItemsChanged() {
        return (
            this.newItems.length > 0 ||
            this.originalData !== JSON.stringify(this.items)
        );
    }

    private initializeItems(list, isSearch: boolean) {
        const items = [];
        const allLabels = [];

        if (!isSearch) {
            this.selectedItems = [];
            this.deSelectedItems = [];
        }

        Object.keys(list).forEach((category) => {
            allLabels.push(list[category].map((label) => label));
        });

        const flattenedLabels = allLabels.flat();

        flattenedLabels.forEach((item) => {
            let itemObj = {
                name: item.name,
                state: this.getCheckedState(item[this.data.countPropertyName]),
                count: item[this.data.countPropertyName],
                visible: true,
            };
            if (this.data.page === 'vings' || 'checklists') {
                itemObj = {
                    name: item.name,
                    // Value set to: tri-state options: 'all', 'none', 'some'
                    state: this.getCheckedState(item['objectCount']),
                    count: item['objectCount'],
                    visible: true,
                };
            }

            items.push(itemObj);

            // adds to selected and deselected items
            if (!isSearch) {
                if (itemObj.state === 'all' || itemObj.state === 'some') {
                    this.selectedItems.push(itemObj);
                    this.originalSelected.push(itemObj);
                } else {
                    this.deSelectedItems.push(itemObj);
                }
            }
        });

        return items;
    }

    private getCheckedState(itemCount: number) {
        let state = 'some';

        if (this.data.selectedAllItems) {
            const selectedCount =
                this.data.itemCount - this.data.excludedItemIds.length;

            if (itemCount >= selectedCount) {
                state = 'all';
            }
        } else {
            if (itemCount === this.data.selectedItemIds.length) {
                state = 'all';
            }
        }

        if (itemCount === 0) {
            state = 'none';
        }

        return state;
    }

    private buildData() {
        const originalItems = JSON.parse(this.originalData),
            data = {
                items: [],
            };

        // Add to data.items array items that have been updated.
        this.items.forEach((updatedItem) => {
            const originalItem = originalItems.find(
                (origItem) => origItem.name === updatedItem.name
            );

            // Compare updated items to originals and set the action
            // for changed items (use state to detect change).
            if (originalItem.state !== updatedItem.state) {
                const existingItem = {
                    name: updatedItem.name,
                    action: updatedItem.state === 'all' ? 'add' : 'remove',
                    type: 'existing',
                };

                data.items.push(existingItem);
            }
        });

        // Add to suggested items to items array.
        this.suggestedItems.forEach((suggestedItem) => {
            if (suggestedItem.state === 'all') {
                data.items.push({
                    name: suggestedItem.name.trim(),
                    action: 'add',
                    type: 'new',
                    id: suggestedItem.id,
                });
            }
        });

        // Add to newly created items to items array.
        this.newItems.forEach((newItem) => {
            if (newItem.state === 'all') {
                data.items.push({
                    name: newItem.name.trim(),
                    action: 'add',
                    type: 'new',
                    id: newItem.id,
                });
            }
        });

        return data;
    }

    private areNewItemsValid(data) {
        const invalidItemIds = [],
            newItems = data.items.filter((item) => item.type === 'new'),
            newItemsLength = newItems.length;

        if (newItemsLength) {
            const min = this.validation.labels.min,
                max = this.validation.labels.max,
                allItemNames = this.getAllItemNames(newItems);

            // Ensure that all new items have unique names and are
            // between the min and max validation lengths.
            for (let i = 0; i < newItemsLength; i++) {
                const newItemName = newItems[i].name;

                if (
                    newItemName.length > max ||
                    newItemName.length < min ||
                    this.getNumberInList(newItemName, allItemNames) !== 1
                ) {
                    invalidItemIds.push(newItems[i].id);
                }
            }

            // Display error messages for invalid items.
            this.displayNewItemErrors(invalidItemIds);
        }

        return invalidItemIds.length === 0;
    }

    private displayNewItemErrors(invalidItemIds) {
        // Set error states for new item that has problems.
        this.newItems.forEach((newItem) => {
            if (invalidItemIds.indexOf(newItem.id) >= 0) {
                newItem.hasError = true;
            }
        });
    }

    private getItemByName(name: string) {
        return this.items.find((item) => item.name === name);
    }

    private searchItemByName(items, name: string) {
        return items.find((item) => item.name === name);
    }

    private isExistingLabel(name: string) {
        return this.items.some((item) => item.name === name.toLowerCase());
    }

    private getAllItemNames(newItems) {
        return newItems
            .map((newItem) => newItem.name)
            .concat(this.items.map((item) => item.name));
    }

    private getNumberInList(item, list) {
        const itemLowerCase = item.toLowerCase();
        let count = 0;

        list.forEach((listItem) => {
            if (listItem.toLowerCase() === itemLowerCase) {
                count++;
            }
        });

        return count;
    }
}
