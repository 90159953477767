import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';

import { ActivateComponent } from './modules/accounts/components/activate/activate.component';
import { LogoutComponent } from './modules/accounts/components/logout/logout.component';
import { SsoComponent } from './modules/accounts/components/sso/sso.component';
import { ForgotPasswordComponent } from './modules/accounts/components/forgot-password/forgot-password.component';

const appRoutes: Routes = [
    { path: '', redirectTo: '/login/signIn', pathMatch: 'full' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes
            /*{enableTracing: true}*/ // <-- debugging purposes only
        ),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router) {
        this.router.errorHandler = (error: any) => {
            console.log(error);
            this.router.navigate(['/user/assignments']);
        };
    }
}
