import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import * as pluralize from 'pluralize';

import { DialogListMessagesComponent } from './dialogs/dialog-list-messages/dialog-list-messages.component';
import { AppHttpService } from '../../shared/services/app.http.service';
import { AppHelpersService } from '../../shared/services/app.helpers.service';
import { AppLoadingIndicatorService } from '../../shared/services/app.loading-indicator.service';
import { AppSelectOption } from '../../shared/components/app-select/classes/app.select.option';
import { AppService } from '../../app.service';
import { Utils } from '../../shared/app.utils';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'app-submissions',
    templateUrl: './submissions.component.html',
    styleUrls: ['./submissions.component.less'],
})
export class SubmissionsComponent implements OnInit, AfterViewInit {
    isMobile = null;
    checklists = [];
    numberOfPages = 0;
    numbersPerPage = 10;
    currentPageIndex = 0;
    numberOfResults = 0;
    entityName = 'checklist';
    pages: Array<AppSelectOption> = [];
    selectedPage: AppSelectOption;
    searchText: string = null;
    isSubmittedPage = false;
    url = 'invited';

    private lastSearchText: string = null;
    private searchSubject: Subject<string> = new Subject();

    constructor(
        private dialog: MatDialog,
        private appService: AppService,
        private appHttpService: AppHttpService,
        private appHelpersService: AppHelpersService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private deviceService: DeviceDetectorService
    ) {
        this.isMobile = this.deviceService.isMobile();
    }

    ngOnInit() {
        this.appLoadingIndicatorService.show('view');
        setTimeout(() => (this.appService.selected.navOption = 'submissions'), 0);

        // Get page offset from page url
        const urlParams = this.appHelpersService.getUrlParams();
        if (urlParams) {
            if ('offset' in urlParams) {
                this.currentPageIndex = parseInt(urlParams.offset);
                if (this.currentPageIndex < 0) {
                    this.currentPageIndex = 0;
                    this.updateOffset(this.currentPageIndex);
                }
            }
            if ('page' in urlParams) {
                if (urlParams.page === 'invited') {
                    this.isSubmittedPage = false;
                } else if (urlParams.page === 'submitted') {
                    this.isSubmittedPage = true;
                } else {
                    this.isSubmittedPage = false;
                }
                this.updateUrl(urlParams.page);
            }
        }

        this.searchSubject.pipe(debounceTime(250)).subscribe((searchText) => {
            this.processSearchTextChange(searchText);
        });
    }

    ngAfterViewInit() {
        // Init assignments
        this.initializeChecklists();
    }

    handlePagination(direction: string): void {
        let newPageIndex: number;

        if (direction === 'previous' && this.currentPageIndex > 0) {
            newPageIndex = this.currentPageIndex - 1;
        } else if (
            direction === 'next' &&
            this.currentPageIndex !== this.numberOfPages - 1
        ) {
            newPageIndex = this.currentPageIndex + 1;
        }

        // Set new selected page object.
        if (newPageIndex !== undefined) {
            this.selectedPage = this.pages[newPageIndex];
            this.currentPageIndex = newPageIndex;

            this.updateOffset(this.currentPageIndex);

            this.appLoadingIndicatorService.show(
                '.assignments-table .assignments-table-body'
            );
            this.initializeChecklists(false);
        }
    }

    handleSearchChange(event): void {
        if (event.key !== 'Enter') {
            const searchText = event.target.value;

            if (this.lastSearchText !== searchText) {
                this.searchSubject.next(searchText);
            }
        }
    }

    clearSearch(): void {
        this.searchText = null;
        const searchText = null;
        this.searchSubject.next(searchText);
    }

    processSearchTextChange(searchText: string) {
        this.lastSearchText = Utils.trim(searchText);
        this.currentPageIndex = 0;

        this.appLoadingIndicatorService.show('.assignments-table .assignments-table-body');
        this.initializeChecklists();
    }

    getTableFooterText(): string {
        const numberOfEntities = this.numberOfResults,
            numberOfPages = this.numberOfPages ? this.numberOfPages : false;
        let text = '';

        // If there are entities within the table to display, generate footer text.
        if (numberOfEntities > 0) {
            if (numberOfPages > 1) {
                const currentPage = this.currentPageIndex + 1,
                    startValue =
                        currentPage * this.numbersPerPage -
                        this.numbersPerPage +
                        1;
                let endValue = startValue + this.numbersPerPage - 1;

                if (endValue > numberOfEntities) {
                    endValue = numberOfEntities;
                }

                // If there are multiple pages, ...
                text =
                    'Displaying ' +
                    startValue +
                    ' to ' +
                    endValue +
                    ' of ' +
                    numberOfEntities +
                    ' ' +
                    pluralize(this.entityName, numberOfEntities);
            } else {
                // ... otherwise, display simpler format text.
                text =
                    'Displaying ' +
                    numberOfEntities +
                    ' ' +
                    pluralize(this.entityName, numberOfEntities);
            }
        } else if (numberOfEntities === 0) {
            text = 'Displaying 0 ' + pluralize(this.entityName);
        }

        return text;
    }

    getFooterPagingWidth(): string {
        let width = '125px';

        if (this.numberOfPages >= 10 && this.numberOfPages <= 99) {
            width = '135px';
        } else if (this.numberOfPages >= 100 && this.numberOfPages <= 999) {
            width = '150px';
        }

        return width;
    }

    private buildTableData(limit = true): object {
        const data = {};

        // Limit
        if (limit) {
            data['limit'] = this.numbersPerPage;
        }

        // Query search text.
        if (this.lastSearchText && this.lastSearchText.length > 0) {
            data['checklistQuery'] = this.lastSearchText;
        }

        // Conditionally set the offset.
        if (this.currentPageIndex + 1 > 0 && limit) {
            data['offset'] = this.currentPageIndex * this.numbersPerPage;
        }

        return data;
    }

    getStub(checklist: any) {
        if (this.url === 'invited') {
            return checklist.invitationStub;
        } else if (this.url === 'submitted') {
            return checklist.submissionStub;
        }
    }

    wasSubmitted(checklist: any) {
        if (checklist.lastSubmittedDate && !checklist.lastInvitedDate) {
            return true;
        } else if (checklist.lastSubmittedDate > checklist.lastInvitedDate) {
            return true;
        }

        return false;
    }

    private initializeChecklists(shouldInitPagingData = true): void {
        const data = this.buildTableData();
        const urlParams = this.appHelpersService.getUrlParams();
        this.updateUrl(this.url);
        const getChecklistUrl = 'GET:api/checklists/' + this.url + '/';
        this.appLoadingIndicatorService.show('.assignments-table .assignments-table-body');
        this.appHttpService.request(getChecklistUrl, data, (response) => {
            if (shouldInitPagingData) {
                this.numberOfResults = response.count;
                this.numberOfPages = Math.ceil(
                    this.numberOfResults / this.numbersPerPage
                );
                // Build page list.
                for (let i = 0; i < this.numberOfPages; i++) {
                    const page = (i + 1).toString();
                    this.pages.push(new AppSelectOption(page, page));
                }
                // Set initial selected page.
                this.selectedPage = this.pages[0];
            }

            this.checklists = response.results;

            this.appLoadingIndicatorService.hide();
        });
    }

    tabChecklists(url) {
        this.currentPageIndex = 0;
        if (url === 'invited') {
            this.isSubmittedPage = false;
            this.updateOffset(this.currentPageIndex);
            this.updateUrl(url);
        } else if (url === 'submitted') {
            this.isSubmittedPage = true;
            this.updateOffset(this.currentPageIndex);
            this.updateUrl(url);
        }
        this.initializeChecklists(true);
    }

    private updateOffset(offset): void {
        // Add offset to page url
        const link = Utils.setUrlParameter(
            window.location.href,
            'offset',
            offset
        );
        window.history.replaceState(null, null, link);
    }
    private updateUrl(url): void {
        // Add page to page url
        this.url = url;
        const link = Utils.setUrlParameter(window.location.href, 'page', url);
        window.history.replaceState(null, null, link);
    }
}
