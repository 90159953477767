import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ElementRef,
    TemplateRef,
    ContentChild,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import * as autoScroll from 'dom-autoscroller';

import { QuestionType } from '../../../../../../shared/classes/questions/question-type';
import { AppDialogConfirmComponent } from '../../../../../../shared/dialogs/dialog-confirm/app.dialog-confirm.component';
import { Choice } from '../../../../../../shared/classes/questions/choice';
import { ValidationKeyInfo } from '../../../../../../shared/services/app-info/app.validation.key.info';
import { AppJobService } from '../../../../../../shared/services/job/app.job.service';
import { AppHelpersService } from '../../../../../../shared/services/app.helpers.service';
import { QuestionsValidationService } from '../../services/questions.validation.service';
import { QuestionErrors } from '../../../../../../shared/classes/questions/question-errors';
import { QuestionValidations } from '../../../../../../shared/classes/questions/question-validations';
import { DialogQuestionsImageComponent } from './dialogs/questions-image/dialog.questions.image.component';
import { Image } from '../../../../../../shared/classes/images/image';
import { AppSelectOption } from '../../../../../../shared/components/app-select/classes/app.select.option';
import * as $ from 'jquery';
import { CdkDragDrop, CdkDragStart, DragRef } from '@angular/cdk/drag-drop';
import { Question } from '../../../../../../shared/classes/questions/question';
import { AppLoadingIndicatorService } from '../../../../../../shared/services/app.loading-indicator.service';

@Component({
    selector: 'app-checklist-questions',
    templateUrl: './app-questions.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: [
        './app-questions.component.less',
        './question.less',
        './choices.less',
    ],
})
export class AppQuestionsComponent implements OnInit, OnDestroy {
    @ViewChild('autoscrollQuestion') autoscrollQuestion: ElementRef;
    @ViewChild('autoscrollAnswer') autoscrollAnswer: ElementRef;
    @ContentChild(TemplateRef, { static: false }) templateRef;
    public dragging: DragRef = null;
    private cdRef: ChangeDetectorRef;
    questionTypes = [
        new AppSelectOption('True / False', 'true_false'),
        new AppSelectOption('Yes / No', 'yes_no'),
        new AppSelectOption('Likert Scale', 'likert'),
        new AppSelectOption('Multiple Choice', 'multiple_choice'),
        new AppSelectOption('Multiple Response', 'multiple_response'),
        new AppSelectOption('Short Answer', 'short_answer'),
    ];
    selectedQuestionType: AppSelectOption;
    questionText = {};
    selectedQuestionIndex = 0;
    questionsMaxLength = 50;
    choicesMinLength = 2;
    choicesMaxLength = 15;
    errorQuestions = {
        questions: '',
        answers: '',
    };
    validations: QuestionValidations;
    questionErrorText = '';

    private questionTextSelector = '.question-text textarea';

    @Input() questions: Array<Question>;
    @Input() questionType: string;
    @Input() isJustAdded: boolean;
    @Input() questionErrors: QuestionErrors;
    @Input() clearSelectedQuestion: Observable<any>;
    @Input() allValidations: any = {};
    @Input() selectedQuestion: Question;

    @Output() saveQuestionsChanges = new EventEmitter();
    @Output() blurQuestion = new EventEmitter();
    @Output() updateQuestionTitle = new EventEmitter();
    @Output() updateQuestion = new EventEmitter();

    constructor(
        public dialog: MatDialog,
        private appJobService: AppJobService,
        private appHelpersService: AppHelpersService,
        private questionsValidationService: QuestionsValidationService,
        private appLoadingIndicatorService: AppLoadingIndicatorService
    ) {}

    ngOnInit() {
        this.appLoadingIndicatorService.show('app', 'Loading...');
        // this.addQuestion();

        // Init validations.
        this.questionsValidationService.setValidations(
            this.allValidations.question.text.min,
            this.allValidations.question.text.max,
            this.allValidations.choice.text.min,
            this.allValidations.choice.text.max
        );
        this.validations = this.questionsValidationService.getValidations();
        this.validateQuestions();

        // Init question error text.
        this.initializeTexts();

        this.appLoadingIndicatorService.hide(600);
    }

    ngOnDestroy() {
        this.appJobService.kill('validations-job');
    }

    onBlurTextarea() {
        this.blurQuestion.emit();
    }

    displayConfirmDeleteChoiceDialog(index: number): void {
        // Display confirmation dialog.
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            data: {
                text: 'Are you sure you want to delete this answer choice?',
                callback: () => {
                    this.deleteChoice(index);
                },
            },
        });
    }

    displayQuestionImageDialog(): void {
        const question = this.getSelectedQuestion(),
            existingImage =
                'image' in question && question.image ? question.image : '',
            existingImageUrl = question.imageUrl;

        this.dialog.open(DialogQuestionsImageComponent, {
            width: '645px',
            height: '430px',
            disableClose: true,
            data: {
                mode: existingImage ? 'edit' : 'create',
                title: 'Question',
                existingImage: existingImage,
                existingImageUrl: existingImageUrl,
                callback: (dialog, vingImage: Image) => {
                    question.image = vingImage;
                    this.saveQuestionsChanges.emit(dialog);
                },
            },
        });
    }

    displayChoiceImageDialog(choiceIndex: number): void {
        const choice = this.getSelectedQuestion().choices[choiceIndex],
            existingImage =
                'image' in choice && choice.image ? choice.image : '',
            existingImageUrl = choice.imageUrl;

        this.dialog.open(DialogQuestionsImageComponent, {
            width: '645px',
            height: '430px',
            disableClose: true,
            data: {
                mode: existingImage ? 'edit' : 'create',
                title: 'Choice',
                existingImage: existingImage,
                existingImageUrl: existingImageUrl,
                callback: (dialog, vingImage: Image) => {
                    choice.image = vingImage;
                    this.saveQuestionsChanges.emit(dialog);
                },
            },
        });
    }

    getQuestionClass(index: number): string {
        let css = '';

        if (this.objectHasImage(this.selectedQuestion)) {
            css += ' has-image';
        }

        if (
            this.questionHasChoiceError(index) ||
            this.questionTextHasError(index) ||
            !this.isValidQuestionTextLength(index)
        ) {
            css += ' text-error';
        }

        return css;
    }
    getChoiceClass(choice): string {
        if (choice.isValid === false) {
            return 'invalid';
        }
    }

    getSelectedQuestion(): Question {
        return this.selectedQuestion;
    }

    isQuestionType(types: Array<string>): boolean {
        return types.indexOf(this.getSelectedQuestion().type) >= 0;
    }

    clickIsFlag(event, choice: Choice): void {
        event.stopPropagation();
        choice.flag = !choice.flag;
        this.selectedQuestion.flag = false;
        this.updateQuestion.emit();
    }

    addChoice(): void {
        this.getSelectedQuestion().choices.push(new Choice('', false));
        this.updateQuestion.emit();
    }

    deleteChoice(index: number): void {
        // Additional validation check.
        if (this.shouldAllowDeleteChoices()) {
            this.getSelectedQuestion().choices.splice(index, 1);
            this.updateQuestion.emit();
        }
    }

    shouldAllowAddQuestions(): boolean {
        return this.questions.length < this.questionsMaxLength;
    }

    shouldAllowAddChoices(): boolean {
        const question = this.getSelectedQuestion();
        let choicesLength;

        if (question.choices) {
            choicesLength = question.choices.length;
        }
        return choicesLength < this.choicesMaxLength;
    }

    shouldAllowDeleteChoices(): boolean {
        return this.getSelectedQuestion().choices.length > 2;
    }

    shouldDisplayQuestionTextError(): boolean {
        return (
            !this.isValidQuestionTextLength(this.selectedQuestionIndex)
        );
    }

    questionTextHasError(questionIndex: number) {
        return this.questionErrors.questionIndexes.indexOf(questionIndex) >= 0;
    }

    questionHasChoiceError(questionIndex: number) {
        return (
            Object.keys(this.questionErrors.answerIndexes).indexOf(
                questionIndex.toString()
            ) >= 0
        );
    }

    isValidQuestionTextLength(index: number): boolean {
        if (this.selectedQuestion.text) {
            const questionLength = this.selectedQuestion.text.length,
                min = this.validations.questionText.min,
                max = this.validations.questionText.max;

            return questionLength >= min && questionLength <= max;
        }
    }

    getQuestionContainerClass(): string {
        let css = 'question-text-and-image';

        if (this.isImageSet('question')) {
            css += ' has-image';
        }

        return css;
    }

    isImageSet(type: string, choiceIndex?: number): boolean {
        const question = this.getSelectedQuestion();
        let isSet = false;

        if (type === 'question') {
            isSet = this.objectHasImage(question);
        } else if (type === 'choice') {
            isSet = this.objectHasImage(question.choices[choiceIndex]);
        }

        return isSet;
    }

    private objectHasImage(obj: any): boolean {
        return Boolean(
            'imageUrl' in obj && obj.imageUrl && obj.imageUrl.length
        );
    }

    private validateQuestions(): void {
        this.questionErrors = this.questionsValidationService.validateQuestions(
            this.questions
        );
        this.checkAndHandleErrors();
    }

    private checkAndHandleErrors(): void {
        const errorsPresent = () => {
            return this.questionErrors.hasErrors();
        };

        if (errorsPresent() === true) {
            this.clearQuestionErrors();

            // Build question text errors question # list.
            if (this.questionErrors.questionIndexes.length > 0) {
                this.errorQuestions.questions =
                    this.buildQuestionNumbersFromIndexArray(
                        this.questionErrors.questionIndexes
                    );
            }

            // Build answer choice text errors question # list.
            if (Object.keys(this.questionErrors.answerIndexes).length > 0) {
                this.errorQuestions.answers =
                    this.buildQuestionNumbersFromIndexArray(
                        Object.keys(this.questionErrors.answerIndexes)
                    );
            }
        } else if (errorsPresent() === false) {
            this.clearQuestionErrors();
        }
    }

    private clearQuestionErrors(): void {
        this.errorQuestions.questions = '';
        this.errorQuestions.answers = '';
    }

    private buildQuestionNumbersFromIndexArray(indexArray) {
        return indexArray
            .map((index) => '#' + (parseInt(index.toString(), 10) + 1))
            .join(', ');
    }

    private highlightQuestionText(): void {
        this.appHelpersService.whenElementPresentDo(
            this.questionTextSelector,
            () => {
                $(this.questionTextSelector).select();
            }
        );
    }

    private initializeTexts(): void {
        // Initialize question texts.
        this.questionText[QuestionType.trueFalse] = 'True / False';
        this.questionText[QuestionType.yesNo] = 'Yes / No';
        this.questionText[QuestionType.likert] = 'Likert Scale';
        this.questionText[QuestionType.multipleChoice] = 'Multiple Choice';
        this.questionText[QuestionType.multipleResponse] = 'Multiple Response';
        this.questionText[QuestionType.shortAnswer] = 'Short Answer';

        // Initialize question error text.
        this.questionErrorText =
            'Question text must be between ' +
            this.validations.questionText.min +
            ' and ' +
            this.validations.questionText.max +
            ' characters';
    }

    dropped(ev: CdkDragDrop<any>) {
        this.questions.splice(
            ev.currentIndex,
            0,
            this.questions.splice(ev.previousIndex, 1)[0]
        );
        this.updateQuestionEmitter();
    }
    droppedChoice(ev: CdkDragDrop<any>) {
        this.selectedQuestion.choices.splice(
            ev.currentIndex,
            0,
            this.selectedQuestion.choices.splice(ev.previousIndex, 1)[0]
        );
        this.updateQuestionEmitter();
    }

    updateTitle(event: any) {
        this.selectedQuestion.text = event;
        this.updateQuestionEmitter();
    }

    clickQuestionIsFlag($event: MouseEvent, flag: boolean) {
        this.selectedQuestion.flag = !flag;
        this.selectedQuestion.choices.forEach((choice) => {
            choice.flag = false;
        });
        this.updateQuestionEmitter();
    }

    clickQuestionIsRequired($event: MouseEvent, required: boolean) {
        this.selectedQuestion.required = !required;
        this.updateQuestionEmitter();
    }

    updateQuestionEmitter() {
        this.updateQuestion.emit();
    }
}
