import { AppSelectOption } from '../../app-select/classes/app.select.option';

export class XtFilter {
    type: string; // 'single', 'multi'
    label: string;
    name: string;
    width: number;
    scrollHeight: number;
    options: Array<AppSelectOption>;
    selected: Array<AppSelectOption>;
    shouldIncludeDefaultValue: boolean;

    constructor(
        type: string,
        label: string,
        name: string,
        width: number,
        scrollHeight: number,
        options: Array<AppSelectOption>,
        selected: Array<AppSelectOption>,
        shouldIncludeDefaultValue?: boolean
    ) {
        this.type = type;
        this.label = label;
        this.name = name;
        this.width = width;
        this.scrollHeight = scrollHeight;
        this.options = options;
        this.selected = selected;
        this.shouldIncludeDefaultValue = this.isShouldIncludeSet(
            shouldIncludeDefaultValue
        );
    }

    private isShouldIncludeSet(shouldIncludeDefaultValue: any): boolean {
        let shouldInclude = false;

        if (
            shouldIncludeDefaultValue !== null &&
            shouldIncludeDefaultValue !== undefined &&
            (shouldIncludeDefaultValue === true ||
                shouldIncludeDefaultValue === false)
        ) {
            shouldInclude = shouldIncludeDefaultValue;
        }

        return shouldInclude;
    }
}
