<div [class]="getViewClass()">
    <!--Header-->
    <div class="view-header" *ngIf="viewIsInitialized">
        <span class="title">
            {{ view.packet.title }}
        </span>
        <div class="header-message-area">
            <i
                *ngIf="isPreview"
                class="header-message-icon material-icons icon yellow"
                [title]="
                    'Preview mode: activity and scores will not be tracked'
                "
                >preview</i
            >
            <p *ngIf="isPreview" class="yellow preview-message header-message">
                Preview mode: activity and scores will not be tracked
            </p>
            <i
                *ngIf="view.consumeMinutes && view.consumeInterval"
                class="header-message-icon material-icons icon"
                [title]="'Microburst training in effect'"
                >timer</i
            >
            <p
                class="microburst-message header-message"
                *ngIf="
                    view.enforceOrder &&
                    view.consumeMinutes == 1 &&
                    view.consumeInterval > 1
                "
            >
                Viewing {{ view.consumeMinutes }} minute every
                {{ view.consumeInterval }} days
            </p>
            <p
                class="microburst-message header-message"
                *ngIf="
                    view.enforceOrder &&
                    view.consumeMinutes > 1 &&
                    view.consumeInterval > 1
                "
            >
                Viewing {{ view.consumeMinutes }} minutes every
                {{ view.consumeInterval }} days
            </p>
            <p
                class="microburst-message header-message"
                *ngIf="
                    view.enforceOrder &&
                    view.consumeMinutes == 1 &&
                    view.consumeInterval == 1
                "
            >
                Viewing {{ view.consumeMinutes }} minute every day
            </p>
            <p
                class="microburst-message header-message"
                *ngIf="
                    view.enforceOrder &&
                    view.consumeMinutes > 1 &&
                    view.consumeInterval == 1
                "
            >
                Viewing {{ view.consumeMinutes }} minutes every day
            </p>
        </div>
    </div>

    <!--Body-->
    <div
        [class]="showList ? 'view-body active' : 'view-body'"
        *ngIf="viewIsInitialized"
    >
        <!--Index-->
        <div class="view-navigation">
            <app-component-index
                [isPreview]="isPreview"
                [selectedComponentId]="selectedComponentId"
                [components]="view.packet.components"
                (selectComponent)="selectComponent($event)"
            >
            </app-component-index>
        </div>

        <!--Selected Component-->
        <div
            class="selected-component {{ selectedComponent.type }} {{
                view.packet.logoUrl ? 'has-footer' : ''
            }}"
            *ngIf="isComponentSelected()"
        >
            <!--Title-->
            <div class="titlebar">
                <div class="control left">
                    <button
                        class="
                            app-button
                            block
                            blue
                            large
                            no-text
                            component-change-button
                        "
                        title="View Components"
                        (click)="displayComponentList()"
                    >
                        <i class="material-icons icon">view_comfy</i>
                    </button>
                </div>

                <div class="title">
                    <p>
                        {{ selectedComponent.title }}
                        <!--Score-->
                        <span
                            class="score"
                            *ngIf="
                                displayFeedback &&
                                isPreview &&
                                selectedComponent.type === 'questions' &&
                                selectedComponent.completed &&
                                selectedComponent.scored &&
                                selectedComponent.hasOwnProperty('score')
                            "
                            [style.background]="
                                getScoreColor(selectedComponent.score)
                            "
                        >
                            {{ getScorePercentage(selectedComponent.score) }}
                        </span>
                    </p>
                </div>

                <div class="control right">
                    <div
                        class="status progress"
                        *ngIf="!selectedComponent.completed && !ongoingCall"
                    >
                        <circle-progress
                            [percent]="getProgress()"
                            [radius]="22"
                            [backgroundColor]="'#F3F3F3'"
                            [backgroundPadding]="0"
                            [titleFontSize]="14"
                            [titleColor]="'#5CB85C'"
                            [unitsFontSize]="12"
                            [unitsColor]="'#5CB85C'"
                            [outerStrokeWidth]="3"
                            [outerStrokeColor]="'#5CB85C'"
                            [innerStrokeWidth]="3"
                            [innerStrokeColor]="'#FFFFFF'"
                            [space]="-3"
                            [responsive]="true"
                            [showSubtitle]="false"
                            [animation]="false"
                        ></circle-progress>
                    </div>

                    <button
                        class="app-button block green large no-text"
                        (click)="displayComponent('next')"
                        [disabled]="!shouldDisplayButton('next') || ongoingCall"
                    >
                        <div *ngIf="ongoingCall" class="m-auto loader"></div>
                        <i *ngIf="!ongoingCall" class="material-icons icon">{{
                            getNextButtonIcon()
                        }}</i>
                    </button>
                </div>
            </div>

            <div
                class="view {{ selectedComponent.type }} {{
                    selectedComponent.type !== 'text' &&
                    selectedComponent.text !== ''
                        ? 'has-summary'
                        : ''
                }}"
            >
                <!--Component Display-->
                <!--Summary-->
                <div
                    class="display summary"
                    *ngIf="
                        selectedComponent.type !== 'text' &&
                        selectedComponent.text !== ''
                    "
                >
                    <div
                        class="text"
                        [innerHtml]="selectedComponent.text"
                    ></div>
                </div>

                <!--Image-->
                <div
                    class="display {{ selectedComponent.type }}"
                    *ngIf="selectedComponent.type === 'image'"
                >
                    <img
                        (click)="openLightBox(selectedComponent.imageUrl)"
                        [src]="selectedComponent.imageUrl"
                        [alt]="selectedComponent.title"
                    />
                    <p *ngIf="isMobile" class="display-image-text">
                        Tap to enlarge
                    </p>
                </div>

                <!--Video-->
                <div
                    class="display {{ selectedComponent.type }}"
                    *ngIf="selectedComponent.type === 'video'"
                >
                    <div class="video-container">
                        <video
                            id="consume-{{ selectedComponent.type }}"
                            controls
                            autoplay
                            #video
                            [src]="selectedComponent.videoUrl"
                        >
                            <!-- [poster]="selectedComponent.imageUrl" -->
                            This web browser does not support HTML5 video.
                        </video>
                    </div>
                </div>

                <!--Audio-->
                <div
                    class="display {{ selectedComponent.type }}"
                    *ngIf="selectedComponent.type === 'audio'"
                >
                    <div class="type-box" *ngIf="selectedComponent.text === ''">
                        <i class="material-icons icon">audiotrack</i>
                    </div>

                    <audio
                        id="consume-{{ selectedComponent.type }}"
                        controls
                        autoplay
                        [src]="selectedComponent.audioUrl"
                    >
                        This web browser does not support HTML5 audio.
                    </audio>
                </div>

                <!--Other-->
                <div
                    class="display {{ selectedComponent.type }}"
                    *ngIf="selectedComponent.type === 'other'"
                >
                    <div class="type-box" *ngIf="selectedComponent.text === ''">
                        <i class="material-icons icon">attachment</i>
                    </div>

                    <a
                        [href]="selectedComponent.downloadUrl"
                        target="_blank"
                        (click)="onClickVisitButton()"
                    >
                        <button type="button" class="app-button green large">
                            Open File
                        </button>
                    </a>
                </div>

                <!--Link-->
                <div
                    class="display {{ selectedComponent.type }}"
                    *ngIf="selectedComponent.type === 'link'"
                >
                    <div class="type-box" *ngIf="selectedComponent.text === ''">
                        <i class="material-icons icon">link</i>
                    </div>

                    <div class="title link">
                        {{ selectedComponent.linkUrl }}
                    </div>

                    <a
                        [href]="selectedComponent.linkUrl"
                        target="_blank"
                        (click)="onClickVisitButton()"
                    >
                        <button type="button" class="app-button green large">
                            Open
                        </button>
                    </a>
                </div>

                <!--Text-->
                <div
                    class="display {{ selectedComponent.type }}"
                    *ngIf="selectedComponent.type === 'text'"
                >
                    <div
                        class="text"
                        [innerHtml]="selectedComponent.text"
                    ></div>
                </div>

                <!--Document-->
                <div
                    class="display {{ selectedComponent.type }}"
                    *ngIf="selectedComponent.type === 'document'"
                >
                    <div class="document-container">
                        <app-document
                            mode="consume"
                            (updateDocumentPageView)="
                                onUpdateDocumentPageView($event)
                            "
                            [document]="selectedComponent.document"
                            [clearSelectedDocument]="
                                clearSelectedDocument.asObservable()
                            "
                        >
                        </app-document>
                    </div>
                </div>

                <!--Questions-->
                <div
                    class="display {{ selectedComponent.type }} {{
                        displayFeedback ? 'feedback' : ''
                    }}"
                    *ngIf="selectedComponent.type === 'questions'"
                >
                    <div
                        class="tac mb-2 green-alert"
                        *ngIf="
                            displayFeedback &&
                            selectedComponent.completed &&
                            selectedComponent.scored &&
                            selectedComponent.passed &&
                            selectedComponent.score == 1 &&
                            !isPreview
                        "
                    >
                        Congratulations, keep up the great work!
                    </div>
                    <div
                        class="tac mb-2 yellow-alert"
                        *ngIf="
                            displayFeedback &&
                            selectedComponent.completed &&
                            selectedComponent.scored &&
                            selectedComponent.passed &&
                            selectedComponent.score < 1 &&
                            !isPreview
                        "
                    >
                        You passed, make sure you review what you missed before
                        you move on.
                    </div>
                    <div
                        class="tac red-alert mb-2"
                        *ngIf="
                            displayFeedback &&
                            selectedComponent.completed &&
                            selectedComponent.scored &&
                            view.enforceOrder &&
                            !selectedComponent.passed &&
                            !isPreview
                        "
                    >
                        Oh no, it looks like you need to review and try again.
                    </div>
                    <div
                        class="tac red-alert mb-2"
                        *ngIf="
                            displayFeedback &&
                            selectedComponent.completed &&
                            selectedComponent.scored &&
                            !selectedComponent.passed &&
                            !view.enforceOrder &&
                            !isPreview
                        "
                    >
                        Oh no, it looks like you need to review.
                    </div>
                    <div class="questions">
                        <div
                            *ngFor="
                                let question of selectedComponent.questions;
                                let i = index
                            "
                            class="question {{
                                isShortAnswer(question) ? 'short-answer' : ''
                            }}"
                        >
                            <!--Text & Image-->
                            <div class="question-text-and-image">
                                <!--Text-->
                                <div class="question-text">
                                    <span class="number">#{{ i + 1 }}</span>
                                    {{ question.text }}

                                    <!--Score Icon-->
                                    <span
                                        class="score"
                                        *ngIf="
                                            displayFeedback &&
                                            selectedComponent.completed &&
                                            selectedComponent.scored &&
                                            question.answerIsCorrect === false
                                        "
                                    >
                                        <i class="material-icons icon red"
                                            >error</i
                                        >
                                    </span>
                                </div>

                                <!--Text-->
                                <div class="question-help-text">
                                    {{ question.helpText }}
                                </div>

                                <!--Image-->
                                <div
                                    class="question-image"
                                    *ngIf="objHasImage(question)"
                                >
                                    <img
                                        [src]="question.imageUrl"
                                        [alt]="question.text"
                                    />
                                </div>
                            </div>

                            <!--Choices-->
                            <div class="choices">
                                <div
                                    *ngFor="let choice of question.choices"
                                    class="choice {{
                                        objHasImage(choice) ? 'has-image' : ''
                                    }}"
                                >
                                    <!--Control-->
                                    <div class="choice-input">
                                        <!--Radio-->
                                        <app-radio
                                            *ngIf="shouldDisplayRadio(question)"
                                            [choiceId]="choice.id"
                                            [answer]="question.answer"
                                            (updateAnswer)="
                                                updateAnswer($event, question)
                                            "
                                        >
                                        </app-radio>

                                        <!--Checkbox-->
                                        <app-checkbox
                                            *ngIf="
                                                shouldDisplayCheckbox(question)
                                            "
                                            [choiceId]="choice.id"
                                            [answer]="question.answer"
                                            (updateAnswer)="
                                                updateAnswer($event, question)
                                            "
                                        >
                                        </app-checkbox>
                                    </div>

                                    <!--Image-->
                                    <div
                                        class="choice-image"
                                        *ngIf="choiceHasImage(choice)"
                                        (click)="
                                            updateAnswerByType(
                                                choice.id,
                                                question
                                            )
                                        "
                                    >
                                        <img
                                            [src]="choice.imageUrl"
                                            [alt]="choice.answer"
                                        />
                                    </div>

                                    <!--Text-->
                                    <div
                                        class="choice-text"
                                        (click)="
                                            updateAnswerByType(
                                                choice.id,
                                                question
                                            )
                                        "
                                    >
                                        <span>{{ choice.text }}</span>

                                        <!--Score Icon-->
                                        <span
                                            class="choice-score"
                                            *ngIf="
                                                displayFeedback &&
                                                selectedComponent.completed &&
                                                selectedComponent.scored &&
                                                selectedComponent.passed &&
                                                question.answerIsCorrect ===
                                                    false
                                            "
                                        >
                                            <i
                                                class="
                                                    material-icons
                                                    icon
                                                    green
                                                "
                                                *ngIf="choice.correct"
                                                >new_releases</i
                                            >
                                        </span>
                                    </div>
                                </div>

                                <!--Short Answer-->
                                <app-textarea
                                    *ngIf="isShortAnswer(question)"
                                    [maxLength]="300"
                                    [answer]="question.answer"
                                    [placeholder]="'Answer question here...'"
                                    (updateAnswer)="
                                        updateAnswer($event, question)
                                    "
                                >
                                </app-textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--Footer-->
            <div
                class="footer"
                *ngIf="
                    view.packet.logoUrl && selectedComponent.type !== 'document'
                "
            >
                <img src="{{ view.packet.logoUrl }}" alt="Logo" />
            </div>
        </div>
    </div>
</div>
