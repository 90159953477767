<div class="app-dialog app-dialog-merge-users">
    <div class="header">
        {{ title }}
    </div>
    <div class="select-body" *ngIf="!this.verificationSent">
        <div>
            <p>{{ this.verifyMessage }}</p>
            <ng-container *ngFor="let method of this.contactMethods">
                <button class="app-button contact-method" (click)="sendVerification(method)">
                    <div class="contact-method-icon">
                        <i class="material-icons icon">{{ method[0] }}</i>
                    </div>
                    <div class="contact-method-detail">
                        {{ method[2] }}
                    </div>
                </button>
            </ng-container>
        </div>
    </div>
    <div class="select-footer" *ngIf="!this.verificationSent">
        <div>
            <button class="app-button light mr-1" (click)="ignore()">
                Not Mine
            </button>
        </div>
    </div>
    <div *ngIf="this.verificationSent">
        <form
            (ngSubmit)="executeMerge()"
            #verificationForm="ngForm"
            novalidate
        >
            <div class="verify-body">
                <p>A verification code was sent via {{ this.verifyType }}, please enter it below:</p>
                <br>
                <app-form-field
                    type="text"
                    name="code"
                    placeholder="Enter Verification Code"
                    [required]="true"
                    [(ngModel)]="this.verificationCode"
                >
                </app-form-field>
            </div>

            <div class="verify-footer">
                <button mat-dialog-close class="app-button light small mr-1">
                    Cancel
                </button>
                <button
                    type="submit"
                    class="app-button green small"
                    [disabled]="!verificationForm.form.valid"
                >
                    Verify
                </button>
            </div>
        </form>
    </div>
</div>
