import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ClipboardModule } from 'ngx-clipboard';

import { AppControlsModule } from '../../shared/modules/app.controls.module';
import { ProfileComponent } from './profile.component';
import { ProfileRoutingModule } from './profile.routing.module';

export function getWindow() {
    return window;
}

export function getLocalStorage() {
    return localStorage;
}

export function getLocation() {
    return location;
}

@NgModule({
    imports: [ClipboardModule, AppControlsModule, ProfileRoutingModule],
    declarations: [ProfileComponent],
    providers: [
        { provide: 'window', useFactory: getWindow },
        { provide: 'storage', useFactory: getLocalStorage },
        { provide: 'location', useFactory: getLocation },
    ],
})
export class ProfileModule {}
