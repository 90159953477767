import { ConsumePacket } from './consume.packet';
import {ConsumeChecklist} from "./consume.checklist";

export class View {
    type: string;
    stub: string;
    nonUserDoneRedirectUrl: string;
    packet?: ConsumePacket;
    checklist?: ConsumeChecklist;
    enforceOrder?: boolean;
    consumeMinutes?: number;
    consumeInterval?: number;
    submittedDate?: any;
    isComplete?: boolean;
    durationComplete?: number;

    constructor(
        type: string,
        stub: string,
        packetOrChecklist: any,
        nonUserDoneRedirectUrl: string,
        enforceOrder?: boolean,
        consumeMinutes?: number,
        consumeInterval?: number,
    ) {
        if (type == 'packet') {
            this.type = type
            this.packet = packetOrChecklist;
            this.enforceOrder = enforceOrder;
            this.consumeMinutes = consumeMinutes;
            this.consumeInterval = consumeInterval;
        } else if (type == 'checklist') {
            this.type = type
            this.checklist = packetOrChecklist;
        }

        this.stub = stub;
        this.nonUserDoneRedirectUrl = nonUserDoneRedirectUrl;
    }
}
