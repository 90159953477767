<div class="sign-in">
    <!--Logo-->
    <div class="logo">
        <img src="/assets/images/app-ving-logo.png" width="150" />
    </div>

    <!--Content-->
    <div class="content">
        <div class="section-header">
            <div class="title">Sign In</div>

            <ng-container *ngIf="step === 1">
                <div class="alt-button-theme">
                    <button
                        class="social-button"
                        (click)="navigateToGoogleSso()"
                    >
                        <img
                            class="icon"
                            src="/assets/images/clients/google.png"
                            alt="Sign In With Google"
                        />
                    </button>
                </div>
                <div class="alt-button-theme">
                    <button class="social-button" (click)="navigateToAdpSso()">
                        <img
                            class="icon"
                            src="/assets/images/clients/adp.png"
                            alt="Sign In With ADP"
                        />
                    </button>
                </div>
            </ng-container>
        </div>

        <div class="divider-bar"></div>

        <ng-container *ngIf="step === 1">
            <p class="text-center">
                Need a Ving account?&nbsp;&nbsp;
                <a
                    class="signup"
                    routerLink="/login/signUp"
                    [queryParams]="{ auth_as: auth_as }"
                    >Sign Up</a
                >
            </p>
        </ng-container>

        <!--Alert-->
        <app-alert></app-alert>

        <!--Sign-In Form-->
        <ng-container *ngIf="step === 1">
            <form
                (ngSubmit)="typePassword()"
                #signInEmailForm="ngForm"
                class="sign-in-form alt-input-theme"
            >
                <!--Email-->
                <div class="form-group">
                    <label>Email or Phone</label>
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="signInUser.username"
                        #email
                        name="username"
                        autocomplete="off"
                        required
                    />
                </div>
                <!--Buttons-->
                <div class="form-buttons">
                    <button
                        class="app-button large green"
                        [disabled]="!signInEmailForm.form.valid"
                    >
                        Use Password
                    </button>
                    <button
                        type="button"
                        class="app-button large green-outline"
                        [disabled]="!signInEmailForm.form.valid"
                        (click)="magicLink()"
                    >
                        Magic Link
                    </button>
                </div>
            </form>
        </ng-container>

        <ng-container *ngIf="step === 2">
            <a href="javascript:" class="back-btn" (click)="back()">
                <i class="material-icons icon">keyboard_arrow_left</i>
            </a>
            <form
                (ngSubmit)="processSignIn()"
                #signInPwdForm="ngForm"
                class="sign-in-form alt-input-theme"
            >
                <!--Password-->
                <div class="form-group">
                    <label>Password</label>
                    <input
                        type="password"
                        class="form-control"
                        [(ngModel)]="signInUser.password"
                        #password
                        name="password"
                        required
                    />
                </div>
                <!--Buttons-->
                <div class="form-buttons">
                    <button
                        type="submit"
                        class="app-button large green full-width"
                        [disabled]="!signInPwdForm.form.valid"
                    >
                        Sign In
                    </button>
                </div>
            </form>
        </ng-container>

        <!--Forgot Password Link-->
        <p class="text-center forgot-password-link">
            <a class="blue" (click)="forgotPassword()">Forgot Password?</a>
        </p>
    </div>
</div>
