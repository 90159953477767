import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChecklistsComponent } from './checklists.component';
import { AuthGuard } from '../../shared/guards/auth.guard';
import {EditComponent} from "./components/edit/edit.component";

const checklistsRoutes: Routes = [
    {
        path: 'checklists',
        redirectTo: 'checklists/',
        canActivate: [AuthGuard],
        pathMatch: 'full',
    },
    {
        path: 'checklists/:id',
        component: ChecklistsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'checklists/:id/edit',
        component: EditComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(checklistsRoutes)],
    exports: [RouterModule],
})
export class ChecklistsRoutingModule {}
