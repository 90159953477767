import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { AppDataTransform } from '../../../../shared/app.data-transform';
import { ContactService } from '../../services/contact.service';
import { AppMessage } from '../../../../shared/app.messages';
import { NewContact } from '../../classes/new-contact';
import { CreateContact } from '../../classes/create-contact';

@Component({
    templateUrl: './dialog-create-contact.component.html',
    styleUrls: ['./dialog-create-contact.component.less'],
})
export class DialogCreateContactComponent implements AfterViewInit {
    messages = {
        error: 'Please ensure that the fields are valid.',
    };
    createContact = new CreateContact('', '', '', '', '', '', '');
    tabs = [
        { name: 'profile', label: 'Profile' },
        { name: 'details', label: 'Details' },
        { name: 'avatar', label: 'Avatar' },
    ];
    selectedTab = this.tabs[0]['name']; // Set default tab.
    isValid = {
        method: 'create',
        emails: true,
        phones: true,
        oneOrMoreValidEmails: true,
        oneOrMoreValidPhones: true,
        internalId: true,
    };
    // Avatar
    contactAvatar = {
        key: '',
        crop: {
            startX: undefined,
            startY: undefined,
            width: undefined,
            height: undefined,
        },
    };
    selectedFile = undefined;

    constructor(
        private router: Router,
        private appHttpService: AppHttpService,
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private contactService: ContactService,
        public dialogRef: MatDialogRef<DialogCreateContactComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            callback: any;
        }
    ) {}

    ngAfterViewInit() {
        const originalData = JSON.stringify({ emails: [], phones: [] });
        this.contactService.initEmailsAndPhonesValidationJob(
            originalData,
            this.createContact,
            this.isValid
        );
    }

    selectTab(tabName) {
        this.selectedTab = tabName;
    }

    getTabClass(tabName) {
        let tabClass = 'tab ';

        // Check to see if the contact is selected.
        if (this.selectedTab === tabName) {
            tabClass += 'selected ';
        }

        return tabClass.trim();
    }

    getTabContainerClass(tabName) {
        let tabContainerClass = 'tab-container ';

        // Check to see if the contact is selected.
        if (this.selectedTab === tabName) {
            tabContainerClass += 'selected ';
        }

        return tabContainerClass.trim();
    }

    allFieldsValid() {
        return this.contactService.checkAllFieldsValid(this.isValid);
    }

    close() {
        this.contactService.killValidationJobs();
    }

    save() {
        // Valid the form fields
        this.isValid['internalId'] = true;

        if (this.isAvatarSet()) {
            this.appLoadingIndicatorService.show('modal');
            this.appHttpService.apiAwsUploadFile(
                this.selectedFile,
                (key) => {
                    this.contactAvatar.key = key;
                    this.apiCreateContact(this.contactAvatar);
                },
                () => {
                    this.appLoadingIndicatorService.hide(350);
                    this.appMessageService.show(
                        'modal',
                        'error',
                        AppMessage.get('awsUploadError'),
                        5
                    );
                }
            );
        } else {
            this.apiCreateContact();
        }
    }

    private apiCreateContact(avatar?: Object) {
        const newContact: NewContact = this.buildNewContact(this.createContact);

        // Initial validation.
        if (this.contactService.isContactValid(newContact, this.isValid)) {
            this.appLoadingIndicatorService.show('modal');

            if (avatar) {
                newContact['avatar'] = avatar;
            }

            this.contactService.apiCreateContact(
                newContact,
                (contact) => {
                    this.data.callback(this.dialogRef, contact.id);
                },
                (error) => {
                    const msg =
                        error.status === 400 && error.data
                            ? error.data[Object.keys(error.data)[0]][0]
                            : this.messages.error;
                    this.appLoadingIndicatorService.hide(350);
                    this.showFormFieldErrors(error);
                    this.appMessageService.show('modal', 'error', msg, 5.0);
                }
            );
        } else {
            this.appLoadingIndicatorService.hide(350);
            this.appMessageService.show(
                'modal',
                'error',
                this.messages.error,
                5.0
            );
        }
    }

    private buildNewContact(createContact: CreateContact) {
        const newContact: NewContact = new NewContact(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null
        );
        this.contactService.setContactData(createContact, newContact);
        return newContact;
    }

    // Avatar
    updateImage(data) {
        // Set image data.
        ['startX', 'startY', 'width', 'height'].forEach((key) => {
            this.contactAvatar.crop[key] = data.image.crop[key];
        });

        // Set file data.
        this.selectedFile = data.selectedFile;
    }

    private isAvatarSet() {
        const crop = this.contactAvatar.crop,
            hasSelectedFile = Boolean(this.selectedFile),
            areCropCoordsValid = this.areCropCoordinatesValid(crop);

        return hasSelectedFile && areCropCoordsValid;
    }

    private areCropCoordinatesValid(crop): boolean {
        return (
            Boolean(crop.startX >= 0) &&
            Boolean(crop.startY >= 0) &&
            Boolean(crop.width >= 0) &&
            Boolean(crop.height >= 0)
        );
    }

    private showFormFieldErrors(error): void {
        if (error.status === 400 && error.data) {
            Object.keys(error.data).map((key) => {
                const updatedKey = AppDataTransform.convertToCamel(key);
                if (updatedKey in this.isValid) {
                    this.isValid[updatedKey] = false;
                }
            });
        }
    }
}
