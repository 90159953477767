import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ClipboardModule } from 'ngx-clipboard';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChecklistsRoutingModule } from './checklists.routing.module';
import { AppControlsModule } from '../../shared/modules/app.controls.module';
import { AppPipesModule } from '../../shared/modules/app.pipes.module';
import { ChecklistsComponent } from './checklists.component';
import { ListComponent } from './components/list/list.component';
import { HeaderComponent } from './components/header/header.component';
import { DetailComponent } from './components/detail/detail.component';
import { DialogGetLinkComponent } from './dialogs/dialog-get-link/dialog.get.link.component';
import { DialogQrCodeComponent } from './dialogs/dialog-qr-code/dialog.qr.code.component';
import { MatChipsModule } from '@angular/material/chips';
import { AngularSplitModule } from 'angular-split';
import { FilterNavigationModule } from '../../shared/components/app-filter-navigation/app.filter.navigation.module';
import { ChecklistsService } from './services/checklists.service';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScheduleComponent } from './components/schedule/schedule.component';
import {DialogUpdateTextComponent} from "./components/edit/dialogs/update-text/dialog.update-text.component";
import {DialogEditDetailsComponent} from "./components/edit/dialogs/edit-details/dialog.edit-details.component";
import {
    DialogQuestionsImageComponent
} from "./components/edit/components/app-questions/dialogs/questions-image/dialog.questions.image.component";
import {AppQuestionsComponent} from "./components/edit/components/app-questions/app-questions.component";
import {EditComponent} from "./components/edit/edit.component";
import {ContentPendingComponent} from "./components/edit/components/content-pending/content.pending.component";
import {QuestionsValidationService} from "./components/edit/services/questions.validation.service";
import {VingsModule} from "../vings/vings.module";

export function getWindow() {
    return window;
}

export function getLocalStorage() {
    return localStorage;
}

export function getLocation() {
    return location;
}

@NgModule({
    imports: [
        ClipboardModule,
        NgSelectModule,
        FormsModule,
        AppControlsModule,
        AppPipesModule,
        ChecklistsRoutingModule,
        MatChipsModule,
        AngularSplitModule,
        FilterNavigationModule,
        NgxMatDatetimePickerModule,
        ReactiveFormsModule,
        DragDropModule,
        VingsModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        ChecklistsComponent,
        ListComponent,
        ScheduleComponent,
        HeaderComponent,
        DetailComponent,
        EditComponent,
        ContentPendingComponent,
        /*Dialogs*/
        DialogGetLinkComponent,
        DialogQrCodeComponent,
        DialogUpdateTextComponent,
        DialogEditDetailsComponent,
        DialogQuestionsImageComponent,
        AppQuestionsComponent,
        ListComponent,
    ],
    providers: [
        ChecklistsService,
        { provide: 'window', useFactory: getWindow },
        { provide: 'storage', useFactory: getLocalStorage },
        { provide: 'location', useFactory: getLocation },
        QuestionsValidationService,
    ],
    entryComponents: [
        /*Dialogs*/
        DialogGetLinkComponent,
        DialogQrCodeComponent,
        DialogUpdateTextComponent,
        DialogEditDetailsComponent,
        DialogQuestionsImageComponent,
    ],
})
export class ChecklistsModule {}
