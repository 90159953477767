import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './app.dialog-browser-compatibility.component.html',
    styleUrls: ['./app.dialog-browser-compatibility.component.less'],
})
export class AppDialogBrowserCompatibilityComponent {
    title: string;

    constructor(
        public dialogRef: MatDialogRef<AppDialogBrowserCompatibilityComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {}
    ) {}

    onConfirm() {
        this.dialogRef.close();
    }
}
