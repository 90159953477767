import { Component, Inject, OnInit } from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialog,
} from '@angular/material/dialog';
import { AppAlertService } from '../../../../shared/services/app.alert.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';

@Component({
    templateUrl: './app.dialog-unassign.component.html',
    styleUrls: ['./app.dialog-unassign.component.less'],
})
export class AppDialogUnassignComponent implements OnInit {
    field: string;
    apis = {
        post: 'POST:api/unassign/packet/',
    };
    messages = {
        success: 'Unassigning was successful.',
        error: 'There was an error while unassigning.',
    };
    error = false;
    selectedItems = [];
    selectedNames = [];
    selectedNamesSet = new Set();
    confirmationText = '';
    reminderText = ' and its analytics.';
    othersText = '';
    results: any;
    constructor(
        private appHttpService: AppHttpService,
        private appMessageService: AppMessageService,
        private appHelpersService: AppHelpersService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        public dialogRef: MatDialogRef<AppDialogUnassignComponent>,
        private dialog: MatDialog,
        private appAlertService: AppAlertService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            analyticsFilterUrl: any;
            analyticsFilterCount: number;
            contactName: string;
            vingTitle: string;
            vingMicroburst: boolean;
            page: string;
            id: number;
            selected: any[];
            allSelected: boolean;
            allSelectedIndexes: any[];
            excluded: any[];
            results: any;
            rows: any;
        }
    ) {
        this.setResults();
    }

    private setResults() {
        if (this.data.results.data) {
            this.results = this.data.results.data.results;
        } else {
            this.results = this.data.results.results;
        }
    }

    private findResultById(id) {
        if (this.data.page === 'PACKET-ASSIGNMENTS') {
            return this.results.find(
                (item) => item.contact.contactId === id
            );
        } else if (this.data.page === 'CONTACT-ASSIGNMENTS') {
            return this.results.find(
                (item) => item.packet.packetId === id
            );
        }
    }

    ngOnInit() {
        let indexes,
            itemCount;

        if (this.data.allSelected) {
            indexes = this.data.allSelectedIndexes;
            itemCount = (this.data.analyticsFilterCount - this.data.excluded.length)
            console.log("All selected: " + itemCount);
        } else {
            indexes = this.data.selected;
            itemCount = this.data.selected.length;
            console.log("Selected: " + itemCount);
        }

        indexes.forEach((id) => {
            const result = this.findResultById(id);
            if (result !== undefined) {
                this.selectedItems.push(result);
                this.selectedNamesSet.add(result.contact.name);
            }
        });

        this.selectedNames = Array.from(this.selectedNamesSet);

        if (this.data.page === 'PACKET-ASSIGNMENTS') {
            if (this.data.vingMicroburst) {
                this.reminderText = ', its analytics and reminders.';
            }
            if (this.selectedItems.length - 2 > 1) {
                this.othersText = ' others from ';
            } else {
                this.othersText = ' other from ';
            }

            this.confirmationText = 'This will remove ';
            if (itemCount > 2) {
                this.confirmationText +=
                    this.selectedNames[0] +
                    ', ' +
                    this.selectedNames[1] +
                    ', and ' +
                    (itemCount - 2) +
                    this.othersText
            } else if (itemCount == 2) {
                this.confirmationText +=
                    this.selectedNames[0] +
                    ' and ' +
                    this.selectedNames[1] +
                    ' from '
            } else {
                this.confirmationText +=
                    this.selectedNames[0] +
                    ' from '
            }
            this.confirmationText +=
                this.data.vingTitle +
                this.reminderText;
        } else if (this.data.page === 'CONTACT-ASSIGNMENTS') {
            if (this.data.vingMicroburst) {
                if (itemCount > 1) {
                    this.reminderText = ', their analytics and reminders.';
                } else {
                    this.reminderText = ', its analytics and reminders.';
                }
            } else {
                if (itemCount > 1) {
                    this.reminderText = ' and their analytics.';
                } else {
                    this.reminderText = ' and its analytics.';
                }
            }
            if (itemCount - 2 > 1) {
                this.othersText = ' others';
            } else {
                this.othersText = ' other';
            }

            this.confirmationText = 'This will remove ';
            if (itemCount > 1) {
                if (itemCount > 2) {
                    this.confirmationText +=
                        this.data.contactName +
                        ' from ' +
                        this.selectedNames[0] +
                        ', ' +
                        this.selectedNames[1] +
                        ', and ' +
                        (itemCount - 2) +
                        this.othersText;
                } else if (itemCount > 1) {
                    this.confirmationText +=
                        this.data.contactName +
                        ' from ' +
                        this.selectedNames[0] +
                        ' and ' +
                        this.selectedNames[1];
                }
            } else {
                if (itemCount > 2) {
                    this.confirmationText +=
                        this.data.contactName +
                        ' from ' +
                        this.selectedNames[0] +
                        ' and ' +
                        (itemCount - 2) +
                        this.othersText;
                } else if (itemCount > 1) {
                    this.confirmationText +=
                        this.data.contactName +
                        ' from ' +
                        this.selectedNames[0];
                } else {
                    this.confirmationText +=
                        this.data.contactName +
                        ' from ' +
                        this.selectedNames[0];
                }
            }
            this.confirmationText += this.reminderText
        }
    }
    unassign() {
        let unassignData = {};

        if (this.data.allSelected) {
            unassignData = {
                analyticsFilter: {
                    requestUrl: this.data.analyticsFilterUrl,
                    count: this.data.analyticsFilterCount,
                    excludedIds: this.data.excluded,
                },
            };
        } else if (this.data.page === 'CONTACT-ASSIGNMENTS') {
            this.apis.post = 'POST:api/unassign/contact/';
            unassignData = {
                packetIds: this.data.selected,
            };
        } else {
            unassignData = {
                contactIds: this.data.selected,
            };
        }

        if (this.data.page === 'CONTACT-ASSIGNMENTS') {
            this.apis.post = 'POST:api/unassign/contact/';
        }

        this.appHttpService.request(
            this.apis.post + this.data.id + '/',
            unassignData,
            (res) => {
                this.dialogRef.close();
                this.appMessageService.show(
                    'app',
                    'success',
                    this.messages.success,
                    4
                );
                this.appLoadingIndicatorService.hide();
            },
            (err) => {
                this.appLoadingIndicatorService.hide();
                this.appMessageService.show(
                    'modal',
                    'error',
                    this.messages.error,
                    4
                );
            }
        );
    }
}
