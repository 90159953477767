export class ImageCrop {
    public startX: number;
    public startY: number;
    public width: number;
    public height: number;

    constructor(startX: number, startY: number, width: number, height: number) {
        this.startX = startX;
        this.startY = startY;
        this.width = width;
        this.height = height;
    }
}
