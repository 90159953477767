import { Component, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Chart } from 'chart.js';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import * as fileSaver from 'file-saver';

import { AppSelectOption } from '../../../../shared/components/app-select/classes/app.select.option';
import {
    XTable, XtAnswer,
    XtCell,
    XtHeader,
    XtRow,
} from '../../../../shared/components/app-power-table/classes/XTable';
import { XtFilter } from '../../../../shared/components/app-power-table/classes/XTableFilter';
import { columnWidths } from '../../../../shared/components/app-power-table/defaults';
import { Analytics } from '../../../vings/components/detail/classes/analytics';
import { AnalyticsLabel } from '../../../../shared/classes/analytics/analytics.label';
import { ColumnAlignEnum } from '../../../../shared/classes/enums/column.align.enum';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppUserService } from '../../../../shared/services/app.user.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppInfoService } from '../../../../shared/services/app-info/app.info.service';
import { AppDialogAcknowledgeComponent } from '../../../../shared/dialogs/dialog-acknowledge/app.dialog-acknowledge.component';
import { Utils } from '../../../../shared/app.utils';
import { AnalyticsFilters } from '../../../../shared/classes/analytics/analytics.filters';

@Component({
    selector: 'app-selected-contact-submissions',
    templateUrl: './submissions.component.html',
    styleUrls: ['./submissions.component.less'],
})
export class ContactSubmissionsComponent implements OnInit, OnDestroy {
    orgHasAccess: boolean;
    displayChart: boolean;
    analytics: Analytics = null;
    selectors = {
        analytics: '.details-and-analytics .analytics',
        tableBody: '.contacts-analytics-table .power-table-body',
    };
    chartKeyItems = [
        { name: 'unresolved', label: 'Flagged', color: this.appInfoService.getAppInfo().colorsInfo.submissionsChart.unresolved },
        { name: 'unflagged', label: 'Not Flagged', color: this.appInfoService.getAppInfo().colorsInfo.submissionsChart.unflagged },
        { name: 'resolved', label: 'Resolved', color: this.appInfoService.getAppInfo().colorsInfo.submissionsChart.resolved },
    ];
    labels: Array<any> = [];
    updatePaging = new Subject();
    entityName = 'submission';
    noDataText: string;
    infoLink: string;

    /*XTable*/
    isTableReady = false;
    xTableData: XTable;
    xTableFilters: Array<XtFilter> = [];
    numberOfPages: number;
    numberOfResults: number;
    currentPageIndex: number;
    currentPageSize: number;
    selectedVings: Array<number> = [];
    excludedVings: Array<number> = [];
    selectedAllVings = false;
    results: any;
    analyticsFilterData: {};

    searchDelayMs: number;
    contactSubscription: any;
    name: string;

    private searchText: string;
    private selected = new AnalyticsFilters(null, null, null);
    private contact: any;
    private analyticsInterval = null;

    @Input() selectNewContactSubmissions: Observable<any>;

    constructor(
        @Inject('window') private window,
        @Inject('location') private location,
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appInfoService: AppInfoService,
        private appUserService: AppUserService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.orgHasAccess = this.appUserService.hasChecklistsAccess()
        const appInfo = this.appInfoService.getAppInfo();
        this.currentPageSize =
            appInfo.analyticsInfo.contactSubmissionsAnalyticsInfo.pageSize;
        this.searchDelayMs =
            appInfo.analyticsInfo.contactSubmissionsAnalyticsInfo.searchDelayMs;
        this.infoLink = appInfo.analyticsInfo.contactSubmissionsAnalyticsInfo.infoLink;
        this.noDataText = appInfo.analyticsInfo.contactSubmissionsAnalyticsInfo.noDataText;

        this.contactSubscription = this.selectNewContactSubmissions.subscribe(
            (newContact: any) => {
                this.currentPageIndex = 0;
                this.isTableReady = false;
                this.analytics = null;
                this.labels = [];
                this.xTableFilters = [];
                this.searchText = '';
                this.selectedVings = [];
                this.excludedVings = [];
                this.selectedAllVings = false;

                this.initializeAnalytics(newContact);
            }
        );

        const pageRefreshMs = appInfo.contactsInfo.pageRefreshMs;

        this.analyticsInterval = setInterval(() => {
            if (this.contact && document.hasFocus()) {
                this.appLoadingIndicatorService.show(
                    this.selectors.tableBody,
                    '',
                    () => {
                        if (this.contact) {
                            this.initializeAnalytics(null, false);
                        } else {
                            this.appLoadingIndicatorService.hide(350);
                        }
                    }
                );
            }
        }, pageRefreshMs);
    }

    ngOnDestroy() {
        this.contactSubscription.unsubscribe();

        if (this.analyticsInterval) {
            clearInterval(this.analyticsInterval);
        }
    }

    // XTable
    handleSearchTextChange(searchText): void {
        this.appLoadingIndicatorService.show(this.selectors.tableBody);
        this.searchText = searchText;
        // Init analytics table page.
        this.currentPageIndex = 0;
        this.initializeAnalytics();
    }

    handleFiltersChange(update): void {
        const currentFilter = this.selected[update.filterName],
            newFilter = update.newFilterValue,
            type = Array.isArray(currentFilter) ? 'multi' : 'single';

        if (
            (type === 'single' &&
                this.selectedFilterHasChanged(currentFilter, newFilter)) ||
            (type === 'multi' &&
                this.selectedFiltersHaveChanged(currentFilter, newFilter))
        ) {
            this.appLoadingIndicatorService.show(this.selectors.tableBody);

            if (type === 'single') {
                this.selected[update.filterName] = update.newFilterValue;
            } else if (type === 'multi') {
                this.selected[update.filterName] =
                    this.appHelpersService.getClone(update.newFilterValue);
            }

            // Init analytics table page.
            this.currentPageIndex = 0;
            this.initializeAnalytics();
        }
    }

    handlePageChange(currentPageIndex): void {
        this.appLoadingIndicatorService.show(this.selectors.tableBody);
        this.currentPageIndex = currentPageIndex;
        this.initializeAnalytics(null, false);
    }

    handleSelectedRows(selectedRows): void {
        this.selectedVings = selectedRows;
    }

    handleExcludedRows(excludedRows): void {
        this.excludedVings = excludedRows;
    }

    handleSelectedAllRows(allSelected): void {
        this.selectedAllVings = allSelected;
    }

    export(): void {
        this.appLoadingIndicatorService.show(this.selectors.tableBody);
        this.exportData();
    }

    private initializeAnalytics(
        contact?: any,
        shouldInitPagingData = true
    ): void {
        if (contact) {
            this.contact = contact;
        }
        this.appLoadingIndicatorService.show(
            this.selectors.analytics,
            '',
            () => {
                const data = this.buildAnalyticsData(),
                    url = `GET:api/data/submissions/contact/${this.contact.id}/`;

                // HTTP call to archive the contact.
                this.appHttpService.request(url, data, (response) => {
                    if (shouldInitPagingData) {
                        this.currentPageIndex = 0;
                        this.results = response;
                        this.numberOfResults = response.data.count;
                        this.numberOfPages = Math.ceil(
                            this.numberOfResults / this.currentPageSize
                        );
                        setTimeout(() => {
                            this.updatePaging.next();
                        }, 10);
                    }

                    this.analytics = response;

                    if (contact) {
                        this.name = this.contact.name;
                        // Initialize labels, table, & filters.
                        this.labels = this.buildLabels(this.analytics.labels);
                        this.initXFilters();
                        this.initTable();
                    } else {
                        // Update table.
                        this.xTableData.rows = this.initRows(
                            response.data.results
                        );
                    }

                    // Initialize chart.
                    this.setChartData();

                    // Redraw chart.
                    this.setChartData();
                });
            }
        );
    }

    private initXFilters(): void {
        // Init above-table drop-downs.
        const appInfo = this.appInfoService.getAppInfo(),
            filtersOptions = this.buildOptions(
                appInfo.analyticsInfo.contactSubmissionsAnalyticsInfo.filters
            ),
            sortsData = appInfo.analyticsInfo.contactSubmissionsAnalyticsInfo.sorts,
            sortsOptions = this.buildOptions(sortsData);
        // Clear previous table filters list.
        this.xTableFilters = [];

        // Filters
        this.xTableFilters.push(
            new XtFilter(
                'multi',
                'Filters',
                'filters',
                115,
                79,
                filtersOptions,
                null,
                true
            )
        );

        // Labels
        this.xTableFilters.push(
            new XtFilter(
                'multi',
                'Labels',
                'labels',
                115,
                105,
                this.labels,
                null,
                true
            )
        );

        // Sorts
        this.xTableFilters.push(
            new XtFilter(
                'single',
                'Sort',
                'sort',
                115,
                105,
                sortsOptions,
                this.getSelectedSorts(sortsData),
                false
            )
        );
    }

    private initTable() {
        const packets = this.analytics.data.results,
            rows: Array<XtRow> = this.initRows(packets),
            headers: Array<XtHeader> = [
                new XtHeader('Name', columnWidths['name']),
                new XtHeader('', columnWidths['bigIcon'], ColumnAlignEnum.CENTER),
                new XtHeader('Submitted', columnWidths['date'], ColumnAlignEnum.LEFT),
                new XtHeader('Flagged', columnWidths['score'], ColumnAlignEnum.CENTER),
            ];

        this.xTableData = new XTable(headers, rows);

        setTimeout(() => {
            this.isTableReady = true;
        }, 100);
    }

    private initRows(submissions): Array<XtRow> {
        const rows: Array<XtRow> = [],
            emptyEntry = '';

        submissions.forEach((submission) => {
            const isViewSet = !this.appHelpersService.isEmptyObject(submission),
                id = submission.submissionId,
                selectable = true,
                flagged = submission.flagged,
                resolved = submission.resolved,
                reportId = submission.submissionId,
                contactId = submission.contact.contactId,
                checklistId = submission.checklist.checklistId,
                submittedDate = isViewSet
                    ? this.getDisplayDate(submission.submittedDate)
                    : '';

            let cells = null,
            flagOption = '';

            if (resolved) {
                flagOption = 'resolved';
            } else {
                if (flagged) {
                    flagOption = 'flagged';
                } else {
                    flagOption = 'unflagged';
                }
            }

            cells = [
                new XtCell(
                    'title',
                    submission.checklist.title,
                    null
                ),
                new XtCell('pdf'),
                new XtCell(null, submittedDate, null, null),
                new XtCell('flag', flagOption, null, null, 'flag_circle'),
            ];

            rows.push(new XtRow(id, cells, selectable, reportId, contactId, checklistId));
        });

        return rows;
    }

    private exportData(): void {
        const data = this.buildAnalyticsData(false);
        data['export'] = true;
        data['timezone'] = new Date().getTimezoneOffset();

        this.appHttpService.request(
            `GET:api/data/submissions/contact/${this.contact.id}/`,
            data,
            (response) => {
                this.appLoadingIndicatorService.hide();

                this.dialog.open(AppDialogAcknowledgeComponent, {
                    width: '425px',
                    disableClose: true,
                    data: {
                        title: 'Data Export',
                        text: 'Your requested export is being generated and you will receive it via email soon!',
                    },
                });
            }
        );
    }

    private buildOptions(list): Array<AppSelectOption> {
        const options: Array<AppSelectOption> = [];

        list.forEach((item) => {
            options.push(new AppSelectOption(item.name, item.value));
        });

        return options;
    }

    private buildLabels(
        labels: Array<AnalyticsLabel>
    ): Array<AppSelectOption> {
        const contactLabels: Array<AppSelectOption> = [];

        if (labels !== undefined) {
            labels.forEach((label) => {
                contactLabels.push(
                    new AppSelectOption(label.text, label.id.toString())
                );
            });
        }

        return contactLabels;
    }

    private getSelectedSorts(list): Array<AppSelectOption> {
        const options: Array<AppSelectOption> = [];

        const selectedItem = list.find((filter) => filter.selected === true);
        options.push(
            new AppSelectOption(selectedItem.name, selectedItem.value)
        );

        return options;
    }

    private selectedFilterHasChanged(
        currentFilter: AppSelectOption,
        newFilter: AppSelectOption
    ): boolean {
        const currentValue = currentFilter ? currentFilter.value : undefined,
            newValue = newFilter ? newFilter.value : undefined;

        return currentValue !== newValue;
    }

    private selectedFiltersHaveChanged(
        currentFilter: Array<AppSelectOption>,
        newFilter: Array<AppSelectOption>
    ): boolean {
        let hasChanged = false;

        // If the lengths of the new and old filters have changed, this is a sign
        // that the selected values have changed.
        if (currentFilter.length !== newFilter.length) {
            hasChanged = true;
        } else {
            // Build the list of current and new values joined into a concatenated
            // strings. This allows us to compare the current and new values in
            // subsequent steps.
            const currentValues = currentFilter
                    .map((filter) => filter.value)
                    .sort()
                    .join(''),
                newValues = newFilter
                    .map((filter) => filter.value)
                    .sort()
                    .join('');

            // If the lengths of the string values of the new and old filters have
            // changed or if the string values representation are different then this
            // tells us that the values have changed.
            if (
                currentValues.length !== newValues.length ||
                currentValues !== newValues
            ) {
                hasChanged = true;
            }
        }

        return hasChanged;
    }

    private buildAnalyticsData(limit = true): object {
        const data = {};

        // Limit
        if (limit) {
            data['limit'] = this.currentPageSize;
        }

        // Query search text.
        if (this.searchText && this.searchText.length > 0) {
            const filterText = Utils.trim(this.searchText);
            data['checklistQuery'] = filterText;
        }

        // Conditionally set the offset.
        if (this.currentPageIndex + 1 > 0 && limit) {
            data['offset'] = this.currentPageIndex * this.currentPageSize;
        }

        // Conditionally apply 'filters', 'sorts', & 'labels'.
        ['filters', 'labels', 'sort'].forEach((filter) => {
            if (filter === 'filters') {
                if (this.selected[filter].length > 0) {
                    data[filter] = this.selected[filter].map(
                        (item) => item.value
                    );
                }
            } else if (filter === 'labels') {
                if (this.selected[filter].length > 0) {
                    data['checklistLabels'] = this.selected[filter].map(
                        (item) => item.value
                    );
                }
            } else if (filter === 'sort') {
                if (this.selected[filter]) {
                    data[filter] = this.selected[filter].value;
                } else {
                    const appInfo = this.appInfoService.getAppInfo(),
                        sortsData =
                            appInfo.analyticsInfo.contactSubmissionsAnalyticsInfo.sorts,
                        sortsOptions = this.buildOptions(sortsData);
                    if (sortsOptions && sortsOptions.length) {
                        data[filter] = sortsOptions[0].value;
                    }
                }
            }
        });

        this.analyticsFilterData = data;

        return data;
    }

    private getDisplayDate(date): string {
        return date ? moment(date).format('L') : '--';
    }

    private getDisplayTime(time): string {
        return time ? moment(time).format('LT') : '';
    }

    // Charts
    private setChartData() {
        this.appHelpersService.whenElementPresentDo(
            '#standard-doughnut-chart',
            () => {
                const chartInvited = document.getElementById(
                    'standard-doughnut-chart'
                );
                if (this.analytics) {
                    let stats;
                    if (this.analytics.stats) {
                        stats = this.appHelpersService.getClone(
                            this.analytics.stats
                        );
                    } else {
                        stats = this.appHelpersService.getClone(
                            this.analytics.stats
                        );
                    }
                    const statsTotal = stats.total;

                    Chart.defaults.global.defaultFontColor = '#757575';
                    Chart.defaults.global.defaultFontFamily =
                        'Lato, sans-serif';
                    Chart.defaults.global.elements.line.fill = false;

                    chartInvited.innerHTML =
                        '<canvas id="canvas-standard-chart"></canvas>';
                    const context = document
                        .getElementById('canvas-standard-chart')
                        ['getContext']('2d');
                    const chartData = {
                        labels: ['Flagged', 'Not Flagged', 'Resolved'],
                        datasets: [
                            {
                                data: [
                                    stats.unresolved,
                                    stats.unflagged,
                                    stats.resolved,
                                ],
                                backgroundColor: [
                                    this.appInfoService.getAppInfo().colorsInfo
                                        .submissionsChart.unresolved,
                                    this.appInfoService.getAppInfo().colorsInfo
                                        .submissionsChart.unflagged,
                                    this.appInfoService.getAppInfo().colorsInfo
                                        .submissionsChart.resolved,
                                ],
                            },
                        ],
                    };

                    this.drawChart('', context, chartData);
                    setTimeout(() => {
                        this.displayChart = statsTotal > 0;
                        this.appLoadingIndicatorService.hide(350);
                    }, 150);
                } else {
                    this.displayChart = false;
                    this.appLoadingIndicatorService.hide(350);
                }
            }
        );
    }

    private drawChart(title, context, data) {
        const chart = new Chart(context, {
            type: 'doughnut',
            data: data,
            options: this.getChartOptionsDoughnut(title),
        });
    }

    private getChartOptionsDoughnut(title) {
        return {
            legend: {
                display: false,
            },
            maintainAspectRatio: false,
            title: {
                display: false,
                text: title,
            },
            tooltips: {
                mode: 'label',
                backgroundColor: '@app-dark',
            },
            responsive: true,
        };
    }
}
