import {
    CdkDragDrop,
    CdkDragStart,
    DragRef,
    moveItemInArray,
    transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
} from '@angular/core';
import * as _ from 'lodash';
import { AppDialogConfirmComponent } from '../../dialogs/dialog-confirm/app.dialog-confirm.component';
import { AppMessageService } from '../../services/app.message.service';
import { AppLoadingIndicatorService } from '../../services/app.loading-indicator.service';
import { ContactService } from '../../../modules/contacts/services/contact.service';
import { MatDialog } from '@angular/material/dialog';
import { AppDialogEditCategoryComponent } from '../../dialogs/dialog-edit-category/app.dialog-edit-category.component';
import { AppDialogCreateCategoryComponent } from '../../dialogs/dialog-create-category/app.dialog-create-category.component';
import { VingsService } from '../../../modules/vings/services/vings.service';
import {ChecklistsService} from "../../../modules/checklists/services/checklists.service";

@Component({
    selector: 'app-multi-drag-drop',
    templateUrl: './app.multi-drag-and-drop.component.html',
    styleUrls: ['./app.multi-drag-and-drop.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMultiDragDropComponent implements OnInit, OnChanges {
    @Input() items: any[];
    @Input() selected: any[];
    @Input() page: string;
    @Input() category: string;
    @Input() toggle: (
        type: any,
        item: any,
        index: any,
        selections: any
    ) => void;
    @Output() toggled = new EventEmitter<any[]>();
    @Output() isDragging = new EventEmitter<boolean>();
    @Output() itemsRemoved = new EventEmitter<any[]>();
    @Output() itemsAdded = new EventEmitter<any[]>();
    @Output() itemsUpdated = new EventEmitter<any[]>();
    @Output() selectionChanged = new EventEmitter<any[]>();
    @Output() createCategory = new EventEmitter<any[]>();
    @Output() clearFilters = new EventEmitter<any[]>();
    @Output() removeLabelFromList = new EventEmitter<object>();
    @ContentChild(TemplateRef, { static: false }) templateRef;

    public dragging: DragRef = null;
    public selections: number[] = [];
    public filters: string[] = [];
    public selectedLabels: [];
    private currentSelectionSpan: number[] = [];
    private lastSingleSelection: number;

    constructor(
        private eRef: ElementRef,
        private cdRef: ChangeDetectorRef,
        private appMessageService: AppMessageService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private contactService: ContactService,
        private vingsService: VingsService,
        private checklistsService: ChecklistsService,
        private dialog: MatDialog
    ) {}
    ngOnInit() {
        this.selected.forEach((label) => {
            this.filters.push(label);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.filters = [];
        this.selected.forEach((label) => {
            this.filters.push(label);
        });
    }

    dragStarted(ev: CdkDragStart, index: number): void {
        this.dragging = ev.source._dragRef;
        this.isDragging.emit(true);
        const indices = this.selections.length ? this.selections : [index];
        ev.source.data = {
            indices,
            values: indices.map((i) => this.items[i]),
            source: this,
        };
        this.cdRef.detectChanges();
    }

    dragEnded(): void {
        this.dragging = null;
        this.isDragging.emit(false);
        this.cdRef.detectChanges();
    }

    dropped(ev: CdkDragDrop<any>): void {
        const values = ev.item.data.values;
        if (!ev.isPointerOverContainer || !_.get(ev, 'item.data.source')) {
            return;
        }
        if (this.filters) {
            values.forEach((value) => {
                this.toggled.emit(value);
            });
            this.filters = [];
        }
        const data = ev.item.data;
        if (data.source === this) {
            const removed = _.pullAt(this.items, data.indices);
            if (ev.previousContainer !== ev.container) {
                this.itemsRemoved.emit(removed);
                this.itemsUpdated.emit(this.items);
            }
        }
        this.dragging = null;
        setTimeout(() => this.clearSelection());
    }

    droppedIntoList(ev: CdkDragDrop<any>, category: any, page: any): void {
        const values = ev.item.data.values;
        const droppedLabels = [];
        droppedLabels.push(values.map((label) => label.name));
        const labelObj = {
            labels: values.map((label) => label.name),
        };
        if (category === 'Create New Folder') {
            this.dialog.open(AppDialogCreateCategoryComponent, {
                height: '200px',
                width: '425px',
                data: {
                    type: page,
                    labels: values,
                    callback: () => {
                        this.createCategory.emit();
                        this.filters = [];
                    },
                },
            });
        } else {
            if (page === 'contact') {
                this.contactService.apiCreateCategory(
                    category,
                    labelObj,
                    () => {
                        // this.createCategory.emit();
                    },
                    () => {
                        // this.createCategory.emit();
                        this.appLoadingIndicatorService.hide(350);
                        let message = '';
                        message =
                            'There was a problem moving the label. Please try again.';
                        this.appMessageService.show(
                            'app',
                            'error',
                            message,
                            5.0
                        );
                    }
                );
            } else if (page === 'packet') {
                this.vingsService.apiCreateCategory(
                    category,
                    labelObj,
                    () => {
                        this.clearFilters.emit();
                    },
                    () => {
                        // this.createCategory.emit();
                        this.appLoadingIndicatorService.hide(350);
                        let message = '';
                        message =
                            'There was a problem moving the label. Please try again.';
                        this.appMessageService.show(
                            'app',
                            'error',
                            message,
                            5.0
                        );
                    }
                );
            } else if (page === 'checklist') {
                this.vingsService.apiCreateCategory(
                    category,
                    labelObj,
                    () => {
                        this.clearFilters.emit();
                    },
                    () => {
                        // this.createCategory.emit();
                        this.appLoadingIndicatorService.hide(350);
                        let message = '';
                        message =
                            'There was a problem moving the label. Please try again.';
                        this.appMessageService.show(
                            'app',
                            'error',
                            message,
                            5.0
                        );
                    }
                );
            }
            if (!ev.isPointerOverContainer || !_.get(ev, 'item.data.source')) {
                return;
            }
            const data = ev.item.data;
            let spliceIntoIndex = ev.currentIndex;
            if (
                ev.previousContainer === ev.container &&
                this.selections.length > 1
            ) {
                this.selections.splice(-1, 1);
                const sum = _.sumBy(this.selections, (selectedIndex) =>
                    selectedIndex <= spliceIntoIndex ? 1 : 0
                );
                spliceIntoIndex -= sum;
            }
            this.items.splice(spliceIntoIndex, 0, ...data.values);

            if (ev.previousContainer !== ev.container) {
                this.itemsAdded.emit(data.values);
            }
            this.itemsUpdated.emit(this.items);
            setTimeout(() => this.cdRef.detectChanges());
        }
    }

    isSelected(i: number): boolean {
        return this.selections.indexOf(i) >= 0;
    }

    isSelectedFilter(name: any) {
        const index = this.filters.indexOf(name);
        if (index === -1) {
            return false;
        } else {
            return true;
        }
    }

    select(event, index, item) {
        const shiftSelect =
            event.shiftKey &&
            (this.lastSingleSelection || this.lastSingleSelection === 0) &&
            this.lastSingleSelection !== index;

        const alreadySelected = _.find(this.selections, (s) => s === index);
        if (alreadySelected || this.filters.includes(item.name)) {
            const labelIndex = this.filters.indexOf(item.name);
            this.filters.splice(labelIndex, 1);
            event.target.closest('.item').classList.remove('label-selected');
            // event.target.closest('.item').classList.remove('selected');
            _.remove(this.selections, (s) => s === index);
            this.lastSingleSelection = null;
        } else {
            this.filters.unshift(item.name);
            event.target.closest('.item').classList.add('label-selected');
            this.selections.push(index);
            this.lastSingleSelection = index;
        }
        this.toggled.emit(item);
        this.selectionChanged.emit(this.selections.map((i) => this.items[i]));
        this.cdRef.detectChanges();
    }

    clearSelection() {
        if (this.selections.length) {
            this.selections = [];
            this.currentSelectionSpan = [];
            this.lastSingleSelection = null;
            this.selectionChanged.emit(
                this.selections.map((i) => this.items[i])
            );
            this.cdRef.detectChanges();
        }
    }

    selectAll() {
        if (this.selections.length !== this.items.length) {
            this.selections = _.map(this.items, (item, i) => i);
            this.currentSelectionSpan = [];
            this.lastSingleSelection = null;
            this.selectionChanged.emit(this.items);
            this.cdRef.detectChanges();
        }
    }

    // handles "ctrl/command + a" to select all
    @HostListener('document:keydown', ['$event'])
    private handleKeyboardEvent(event: KeyboardEvent) {
        if (
            event.key === 'a' &&
            (event.ctrlKey || event.metaKey) &&
            this.selections.length &&
            document.activeElement.nodeName !== 'INPUT'
        ) {
            event.preventDefault();
            this.selectAll();
        } else if (event.key === 'Escape' && this.dragging) {
            this.dragging.reset();
            document.dispatchEvent(new Event('mouseup'));
        }
    }

    @HostListener('document:click', ['$event'])
    private clickout(event) {
        if (
            this.selections.length &&
            !this.eRef.nativeElement.contains(event.target)
        ) {
            // this.clearSelection();
        }
    }

    deleteLabel(event, label: any, category): void {
        event.stopPropagation();
        // Display confirmation dialog.
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            data: {
                text: 'Are you sure you want to delete this label?',
                callback: () => {
                    if (this.filters.includes(label.name)) {
                        this.toggled.emit(label);
                        const labelIndex = this.filters.indexOf(label.name);
                        this.filters.splice(labelIndex, 1);
                    }
                    this.apiDeleteFilter('label', label, category);
                },
            },
        });
    }

    private apiDeleteFilter(type, label, category) {
        this.appLoadingIndicatorService.show('view');
        if (this.page === 'contact') {
            this.contactService.apiDeleteFilter(
                type,
                label.name,
                () => {
                    // const resetLabel = type === 'label' ? true : 'none';
                    // const resetList = type === 'list' ? true : 'none';
                    // this.getContactsData(resetLabel, resetList, false, () => {
                    const info = {
                        label: label,
                        category: category,
                    };
                    this.removeLabelFromList.emit(info);
                    this.appLoadingIndicatorService.hide(350);
                    const message = 'Label successfully removed.';
                    this.appMessageService.show('app', 'success', message, 3);
                    // });
                },
                () => {
                    this.appLoadingIndicatorService.hide(350);
                    const message =
                        'There was an error deleting the label. Please try again.';
                    this.appMessageService.show('app', 'error', message, 5.0);
                }
            );
        } else if (this.page === 'packet') {
            this.vingsService.apiDeleteFilter(
                type,
                label.name,
                () => {
                    const info = {
                        label: label,
                        category: category,
                    };
                    this.removeLabelFromList.emit(info);
                    this.appLoadingIndicatorService.hide(350);
                    const message = 'Label successfully removed.';
                    this.appMessageService.show('app', 'success', message, 3);
                    // });
                },
                () => {
                    this.appLoadingIndicatorService.hide(350);
                    const message =
                        'There was an error deleting the label. Please try again.';
                    this.appMessageService.show('app', 'error', message, 5.0);
                }
            );
        } else if (this.page === 'checklist') {
            this.checklistsService.apiDeleteFilter(
                type,
                label.name,
                () => {
                    const info = {
                        label: label,
                        category: category,
                    };
                    this.removeLabelFromList.emit(info);
                    this.appLoadingIndicatorService.hide(350);
                    const message = 'Label successfully removed.';
                    this.appMessageService.show('app', 'success', message, 3);
                    // });
                },
                () => {
                    this.appLoadingIndicatorService.hide(350);
                    const message =
                        'There was an error deleting the label. Please try again.';
                    this.appMessageService.show('app', 'error', message, 5.0);
                }
            );
        }
    }
}
