import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { EditContact } from '../../classes/edit-contact';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppPrototypes } from '../../../../shared/app.prototypes';
import { AppDataTransform } from '../../../../shared/app.data-transform';
import { Contact } from '../../classes/contact';
import { ContactService } from '../../services/contact.service';
import { AppMessage } from '../../../../shared/app.messages';
import { Utils } from '../../../../shared/app.utils';

@Component({
    templateUrl: './dialog-edit-contact.component.html',
    styleUrls: ['./dialog-edit-contact.component.less'],
})
export class DialogEditContactComponent implements OnInit, AfterViewInit {
    messages = {
        error: 'Please ensure that the fields are valid.',
        contactHasNotChanged: 'No changes to the contact have been made.',
    };
    editContact = new EditContact('', '', '', false, '', '', '', '');
    updatedContact = {};
    tabs = [
        { name: 'profile', label: 'Profile' },
        { name: 'details', label: 'Details' },
        { name: 'avatar', label: 'Avatar' },
    ];
    selectedTab = this.tabs[0]['name']; // Set default tab.
    originalData = '';
    isValid = {
        method: 'edit',
        emails: true,
        phones: true,
        oneOrMoreValidEmails: true,
        oneOrMoreValidPhones: true,
        internalId: true,
    };
    // Avatar
    contactAvatar = {
        key: '',
        crop: {
            startX: undefined,
            startY: undefined,
            width: undefined,
            height: undefined,
        },
    };
    originalAvatar = undefined;
    originalAvatarUrl = undefined;
    selectedFile = undefined;
    avatarTabHasBeenSelected = false;
    imageRemoved = false;

    constructor(
        private appHttpService: AppHttpService,
        private appMessageService: AppMessageService,
        private appHelpersService: AppHelpersService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private contactService: ContactService,
        public dialogRef: MatDialogRef<DialogEditContactComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            contact: any;
            callback: any;
        }
    ) {
        this.originalAvatar = this.data.contact.avatar;
        this.originalAvatarUrl = this.data.contact.avatarUrl;
    }

    ngOnInit() {
        // Prepare the contact for display within the UI edit form.
        this.initializeContact(this.data.contact);

        // Set the original lists data. Will be used to detect changes when the user saves.
        const originalContact = this.appHelpersService.getClone(
            this.data.contact
        );
        const emails = [],
            phones = [];
        delete originalContact['id'];
        delete originalContact['avatar_url'];
        originalContact.contactMethods['active'].map((item) => {
            if (Utils.validateEmail(item)) {
                emails.push(item);
            } else {
                phones.push(AppPrototypes.cleanPhone(item));
            }
        });
        originalContact['emails'] = emails;
        originalContact['phones'] = phones;
        delete originalContact.contactMethods['active'];
        delete originalContact.contactMethods['bad'];
        delete originalContact['labels'];
        delete originalContact['lists'];
        this.originalData = JSON.stringify(originalContact);

        setTimeout(() => {
            this.appLoadingIndicatorService.hide();
        }, 200);
    }

    ngAfterViewInit() {
        this.contactService.initEmailsAndPhonesValidationJob(
            this.originalData,
            this.editContact,
            this.isValid
        );
    }

    selectTab(tabName) {
        this.selectedTab = tabName;
        if (this.selectedTab === 'avatar') {
            this.avatarTabHasBeenSelected = true;
        }
    }

    getTabClass(tabName) {
        let tabClass = 'tab ';

        // Check to see if the contact is selected.
        if (this.selectedTab === tabName) {
            tabClass += 'selected ';
        }

        return tabClass.trim();
    }

    getTabContainerClass(tabName) {
        let tabContainerClass = 'tab-container ';

        // Check to see if the contact is selected.
        if (this.selectedTab === tabName) {
            tabContainerClass += 'selected ';
        }

        return tabContainerClass.trim();
    }

    allFieldsValid() {
        return this.contactService.checkAllFieldsValid(this.isValid);
    }

    close() {
        this.contactService.killValidationJobs();
    }

    save() {
        this.updatedContact = {
            avatar: {},
        };
        this.buildUpdatedContact();

        // Initial check to see if contact has changed.
        if (!this.hasContactChanged()) {
            this.dialogRef.close();
            this.appMessageService.show(
                'app',
                'general',
                this.messages.contactHasNotChanged,
                3
            );
        } else {
            // Valid the form fields
            this.isValid['internalId'] = true;

            if (this.avatarSetAndWasNotUpdated()) {
                this.apiUpdateContact({
                    crop: this.data.contact.avatar['crop'],
                });
            } else if (
                this.isAvatarSet() &&
                typeof this.selectedFile === 'object'
            ) {
                this.appLoadingIndicatorService.show('modal');
                this.appHttpService.apiAwsUploadFile(
                    this.selectedFile,
                    (key) => {
                        this.contactAvatar.key = key;
                        this.apiUpdateContact(this.contactAvatar);
                    },
                    () => {
                        this.appLoadingIndicatorService.hide(350);
                        this.appMessageService.show(
                            'modal',
                            'error',
                            AppMessage.get('awsUploadError'),
                            5
                        );
                    }
                );
            } else if (
                this.isAvatarSet() &&
                typeof this.selectedFile === 'boolean'
            ) {
                this.apiUpdateContact({
                    crop: this.contactAvatar.crop,
                });
            } else {
                this.apiUpdateContact();
            }
        }
    }

    // Avatar
    updateImage(data) {
        // Check to see if the avatar was removed.
        this.imageRemoved =
            'imageRemoved' in data && data.imageRemoved === true;
        if (this.imageRemoved) {
            // Set image data.
            this.originalAvatar = null;
            this.originalAvatarUrl = null;

            // Set file data.
            this.selectedFile = null;
        } else {
            // Set image data.
            ['startX', 'startY', 'width', 'height'].forEach((key) => {
                this.contactAvatar.crop[key] = data.image.crop[key];
            });

            // Set file data.
            this.selectedFile = data.selectedFile;
        }
    }

    private initializeContact(contact: any) {
        this.editContact.name = contact.name;
        this.editContact.blacklist = contact.blacklist;
        this.editContact.title = contact.title;
        this.editContact.hireDate = contact.hireDate;
        this.editContact.separationDate = contact.separationDate;
        this.editContact.internalId = contact.internalId;

        const phones = [],
            emails = [];

        // Process emails, phones.
        ['active', 'inactive', 'bad'].forEach((property) => {
            if (contact.contactMethods[property].length > 0) {
                contact.contactMethods[property].map((item) => {
                    if (Utils.validateEmail(item)) {
                        emails.push(item);
                    } else {
                        phones.push(AppPrototypes.formatPhone(item));
                    }
                });
                this.editContact['phones'] = phones.join('\n');
                this.editContact['emails'] = emails.join('\n');
            }
        });
    }

    private apiUpdateContact(avatar?: Object) {
        // Initial validation.
        if (
            this.contactService.isContactValid(
                this.updatedContact,
                this.isValid
            )
        ) {
            const id = this.data.contact['id'];

            this.appLoadingIndicatorService.show('modal');

            if (avatar) {
                this.updatedContact['avatar'] = avatar;
            }

            this.contactService.apiUpdateContact(
                id,
                this.updatedContact,
                (contact) => {
                    this.data.callback(this.dialogRef, contact);
                },
                (error) => {
                    const msg =
                        error.status === 400 && error.data
                            ? error.data[Object.keys(error.data)[0]][0]
                            : this.messages.error;
                    this.appMessageService.show('modal', 'error', msg, 5.0);
                    this.showFormFieldErrors(error);
                    this.appLoadingIndicatorService.hide(200);
                }
            );
        } else {
            this.appLoadingIndicatorService.hide(350);
            this.appMessageService.show(
                'modal',
                'error',
                this.messages.error,
                6.5
            );
        }
    }

    private buildUpdatedContact() {
        this.contactService.setContactData(
            this.editContact,
            this.updatedContact
        );
    }

    private hasContactChanged() {
        const tempUpdatedContact = this.appHelpersService.getClone(
            this.updatedContact
        );
        tempUpdatedContact['phones'] = tempUpdatedContact['phones'].map(
            (phone) => AppPrototypes.cleanPhone(phone)
        );

        return this.originalData !== JSON.stringify(tempUpdatedContact);
    }

    private avatarSetAndWasNotUpdated() {
        let isSetAndNotUpdated = false;

        if (
            !this.imageRemoved &&
            !this.isAvatarSet() &&
            this.selectedFile === undefined
        ) {
            if (
                'avatar' in this.data.contact &&
                'crop' in this.data.contact.avatar
            ) {
                const originalCrop = this.data.contact.avatar['crop'];

                if (this.areCropCoordinatesValid(originalCrop)) {
                    isSetAndNotUpdated = true;
                }
            }
        }

        return isSetAndNotUpdated;
    }

    private isAvatarSet() {
        const crop = this.contactAvatar.crop,
            hasSelectedFile = Boolean(this.selectedFile),
            areCropCoordsValid = this.areCropCoordinatesValid(crop);

        return hasSelectedFile && areCropCoordsValid;
    }

    private areCropCoordinatesValid(crop): boolean {
        return (
            Boolean(crop.startX >= 0) &&
            Boolean(crop.startY >= 0) &&
            Boolean(crop.width >= 0) &&
            Boolean(crop.height >= 0)
        );
    }

    private showFormFieldErrors(error): void {
        if (error.status === 400 && error.data) {
            Object.keys(error.data).map((key) => {
                const updatedKey = AppDataTransform.convertToCamel(key);
                if (updatedKey in this.isValid) {
                    this.isValid[updatedKey] = false;
                }
            });
        }
    }
}
