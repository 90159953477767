import { ColumnAlignEnum } from '../../../classes/enums/column.align.enum';

export class XTable {
    headers: Array<XtHeader>;
    rows: Array<XtRow>;
    headerCss: string;
    height: string;

    constructor(
        headers: Array<XtHeader>,
        rows: Array<XtRow>,
        headerCss?: string,
        height?: string
    ) {
        this.headers = headers;
        this.rows = rows;
        this.headerCss = headerCss ? headerCss : '';
        this.height = height ? height : null;
    }
}

export class XtHeader {
    text: string;
    width: string;
    align: ColumnAlignEnum;
    css: string;

    constructor(
        text: string,
        width: string,
        align?: ColumnAlignEnum,
        css?: string
    ) {
        this.text = text;
        this.width = width;
        this.align = align ? align : ColumnAlignEnum.LEFT;
        this.css = css ? css : null;
    }
}

export class XtRow {
    id: number;
    cells: Array<XtCell>;
    selectable: boolean;
    reportId?: number;
    contactId?: number;
    checklistId?: number;

    constructor(id: number, cells: Array<XtCell>, selectable?: boolean, reportId?: number, contactId?: number, checklistId?: number) {
        this.id = id;
        this.cells = cells;
        this.selectable = Boolean(selectable);
        if (reportId) {
            this.reportId = reportId;
        } else {
            this.reportId = id;
        }
        if (contactId) {
            this.contactId = contactId;
        }
        if (checklistId) {
            this.checklistId = checklistId;
        }
    }
}

export class XtCell {
    name: any;
    text: any;
    css: string;
    subText: string;
    icon: string;
    progress: number;
    progressComplete: boolean;
    errText: string;
    answers: Array<XtAnswer>;
    color: string;

    constructor(
        name?: any,
        text?: any,
        css?: string,
        subText?: string,
        icon?: string,
        progress?: number,
        progressComplete?: boolean,
        errText?: string,
        answers?: Array<XtAnswer>,
        color?: string
    ) {
        this.name = name ? name : null;
        this.text = text ? text : null;
        this.css = css ? css : '';
        this.subText = subText ? subText : null;
        this.icon = icon ? icon : null;
        if (progress !== null && progress !== undefined) {
            this.progress = progress;
        } else {
            this.progress = null;
        }
        this.progressComplete = Boolean(progressComplete);
        this.errText = errText ? errText : null;
        this.answers = answers ? answers : null;
        this.color = color ? color : null;
    }
}

export class XtAnswer {
    text: any;
    imageUrl: string;

    constructor(text?: any, imageUrl?: string) {
        this.text = text ? text : null;
        this.imageUrl = imageUrl ? imageUrl : null;
    }
}
