import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import {
    NgxFileDropEntry,
    FileSystemFileEntry,
    FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import * as pluralize from 'pluralize';
import * as getUserMedia from 'getusermedia';

import { AppService } from '../../../../app.service';
import { AppLoadingIndicatorService } from '../../../../shared/services/app.loading-indicator.service';
import { AppJobService } from '../../../../shared/services/job/app.job.service';
import { AppMessageService } from '../../../../shared/services/app.message.service';
import { AppHelpersService } from '../../../../shared/services/app.helpers.service';
import { AppHttpService } from '../../../../shared/services/app.http.service';
import { AppAlertService } from '../../../../shared/services/app.alert.service';
import { AppInfoService } from '../../../../shared/services/app-info/app.info.service';
import { AppMessage } from '../../../../shared/app.messages';
import { PacketComponent } from '../../classes/packet-component';
import { GroupOption } from '../../../../shared/classes/group-option';
import { ComponentFile } from './classes/component-file';
import { ComponentFileType } from './classes/component-file-type';
import { ComponentFileStatus } from './classes/component-file-status';
import { AppDialogAcknowledgeComponent } from '../../../../shared/dialogs/dialog-acknowledge/app.dialog-acknowledge.component';
import { AppDialogConfirmComponent } from '../../../../shared/dialogs/dialog-confirm/app.dialog-confirm.component';
import { QuestionErrors } from '../../../../shared/classes/questions/question-errors';
import { QuestionsValidationService } from './services/questions.validation.service';
import { DialogUpdateTextComponent } from './dialogs/update-text/dialog.update-text.component';
import { DialogEditDetailsComponent } from './dialogs/edit-details/dialog.edit-details.component';
import { DialogCreateComponentsComponent } from './dialogs/create-components/dialog.create-components.component';
import * as $ from 'jquery';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-edit-ving',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.less'],
})
export class EditComponent implements OnInit, OnDestroy {
    @ViewChild('autoscroll') autoscroll: ElementRef;
    @ViewChild('selectFileField') selectFileField: ElementRef;
    addComponentButtons = [
        {
            type: 'questions',
            text: 'Question Set',
            isSupported: true,
            componentTypeImage: '../../../assets/images/questions.svg',
        },
        {
            type: 'record-audio',
            text: 'Record Audio',
            isSupported: true,
            componentTypeImage: '../../../assets/images/audio.svg',
        },
        {
            type: 'link',
            text: 'Link',
            isSupported: true,
            componentTypeImage: '../../../assets/images/link.svg',
        },
        {
            type: 'text',
            text: 'Text',
            isSupported: true,
            componentTypeImage: '../../../assets/images/text.svg',
        },
    ];
    addComponentDragDropTypes = [
        {
            type: 'image',
            text: 'Image',
        },
        {
            type: 'file',
            text: 'File',
        },
        {
            type: 'audio',
            text: 'Audio',
        },
        {
            type: 'video',
            text: 'Video',
        },
    ];
    allowableFileExtensions: string;
    componentFiles: Array<ComponentFile> = [];
    componentsIsFileOver = false;
    icons = {
        image: 'photo',
        file: 'insert_drive_file',
        document: 'content_copy',
        other: 'insert_drive_file',
        text: 'title',
        link: 'link',
        audio: 'volume_up',
        video: 'videocam',
        questions: 'assignment',
    };
    isAddingNewComponents = true;
    messages = {
        success: {
            componentDeleted: 'Component successfully deleted.',
            componentUpdated: 'Component successfully updated.',
            vingSaved: 'Successfully saved.',
        },
        error: {
            questionsComponent:
                'Errors have been detected within your question set. Please review.',
            saveComponent: 'The following fields have errors: ',
            ving: {
                save: 'Errors have been detected within the ving. Please review.',
                componentsNone: 'Must have at least 1 component.',
                componentsHasInvalid: 'One or more components have errors.',
            },
        },
    };
    questionErrors: QuestionErrors;
    selectedComponent: PacketComponent;
    show = {
        draftSave: 'button', // 'button', 'saving', 'saved'
        draftSaveLoadingIndicator: false,
        draftSuccessfullySaved: false,
        draftSaveButton: true,
        selectAnotherFile: false,
    };
    tempAwsRequests = {};
    tempAwsRequest: any;
    tempFile: File;
    tempFileName: string;
    tempFileSrc: any;
    tempFileData: any;
    tempId: null;
    validations: any;
    viewAccessOptions: Array<GroupOption> = [
        {
            value: 'public',
            text: 'Anyone',
        },
        {
            value: 'verified',
            text: 'Verified',
        },
        {
            value: 'invited',
            text: 'Assigned Only',
        },
    ];
    enforceOrderOptions: Array<GroupOption> = [
        {
            value: true,
            text: 'Yes',
        },
        {
            value: false,
            text: 'No',
        },
    ];
    vingHasNoComponentsError = false;
    allComponentsValidError = false;
    isJustAdded = false;
    displayedLinkUrl = '';

    // Ving
    id: number;
    status: string;
    title: string;
    summary: string;
    components: Array<PacketComponent> = [];
    labels: Array<string> = [];
    share = {
        id: 0,
        notify: true,
        stub: '',
        enforceOrder: false,
        privacy: this.viewAccessOptions[0].value, // View Access
    };
    clearSelectedQuestion = new Subject();
    clearSelectedDocument = new Subject();

    private lastVingJson: string;
    private urlProperties = [
        'audioUrl',
        'imageUrl',
        'videoUrl',
        'downloadUrl',
        'thumbnailUrl',
    ];
    private selectedComponentLastSavedJson: string;
    private urlPrefix = {
        http: 'http://',
        https: 'https://',
    };
    private processDialog: any;
    private selectedFiles: object = {};
    private createComponentsDialog: any;

    // Jobs
    private urlSyncRunning = false;
    private autoSaveEnabled = true;
    private isDetailsEditted: any;
    private currentPersistRequest: any;
    private saveTime = null;
    private filesProcessed = 0;
    private validFiles = 0;

    constructor(
        @Inject('location') private location: Location,
        public dialog: MatDialog,
        private appService: AppService,
        private appHelpersService: AppHelpersService,
        private appHttpService: AppHttpService,
        private appAlertService: AppAlertService,
        private appJobService: AppJobService,
        private appLoadingIndicatorService: AppLoadingIndicatorService,
        private appMessageService: AppMessageService,
        private appInfoService: AppInfoService,
        private questionsValidationService: QuestionsValidationService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        setTimeout(
            () => (this.appService.selected.navOption = 'create-ving'),
            0
        );
        const id = this.route.snapshot.paramMap.get('id');
        this.filesProcessed = 0;

        this.appLoadingIndicatorService.show('app', '');
        this.appHttpService.request(
            'GET:api/packets/' + id + '/',
            {},
            (response) => {
                // Check Published Ving
                if (response.status === 'published') {
                    this.dialog.open(AppDialogAcknowledgeComponent, {
                        width: '425px',
                        disableClose: true,
                        data: {
                            text: 'You cannot edit a ving after it has been published.',
                            label: 'Return',
                            callback: () => {
                                this.router.navigateByUrl('/vings/' + id);
                            },
                        },
                    });
                } else {
                    const appInfo = this.appInfoService.getAppInfo();
                    this.validations = appInfo.validationInfo;

                    // Initialize local Ving data.
                    this.initializeVing(response);

                    // Start Ving draft auto-save job.
                    this.appJobService.create(
                        'edit-ving-draft-save-job',
                        () => {
                            this.checkAndSaveDraft();
                        },
                        500
                    );

                    // Start component file url scan.
                    this.appJobService.create(
                        'edit-ving-url-sync',
                        () => {
                            this.checkComponentUrls();
                        },
                        3000
                    );

                    this.appJobService.create(
                        'edit-ving-is-valid-job',
                        () => {
                            if (this.selectedComponent) {
                                this.selectedComponent.isValid =
                                    this.isComponentValid(
                                        this.selectedComponent
                                    ) === true;
                            }
                        },
                        250
                    );
                }

                this.appLoadingIndicatorService.hide(500);
            },
            () => {
                this.router.navigate(['/dashboard'], {
                    queryParams: { error: 'gettingVing' },
                });
            }
        );
    }

    ngOnDestroy() {
        this.appJobService.kill('edit-ving-draft-save-job');
        this.appJobService.kill('edit-ving-url-sync');
        this.appJobService.kill('edit-ving-is-valid-job');

        this.clearAndCancelHttpForAllComponentFiles();
    }

    onBlurTextarea() {
        this.isJustAdded = false;
    }

    displayEditDetailsDialog(): void {
        const editDetailsDialog = this.dialog.open(DialogEditDetailsComponent, {
            width: '475px',
            height: '450px',
            disableClose: true,
            autoFocus: false,
            data: {
                title: this.title,
                summary: this.summary,
                privacy: this.share.privacy,
                enforceOrder: this.share.enforceOrder,
                validations: this.validations,
                viewAccessOptions: this.viewAccessOptions,
                enforceOrderOptions: this.enforceOrderOptions,
                callback: (data, dialog) => {
                    // Update details
                    ['title', 'summary'].forEach((key) => {
                        this[key] = data[key];
                    });
                    ['privacy', 'enforceOrder'].forEach((key) => {
                        this.share[key] = data[key];
                    });

                    dialog.close();
                },
            },
        });

        editDetailsDialog.afterClosed().subscribe(() => {
            this.isDetailsEditted = true;
        });
    }

    clearFileField(): void {
        // Clear input file
        this.selectFileField.nativeElement.value = '';
    }

    selectMultipleFiles(event: any): void {
        // Hide components error.
        this.vingHasNoComponentsError = false;
        this.allComponentsValidError = false;

        this.componentsIsFileOver = false;

        const files = this.getFilesArrayFromFileList(event.target.files),
            count = {
                filesProcessed: this.filesProcessed,
                validFiles: this.validFiles,
            };

        // Validate each dropped in file.
        files.forEach((file: File) => {
            // Create new ComponentFile object from the uploadFile.
            const componentFile = <ComponentFile>{},
                componentType =
                    this.appHelpersService.getComponentTypeByFile(file);

            componentFile.id = this.appHelpersService.generateUniqueId();
            componentFile.type = ComponentFileType[componentType];
            componentFile.name = file.name;
            componentFile.size = this.appHelpersService.bytesToSize(file.size);
            componentFile.status = ComponentFileStatus.uploading;
            componentFile.valid = this.isValidMultiSelectFile(file);

            this.componentFiles.push(componentFile);

            if (this.isValidMultiSelectFile(file)) {
                this.selectedFiles[componentFile.id] = file;

                this.validFiles++;
            }
            this.filesProcessed++;
        });

        // File upload process...
        const waitAndProcessFileUploads = () => {
            setTimeout(() => {
                if (files.length === this.filesProcessed) {
                    if (files.length !== this.validFiles) {
                        const wrongFiles = files.length - this.validFiles;
                        this.displayWrongFileNotification(wrongFiles);
                    }

                    this.processFileUploads();
                } else {
                    waitAndProcessFileUploads();
                }
            }, 250);
        };

        setTimeout(() => {
            waitAndProcessFileUploads();
        }, 1000);
    }

    componentFilesDropped(files: NgxFileDropEntry[]) {
        // Hide components error.
        this.vingHasNoComponentsError = false;
        this.allComponentsValidError = false;

        this.componentsIsFileOver = false;

        const count = {
            filesProcessed: this.filesProcessed,
            validFiles: this.validFiles,
        };

        // Validate each dropped in file.
        for (let droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    if (this.isValidFile(file)) {
                        // Create new ComponentFile object from the uploadFile.
                        const componentFile = <ComponentFile>{},
                            componentType =
                                this.appHelpersService.getComponentTypeByFile(
                                    file
                                );

                        componentFile.id =
                            this.appHelpersService.generateUniqueId();
                        componentFile.type = ComponentFileType[componentType];
                        componentFile.name = file.name;

                        fileEntry.file((f: File) => {
                            componentFile.size =
                                this.appHelpersService.bytesToSize(f.size);
                            componentFile.status =
                                ComponentFileStatus.uploading;
                            componentFile.valid = this.isValidFile(file);

                            this.componentFiles.push(componentFile);

                            if (this.isValidFile(file)) {
                                this.selectedFiles[componentFile.id] = f;
                                this.validFiles++;
                            }
                            this.filesProcessed++;
                        });
                    }
                });
            } else {
                const fileEntry =
                    droppedFile.fileEntry as FileSystemDirectoryEntry;
                droppedFile = null;
                this.componentsIsFileOver = false;
                this.appAlertService.set(
                    'view',
                    'error',
                    'Invalid file selected.',
                    false
                );
            }
        }
        // File upload process...
        const waitAndProcessFileUploads = () => {
            setTimeout(() => {
                if (this.componentFiles.length === this.filesProcessed) {
                    if (this.componentFiles.length !== this.validFiles) {
                        const wrongFiles =
                            this.componentFiles.length - this.validFiles;
                        this.displayWrongFileNotification(wrongFiles);
                    }
                    this.processFileUploads();
                } else {
                    waitAndProcessFileUploads();
                }
            }, 250);
        };

        setTimeout(() => {
            waitAndProcessFileUploads();
        }, 1000);
    }

    retryNewComponentFileUpload(componentFileId: string): void {
        this.processFileUpload(componentFileId);
    }

    createComponentsFromFiles(dialog: any) {
        if (this.shouldEnableCreateComponentsFromFiles()) {
            this.appLoadingIndicatorService.show(
                '#panels',
                'Creating...',
                () => {
                    const readyComponentFiles = this.getReadyComponentFiles(),
                        readyComponentFilesCount = readyComponentFiles.length;

                    this.autoSaveEnabled = false;
                    this.urlSyncRunning = true;

                    // Create the new component from file by adding the key
                    // within a new component to the end of the components list.
                    readyComponentFiles.forEach((readyComponentFile) => {
                        this.components.push({
                            isValid: true,
                            id: new Date().getTime().toString(),
                            title: '',
                            status: '',
                            type: '',
                            text: '',
                            fileKey: readyComponentFile.fileKey,
                        });
                    });

                    this.componentFiles = [];
                    this.selectedFiles = {};
                    this.isAddingNewComponents = true;
                    this.saveVingDraft('manual', readyComponentFilesCount);

                    // Close the dialog
                    dialog.close();

                    setTimeout(() => {
                        this.createComponentsDialog = undefined;
                    }, 300);
                }
            );
        }
    }

    removeComponentFromFilesList(id: string, dialog: any) {
        const componentFilesLength = this.componentFiles.length;

        // Delete the backing component file data.
        for (let i = 0; i < componentFilesLength; i++) {
            if (this.componentFiles[i].id === id) {
                this.componentFiles.splice(i, 1);
                break;
            }
        }

        // Kill the request.
        if (id in this.tempAwsRequests) {
            this.tempAwsRequests[id].unsubscribe();
        }

        if (this.componentFiles.length === 0) {
            this.filesProcessed = 0;
            this.validFiles = 0;
            dialog.close();

            setTimeout(() => {
                this.createComponentsDialog = undefined;
            }, 300);
        }
    }

    selectComponent(id: number, shouldValidate = true, premium = false) {
        if (!premium) {
            const newComponent = this.findComponentById(id);
            const setNewComponent = () => {
                if (
                    !this.selectedComponent ||
                    (this.selectedComponent && id !== this.selectedComponent.id)
                ) {
                    this.isJustAdded = false;
                }

                if (shouldValidate === true) {
                    this.validateAllComponents();
                }
                this.isAddingNewComponents = false;
                this.questionErrors = new QuestionErrors();
                this.clearSelectedQuestion.next();
                this.selectedComponent = newComponent;
                this.allowableFileExtensions =
                    this.appHelpersService.getFileExtensionsByType(
                        this.selectedComponent.type
                    );
                if (this.selectedComponent.type === 'link') {
                    this.displayedLinkUrl = this.selectedComponent.linkUrl;
                }
                if (this.selectedComponent.type === 'document') {
                    this.clearSelectedDocument.next(
                        this.selectedComponent.document
                    );
                }
                this.setComponentLastSavedJson();
                this.initComponentEditorClass(this.selectedComponent.type);
            };

            if (
                (this.tempFile || this.tempFileSrc) &&
                newComponent.type === 'audio'
            ) {
                this.dialog.open(AppDialogConfirmComponent, {
                    height: '165px',
                    width: '425px',
                    disableClose: true,
                    data: {
                        text: 'A new audio file has been selected or recorded. Continuing will lose this data. Proceed?',
                        callback: () => {
                            this.cancelFile();
                            setNewComponent();
                        },
                    },
                });
            } else {
                this.cancelFile();
                setNewComponent();
            }
        }
    }

    displayConfirmDeleteModal(id: number, locked: boolean) {
        // Prevent pass through event click of the parent component card.
        event.stopPropagation();

        if (!locked) {
            this.dialog.open(AppDialogConfirmComponent, {
                height: '165px',
                width: '425px',
                disableClose: true,
                data: {
                    text: 'Are you sure you want to delete this component?',
                    callback: () => {
                        this.deleteComponent(id);
                        this.appMessageService.show(
                            'app',
                            'success',
                            this.messages.success.componentDeleted,
                            3
                        );
                    },
                },
            });
        }
    }

    displayAddComponentView() {
        this.appLoadingIndicatorService.show('#panel-two');

        this.vingHasNoComponentsError = false;
        this.allComponentsValidError = false;
        this.selectedComponent = undefined;
        this.clearComponentLastSavedJson();
        this.isAddingNewComponents = true;

        this.appLoadingIndicatorService.hide();
    }

    addComponent(type: string, isSupported: boolean) {
        const addNewComponent = () => {
            this.appLoadingIndicatorService.show('app', 'Loading...');
            if (isSupported) {
                // Hide components error.
                this.vingHasNoComponentsError = false;
                this.allComponentsValidError = false;

                // Temporarily disable auto-save and url syncing.
                this.autoSaveEnabled = false;
                this.urlSyncRunning = true;

                const component = <PacketComponent>{};

                component.isValid = true;
                component.id = new Date().getTime().toString();
                component.title = '';
                component.text = '';
                component.type = type;

                // Set component-specific data property.
                switch (component.type) {
                    case 'questions':
                        component.questions = [];
                        break;
                }

                this.components.push(component);
                this.isAddingNewComponents = false;

                this.saveVingDraft('add-component');
                this.appLoadingIndicatorService.hide(300);
            }
        };

        if (type === 'record-audio') {
            getUserMedia({ audio: true }, (err, stream) => {
                if (
                    err &&
                    typeof err.name !== 'undefined' &&
                    err.name === 'NotSupportedError'
                ) {
                    this.addComponentButtons.map((button) => {
                        if (button.type === 'record-audio') {
                            button.isSupported = false;
                        }
                    });
                } else {
                    addNewComponent();
                }
            });
        } else {
            addNewComponent();
        }
    }

    validateAndSaveUrl(): void {
        if (this.appHelpersService.isValidUrl(this.displayedLinkUrl)) {
            // Check to see if the 'linkUrl' starts with http or https.
            if (!this.urlHasValidPrefix(this.displayedLinkUrl)) {
                this.selectedComponent.linkUrl =
                    this.urlPrefix.http + this.displayedLinkUrl;
            } else {
                this.selectedComponent.linkUrl = this.displayedLinkUrl;
            }
        } else {
            this.selectedComponent.linkUrl = '';
        }
    }

    displayLinkUrlInNewTab(): void {
        const linkUrl = this.displayedLinkUrl;

        // Test url. If valid url, open in new tab. Otherwise display error message.
        if (this.appHelpersService.isValidUrl(linkUrl)) {
            window.open(this.selectedComponent.linkUrl, '_blank');
        } else {
            this.appMessageService.show(
                'app',
                'error',
                'Please specify a valid URL.'
            );
        }
    }

    displayEditTextDialog(type: string): void {
        let editText: string;

        switch (type) {
            case 'component':
                editText = this.selectedComponent.text;
                break;
        }

        this.dialog.open(DialogUpdateTextComponent, {
            width: '475px',
            height: '200px',
            disableClose: true,
            data: {
                text: editText,
                validations: this.validations,
                callback: (updatedText: string, dialog) => {
                    switch (type) {
                        case 'component':
                            this.selectedComponent.text = updatedText;
                            break;
                    }

                    dialog.close();
                },
            },
        });
    }

    saveRecording(data): void {
        this.tempFile = data.audio;
        this.tempFileData = data.audioData;
        this.tempId = this.selectedComponent.id;

        if (this.tempFile) {
            setTimeout(() => {
                this.appLoadingIndicatorService.show(
                    '.content-audio-display .loader',
                    'Uploading...'
                );
                this.updateAudioComponent(() => {
                    this.checkComponentUrls();
                    this.appLoadingIndicatorService.hide(500);
                });
            }, 200);
        }
    }

    deleteAudio(): void {
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            disableClose: true,
            data: {
                text: 'Are you sure you want to delete this audio?',
                callback: () => {
                    this.autoSaveEnabled = false;

                    if (this.tempAwsRequest) {
                        this.tempAwsRequest.unsubscribe();
                        delete this.tempAwsRequest;
                    }

                    // Remove temp file
                    this.isJustAdded = true;
                    this.tempFileData = undefined;
                    this.tempFile = undefined;
                    this.tempFileSrc = undefined;
                    this.tempFileName = undefined;

                    const idx = this.components
                        .map((component) => component.id)
                        .indexOf(this.selectedComponent.id);

                    if (this.components[idx].audioUrl) {
                        this.components[idx].id = undefined;
                        this.components[idx].type = 'record-audio';
                        this.components[idx].audioUrl = undefined;
                        this.components[idx].status = undefined;
                        this.components[idx].imageUrl = undefined;
                        this.components[idx].thumbnailUrl = undefined;
                    }

                    this.updateComponent(() => {
                        this.saveVingDraft('auto');
                    });
                },
            },
        });
    }

    getAudioMode(): string {
        if (
            (!this.tempFileSrc &&
                this.shouldDisplayPreview('audio') &&
                !this.shouldDisplayAudioPreview()) ||
            (this.tempFileSrc && !this.shouldDisplayAudioPreview()) ||
            (this.shouldDisplayAudioPreview() &&
                !this.shouldDisplayAppContentPending()) ||
            this.tempFileData
        ) {
            return 'file';
        } else {
            return 'recording';
        }
    }

    saveVingDraft(type, newComponentsCount?: number) {
        if (
            this.currentPersistRequest &&
            this.currentPersistRequest.closed === false
        ) {
            this.currentPersistRequest.unsubscribe();
        }

        // Reset saveTime
        this.saveTime = null;

        // Temporarily disable url syncing.
        this.urlSyncRunning = true;

        if (type === 'manual') {
            this.appLoadingIndicatorService.show('view', 'Saving...');
        } else if (type === 'auto') {
            this.show.draftSave = 'saving';
        }

        const vingDraft = {
            id: this.id,
            status: this.status,
            title: this.title,
            summary: this.summary,
            components: this.appHelpersService.getClone(this.components),
            labels: this.labels,
            share: this.share,
        };

        // Prepare ving draft by:
        //  1: Removing all temp ids from components.
        this.prepVingDraft(vingDraft);

        // Special loading indicator.
        setTimeout(() => {
            this.currentPersistRequest = this.appHttpService.request(
                'PUT:api/packets/' + this.id + '/',
                vingDraft,
                (ving) => {
                    this.updateComponentsLive(ving.components);
                    if (type === 'auto' && this.selectedComponent) {
                        const updatedComponent = ving.components.find(
                            (c) => c.id === this.selectedComponent.id
                        );
                        if (updatedComponent) {
                            this.updateComponentLive(
                                this.selectedComponent,
                                updatedComponent
                            );
                        }
                    }
                    this.updateLastVingJson();

                    if (type === 'manual') {
                        this.appLoadingIndicatorService.hide(750);
                        this.appMessageService.show(
                            'app',
                            'success',
                            this.messages.success.vingSaved
                        );
                    } else if (type === 'auto') {
                        this.show.draftSave = 'saved';
                        setTimeout(() => {
                            this.show.draftSave = 'button';
                        }, 1750);
                    }

                    // Add new components to the local components data list.
                    if (newComponentsCount) {
                        const lastIndex = this.components.length - 1,
                            startNewIndex = lastIndex - newComponentsCount + 1;

                        for (let i = startNewIndex; i <= lastIndex; i++) {
                            this.components[i] = ving.components[i];
                        }

                        // Select the first of the newly added components.
                        const NewComponent = ving.components[startNewIndex];
                        this.selectComponent(
                            NewComponent.id,
                            false,
                            NewComponent.premium
                        );
                    }

                    // Init record audio view.
                    if (type === 'add-component') {
                        // Select the newly added component and clear the newly added component type.
                        const lastComponent =
                            ving.components[ving.components.length - 1];
                        this.selectComponent(
                            lastComponent.id,
                            false,
                            lastComponent.premium
                        );
                        this.isJustAdded = true;
                    }

                    // If there was an auto-save and there is dialog open, close it.
                    if (type === 'auto' && Boolean(this.processDialog)) {
                        this.closeAndClearProcessDialog();
                    }

                    // Re-enable auto-save and url syncing.
                    setTimeout(() => {
                        this.autoSaveEnabled = true;
                        this.urlSyncRunning = false;
                    }, 500);
                }
            );
        }, 10);
    }

    onNewFileKey(event): void {
        this.autoSaveEnabled = false;
        this.urlSyncRunning = true;
        this.processDialog = event.dialog;
        this.selectedComponent.fileKey = event.key;
        this.updateComponent(() => {
            this.saveVingDraft('auto');
        });
    }

    shouldDisplayUrlErrorMessage() {
        return (
            !this.isJustAdded &&
            !this.appHelpersService.isValidUrl(this.displayedLinkUrl)
        );
    }

    saveQuestionsChanges(event): void {
        this.autoSaveEnabled = false;
        this.urlSyncRunning = true;
        this.processDialog = event;
        this.updateComponent(() => {
            this.saveVingDraft('auto');
        });
    }

    shouldDisplayAppContentPending(): boolean {
        let shouldDisplay = false;

        switch (this.selectedComponent.type) {
            case 'image':
                shouldDisplay =
                    !this.tempFileSrc && !this.selectedComponent.imageUrl;
                break;
            case 'video':
                shouldDisplay =
                    !this.tempFileSrc && !this.shouldDisplayPreview('video');
                break;
            case 'audio':
                shouldDisplay = this.shouldDisplayAudioPending();
                break;
        }

        return shouldDisplay;
    }

    shouldDisplayAudioPending(): boolean {
        return this.selectedComponent.status === 'transcoding';
    }

    shouldDisplayAudioPreview(): boolean {
        let should = false;

        if (this.tempFileSrc) {
            const ext = this.appHelpersService.getFileExtensionByFileName(
                    this.tempFileName
                ),
                extByType =
                    this.appHelpersService.getViewerExtensionByType('audio');

            should = ext === extByType;
        }

        return should;
    }

    shouldDisplayOverlay(): boolean {
        return (
            Boolean(this.isAddingNewComponents) &&
            Boolean(this.componentFiles.length)
        );
    }

    shouldDisplayPreview(type: string): boolean {
        const getFileExtension = (selectedComponent, urlProperty) => {
            let ext = '';

            if (this.tempFileName) {
                ext = this.appHelpersService.getFileExtensionByFileName(
                    this.tempFileName
                );
            } else if (selectedComponent[urlProperty]) {
                ext = this.appHelpersService.getFileExtensionByFileName(
                    selectedComponent[urlProperty]
                );
            }

            return ext;
        };
        const extByType = this.appHelpersService.getViewerExtensionByType(type),
            urlProp = type + 'Url',
            sc = this.selectedComponent,
            fileExt = getFileExtension(sc, urlProp);

        return fileExt !== extByType
            ? false
            : (!sc.status && Boolean(sc[urlProp])) ||
                  sc.status === 'transcoding';
    }

    shouldDisplayTextError(): boolean {
        return (
            !this.isJustAdded &&
            this.selectedComponent.text.length <
                this.validations.component.text.min
        );
    }

    shouldDisplayTextLimit(): boolean {
        return (
            !this.isJustAdded &&
            this.selectedComponent.text.length ===
                this.validations.component.text.max
        );
    }

    getComponentClass(component: PacketComponent) {
        let componentClass = 'ving-component';

        // Check to see if premium is true
        if (component.premium) {
            componentClass += ' premium';
        }

        // Check to see if description is set.
        if (component.text && component.text.length) {
            componentClass += ' has-text';
        }

        // Check to see if the component is currently selected.
        if (
            this.selectedComponent &&
            this.selectedComponent.id === component.id
        ) {
            componentClass += ' selected';
        }

        // Check component validation by index. Should only highlight if there is a present
        // error with the component and the component isn't just selected and just added.
        if (component.isValid === false) {
            if (!this.selectedComponent) {
                componentClass += ' invalid';
            } else if (this.selectedComponent) {
                if (component.id === this.selectedComponent.id) {
                    if (!this.isJustAdded) {
                        componentClass += ' invalid';
                    }
                } else {
                    componentClass += ' invalid';
                }
            }
        }

        // Check component title validation
        if (this.validations) {
            if (
                (component.title.length <
                    this.validations.component.title.min ||
                    component.title.length >
                        this.validations.component.title.max) &&
                !this.isJustAdded
            ) {
                componentClass += ' invalid';
            }
        }

        return componentClass;
    }

    getEditDetailsClass(): string {
        if (
            (this.isDetailsEditted &&
                this.isVingTitleValid() &&
                this.isVingSummaryValid()) ||
            !this.isDetailsEditted
        ) {
            return 'light';
        } else {
            return 'red';
        }
    }

    drop(event: CdkDragDrop<Array<PacketComponent>, any>) {
        moveItemInArray(
            event.container.data,
            event.previousIndex,
            event.currentIndex
        );
    }

    publish(): void {
        // Disable auto save and url updates before publishing
        this.autoSaveEnabled = false;
        this.urlSyncRunning = true;

        this.isJustAdded = false;
        this.vingHasNoComponentsError = false;
        this.allComponentsValidError = false;

        if (this.isVingValid()) {
            this.appLoadingIndicatorService.show('app', 'Publishing...', () => {
                const ving = {
                    id: this.id,
                    status: this.status,
                    title: this.title,
                    summary: this.summary,
                    components: this.appHelpersService.getClone(
                        this.components
                    ),
                    labels: this.labels,
                    share: this.share,
                };

                // Build ving object.
                this.prepVingDraft(ving);
                ving.status = 'published';

                this.appHttpService.request(
                    'PUT:api/packets/' + this.id + '/',
                    ving,
                    (response) => {
                        // TODO - keep for toggling while in dev to test consumption.
                        // this.router.navigateByUrl('/view/' + response.share.stub);

                        // Route user to Vings area with packet id in the URL so that the user can view their newly published ving.
                        this.router.navigateByUrl('/vings/' + response.id);
                    }
                );
            });
        } else {
            this.appMessageService.show(
                'app',
                'error',
                'Cannot publish. Please review error messages to continue.'
            );
        }
    }

    delete(): void {
        this.dialog.open(AppDialogConfirmComponent, {
            height: '165px',
            width: '425px',
            disableClose: true,
            data: {
                text: 'Are you sure you want to delete this?',
                callback: () => {
                    this.isJustAdded = false;
                    this.vingHasNoComponentsError = false;
                    this.allComponentsValidError = false;

                    this.appLoadingIndicatorService.show(
                        'app',
                        'Deleting...',
                        () => {
                            this.appHttpService.request(
                                'DELETE:api/packets/' + this.id + '/',
                                {},
                                (response) => {
                                    this.router.navigateByUrl('/vings/');
                                }
                            );
                        }
                    );
                },
            },
        });
    }

    private isValidFile(file: File) {
        return file && this.appHelpersService.isValidComponentFile(file);
    }

    private updateAudioComponent(callback?: Function) {
        const index = this.components
                .map((component) => component.id)
                .indexOf(this.tempId),
            audioComponent = this.findComponentById(this.tempId),
            processUpdateComponent = (fileKey: string) => {
                this.components[index] = audioComponent;

                if (fileKey && fileKey.length) {
                    // Clear URL properties for local components and selected component data.
                    this.urlProperties.forEach((url) => {
                        audioComponent[url] = '';
                    });

                    this.components[index].fileKey = fileKey;
                }

                // If a processing dialog is not displayed, display the success message.
                if (this.processDialog === undefined) {
                    this.appMessageService.show(
                        'app',
                        'success',
                        this.messages.success.componentUpdated
                    );
                }
            };

        this.appLoadingIndicatorService.show('app', 'Uploading...');
        this.tempAwsRequest = this.appHttpService.apiAwsUploadFile(
            this.tempFile,
            (key) => {
                this.tempId = undefined;
                this.cancelFile(); // Clear temp selected file data.

                if (this.tempAwsRequest) {
                    delete this.tempAwsRequest;

                    processUpdateComponent(key);

                    if (this.getAudioMode() === 'recording') {
                        audioComponent.audioUrl = null;
                    }
                }

                this.appLoadingIndicatorService.hide();
                this.setComponentLastSavedJson();
                if (callback) {
                    callback();
                }
            },
            () => {
                this.appMessageService.show(
                    'modal',
                    'error',
                    AppMessage.get('awsUploadError'),
                    5
                );
            }
        );
    }

    private selectedComponentHasChanges(): boolean {
        let hasChanges = false;

        if (this.selectedComponent) {
            if (this.selectedComponent.type === 'questions') {
                hasChanges =
                    this.selectedComponentLastSavedJson !==
                    this.buildQuestionsComponentLastSavedJson();
            } else {
                const hasJsonChanges =
                        this.selectedComponentLastSavedJson !==
                        JSON.stringify(this.selectedComponent),
                    hasNewFile = Boolean(this.tempFile);

                hasChanges = hasJsonChanges || hasNewFile;
            }
        }

        return hasChanges;
    }

    private updateComponent(callback?: Function) {
        if (this.selectedComponentHasChanges()) {
            // For 'questions' components, clear previous question errors.
            if (this.selectedComponent.type === 'questions') {
                this.questionErrors = new QuestionErrors();
            }

            const index = this.components
                    .map((component) => component.id)
                    .indexOf(this.selectedComponent.id),
                processUpdateComponent = (fileKey?: string) => {
                    this.components[index] = this.selectedComponent;

                    if (fileKey && fileKey.length) {
                        // Clear URL properties for local components and selected component data.
                        this.urlProperties.forEach((url) => {
                            this.selectedComponent[url] = '';
                        });

                        this.components[index].fileKey = fileKey;
                    }

                    // If a processing dialog is not displayed, display the success message.
                    if (this.processDialog === undefined) {
                        this.appMessageService.show(
                            'app',
                            'success',
                            this.messages.success.componentUpdated
                        );
                    }
                };

            // Check to see if new file is present, if so go through the AWS file upload process.
            if (this.tempFile) {
                this.appLoadingIndicatorService.show('app', 'Uploading...');
                this.tempAwsRequest = this.appHttpService.apiAwsUploadFile(
                    this.tempFile,
                    (key) => {
                        this.cancelFile(); // Clear temp selected file data.

                        if (this.tempAwsRequest) {
                            delete this.tempAwsRequest;

                            processUpdateComponent(key);

                            // Set the file tab as selected.
                            if (this.selectedComponent.type === 'audio') {
                                if (this.getAudioMode() === 'recording') {
                                    this.selectedComponent.audioUrl = null;
                                }
                            }
                        }

                        this.appLoadingIndicatorService.hide();
                        this.setComponentLastSavedJson();
                        if (callback) {
                            callback();
                        }
                    },
                    () => {
                        this.appMessageService.show(
                            'modal',
                            'error',
                            AppMessage.get('awsUploadError'),
                            5
                        );
                    }
                );
            } else {
                processUpdateComponent();
                this.setComponentLastSavedJson();
                if (callback) {
                    callback();
                }
            }
        }
    }

    private cancelFile() {
        this.show.selectAnotherFile = false;
        this.tempFile = undefined;
        this.tempFileSrc = undefined;
        this.tempFileName = undefined;
    }

    private urlHasValidPrefix(url: string): boolean {
        return (
            url &&
            (url.startsWith(this.urlPrefix.http) ||
                url.startsWith(this.urlPrefix.https))
        );
    }

    private validateAllComponents() {
        this.components.forEach((component) => {
            component.isValid = this.isComponentValid(component) === true;
        });
    }

    private initComponentEditorClass(type: string): void {
        setTimeout(() => {
            $('.component-editor').attr('component-type', type);
        }, 250);
    }

    private clearAndCancelHttpForAllComponentFiles() {
        // Clear out component files list.
        this.componentFiles.length = 0;

        // Kill any requests for uploading.
        Object.keys(this.tempAwsRequests).forEach((id) => {
            this.tempAwsRequests[id].unsubscribe();
            delete this.tempAwsRequests[id];
        });

        // Default the aws request list.
        this.tempAwsRequests = {};
    }

    // private isValidUploadFile(uploadFile: NgxFileDropEntry) {
    //     // uploadFile.fileEntry.isFile - for all browsers except to Safari and IE
    //     // uploadFile.fileEntry.resultFile - for IE and Safari
    //     return (uploadFile.fileEntry.isFile || uploadFile.fileEntry.resultFile) &&
    //             this.appHelpersService.isValidComponentFile(uploadFile.fileEntry);
    // }

    private isValidMultiSelectFile(file: File) {
        return file && this.appHelpersService.isValidComponentFile(file);
    }

    private checkComponentUrls() {
        // If the URL sync is already running, exit function.
        if (this.urlSyncRunning) {
            return;
        }

        // Check to see if any of the URLs are empty. If so, identify them
        // and do a get call to see if they've been updated. When updated
        // update the local data objects for them (both the components and
        // selected component data).
        const componentIds = [];

        // Scan local component data.
        this.components.forEach((component) => {
            if (typeof component.id === 'number') {
                if (this.isComponentProcessing(component)) {
                    if (componentIds.indexOf(component.id) < 0) {
                        componentIds.push(component.id);
                    }
                }
            }
        });

        // If component ids list contains any items, there are components that
        // do not have their final processed URLs yet. In turn, we'll kick off
        // an HTTP call to retrieve the latest components and update the URLs.
        if (componentIds.length) {
            // Disable auto-saving during this time.
            this.autoSaveEnabled = false;
            this.urlSyncRunning = true;

            this.appHttpService.request(
                'GET:api/packets/' + this.id + '/',
                {},
                (ving) => {
                    componentIds.forEach((id) => {
                        const updatedComponent = ving.components.find(
                                (c) => c.id === id
                            ),
                            existingComponentIndex =
                                this.appHelpersService.findIndexByProperty(
                                    this.components,
                                    'id',
                                    id
                                );

                        if (
                            updatedComponent.status !== 'transcoding' ||
                            updatedComponent.status !== 'converting'
                        ) {
                            // If the status has changed, update the local data
                            // in list and the selected component.
                            this.updateComponentLive(
                                this.components[existingComponentIndex],
                                updatedComponent
                            );
                            if (
                                updatedComponent.id ===
                                this.selectedComponent.id
                            ) {
                                this.updateComponentLive(
                                    this.selectedComponent,
                                    updatedComponent
                                );
                            }
                        }
                    });

                    // Re-enable auto-saving now that URL sync work is done.
                    this.autoSaveEnabled = true;
                    this.urlSyncRunning = false;
                },
                () => {
                    // Re-enable auto-saving now that URL sync work is done.
                    console.error(
                        'ERROR :: problem performing GET:api/packets/ for URL sync.'
                    );
                    this.autoSaveEnabled = true;
                    this.urlSyncRunning = false;
                }
            );
        }
    }

    private isComponentProcessing(component: PacketComponent): boolean {
        let isProcessing = false;

        if (
            component.status === 'transcoding' &&
            (component.type === 'video' || component.type === 'audio')
        ) {
            isProcessing = true;
        } else if (
            component.type === 'document' &&
            component.status === 'converting'
        ) {
            isProcessing = true;
        }

        return isProcessing;
    }

    private updateComponentsLive(components): void {
        // Update ids.
        const componentsLength = components.length;
        for (let i = 0; i < componentsLength; i++) {
            if (typeof this.components[i].id !== 'number') {
                delete this.components[i]['id'];
                this.components[i]['id'] = components[i].id;
            }
        }

        // Update all components.
        components.forEach((updatedComponent) => {
            const existingComponent = this.components.find(
                (c) => c.id === updatedComponent.id
            );
            if (existingComponent && updatedComponent) {
                this.updateComponentLive(existingComponent, updatedComponent);
            }
        });
    }

    private updateChoiceLive(existingChoice, updatedChoice): void {
        // Update all properties of the selected question object.
        try {
            Object.keys(updatedChoice).forEach((key) => {
                if (['text', 'correct'].indexOf(key) < 0) {
                    existingChoice[key] = updatedChoice[key];
                }
            });
        } catch (error) {
            console.log('- Error syncing component -----');
            console.log(error);
        }
    }

    private updateQuestionLive(existingQuestion, updatedQuestion): void {
        // Update all properties of the selected question object.
        try {
            Object.keys(updatedQuestion).forEach((key) => {
                if (['text'].indexOf(key) < 0) {
                    if (key === 'choices') {
                        // Keep the order
                        for (
                            let i = 0;
                            i < updatedQuestion['choices'].length;
                            i++
                        ) {
                            this.updateChoiceLive(
                                existingQuestion['choices'][i],
                                updatedQuestion['choices'][i]
                            );
                        }
                    } else {
                        existingQuestion[key] = updatedQuestion[key];
                    }
                }
            });
        } catch (error) {
            console.log('- Error syncing component -----');
            console.log(error);
        }
    }

    private updateQuestionsLive(existingComponent, questions): void {
        // Update ids.
        const questionsLength = questions.length;
        for (let i = 0; i < questionsLength; i++) {
            if (existingComponent['questions'][i].id === existingComponent['questions'][i].clientId) {
                delete existingComponent['questions'][i]['id'];
                existingComponent['questions'][i]['id'] = questions[i].id;
            }
        }

        // Update all questions
        questions.forEach((updatedQuestion) => {
            const existingQuestion = existingComponent['questions'].find(
                (q) => q.id === updatedQuestion.id
            );
            if (existingQuestion && updatedQuestion) {
                this.updateQuestionLive(existingQuestion, updatedQuestion);
            }
        });
    }

    private updateComponentLive(existingComponent, updatedComponent): void {
        let hasNewDocumentUrl = false;

        // Check to see if there was a new document url change.
        if (updatedComponent.type === 'document') {
            if ('document' in existingComponent) {
                hasNewDocumentUrl =
                    existingComponent.document.url !==
                    updatedComponent.document.url;
            }
        }

        // Update all properties of the selected component object.
        try {
            Object.keys(updatedComponent).forEach((key) => {
                // Ensuring that any 'title' or 'text' changes that are in progress are not lost.
                if (['title', 'text', 'linkUrl'].indexOf(key) < 0) {
                    if (
                        existingComponent.type === 'questions' &&
                        key === 'questions'
                    ) {
                        this.updateQuestionsLive(existingComponent, updatedComponent['questions']);
                    } else if (typeof existingComponent[key] === 'object') {
                        existingComponent[key] =
                            this.appHelpersService.getClone(
                                updatedComponent[key]
                            );
                    } else {
                        existingComponent[key] = updatedComponent[key];
                    }
                }
            });
        } catch (error) {
            console.log('- Error syncing component -----');
            console.log(error);
        }

        delete existingComponent.fileKey;

        // If there is a new document, send it into the document component.
        if (
            updatedComponent.type === 'document' &&
            hasNewDocumentUrl === true
        ) {
            this.clearSelectedDocument.next(existingComponent.document);
        }

        this.setComponentLastSavedJson();
    }

    private prepVingDraft(vingDraft): void {
        const componentsLength = vingDraft.components.length;

        for (let i = 0; i < componentsLength; i++) {
            // 1.) Delete temp IDs.
            if (typeof vingDraft.components[i].id === 'string') {
                delete vingDraft.components[i].id;
            }
        }
    }

    private isVingValid(): boolean {
        let isValid = true;

        // Reset all previous errors.
        this.vingHasNoComponentsError = false;
        this.allComponentsValidError = false;

        // Validate title.
        if (!this.isVingTitleValid()) {
            isValid = false;
        }

        // Validate components.
        if (this.components.length === 0) {
            isValid = false;
            this.vingHasNoComponentsError = true;
        }

        // Check that all components are valid.
        const allComponentsValid = this.areAllComponentsValid();
        if (!allComponentsValid) {
            isValid = false;
            this.allComponentsValidError = true;
        }

        // If all components valid, hide components error message.
        if (allComponentsValid) {
            this.allComponentsValidError = false;
        }

        return isValid;
    }

    private areAllComponentsValid(): boolean {
        const componentsLength = this.components.length;
        let areValid = true;

        for (let i = 0; i < componentsLength; i++) {
            if (this.components[i].isValid === false) {
                areValid = false;
                break;
            }
            // Check component title validation
            if (
                this.components[i].title.length <
                    this.validations.component.title.min ||
                this.components[i].title.length >
                    this.validations.component.title.max
            ) {
                areValid = false;
                break;
            }
        }

        return areValid;
    }

    private isComponentTitleValid(): boolean {
        return (
            this.isJustAdded ||
            (this.selectedComponent.title.length >=
                this.validations.component.title.min &&
                this.selectedComponent.title.length <=
                    this.validations.component.title.max)
        );
    }

    private isVingTitleValid(): boolean {
        return (
            this.title &&
            this.title.length >= this.validations.packet.title.min &&
            this.title.length <= this.validations.packet.title.max
        );
    }

    private isVingSummaryValid(): boolean {
        return (
            this.summary &&
            this.summary.length >= this.validations.packet.summary.min &&
            this.summary.length <= this.validations.packet.summary.max
        );
    }

    private updateLastVingJson() {
        this.lastVingJson = this.getVingJson();
    }

    private checkAndSaveDraft() {
        if (this.lastVingJson !== this.getVingJson()) {
            // Save changes in 2 seconds from now
            this.saveTime = Date.now() + 2000;
            this.lastVingJson = this.getVingJson();
        }

        if (this.shouldSave() && this.autoSaveEnabled) {
            this.saveVingDraft('auto');
        }
    }

    private shouldSave() {
        if (this.saveTime && this.saveTime <= Date.now()) {
            return true;
        } else {
            return false;
        }
    }

    private getVingJson() {
        return JSON.stringify({
            id: this.id,
            status: this.status,
            title: this.title,
            summary: this.summary,
            components: this.components,
            labels: this.labels,
            share: this.share,
        });
    }

    private initializeVing(ving) {
        // Init base properties.
        ['id', 'status', 'title', 'summary', 'components', 'labels'].forEach(
            (key) => {
                this[key] = ving[key];
            }
        );

        // Check and init the 'View Access' (share.privacy).
        this.share = ving.share;

        // Initialize valid state of each component.
        this.validateAllComponents();

        this.updateLastVingJson();

        // Open details dialog if ving is new
        if (!this.title) {
            this.displayEditDetailsDialog();
        }
    }

    private clearComponentLastSavedJson(): void {
        this.selectedComponentLastSavedJson = '';
    }

    private setComponentLastSavedJson(): void {
        if (this.selectedComponent) {
            if (this.selectedComponent.type === 'questions') {
                this.selectedComponentLastSavedJson =
                    this.buildQuestionsComponentLastSavedJson();
            } else {
                this.selectedComponentLastSavedJson = JSON.stringify(
                    this.selectedComponent
                );
            }
        }
    }

    private buildQuestionsComponentLastSavedJson(): string {
        // console.log("buildQuestionsComponentLastSavedJson")
        const questionComponent = {
            title: this.selectedComponent.title,
            text: this.selectedComponent.text,
            questions: [],
        };

        this.selectedComponent.questions.forEach((question) => {
            const questionObject = {
                type: question.type,
                text: question.text,
                imageUrl: question.imageUrl,
                image: question.image,
                choices: [],
            };

            question.choices.forEach((choice) => {
                questionObject.choices.push({
                    text: choice.text,
                    correct: choice.correct,
                    imageUrl: choice.imageUrl,
                    image: choice.image,
                });
            });

            questionComponent.questions.push(questionObject);
        });

        // console.log(JSON.stringify(questionComponent));
        return JSON.stringify(questionComponent);
    }

    private isComponentValid(component): any {
        const invalidProperties = [];

        // Confirm that 'title' is present.
        if (component.title.trim().length === 0) {
            invalidProperties.push('Title');
        }

        // Type specific validation.
        if (component.type === 'text' && component.text.trim().length === 0) {
            invalidProperties.push('Text');
        }

        // Validate 'audio' component.
        if (component.type === 'audio' && !component.audioUrl) {
            invalidProperties.push('Audio');
        }

        // Validate 'questions' component.
        if (component.type === 'questions') {
            if (this.getQuestionErrors(component).hasErrors()) {
                invalidProperties.push('Questions');
            }
        }

        // Validate 'link' component.
        if (
            component.type === 'link' &&
            !this.appHelpersService.isValidUrl(component.linkUrl)
        ) {
            invalidProperties.push('URL');
        }

        return invalidProperties.length === 0 ? true : invalidProperties;
    }

    private getQuestionErrors(component): QuestionErrors {
        const questionErrors =
            this.questionsValidationService.validateQuestions(
                component.questions
            );

        if (questionErrors.hasErrors()) {
            this.questionErrors = questionErrors;
        }

        return questionErrors;
    }

    private findComponentById(id: number) {
        return this.components.find((component) => component.id === id);
    }

    private deleteComponent(id: number) {
        // Delete the component from the components list.
        const index = this.components
            .map((component) => component.id)
            .indexOf(id);
        this.components.splice(index, 1);

        // If selected, clear the selected component object.
        if (this.selectedComponent && id === this.selectedComponent.id) {
            this.displayAddComponentView();
        }
    }

    private getFilesArrayFromFileList(filesObject: FileList) {
        const files: Array<File> = [];

        Object.keys(filesObject).forEach((key) => {
            files.push(filesObject[key]);
        });

        return files;
    }

    private processFileUploads() {
        const componentFileIds = Object.keys(this.selectedFiles);

        componentFileIds.forEach((id) => {
            this.processFileUpload(id);
        });

        if (this.createComponentsDialog === undefined) {
            this.displayCreateComponentsDialog();
        }
    }

    private processFileUpload(id: string) {
        const componentFile = this.componentFiles.find((cf) => cf.id === id);

        if (componentFile) {
            componentFile.status = ComponentFileStatus.uploading;

            this.tempAwsRequests[id] = this.appHttpService.apiAwsUploadFile(
                this.selectedFiles[id],
                (key) => {
                    delete this.selectedFiles[id];
                    delete this.tempAwsRequests[id];
                    componentFile.fileKey = key;
                    componentFile.status = ComponentFileStatus.ready;
                },
                () => {
                    componentFile.status = ComponentFileStatus.error;
                }
            );
        }
    }

    private closeAndClearProcessDialog(): void {
        setTimeout(() => {
            this.processDialog.close();

            setTimeout(() => {
                this.processDialog = undefined;
            }, 500);
        }, 1000);
    }

    private shouldEnableCreateComponentsFromFiles(): boolean {
        const atLeastOneComponentFile = this.componentFiles.length > 0,
            atLeastOneComponentReady = this.getReadyComponentFiles().length > 0,
            allComponentsReadyOrError = this.componentFiles.every(
                (cf) =>
                    cf.status === ComponentFileStatus.ready ||
                    cf.status === ComponentFileStatus.error ||
                    !cf.valid
            );

        return (
            atLeastOneComponentFile &&
            atLeastOneComponentReady &&
            allComponentsReadyOrError
        );
    }

    private getReadyComponentFiles(): Array<ComponentFile> {
        return this.componentFiles.filter(
            (cf) => cf.status === ComponentFileStatus.ready
        );
    }

    private displayWrongFileNotification(num = 0): void {
        if (num > 0) {
            this.dialog.open(AppDialogAcknowledgeComponent, {
                width: '425px',
                disableClose: true,
                data: {
                    text: `${num} ${pluralize('file', num)} ${pluralize(
                        'was',
                        num
                    )} discarded because of missing or invalid extension.`,
                    label: 'OK',
                },
            });
        }
    }

    private displayCreateComponentsDialog(): void {
        if (this.componentFiles.length) {
            this.clearFileField();

            this.createComponentsDialog = this.dialog.open(
                DialogCreateComponentsComponent,
                {
                    width: '500px',
                    height: '450px',
                    disableClose: true,
                    autoFocus: false,
                    data: {
                        icons: this.icons,
                        componentFiles: this.componentFiles,
                        dropFiles: (event) => {
                            // Drag & Drop
                            this.componentFilesDropped(event);
                        },
                        selectFiles: (event) => {
                            // Select files from file field
                            this.selectMultipleFiles(event);
                        },
                        createComponents: (dialog) => {
                            this.filesProcessed = 0;
                            this.validFiles = 0;
                            this.createComponentsFromFiles(dialog);
                        },
                        retryNewComponent: (id) => {
                            this.retryNewComponentFileUpload(id);
                        },
                        removeComponent: (id, dialog) => {
                            this.removeComponentFromFilesList(id, dialog);
                        },
                        cancelCreateComponents: (dialog) => {
                            // Default the adding new components state.
                            this.isAddingNewComponents = true;
                            this.filesProcessed = 0;
                            this.validFiles = 0;
                            this.clearAndCancelHttpForAllComponentFiles();

                            setTimeout(() => {
                                this.createComponentsDialog = undefined;
                            }, 300);
                        },
                    },
                }
            );
        }
    }
}
