import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable()
export class AppMessageService {
    private fadeInInterval = 250;
    private fadeOutInterval = 350;
    private defaultDelay = 3.0; // seconds
    private defaultLocations = ['app', 'view', 'modal']; // possible values: 'app', 'view', 'modal', <any selector>
    private html = {
        loadingIndicator: `
            <div id="app-message" class="{{css}}" location="{{location}}">
                <span>{{text}}</span>
            </div>
        `,
    };

    isShown() {
        return $('#app-message').length > 0;
    }

    show(
        location: string,
        type: string,
        text: string,
        delay: number = this.defaultDelay
    ) {
        if (!this.isShown()) {
            // Initialize html and set text.
            let html = this.html.loadingIndicator;

            html = html.replace('{{text}}', text.toString());
            html = html.replace('{{css}}', type.toString());

            // Check location to confirm if default or selector.
            if (this.defaultLocations.indexOf(location.toString()) >= 0) {
                // Using pre-set location.
                html = html.replace('{{location}}', location.toString());
                /*$('#' + location).append(html);*/

                if (location === 'modal') {
                    // modal
                    $('.app-dialog').append(html);
                } else {
                    // app, view
                    $('#' + location).append(html);
                }
            } else {
                const element = $(location);
                html = html.replace('{{location}}', '');

                // Using any selector.
                if (element.length > 0) {
                    element.append(html);
                } else {
                    console.log('ERROR :: Invalid element selector provided.');
                }
            }

            const appMessage = $('#app-message'),
                delayInterval = delay * 1000,
                waitToHideInterval =
                    this.fadeInInterval + delayInterval + this.fadeOutInterval;

            appMessage
                .fadeIn(this.fadeInInterval)
                .delay(delayInterval)
                .fadeOut(this.fadeOutInterval);
            setTimeout(function () {
                appMessage.remove();
            }, waitToHideInterval);
        } else {
            console.log(
                'WARNING :: Message already being displayed on the page.'
            );
        }
    }
}
