import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MomentModule } from 'ngx-moment';
import { AngularSplitModule } from 'angular-split';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';

import { AppAlertComponent } from '../components/app-alert/app.alert';
import { AppHeaderComponent } from '../components/app-header/app.header';
import { AppCheckboxComponent } from '../components/controls/app-checkbox/app-checkbox.component';
import { AppRadioComponent } from '../components/controls/app-radio/app-radio.component';
import { AppTextareaComponent } from '../components/controls/app-textarea/app-textarea.component';
import { AppGroupToggleComponent } from '../components/app-group-toggle/app.group-toggle.component';
import { AppSelectComponent } from '../components/app-select/app.select.component';
import { AppSelectMultiComponent } from '../components/app-select-multi/app.select.multi.component';
import { AppFormFieldComponent } from '../components/app-form-field/app.form.field';
import { AppFormCheckboxComponent } from '../components/app-form-checkbox/app.form.checkbox';
import { AppMultiSelectComponent } from '../components/app-multi-select/app.multi-select.component';
import { AppListFieldComponent } from '../components/app-list-field/app.list-field.component';
import { AppToggleComponent } from '../components/app-toggle/app.toggle.component';
import { AppRadioboxComponent } from '../components/app-radiobox/app.radiobox.component';
import { AppDialogConfirmComponent } from '../dialogs/dialog-confirm/app.dialog-confirm.component';
import { AppDialogAcknowledgeComponent } from '../dialogs/dialog-acknowledge/app.dialog-acknowledge.component';
import { AppDialogNewItemComponent } from '../dialogs/dialog-new-item/app.dialog-new-item.component';
import { AppDialogVerificationComponent } from '../../modules/accounts/dialogs/dialog-verification/app.dialog-verification.component';
import { AppDialogImageComponent } from '../dialogs/dialog-image/app.dialog-image.component';
import { AppDialogLightBoxComponent } from '../dialogs/dialog-light-box/app.dialog-light-box.component';
import { AppDialogBrowserCompatibilityComponent } from '../dialogs/dialog-browser-compatibility/app.dialog-browser-compatibility.component';
import { AppDialogChangeOwnerComponent } from '../dialogs/dialog-change-owner/app.dialog-change-owner.component';
import { AppDialogExportDataComponent } from '../dialogs/dialog-export-data/app.dialog-export-data.component';
import { AppXTableComponent } from '../components/app-power-table/app.x.table.component';

import { AppPipesModule } from './app.pipes.module';
import { AppDocumentModule } from './app.document.module';
import { AppImageCropperModule } from './app.image.cropper.module';
import { AppMaterialModule } from '../../material-custom/material-custom.module';

import { AlphanumericDirective } from '../directives/alphanumeric.directive';
import { PasswordValidator } from '../validators/password.validator';
import { AppDialogResetPasswordComponent } from '../../modules/accounts/dialogs/dialog-reset-password/app.dialog-reset-password.component';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { AppDialogUnassignComponent } from '../../modules/vings/dialogs/dialog-unassign/app.dialog-unassign.component';
import { AppSelect2Component } from '../components/app-select-2/app.select2.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DialogSendInvitationComponent } from '../dialogs/dialog-send-invitation/dialog.send.invitation.component';
import { AppComponentButtonComponent } from '../components/app-component-button/app.component.button.component';
import { AppDialogResolveComponent } from '../../modules/checklists/dialogs/dialog-resolve/app.dialog-resolve.component';
import {DragDropModule} from "@angular/cdk/drag-drop";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AppMaterialModule,
        AngularSplitModule,
        NgxFileDropModule,
        AppPipesModule,
        AppDocumentModule,
        AppImageCropperModule,
        MomentModule,
        UiSwitchModule,
        PinchZoomModule,
        NgSelectModule,
        DragDropModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        AppAlertComponent,
        AppHeaderComponent,
        AppCheckboxComponent,
        AppRadioComponent,
        AppTextareaComponent,
        AppGroupToggleComponent,
        AppSelectComponent,
        AppSelect2Component,
        AppSelectMultiComponent,
        AppFormFieldComponent,
        AppFormCheckboxComponent,
        AppMultiSelectComponent,
        AppListFieldComponent,
        AppToggleComponent,
        AppRadioboxComponent,
        AppDialogConfirmComponent,
        AppDialogAcknowledgeComponent,
        AppDialogNewItemComponent,
        AppDialogVerificationComponent,
        AppDialogImageComponent,
        AppDialogLightBoxComponent,
        AppDialogBrowserCompatibilityComponent,
        AppDialogChangeOwnerComponent,
        AppDialogExportDataComponent,
        AppDialogResetPasswordComponent,
        AppDialogUnassignComponent,
        AppDialogResolveComponent,
        AppXTableComponent,
        DialogSendInvitationComponent,

        AlphanumericDirective,
        PasswordValidator,
        AppComponentButtonComponent,
    ],
    exports: [
        /* Modules */
        CommonModule,
        FormsModule,
        AppMaterialModule,
        NgxFileDropModule,
        AppDocumentModule,
        AppImageCropperModule,
        MomentModule,
        UiSwitchModule,

        /* Components */
        AppAlertComponent,
        AppHeaderComponent,
        AppCheckboxComponent,
        AppRadioComponent,
        AppTextareaComponent,
        AppGroupToggleComponent,
        AppSelectComponent,
        AppSelectMultiComponent,
        AppFormFieldComponent,
        AppFormCheckboxComponent,
        AppMultiSelectComponent,
        AppListFieldComponent,
        AppToggleComponent,
        AppRadioboxComponent,
        AppDialogConfirmComponent,
        AppDialogAcknowledgeComponent,
        AppDialogNewItemComponent,
        AppDialogVerificationComponent,
        AppDialogImageComponent,
        AppDialogLightBoxComponent,
        AppDialogBrowserCompatibilityComponent,
        AppDialogChangeOwnerComponent,
        AppDialogExportDataComponent,
        AppDialogResetPasswordComponent,
        AppDialogUnassignComponent,
        AppDialogResolveComponent,
        AppXTableComponent,
        AppComponentButtonComponent,

        /* Directives */
        AlphanumericDirective,
        PasswordValidator,
        AppSelect2Component,
        AppComponentButtonComponent,
    ],
    entryComponents: [
        AppDialogConfirmComponent,
        AppDialogAcknowledgeComponent,
        AppDialogNewItemComponent,
        AppDialogVerificationComponent,
        AppDialogImageComponent,
        AppDialogLightBoxComponent,
        AppDialogBrowserCompatibilityComponent,
        AppDialogChangeOwnerComponent,
        AppDialogExportDataComponent,
        AppDialogResetPasswordComponent,
        AppDialogUnassignComponent,
        AppDialogResolveComponent,
    ],
})
export class AppControlsModule {}
