import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './dialog.update-text.component.html',
    styleUrls: ['./dialog.update-text.component.less'],
})
export class DialogUpdateTextComponent implements OnInit {
    text: string;
    validations: any = {};

    constructor(
        public dialogRef: MatDialogRef<DialogUpdateTextComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            text: any;
            validations: any;
            callback: Function;
        }
    ) {}

    ngOnInit() {
        this.text = this.data.text;
        this.validations = this.data.validations;
    }

    save() {
        this.data.callback(this.text, this.dialogRef);
    }
}
