import { Choice } from '../questions/choice';
import { QuestionType } from '../questions/question-type';
import { Image } from '../images/image';

export class Question {
    id: number; // string: new, client-side generated id; number: proper server side id
    clientId: number;
    type: QuestionType;
    friendlyType: string;
    helpText: string;
    text: string;
    choices: Array<Choice> = [];
    image?: Image;
    imageUrl?: string;
    scored?: boolean;
    answer?: any;
    answerIsCorrect?: boolean;
    flag?: boolean;
    title?: string;
    required?: boolean;
    hasUnresolvedFlags?: boolean;
    isValid: boolean;

    constructor(
        id: number,
        type: QuestionType,
        text: string,
        friendlyType?: string,
        helpText?: string,
        choices?: Array<Choice>,
        image?: Image,
        imageUrl?: string,
        flag?: boolean,
        scored?: boolean,
        required?: boolean,
        hasUnresolvedFlags?: boolean
    ) {
        if (id === null) {
            this.clientId = Math.floor(Math.random() * 100000 + 1);
        }
        this.id = id === null ? this.clientId : id;
        this.type = type;
        this.text = text ? text : '';
        this.flag = flag ? flag : false;
        this.scored = scored ? scored : false;
        this.required = required;
        this.hasUnresolvedFlags = hasUnresolvedFlags;
        this.initQuestionByType(type);
    }

    private initQuestionByType(type: QuestionType) {
        if (!this.choices.length) {
            switch (type) {
                case QuestionType.trueFalse:
                    this.choices.push(new Choice('True'));
                    this.choices.push(new Choice('False'));
                    break;
                case QuestionType.yesNo:
                    this.choices.push(new Choice('Yes'));
                    this.choices.push(new Choice('No'));
                    break;
                case QuestionType.likert:
                    this.choices.push(new Choice('Strongly Agree'));
                    this.choices.push(new Choice('Agree'));
                    this.choices.push(new Choice('Neutral'));
                    this.choices.push(new Choice('Disagree'));
                    this.choices.push(new Choice('Strongly Disagree'));
                    break;
                case QuestionType.multipleChoice:
                case QuestionType.multipleResponse:
                    this.choices.push(new Choice(''));
                    this.choices.push(new Choice(''));
                    break;
            }
        }
    }
}
