<div
    class="app-form-field {{fieldCss}} {{fieldMarginSize}} {{hasError ? 'error' : ''}}"
>
    <!--Label-->
    <div class="form-field-label" *ngIf="fieldLabel">
        {{fieldLabel}}
        <span class="field-required" *ngIf="fieldIsRequired">*</span>
    </div>

    <!--Control-->
    <div class="form-field-control">
        <!--Text-->
        <input
            *ngIf="type === 'text'"
            type="text"
            class="form-field"
            [(ngModel)]="value"
            [name]="fieldName"
            [minlength]="fieldMin"
            [maxlength]="fieldMax"
            [placeholder]="fieldPlaceholder"
            (focus)="onFocus($event)"
            (keyup)="onKeyup($event)"
            (blur)="onTouched($event)"
            [required]="fieldIsRequired"
            [disabled]="fieldIsDisabled"
        />

        <!--Password-->
        <input
            *ngIf="type === 'password'"
            type="password"
            class="form-field"
            [(ngModel)]="value"
            [name]="fieldName"
            [minlength]="fieldMin"
            [maxlength]="fieldMax"
            [placeholder]="fieldPlaceholder"
            (focus)="onFocus($event)"
            (keyup)="onKeyup($event)"
            (blur)="onTouched($event)"
            [required]="fieldIsRequired"
            [disabled]="fieldIsDisabled"
        />

        <!--Textarea-->
        <textarea
            *ngIf="type === 'textarea'"
            class="form-field textarea"
            [(ngModel)]="value"
            [name]="fieldName"
            [minlength]="fieldMin"
            [maxlength]="fieldMax"
            [placeholder]="fieldPlaceholder"
            (focus)="onFocus($event)"
            (keyup)="onKeyup($event)"
            (blur)="onTouched($event)"
            [required]="fieldIsRequired"
            [disabled]="fieldIsDisabled"
        >
        </textarea>

        <!--Select-->
        <select
            *ngIf="type === 'select'"
            class="form-field select"
            [(ngModel)]="value"
            [name]="fieldName"
            (blur)="onTouched($event)"
            [required]="fieldIsRequired"
            [disabled]="fieldIsDisabled"
            autocomplete="off"
        >
            <option
                *ngFor="let option of fieldOptions"
                value="{{option.id || option}}"
            >
                {{option.label || option}}
            </option>
        </select>

        <!--DatePicker-->
        <ng-container *ngIf="type === 'date'">
            <input
                type="text"
                class="form-field"
                [(ngModel)]="value"
                [name]="fieldName"
                [placeholder]="fieldPlaceholder"
                [required]="fieldIsRequired"
                [disabled]="fieldIsDisabled"
                autocomplete="off"
                [matDatepicker]="picker"
                #date
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </ng-container>

        <!--Readonly-->
        <div class="control-readonly" *ngIf="type === 'readonly'">
            {{value}}
        </div>

        <!--Help Text-->
        <div class="form-field-help-text" *ngIf="fieldHelpText">
            {{fieldHelpText}}
        </div>

        <!--Error-->
        <div class="form-field-error" *ngIf="hasError">{{fieldErrorText}}</div>
    </div>
</div>
