import { Component, EventEmitter, AfterViewInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import * as Recorder from 'recorderjs';
import * as getUserMedia from 'getusermedia';

import { AppDialogBrowserCompatibilityComponent } from '../../../../../../shared/dialogs/dialog-browser-compatibility/app.dialog-browser-compatibility.component';

@Component({
    selector: 'app-record-audio',
    templateUrl: './app.record-audio.component.html',
    styleUrls: ['./app.record-audio.component.less'],
})
export class AppRecordAudioComponent implements AfterViewInit {
    recorder: any;
    recordInterval: any;
    audioData: any;
    status = 'init'; // ['init', stopped', 'recording']
    isDisabled = true;
    isSupported = true;
    startTime = 0;
    duration = 0;
    offset = 0;
    displayTime = '00:00.00';

    @Output() saveRecording = new EventEmitter<any>();

    constructor(private sanitizer: DomSanitizer, private dialog: MatDialog) {}

    ngAfterViewInit() {
        getUserMedia({ audio: true }, (err, stream) => {
            if (err) {
                if (
                    typeof err.name !== 'undefined' &&
                    err.name === 'NotSupportedError'
                ) {
                    this.isSupported = false;
                }

                this.disableButtons(err);
            } else {
                this.processSteam(stream);
            }
        });
    }

    startRecording() {
        if (!this.isDisabled) {
            this.status = 'recording';
            this.audioData = undefined;
            this.recorder.record();
            this.recorder.recording = true;

            this.startTime = this.recorder.context.currentTime;
            this.recordInterval = setInterval(() => {
                if (this.recorder.recording) {
                    this.duration =
                        this.recorder.context.currentTime -
                        this.startTime +
                        this.offset;
                    const hours = Math.floor(this.duration / 3600),
                        minutes = Math.floor(this.duration / 60),
                        seconds = Math.floor(this.duration - minutes * 60),
                        micros = Math.floor((this.duration - seconds) * 100);
                    this.displayTime =
                        ('0' + minutes).slice(-2) +
                        ':' +
                        ('0' + seconds).slice(-2) +
                        '.' +
                        ('0' + micros).slice(-2);
                    if (hours) {
                        this.displayTime =
                            ('0' + hours).slice(-2) + ':' + this.displayTime;
                    }
                }
            }, 10);
        }
    }

    pauseRecording() {
        if (!this.isDisabled) {
            const pauseBtn = document.getElementById('pause');

            if (this.recorder.recording) {
                // pause
                this.recorder.stop();
                this.recorder.recording = false;
                this.offset = this.duration;
                pauseBtn.setAttribute('title', 'Resume');
                pauseBtn.innerHTML =
                    '<i class="material-icons icon">play_arrow</i>';
            } else {
                // resume
                this.recorder.record();
                this.recorder.recording = true;
                this.startTime = this.recorder.context.currentTime;
                pauseBtn.setAttribute('title', 'Pause');
                pauseBtn.innerHTML = '<i class="material-icons icon">pause</i>';
            }
        }
    }

    stopRecording() {
        if (!this.isDisabled) {
            this.status = 'stopped';
            this.recorder.exportWAV((blob) => {
                this.audioData = blob;
                this.recorder.stop();
                this.recorder.recording = false;
                clearInterval(this.recordInterval);

                const reader = new FileReader();
                reader.readAsDataURL(this.audioData);
                reader.onloadend = () => {
                    const processedAudioData =
                        this.sanitizer.bypassSecurityTrustResourceUrl(
                            typeof reader.result === 'string'
                                ? reader.result
                                : ''
                        );
                    const fileName =
                        'new-recording-' + new Date().getTime() + '.mp3';
                    this.saveRecording.emit({
                        audio: new File([this.audioData], fileName),
                        audioData: processedAudioData,
                    });
                    this.audioData = undefined;
                };
            }, 'audio/mpeg');
        }
    }

    private processSteam(stream) {
        const AudioContext =
            window['AudioContext'] || window['webkitAudioContext'] || false;
        if (AudioContext) {
            const context = new AudioContext();
            const mediaStreamSource = context.createMediaStreamSource(stream);
            this.recorder = new Recorder(mediaStreamSource);
            this.isDisabled = false;
        } else {
            this.dialog.open(AppDialogBrowserCompatibilityComponent, {
                width: '550px',
                disableClose: true,
            });
        }
    }

    private disableButtons(error) {
        this.isDisabled = true;
    }
}
